import React, { Component } from 'react';
import { SiigoSelectAtom } from '@siigo/siigo-select-atom-react'
import { countryOptions, statesOptions } from '../../data/data' 


class SiigoSelectPais extends Component {
    constructor(props) {
	    super(props);

        let citems = countryOptions;
        let sitems = statesOptions;

        if ((this.props.country != undefined)&&(this.props.state != undefined)&&(this.props.country > 0)&&(this.props.state > 0))
        {
            let objIndex = countryOptions.findIndex((obj => obj.id == this.props.country));
            citems[objIndex].selected = true;          
            
            sitems = statesOptions.filter(x => x.country == this.props.country);
            objIndex = sitems.findIndex((sobj => sobj.id == this.props.state));
            sitems[objIndex].selected = true;  
        }

        this.state = { 
            statesItems : sitems,
            countryItems: citems,
            country: this.props.country ?? 0,
            states: this.props.state ?? 0,
            test: ''
        };            
    } 

    changeName = (newname ) => {
        this.setState({
            ...this.state,
            test: newname
        });
    };

    onChangeCountry(e, val){
        console.log('onChangeCountry: ' + e.target.value);
        let citems = countryOptions;
        let items = statesOptions.filter(x => x.country == e.target.value);        
        let objIndex = countryOptions.findIndex((obj => obj.id == e.target.value));
        citems[objIndex].selected = true;

        this.setState({ ...this.state, 
                        country: e.target.value, 
                        statesItems: items, 
                        countryItems: citems 
                    })
       
    }

    onChangeStates(e, val){
        console.log('onChangeStates: ' + e.target.value);
        let sitems = this.state.statesItems;
        // sitems = sitems.map(a=>a.selected=false);
        let objIndex = sitems.findIndex((obj => obj.id == e.target.value));
        sitems[objIndex].selected = true;

        this.setState({ 
                        ...this.state, 
                        states: e.target.value, 
                        statesItems: sitems 
                    })
    }

    render(){
        return(
            <>
                <SiigoSelectAtom
                    label="País"
                    options={this.state.countryItems}
                    onChangeData={ (e, val) => {this.onChangeCountry(e, val)} }
                >
                </SiigoSelectAtom>        
                <hr className='siiger'></hr>
                <SiigoSelectAtom
                    label="Ciudad"
                    disabled={ this.props.disabled }
                    options={this.state.statesItems}
                    onChangeData={ (e, val) => {this.onChangeStates(e, val)} }
                >
                </SiigoSelectAtom> 
            </>
        )
    }
}

export default SiigoSelectPais;