import * as types from '../index';

export const getLogs = params => {
    return { type: types.GET_LOGS, params };
  };
  
export const getQueryLogs = params => {
  return { type: types.GET_QUERY_LOGS, params };
};

export const createLog = params => {
  return { type: types.CREATE_LOG, params };
}