import React, { Component } from 'react';
import MenuAdminComponent from '../../components/menuadmin'
class MenuAdminContainer extends Component {
  render() {
    return (
      <div>
          <MenuAdminComponent parentPath={this.props.path}/>
      </div>
    );
  }
}
export default MenuAdminContainer;