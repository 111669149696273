import { gql } from 'graphql-request';

export const deleteUserMutation = gql`
        mutation User(
            $userLogin: String!
            $tenantId: String!
            $source: String!
        ) {
            RemoveUser (
                userLogin: $userLogin
                tenantId: $tenantId
                source: $source
            ) {
                userId
            }
        }
    `;

export const getDeleteUserVariables = (data) => {
    return {
        userLogin: data.email,
        tenantId: data.tenantId,
        source: "Backoffice",
    }
}