export default function(api) {
  return {
    users: () => api.get('users'),
    userDetail: uri => api.get(`users/id/${uri}`),
    userPerfil: uri => api.get(`users/perfil/${uri}`),
    userAvatar: (avatar, uri) => api.put(`users/avatar/${uri}`, avatar),
    createUser: data => api.post('users', data),
    updateUser: (data, uri) => api.put(`users/id/${uri}`, data),
    deleteUser: uri => api.delete(`users/id/${uri}`),
    menuUser: uri => api.get(`users/menu/${uri}`)
  };
}
