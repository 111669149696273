import * as types from '../index';


export const setCampaign = (image) => {
  return { type: types.SAVE_CAMPAIGN, image };
};
export const getCampaignUrl = () => {
  return { type: types.GET_CAMPAIGN };
};
export const clearCampaignState = () => {
  return { type: types.CLEAR_CAMPAIGN_STATE };
};