
export default function (api) {
    return {
        getCountries: params => api.post('updateCountryFive9', params),
        addCountries: params => api.post('updateCountryFive9', params),
        editCountries: params => api.post('updateCountryFive9', params),
        deleteCountries: params => api.post('updateCountryFive9', params),
        getRules: params => api.post('updateRulesFive9', params),
        addRules: params => api.post('updateRulesFive9', params),
        editRules: params => api.post('updateRulesFive9', params),
        deleteRules: params => api.post('updateRulesFive9', params)
    };
}