import { object, string, bool, number } from 'yup';
import { COUNTRYS } from '../utils';

const formUserSchemaCo = (countryCode, t) => {
    return object().shape({
        firstName: string().min(3, "No debe tener menos de 3 caracteres", { value: 3 }).max(25, "No debe tener más de 25 caracteres", {value: 25}).matches(/^[a-zA-ZÁÉÍÓÚáéíóúñÑ0-9 +$/%*@?\-!_]+$/, "Nombre no valido").required("Este campo es requerido"),
        lastName: string().min(3, "No debe tener menos de 3 caracteres", { value: 3 }).max(25, "No debe tener más de 25 caracteres", {value: 25}).matches(/^[a-zA-ZÁÉÍÓÚáéíóúñÑ0-9 +$/%*@?\-!_]+$/, "Apellidos no validos").required("Este campo es requerido"),
        email: string().email("Comprueba el E-mail").max(100, "No debe tener más de 100 caracteres", { value: 100 }).matches(/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+\.[a-zA-Z0-9-_.]+$/, "No es un correo válido").required("Este campo es requerido"),
        identification: string().min(5, "No debe tener menos de 5 caracteres", { value: 5 }).max(12, "No debe tener más de 12 caracteres", {value: 12}).required("Este campo es requerido"),
        cellPhone: string().min(8, "No debe tener menos de 8 caracteres", { value: 8 }).max(15, "No debe tener más de 15 caracteres", {value: 15}).matches(/^[0-9]+$/, "No es un teléfono válido").required("Este campo es requerido"),
        role: number().oneOf([0, 1, 2, 3], "Selecciona un tipo de usuario").required("Este campo es requerido"),
        isAdmin: bool(),
        status: bool(),
    })
}

const formUserSchemaMx = (countryCode, t) => {
    return object().shape({
        firstName: string().min(2, "No debe tener menos de 2 caracteres", { value: 2 }).max(25, "No debe tener más de 25 caracteres", {value: 25}).matches(/^[a-zA-ZÁÉÍÓÚáéíóúñÑ0-9 +$/%*@?\-!_]+$/, "Nombre no valido").required("Este campo es requerido"),
        lastName: string().min(2, "No debe tener menos de 2 caracteres", { value: 2 }).max(25, "No debe tener más de 25 caracteres", {value: 25}).matches(/^[a-zA-ZÁÉÍÓÚáéíóúñÑ0-9 +$/%*@?\-!_]+$/, "Apellidos no validos").required("Este campo es requerido"),
        email: string().email("Comprueba el E-mail").max(100, "No debe tener más de 100 caracteres", { value: 100 }).matches(/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+\.[a-zA-Z0-9-_.]+$/, "No es un correo válido").required("Este campo es requerido"),
        identification: string().min(5, "No debe tener menos de 5 caracteres", { value: 5 }).max(13, "No debe tener más de 13 caracteres", {value: 13}).required("Este campo es requerido"),
        cellPhone: string().min(8, "No debe tener menos de 8 caracteres", { value: 8 }).max(15, "No debe tener más de 15 caracteres", {value: 15}).matches(/^[0-9]+$/, "No es un teléfono válido").required("Este campo es requerido"),
        role: number().oneOf([0, 1, 2, 3], "Selecciona un tipo de usuario").required("Este campo es requerido"),
        isAdmin: bool(),
        status: bool(),
    })
}


export const returnFormUserByCountry = (countryCode, t) => countryCode == COUNTRYS.MEXICO.CODE ? formUserSchemaMx(countryCode, t) : formUserSchemaCo(countryCode, t);