import React, { useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { SiigoInputAtom } from '@siigo/siigo-input-atom-react';
import { SiigoSelectAtom } from '@siigo/siigo-select-atom-react';
import { SiigoNotificationsInlineMolecule } from "@siigo/siigo-notifications-inline-molecule-react";

import { DATAOPTIONS, CONSTVALUES } from "./data";

const FormDataAuth = ({ authData, setAuthData, errorFormAuth, t, isEdit = false }) => {
    const handleChange = (e) => {
        setAuthData({
            ...authData,
            [e.target.name]: e.target.value
        })
    };

    return (
        <>
            <div className="column">
                <SiigoSelectAtom
                    style={{ width: '100%' }}
                    options={DATAOPTIONS.TYPEAUTHENTICATION}
                    name="method"
                    id="method"
                    label="Tipo de autenticación"
                    required
                    onClick={handleChange}
                    value={authData?.method}
                />
            </div>

            <div className="column">
                <SiigoInputAtom
                    type={isEdit ? "password" : "text"}
                    style={{ width: '100%' }}
                    align="left"
                    errorMessage="Error."
                    id="token"
                    label="Consumer Key"
                    name="consumerKey"
                    onBlur={handleChange}
                    value={authData?.consumerKey}
                />
            </div>

            <div className="column">
                <SiigoInputAtom
                    type={isEdit ? "password" : "text"}
                    style={{ width: '100%' }}
                    align="left"
                    errorMessage="Error."
                    id="token"
                    label="Consumer Secret"
                    name="consumerSecret"
                    onBlur={handleChange}
                    value={authData?.consumerSecret}
                />
            </div>

            <div className="column">
                <SiigoInputAtom
                    type={isEdit ? "password" : "text"}
                    style={{ width: '100%' }}
                    align="left"
                    errorMessage="Error."
                    id="token"
                    label="Access Token"
                    name="accessToken"
                    onBlur={handleChange}
                    value={authData?.accessToken}
                />
            </div>

            <div className="column">
                <SiigoInputAtom
                    type={isEdit ? "password" : "text"}
                    style={{ width: '100%' }}
                    align="left"
                    errorMessage="Error."
                    id="token"
                    label="Token Secret"
                    name="tokenSecret"
                    onBlur={handleChange}
                    value={authData?.tokenSecret}
                />
            </div>
            <div className="column">
                <SiigoInputAtom
                    type={isEdit ? "password" : "text"}
                    style={{ width: '100%' }}
                    align="left"
                    errorMessage="Error."
                    id="realm"
                    label="Realm"
                    name="realm"
                    onBlur={handleChange}
                    value={authData?.realm}
                />
            </div>
            {errorFormAuth ? (
                <div className="column">
                    <SiigoNotificationsInlineMolecule
                        style={{ width: "95%" }}
                        eventTimeout={90000}
                        textBody={{
                            text: t('dataSourceReport.errorValidation.validateFormAuth')
                        }}
                        type="error"
                    />
                </div>
            ) : null}
        </>
    );
}

export default withRouter(FormDataAuth);