import * as types from '../actions';

const activationManagementReducer = (state = { isLoading: true }, action = null) => {
  switch (action.type) {
    case types.SAVE_ACTIVATION_MANAGEMENT:
      return { ...state, isLoading: true, buttonAction: true };
      case types.SAVE_ACTIVATION_MANAGEMENT_RECEIVED:
      return { ...state, isLoading: false, fileResponse: action.data, menuActivated: true, buttonAction: false };
    case types.SAVE_ACTIVATION_MANAGEMENT_REQUEST_FAILED:
      return { ...state, isLoading: false, error: action.error, menuActivated: true, buttonAction: false };
    case types.CLEAR_ACTIVATION_MANAGEMENT_STATE:
      return { ...state, isLoading: false, error: null, fileResponse: null, buttonAction: false };

    case types.REMOVE_ACTIVATION_MANAGEMENT:
      return { ...state, isLoading: true, buttonAction: true };
    case types.REMOVE_ACTIVATION_MANAGEMENT_RECEIVED:
      return { ...state, isLoading: false, error: null, fileResponse: action.data, menuActivated: false, buttonAction: false };
    case types.REMOVE_ACTIVATION_MANAGEMENT_REQUEST_FAILED:
      return { ...state, isLoading: false, error: action.error, menuActivated: false, buttonAction: false };
    case types.CLEAR_REMOVE_ACTIVATION_MANAGEMENT_STATE:
      return { ...state, isLoading: false, error: null, fileResponse: null, buttonAction: false };
    default:
      return state;
  }
};

export default activationManagementReducer;
