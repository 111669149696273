import React from 'react';
import './modal.css';
import PropTypes from 'prop-types';

export default class Modal extends React.Component {

	onClose = (e) => {
		this.props.onClose && this.props.onClose(e);
		//this.props.hideFrom();
	};

	send = (e) => {
		e.preventDefault();
		this.props.addPackage(e);
	};


	render() {
		if (!this.props.show) {
		return null;
		}
		return (
		<div className="modal-parent">
			<div className="modal" id="modal">
			<div className="h2">
				<span>{this.props.title}</span>{' '}
				<i
				onClick={this.onClose}
				className=" siicon icon-close closeModal"
				></i>
			</div>
			<form onSubmit={this.send}>
				<div className="content">{this.props.children}</div>
				<div className="actions">
				<button className={!(this.props.loading)? "red btnCancelarModal" :"gray-fill ml20"} onClick={this.onClose}>
					Cancelar
				</button>
				<button type="submit" className={!(this.props.loading)? "green-fill btnPagarModal ml20" :"gray-fill ml20"} disabled = {this.props.loading}>		
					Adicionar paquete
				</button>
				</div>
			</form>
			</div>
		</div>
		);
	}
}
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
