export const accountantStates = {
    PENDING: 2, // Creacion local y envio de correo.
    CONFIRMED: 3, // Confirmado correo.
    PROVIDED: 4, // Market Creado
    SERIALIZED: 5, // Cuenta Synergy Creada 
    TASKED: 6, // Seguimiento creado
    MARKET_SERIALIZED: 7, // Serial Market Actualizado.
    ERROR_PENDING: 'E2', // Error al enviar correo
    ERROR_CONFIRMED: 'E3', // Error al confirmar correo.
    ERROR_PROVIDED: 'E4', // Error al aprovisionar
    ERROR_SERIALIZED: 'E5', // Error al crear cuenta en Synergy
    ERROR_TASKED: 'E6', // Error al crear seguimiento
    ERROR_MARKET_SERIALIZED: 'E7' // Error al actualizar serial
};

export const accountantStatesInvert = {
    "2": "PENDING", // Creacion local y envio de correo.
    "3": "CONFIRMED", // Confirmado correo.
    "4": "PROVIDED", // Market Creado
    "5": "SERIALIZED", // Cuenta Synergy Creada 
    "6": "TASKED", // Seguimiento creado
    "7": "MARKET_SERIALIZED", // Serial Market Actualizado.
    "E2": "ERROR_PENDING", // Error al enviar correo
    "E3": "ERROR_CONFIRMED", // Error al confirmar correo.
    "E4": "ERROR_PROVIDED", // Error al aprovisionar
    "E5": "ERROR_SERIALIZED", // Error al crear cuenta en Synergy
    "E6": "ERROR_TASKED", // Error al crear seguimiento
    "E7": "ERROR_MARKET_SERIALIZED" // Error al actualizar serial
};


export const statesClass = {
	"2": 'successState',
	"3": 'warningState',
	"4": 'warningState',
	"5": 'warningState',
	"6": 'warningState',
	"7": 'successState',
	"E2": 'errorState',
	"E3": 'errorState',
	"E4": 'errorState',
	"E5": 'errorState',
	"E6": 'errorState',
	"E7": 'errorState'
};

export const humanStates = {
    "2": '20 - Pendiente',
    "3": '30 - Confirmado',
    "4": '40 - Aprovisionado',
    "5": '50 - Serializado',
    "6": '60 - Creado seguimiento',
    "7": '70 - Market Serializado',
    "E2": 'E20 - Error en pendiente',
    "E3": 'E30 - Error en confirmado',
    "E4": 'E40 - Error en aprovisionado',
    "E5": 'E50 - Error en serializado',
    "E6": 'E60 - Error creando seguimiento',
    "E7": 'E70 - Error actualizando serial en Market'
};



export function getState(state) {
    state = String(state);        
    if (accountantStates[state] !== undefined) {        
        return accountantStates[state];
    } else {        
        state = accountantStatesInvert[state];
        state = accountantStates[state];        
        return state;
    }
}
