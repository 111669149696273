import React, { Component } from 'react';
import DataSourceGroups from '../../components/datasource/dataGroups';
import Layout from '../layout';

class DataSourceContainer extends Component {
  

	render() {
		return (	
			<div>
				<Layout route={'/datasource/create'} />
				<div>
					<section className={'container'}>
              			<div className="section">
							<DataSourceGroups  breadcrumbs = {[this.props.location.pathname]}/>
						</div>
					</section>
				</div>
			</div>
		);
	}
}

export default DataSourceContainer;
