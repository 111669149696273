import axios from 'axios';
import config from '../../config';
import { getCookie } from '../../utils/cookies';
export class AxiosConfig {

  static instanceAxios = axios.create({
    baseURL: config.url,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    timeout: 10000
  });

  static getConfigAxios = (configAxios = {}, auth = true) => {
    if(configAxios === null || configAxios === undefined || typeof configAxios !== 'object') return {};
    configAxios.headers = auth ? { Authorization: 'bearer ' + getCookie('token'), ...configAxios.headers } : configAxios.headers;
    return configAxios;
  }

  static getAllProvidersConfig = (name, configAxios = {}, auth = true) => {
    return AxiosConfig.instanceAxios.get(`aurora/provider?name=` + name, AxiosConfig.getConfigAxios(configAxios, auth));
  }

  static getProviderIdConfig = (id, configAxios = {}, auth = true) => {
    return AxiosConfig.instanceAxios.get("aurora/IdPayProvider/" + id, AxiosConfig.getConfigAxios(configAxios, auth));
  }

  static createProviderConfig = (obj, configAxios = {}, auth = true) => {
    return AxiosConfig.instanceAxios.post("aurora/addPayProvider", obj, AxiosConfig.getConfigAxios(configAxios, auth));
  }

  static updateProviderConfig = (obj, configAxios = {}, auth = true) => {
    return AxiosConfig.instanceAxios.put("aurora/updatePayProvider/" + obj._id, obj, AxiosConfig.getConfigAxios(configAxios, auth));
  }
}
