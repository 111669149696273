import React, { useState } from "react";
import { Button, TextField, MenuItem, makeStyles } from "@material-ui/core";
import Constants from "../../../constants";
import UrlHelper from "../../../helpers/url";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getCampaigns } from "../../../../../actions/shop/campaign";
import { connect } from "react-redux";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 150,
    maxWidth: 300,
  },
}));

function mapStateToProps(state) {
  return {
    error: state.shopReducer.error,
    campaigns: state.shopReducer.campaigns,
    isLoading: state.shopReducer.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return { getCampaignsAction: bindActionCreators(getCampaigns, dispatch) };
}

function SearchForm(props) {
  const { getCampaignsAction } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = new URLSearchParams(history?.location?.search);

  const [formData, setFormData] = useState(
    UrlHelper.getParsedQueryParams(
      Constants.SEARCH_CAMPAIGN_INPUTS_FORM,
      queryParams
    )
  );

  const formSubmit = (event) => {
    event.preventDefault();
    getCampaignsAction(formData);

    Object.entries(formData).forEach(([key, value]) => {
      queryParams.set(key, value);
    });
    history.push({
      search: queryParams.toString(),
    });
  };

  const inputFormOnChange = (property, value) => {
    const data = { ...formData };
    data[property] = value;
    setFormData(data);
  };

  return (
    <form autoComplete="off" className={props?.class} onSubmit={formSubmit}>
      <TextField
        name={Constants.SEARCH_CAMPAIGN_INPUTS_FORM.destination}
        select
        label={t("shop.campaigns.management.form.destination")}
        onChange={(event) => {
          inputFormOnChange(event.target?.name, event.target.value);
        }}
        className={classes.formControl}
        defaultValue={formData?.destination ?? ""}
      >
        {Object.values(Constants.CAMPAIGN_DESTINATIONS)?.map(
          (campaignDestination) => (
            <MenuItem
              key={campaignDestination?.VALUE}
              value={campaignDestination?.VALUE}
            >
              {campaignDestination?.LABEL}
            </MenuItem>
          )
        )}
      </TextField>
      <TextField
        name={Constants.SEARCH_CAMPAIGN_INPUTS_FORM.type}
        select
        label={t("shop.campaigns.management.form.type")}
        onChange={(event) => {
          inputFormOnChange(event.target?.name, event.target.value);
        }}
        className={classes.formControl}
        defaultValue={formData?.type ?? ""}
      >
        {Object.values(Constants.CAMPAIGN_TYPES)?.map((campaignType) => (
          <MenuItem key={campaignType?.VALUE} value={campaignType?.VALUE}>
            {campaignType?.LABEL}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        name={Constants.SEARCH_CAMPAIGN_INPUTS_FORM.code}
        label={t("shop.campaigns.management.form.code")}
        variant="standard"
        onChange={(event) => {
          inputFormOnChange(event.target?.name, event.target.value);
        }}
        inputProps={{ maxLength: Constants.MAX_INPUT_LENGTHS.TWENTY }}
        defaultValue={formData?.code ?? ""}
      />
      <Button
        color="primary"
        variant="contained"
        type="submit"
        disabled={formData?.destination && formData?.type ? false : true}
      >
        {t("shop.campaigns.management.form.consultButton")}
      </Button>
    </form>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
