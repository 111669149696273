import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/indexGeneral';
import config from '../config.js';

export function* getStatePayments(action) {
    const api = API.create(config.urlPaymentManager);
    try {
        const { params } = action;
        let orders = yield call(api.auroraPayment.getPayment, params);
        yield put({ type: types.GET_STATEPAYMENTS_RECEIVED, data: orders });
    } catch (error) {
        yield put({ type: types.GET_STATEPAYMENTS_FAILED, error });
    }
}
export function* clearGetPayments() {
    yield put({ type: types.GET_PAYMENTS_CLEARED });
}