import React, { Component } from 'react';
import CampaignComponent from '../../components/campaigns'
class CampaignsContainer extends Component {
  render() {
    return (
      <div>
          <CampaignComponent parentPath={this.props.path}/>
      </div>
    );
  }
}

export default CampaignsContainer;