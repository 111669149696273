import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import APIGeneral from '../services/api/indexGeneral';
import API from '../services/api/indexMarket';
import config from '../config.js';

export function* processGifts(action) {
	const api = API.create();

	try {
		api.setHeader('userdta', 'stark:iidkju===dk0');
		let auth = yield call(api.market.auth);		
		api.setHeader('auth', auth.data);
		const { data } = action;		
		let process = yield call(api.market.processNSN, data);	
		yield put({
			type: types.PROCESS_FILE_NSN_RECEIVED,
			data: process.data,
		});
	} catch (err) {
		yield put({ type: types.PROCESS_FILE_NSN_FAILED, err });
	}
}



export function* downloadFile(action) {	
	const api = APIGeneral.create(config.urlExcelApi);
	try {
		const { params } = action;		
		let auth = yield call(api.accountant.authExcel, { username: 'admin', password: 'admin' });		
		api.setHeader('authorizat ion', auth.data.token);
		let excel = yield call(api.accountant.sendExcelObject, params);		
		yield put({ type: types.SEND_EXCEL_OBJECT_RECEIVED, excel: excel });
	} catch (err) {
		yield put({ type: types.SEND_EXCEL_OBJECT_FAILED, err });
	}
}