import config from '../../../config';
export default function (api) {
  return {
    getAccountStatements: (params) =>
      api.get('getAccountStatement', {
        ...params,
        code: config.cod_account,
      }),
    sendEmail: (params) =>
      api.get('sendEmail', {
        ...params,
        code: config.cod_email,
      }),
  };
}
