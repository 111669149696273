import React from "react";
import UsersManagement from "../../components/market/usersManagement";
import { checkCookie } from "../../utils/cookies";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Layout from "../layout";

const UsersPageManagement = (props) => {
  return (
    <div>
      {checkCookie() !== null ? (
        <div>
          <Layout route="/market" />
          <section className={"container"}>
            <div className="section">
              <UsersManagement
                action={props}
                parentPath={"/market"}
                id={props.match.params.id}
              />
            </div>
          </section>
        </div>
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )}
    </div>
  );
}

export default withRouter(UsersPageManagement);
