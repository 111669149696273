import { put, call } from 'redux-saga/effects';
import * as types from '../actions'
import API from "../services/api/indexMail";


const api = API.create();

export function* getBlackListEmail(data) {
    try {                
        const response = yield call(api.mailActions.getBlackEmail, data.params);        
        yield put({ type: types.GET_BLACK_LIST_EMAIL_RECEIVED, emails: response });        
    } catch(error) {                
        yield put({ type: types.GET_BLACK_LIST_EMAIL_FAILED, error });
    }
}




export function* removeBlackListEmail(data) {
    try {                
        const response = yield call(api.mailActions.deteleBlackEmail, data.params);                
        yield put({ type: types.DELETE_BLACK_LIST_EMAIL_RECEIVED, remove: response });        
    } catch(error) {                
        yield put({ type: types.DELETE_BLACK_LIST_EMAIL_FAILED, remove: error });
    }
}


