import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as DataSourceActions from '../../actions/dataSource/dataSourceActions';

import Loader from '../tools/loader';
import FormDataGroup from './formDataGroup';

class DataSourceGroups extends Component {
	constructor(props) {
		super(props);
		this.state = {
			init: false
		};
	}

	componentDidMount() {
		this.props.actions.getDataSource({});
	}


	render() {

		const { isLoading, dataGroups, isLoadingNewGroup, groupCreated, t } = this.props;

		return (
			<div>
				{isLoading ? (
					<Loader/>
				) : (
					<FormDataGroup
						isLoading={isLoadingNewGroup}
						dataGroups={dataGroups}
						actions={this.props.actions}
						groupCreated={groupCreated}
						t={t}
					/>
				)}
			</div>
		);
	}
}

DataSourceGroups.propTypes = {
	actions: PropTypes.object.isRequired,
	dataGroups: PropTypes.object,
	error: PropTypes.object,
	planUpdate: PropTypes.object,
	modifyCompany: PropTypes.object,
};

const mapStateToProps = (state) => ({
	error: state.datasource.error,
	isLoading: state.datasource.isLoading,
	isLoadingNewGroup: state.datasource.isLoadingNewGroup,
	groupCreated: state.datasource.groupCreated,
	dataGroups: state.datasource.dataGroups,
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(DataSourceActions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(DataSourceGroups), withRouter(DataSourceGroups));