import * as types from '../index';

export const saveMenuActivate = params => {
  return { type: types.SAVE_ACTIVATION_MANAGEMENT, params };
};

export const removeMenuActivate = params => {
  return { type: types.REMOVE_ACTIVATION_MANAGEMENT, params };
};

export const clearMenuActivateState = () => {
  return { type: types.CLEAR_ACTIVATION_MANAGEMENT_STATE};
};

export const clearMenuRemoveState = () => {
  return { type: types.CLEAR_REMOVE_ACTIVATION_MANAGEMENT_STATE};
}
