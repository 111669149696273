import React, { Component } from 'react';

class Search extends Component{
    constructor(props, context){
        super(props, context);
        // const {data} = this.props
        this.state ={
            userData: this.props.data, 
        }
        this.filterData = this.filterData.bind(this);
    }

    filterData(e){
        let dataInput = e.target.value
        let number = e.target.value.length
        let usersData = this.props.data
        const partitionalInput = usersData.filter((item) => {
            const itemFirstName = item.firstName.toUpperCase()
            const itemLastName = item.lastName.toUpperCase()
            const itemEmail = item.email.toUpperCase()
            const groupSearch = itemFirstName+" "+itemLastName+" "+itemEmail
            const itemDataInput = dataInput.toUpperCase()
            return groupSearch.indexOf(itemDataInput) > -1
        })
        this.props.returnData(partitionalInput, number);
    }

    render() {
       
        return (
            <div className="row divFilter">
                <input
                type="text"
                onChange={this.filterData}
                name="name"
                id="name"
                className="inputFilter"
                placeholder="Busca aquí por nombre, apellido o correo "
                />
            </div>
        )
    }

}

export default Search