import getEndPoint from "../../../utils/endpoints";

export default function (api) {
    const urlExport = getEndPoint('exportExcelReport');

    return {
        getConfigReport: params => api.get('general-report/getConfigReport/' + params.id),
        filterDataBySource: params => api.post('general-report/filterDataBySource', params),
        downloadReportDataBySource: params => api.post(urlExport, params),
    };
}