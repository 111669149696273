import { CONSTVALUES, TYPES_AUTH } from "./data"

export const validateDataSearch = data => {
    if(data.dataSource.trim() === "" || data.nameReport.trim() === "" || data.typeConnection === null){
        return false;
    }

    if(
        data.typeConnection === CONSTVALUES.MICROSERVICE && 
        data.method.trim() === "" || 
        (data.method.trim() === "POST" && !data.bodyRequest.trim() === "")){
        return false;
    }

    if(
        data.typeConnection === CONSTVALUES.MICROSERVICE && 
        data.typeAuthenticate === TYPES_AUTH.NEW_AUTH && 
        data?.configAuth?.consumerKey.trim() === ""){
        return false;
    }

    return !(data.jsonColumns.some(item => typeof item === "object" && item.label.trim()===""));
    
}

export const validateDataForm = data => {
    const isValid = validateDataSearch(data);
    if(!isValid){
        return false;
    }

    return data.jsonColumns.length;
}

export const validateDataAuthForm = data => {
    const { method, consumerKey, consumerSecret, accessToken, tokenSecret } = data;
    if(method.trim() === "" || consumerKey.trim() === "" || consumerSecret.trim() === "" || accessToken.trim() === "" || tokenSecret.trim() === ""){
        return false;
    }
    return true;
}

export const resetForm = (
    data, 
    setFormDatasource, 
    setFilterData, 
    setExportExcel, 
    setExportPDF,
    setOptionsValueFilter,
    setOptionsCollection,
    setCollectionSelected,
    setDataColumns,
    setErrorFilter,
    setErrorValidSearch,
    setErrorForm
) => {
    setFormDatasource({
        typeConnection: null,
        dataSource: "",
        method: "",
        nameReport: "",
        parentGroupId: data.groupId,
        token: "",
        typeAuthenticate: "",
        viewCollection:"",
        userModified: data.userModified,
        fieldFilter: [],
        jsonColumns: [],
        exportsType: {
            excel: false,
            pdf: false
        }
    });

    setFilterData({
        type: "",
        value: ""
    });

    setExportExcel({
        checked: false,
        indeterminate: false,
        label: 'Excel',
        name: 'Op1'
    });

    setExportPDF({
        checked: false,
        indeterminate: false,
        label: 'PDF',
        name: 'Op2'
    });

    setOptionsValueFilter([]);
    setOptionsCollection([]);
    setCollectionSelected("");
    setDataColumns([]);
    setErrorFilter(false);
    setErrorValidSearch(false);
    setErrorForm(false);
}