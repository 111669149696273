import React, { useContext, useEffect, useState } from 'react';
import './topbar.scss';
import PropTypes from 'prop-types';
import { deleteCookie, getCookie } from '../../../utils/cookies';
import { Link } from 'react-router-dom';
import config from '../../../config.js';
import svgs from '../icons';
import { connect } from 'react-redux';
import {
  getCountries,
  getConfig,
} from '../../../actions/countries/countriesAction';
import CountryContext from '../../../context/countries-context.js';


const TopBar = ({
  dispatch,
  configInst,
  countries
}) => {
  const [menuList, setMenuList] = useState({});
  const [availableCountries, setAvailableCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countryContext = useContext(CountryContext);

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (countries?.data.length && (countries?.data.length !== availableCountries.length)) {
      setAvailableCountries(countries.data);
    }
  }, [countries, availableCountries]);

  useEffect(() => {
    if (availableCountries.length && (selectedCountry?.countryCode !== countryContext?.country)) {
      const selectedCountry = availableCountries.find((element) => element.countryCode === countryContext.country);
      setSelectedCountry(selectedCountry);
    }
  }, [countryContext, availableCountries]);

  useEffect(() => {
    if (selectedCountry) {
      fillDefault();
      countryContext.changeCountry(selectedCountry.countryCode);
      localStorage.setItem('country', selectedCountry.name);
      localStorage.setItem("countryConfigActive", selectedCountry._id);
      document.getElementById('countryItem1').checked = false;
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry) {
      const instance = selectedCountry?.instance?.find(element => element.default === "true");
      if (instance?._id)
        dispatch(getConfig(instance?._id));
    }
  }, [selectedCountry, countryContext?.country]);

  useEffect(() => {
    if (configInst?.data?.config.length) {
      countryContext.setInstanceId(configInst.data._id);
      countryContext.setEndpoints(configInst.data.config[0].endPoint);
    }
  }, [configInst, countryContext?.country])

  useEffect(() => {
    let menuList = getCookie('configuration');
    if (menuList  && menuList !== '[]') {
      setMenuList(JSON.parse(menuList));
    }
  }, [configInst, countryContext?.country]);

  const logout = () => {
    deleteCookie(['isSuccess', 'token', 'email', 'sub']);
    window.open(config.url + 'auth/azureadoauth2/logout', '_self');
  };

  const fillDefault = () => {
    if (selectedCountry) {
      document.getElementById('countryItemLabel').innerHTML =
        '<span>' +
        selectedCountry.name +
        '</span>' +
        "<img class='imgPais' src=" +
        svgs[selectedCountry.icon] +
        " alt='' />" +
        "<img class='icon-flecha-abajo' src=" +
        svgs['flecha_abajo'] +
        " alt='' />";

      let img = document.querySelector('label[for=emailItem1] .imgPais2');
      if (img != null) {
        img.remove()
      }
      let imgCurrent = document.querySelector('label[for=emailItem1]');
      imgCurrent.innerHTML = imgCurrent.innerHTML + "<img class='imgPais2' src=" + svgs[selectedCountry.icon] + " alt='' />";
    }
  };

  const changeCountry = (idCountry) => {
    const selectedCountry = availableCountries.find((element) => element._id === idCountry);
    setSelectedCountry(selectedCountry);
  };

  return (
    <div className="top-bar">
      <div className="container">
        <div className="search-bar">
        </div>
        <div className="user-action">
          <div className="right-box">
            <div className="account">
              <input type="checkbox" name="emailItem" id="emailItem1" />
              <label htmlFor="emailItem1">
                <img className="icon-flecha-abajo" src={svgs['flecha_abajo']} alt="" />
                <span>{getCookie('name')}</span>
              </label>
              <ul className="submenu">
                <li>
                  <Link to={'/perfil/' + getCookie('sub')}>{getCookie('name')}</Link>
                </li>
                <li>
                  <Link to={'/perfil/' + getCookie('sub')}>{getCookie('email')}</Link>
                </li>
                <li style={{ "marginLeft": '-1vh' }}>
                  <div className="countries">
                    <div>
                      <input type="checkbox" name="countryItem" id="countryItem1" />
                      <label id="countryItemLabel" htmlFor="countryItem1">
                        <span>Pais</span>
                        <img
                          className="icon-flecha-abajo"
                          src={svgs['flecha_abajo']}
                          alt=""
                        />
                      </label>
                      <ul className="submenuCountry">
                        {countries?.data.map((val, id) => (
                          <li key={id}>
                            <Link
                              id={'linkPais' + val._id}
                              onClick={(e) => changeCountry(val._id)}
                              to={'#'}
                            >
                              {val.name}
                              <img className="imgPais" src={svgs[val.icon]} alt={`flag${val.icon}`} />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </li>
                <hr />
                <li>
                  <div className='menu-admin'>
                    <ul>
                      {menuList && menuList.child ? menuList.child.map((menuItem, id) => (
                        <li key={id}>
                          <Link to={menuItem.path}>
                            <span>{menuItem.name}</span>
                          </Link>
                        </li>
                      )) : null}
                    </ul>
                  </div>
                </li>
                <li>
                  <div className='align-button'>
                    <button
                      className="button btn-logout"
                      type="button"
                      value="remove"
                      onClick={logout}
                    >
                      <i className="fa fa-trash"></i> Cerrar Sesión
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TopBar.propTypes = {
  actions: PropTypes.object,
  countries: PropTypes.object,
  error: PropTypes.object,
  configInst: PropTypes.object
};

const mapStateToProps = (state) => ({
  countries: state.countries.countries,
  configInst: state.countries.config,
  error: state.countries.err,
  isLoading: state.countries.isLoading,
});

export default connect(mapStateToProps)(TopBar);
