import React, { Component } from 'react';
import LeadsList from '../../components/leads';

class LeadsContainer extends Component {
  render() {
    return (
      <div>
        <LeadsList  parentPath={this.props.path}/>
      </div>
    );
  }
}

export default LeadsContainer;
