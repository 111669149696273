import * as types from '../actions';

const productListSynergy = (state = { isLoading: false }, action = null) => {
  switch (action.type) {

    case types.DOWNLOAD_FILE_SYNERGY:
        return { ...state, isLoading: true }
    case types.DOWNLOAD_FILE_SYNERGY_RECEIVED:
        return { ...state, isLoading: false, data: action.data }
    case types.DOWNLOAD_FILE_SYNERGY_FAILED:
        return { ...state, isLoading: false, data: action.error }    
        
        
    case types.SEND_EXCEL_OBJECT_SYNERGY:
        return { ...state, isLoading: true }
    case types.SEND_EXCEL_OBJECT_RECEIVED_SYNERGY:
        return { ...state, isLoading: false, updateData: action.updateData }
    case types.SEND_EXCEL_OBJECT_FAILED_SYNERGY:
        return { ...state, isLoading: false, updateData: action.error }


    default:
      return state;
  }
};

export default productListSynergy;
