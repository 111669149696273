import axios from 'axios';
import { CreateSiiger, AssingSiiger, uuidv4 } from '../schema/siiger'
import config from '../../../config';

let REACT_APP_SIIGER_API= config.reactAppSiigerApi;
let m_dataSource =config.reactAppSiigerDataSource;
let m_database = config.reactAppSiigerDatabase;
let m_collection = config.reactAppSiigerCollection;
let m_siiger_key = config.reactAppSiigerApiKey;

let REACT_APP_MSPROVIDER_EMAIL= config.reactAppSiigerMailproviderApi;
var Proxy = '';

let customConfig = {
    method: 'HEAD',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',     
      'Accept':'application/json, text/plain, */*', 
      'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS, DELETE',
      'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept",
      'Content-Type':  'application/json',      
      'api-key': m_siiger_key      
    },
    proxy: {
      host: Proxy,
    }
}; 


//Actualiza el documento Siiger
export function UpdateSiiger(s){
    console.log('UpdateSiiger')  

    let b = AssingSiiger(s);

    if (b.host.country == 0) { b.host.country = 1 };
    if (b.host.state == 0) { b.host.country = 1 };

    console.log("email: " + s.contact.email);
    console.log(b);

    let paramsUpdate = {
        dataSource: m_dataSource,
        database: m_database,
        collection: m_collection,
        filter: { "contact.email": s.contact.email },
        update: b
    };
    
    axios.post(Proxy + REACT_APP_SIIGER_API + 'updateOne', JSON.stringify(paramsUpdate), customConfig).then((response)  => {        
        console.log('SiigerHelper.updateOne: ' + response.status)
        console.log(response.data);
    }, []).catch(function (error) {
        console.log(error);
    });    
}

//Actualizo solo la data de host
export function UpdateSiigerPerfilHost(s){
  console.log('UpdateSiiger')  

  let b = AssingSiiger(s);

  console.log("email: " + s.contact.email);
  console.log(b);

  let paramsUpdate = {
      dataSource: m_dataSource,
      database: m_database,
      collection: m_collection,
      filter: { "contact.email": s.contact.email },
      update: { "$set": { "host": s.host } }
  };
  
  axios.post(Proxy + REACT_APP_SIIGER_API + 'updateOne', JSON.stringify(paramsUpdate), customConfig).then((response)  => {        
      console.log('SiigerHelper.updateOne: ' + response.status)
      console.log(response.data);
  }, []).catch(function (error) {
      console.log(error);
  });    
}

//Actualiza solo si es host o no Siiger
export function UpdateSiigerIsHost(s, bisHost){
  console.log('UpdateSiigerIsHost')  

  let b = AssingSiiger(s);

  console.log("email: " + s.contact.email);
  console.log(b);

  let paramsUpdate = {
      dataSource: m_dataSource,
      database: m_database,
      collection: m_collection,
      filter: { "contact.email": s.contact.email },
      update: { "$set": { "isHost": bisHost } }      
  };
  
  axios.post(Proxy + REACT_APP_SIIGER_API + 'updateOne', JSON.stringify(paramsUpdate), customConfig).then((response)  => {        
      console.log('SiigerHelper.updateOne: ' + response.status)
  }, []).catch(function (error) {
      console.log(error);
  });    
}

//Obtiene o crear un nuevo Siiger
export function GetOrCreateSiiger(s){
    console.log('GetOrCreateSiiger')  
    let paramsGet = {
        dataSource: m_dataSource,
        database: m_database,
        collection: m_collection,
        filter: { "contact.email": s.username }
    };    

    let TengoSiiger = false;
    //voy a buscar a ver si existe en la base 
    axios.post(Proxy + REACT_APP_SIIGER_API + 'findOne', JSON.stringify(paramsGet), customConfig).then((response)  => {      
      TengoSiiger = !!(response.data.document); //!!(response.data.document) evalúa a true si el objeto response.data tiene una propiedad document
      if (TengoSiiger){            
        localStorage.setItem("siiger", JSON.stringify(response.data.document));
      }           
    }, []).catch(function (error) {
      console.log(error);
    }).then((response)  => {    
    
    //Si no tengo siiger lo creo
      if (!TengoSiiger)
      {
        console.log("No tengo siiger.")
        let nuevoSiiger = CreateSiiger();
        nuevoSiiger.name = s.name;
        nuevoSiiger.picture = s.avatar;
        nuevoSiiger.contact.email = s.username; 

        AddSiiger(nuevoSiiger);
      }
    }, []);
}

export function GetSiigerByEmail(email){
    console.log('GetSiigerByEmail')  
    let paramsGet = {
        dataSource: m_dataSource,
        database: m_database,
        collection: m_collection,
        filter: { "contact.email": email }
    };  

    return axios.post(Proxy + REACT_APP_SIIGER_API + 'findOne', JSON.stringify(paramsGet), customConfig);
}

//Agregar un documento a siiger
export function AddSiiger(s)
{
    console.log('AddSiiger') 
    let paramsAdd = {
        dataSource: m_dataSource,
        database: m_database,
        collection: m_collection,
        document: s
      };

      axios.post(Proxy + REACT_APP_SIIGER_API + 'insertOne', JSON.stringify(paramsAdd), customConfig).then((response)  => {
        console.log('Se creo el siiger: ' + JSON.stringify(response.data));            
        localStorage.setItem("siiger", JSON.stringify(s));
      }, []).catch(function (error) {
        console.log(error);
      });  
}

export function SearchLodging(filter)
{
    console.log('SearchLodging');
    console.log('filter: ' + JSON.stringify(filter));

    //no me la juego con las fechas, no vaya a ser cosa que en un server de eeuu tome otro formato... 
    const desdeArray = filter.tdesde.split('/');
    const desdeDate = new Date(desdeArray[2],desdeArray[1],desdeArray[0]);
    var desde = `${'2023'}-${('01')}-${desdeDate.getDate().toString().padStart(2, '0')}`;
   
    //desdeDate.getFullYear()
    //desdeDate.getMonth()

    console.log(desde);

    const hastaArray = filter.thasta.split('/');
    const hastaDate = new Date(hastaArray[2],hastaArray[1],hastaArray[0]);
    var hasta = `${'2024'}-${('01')}-${hastaDate.getDate().toString().padStart(2, '0')}`;    
    console.log(hasta);

    //`${desdeDate.getFullYear()}-${(hastaDate.getMonth()).toString().padStart(2, '0')}

    let paramsSearchLodging = {
        dataSource: m_dataSource,
        database: m_database,
        collection: m_collection,
        filter: { "isHost": true, 
                  "host.country": filter.tpais, 
                  //"host.state": filter.tciudad, 
                  "host.place.peoplecount": {"$gte": filter.tcantidad},                 
                  // "host.place.enableFrom": {"$lte": desde}, //hay que corregir el tema de las fiesta
                  // "host.place.enableUntil": {"$gte": hasta},
                  "$or": [{"booking": {"$size":0} },{"booking.state": {"$nin": [1,2]} }] //dame los que no tengan booking, y si tienen, no tienen que ser reservado ni aceptado
                }                
    };

    //devuelvo la promesa para que construyan del otro lado.
    return axios.post(Proxy + REACT_APP_SIIGER_API + 'find', JSON.stringify(paramsSearchLodging), customConfig); 
}


//status 
// 0 - nada, sin estado, disponible
// 1 - Reservada
// 2 - Aceptada
// 3 - Rechazada
// 4 - Cancelada
// 5 - Finalizada

export function SiigerReservation(huespedEmail, hospedador, travel, status)
{
    console.log('SiigerReservation')  

    let b = AssingSiiger(hospedador);

    let reservation = {
        id: uuidv4(),
        guestEmail: huespedEmail,
        placeId: b.host.place[0].id,
        startPoint: 0,
        comment: "",
        dateFrom: travel.tdesde,
        dateUntil: travel.thasta,
        state: status
    };
    
    console.log("booking: " + b.booking)

    b.booking.push(reservation);

    let paramsUpdate = {
        dataSource: m_dataSource,
        database: m_database,
        collection: m_collection,
        filter: { "contact.email": b.contact.email },
        update: { "$set": { "booking": b.booking } }
    };  

    return axios.post(Proxy + REACT_APP_SIIGER_API + 'updateOne', JSON.stringify(paramsUpdate), customConfig).then((response)  => {        
      console.log('SiigerHelper.updateOne: ' + response.status)
      console.log(response.data);
    }, []);            
}

//Devuelve la lista de reservas de un email
export function SearchBooking(e)
{
  console.log('SearchBooking: ' + e);
  let paramsSearchLodging = {
      dataSource: m_dataSource,
      database: m_database,
      collection: m_collection,
      filter: { "$or": [{"booking.guestEmail": e}, {"contact.email": e}] }                
  };

  //devuelvo la promesa para que construyan del otro lado.
  return axios.post(Proxy + REACT_APP_SIIGER_API + 'find', JSON.stringify(paramsSearchLodging), customConfig);  
}

export function ChangeStateBooking(hostEmail, placeId, bookingId, bookingIndex, stateBooking)
{
  console.log('ChangeStateBooking');
  let paramsUpdate = {
    dataSource: m_dataSource,
    database: m_database,
    collection: m_collection,
    // filter: { "booking": { "$elemMatch": { "id": bookingId } } },
    // update: { "$set": { "booking.$.state": stateBooking } }
     filter: { "booking.id": bookingId  },
     update: { "$set": { "booking.$[elem].state": stateBooking } },
     arrayFilters : { "elem.id": bookingId }
  }

  return axios.post(Proxy + REACT_APP_SIIGER_API + 'updateOne2', JSON.stringify(paramsUpdate), customConfig);
}

export function SendEmail(data)
{
  console.log(JSON.stringify(data));

  let paramMsProvider = {
    "From":{
        "Name":"SIIGO S.A.",
        "Email":"siiger@siigo.com"
    },
    "Subject" : "Adopta un siiger - " + data.subject,
    "HTMLPart" : GenerateHTMLemail(data),
    "To":[
        {
          "Name":"Siiger huespéd",
          "Email": data.emailTo
        }
    ],
    "Cc":[
      {
        "Name":"Siiger Host",
        "Email": data.emailFrom
      }
    ]
  }

  let customConfigEmail = {
    headers: {
      'Content-Type': 'application/json'      
    }
  };   


  return axios.post(Proxy + REACT_APP_MSPROVIDER_EMAIL, JSON.stringify(paramMsProvider), customConfigEmail).then(() => {
     console.log('Email enviado')
  }).catch((error) => {
    console.log('ERROR enviando email: ' + error)
  });
}

function GenerateHTMLemail(d)
{
    let _html = '<div class="im"> '+
                '  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%"> '+
                '	 <tbody> '+
                '		<tr> '+
                '		   <td> '+
                '			  <table align="center" border="0" cellpadding="0" cellspacing="0" class="m_6656151693843709654row-content m_6656151693843709654stack" role="presentation" style="color:#000;width:500px" width="500"> '+
                '				 <tbody> '+
                '					<tr> '+
                '					   <td class="m_6656151693843709654column" style="font-weight:400;text-align:left;vertical-align:top;padding-top:5px;padding-bottom:5px;border-top:0;border-right:0;border-bottom:0;border-left:0" width="100%"> '+
                '						  <table border="0" cellpadding="10" cellspacing="0" role="presentation" style="word-break:break-word" width="100%"> '+
                '							 <tbody> '+
                '								<tr> '+
                '								   <td> '+
                '									  <div style="color:#000;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;font-weight:400;letter-spacing:0;line-height:120%;text-align:left"> '+
                '										 <p style="margin:0;margin-bottom:2px">Gracias por participar en el programa <strong>Adopta un Siiger</strong>!!!&nbsp;</p> '+

                '                    <p style="margin:0"><br>{intro_msg}</p> '+

                '									  </div> '+
                '								   </td> '+
                '								</tr> '+
                '							 </tbody> '+
                '						  </table> '+
                '						  <table border="0" cellpadding="10" cellspacing="0" role="presentation" style="word-break:break-word" width="100%"> '+
                '							 <tbody> '+
                '								<tr> '+
                '								   <td> '+
                '									  <div style="color:#000;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;font-weight:400;letter-spacing:0;line-height:120%;text-align:left"> '+
                '										 <p style="margin:0;margin-bottom:2px"><strong>Host</strong></p> '+
                '										 <p style="margin:0;margin-bottom:2px">&nbsp;</p> '+
                '										 <p style="margin:0;margin-bottom:2px">Nombre: {host_name}</p> '+
                '										 <p style="margin:0;margin-bottom:2px">Email:  {host_email}</p> '+
                '										 <p style="margin:0;margin-bottom:2px">Teléfono:  {host_Telefono}</p> '+
                '										 <p style="margin:0;margin-bottom:2px">Dirección:  {host_Direccion}</p> '+
                '										 <p style="margin:0;margin-bottom:2px; color:#FF0000;">Mensaje:  {host_mensaje}</p> '+
                '										 <p style="margin:0;margin-bottom:2px">&nbsp;</p> '+                  
                '										 <p style="margin:0;margin-bottom:2px"><br><strong>Huespéd</strong></p> '+
                '										 <p style="margin:0;margin-bottom:2px">&nbsp;</p> '+
                '										 <p style="margin:0;margin-bottom:2px">Nombre: {huesped_nombre}<br>Email:  {huesped_email}</p> '+
                '										 <p style="margin:0">Estadía: {huesped_estadia}&nbsp;<br><br><br> '+
                '											<strong>Nos vemos en la fiesta!!!!</strong><br><br><strong>Adopta un Siiger 2032</strong><br><br> '+
                '										 </p> '+
                '									  </div> '+
                '								   </td> '+
                '								</tr> '+
                '							 </tbody> '+
                '						  </table> '+
                '					   </td> '+
                '					</tr> '+
                '				 </tbody> '+
                '			  </table> '+
                '		   </td> '+
                '		</tr> '+
                '	 </tbody> '+
                '  </table> '+
                '  </table> '+
                '</div> ';

    _html = _html.replace('{host_name}', d.host.name);
    _html = _html.replace('{host_email}', d.emailFrom);

    if ((d.status != 3)&&(d.status != 4)) //cancelada o rechazada
    {
      _html = _html.replace('{host_Telefono}', d.telefono);
      _html = _html.replace('{host_Direccion}', d.direccion);
      _html = _html.replace('{intro_msg}', 'Estamos muy felices en que compartan la fiesta junto a nosotros.&nbsp;<br>Le enviamos los datos para que se pongan en contacto y afinen los detalles.');
    }
    else{
      _html = _html.replace('{host_Telefono}', d.telefono);
      _html = _html.replace('{host_Direccion}', d.direccion);
      _html = _html.replace('{intro_msg}', 'Esta vez no va a poder ser, el host, rechazo el alojamiento.&nbsp;<br>Por favor, busca otro.');      
    }


    _html = _html.replace('{host_mensaje}', d.msg);
    
    _html = _html.replace('{huesped_nombre}', d.guest.name);
    _html = _html.replace('{huesped_email}', d.guest.contact.email);
    _html = _html.replace('{huesped_estadia}', d.booking.dateFrom + ' - ' + d.booking.dateUntil);


    return _html;
}

export function GetMenuOptionSiiger(){

    let siigerMenu = {
        "access": "",
        "accessList": [],
        "child": [],
        "icon": "",
        "name": "Adopta un Siiger",
        "order": 20,
        "parent": [],
        "path": "/siigers"
    }

    return siigerMenu;
}