import React, { Component } from 'react';

import './siigerAlertMessages.scss'

class SiigerAlertMessages extends Component {

    constructor(props) {
        super(props);
    }

    render(){
        return(    
            <div className='siiger-alert-message'>
                <div className='row'>
                    <h4 className='siiger-alert-message-title' >{ this.props.title }</h4>
                </div>
                <div className='row'>
                    <label className='siiger-alert-message-label'>{ this.props.msg } </label>
                </div>
            </div>
        )
    }
}

export default SiigerAlertMessages;