export const tableHeaders = [
    { name: 'Fecha', selector: 'createdDate', sortable: true, filterDDL: true, money: false },    
    { name: 'Correo Electrónico', selector: 'email', sortable: true, filterDDL: true, money: false },
    { name: 'Motivo de bloqueo', selector: 'reason', sortable: true, filterDDL: true, money: false },
    { name: 'Cantidad de intentos', selector: 'hits', sortable: false, filterDDL: true, money: false },
];

export const reasons = {
    0: "Ninguno",
    1: "Bloqueado",
    2: "Hard Bounce",
    3: "Correo inválido",
    4: "Suppress-Bounce",
    5: "Bounced",
    6: "Soft Bounce",
    7: "Complained",
    8: "Unsubscribed"
}


export const toolTipReason = {
    "Ninguno": "El correo no presenta ningún estado de bloqueo",
    "Bloqueado": "Bloqueado",
    "Hard Bounce": "Existe una razón permanente por la cual no se pueden entregar los correos electrónicos.",
    "Correo inválido": "El correo contiene un formato inválido o caracteres especiales",
    "Suppress-Bounce": "Si una dirección de correo electrónico rebota una vez o tiene problemas temporales en el buzón demasiadas veces, se suprimirá el envío para evitar que seamos bloqueados por spam",
    "Bounced": "El buzón tiene un error permanente de entrega",
    "Soft Bounce": "El buzón tiene un problema temporal de entrega",
    "Complained": "Cliente nos reportó como SPAM",
    "Unsubscribed": "Cliente se dio de baja para recibir los correos electrónicos"
}


export const statesEnabledToRemove = [2, 4, 5, 6, 7, 8];