import * as types from "../actions";

const shopReducer = (state = { isLoading: false }, action = null) => {
  switch (action.type) {
    case types.GET_PRODUCTS:
      return { ...state, isLoading: true };

    case types.GET_PRODUCTS_RECEIVED:
      return { ...state, isLoading: false, products: action.products };

    case types.GET_PRODUCTS_FAILED:
      return { ...state, isLoading: false, error: action.error };

    case types.POST_CAMPAIGN:
      return { ...state, isLoading: true };

    case types.POST_CAMPAIGN_RECEIVED:
      return { ...state, isLoading: false, response: action.response };

    case types.POST_CAMPAIGN_FAILED:
      return { ...state, isLoading: false, error: action.error };

    case types.GET_CAMPAIGNS:
      return { ...state, isLoading: true };

    case types.GET_CAMPAIGNS_RECEIVED:
      return { ...state, isLoading: false, campaigns: action.campaigns };

    case types.GET_CAMPAIGNS_FAILED:
      return { ...state, isLoading: false, error: action.error };

    case types.PATCH_CAMPAIGNSTATE:
      return { ...state, isLoading: true };

    case types.PATCH_CAMPAIGNSTATE_RECEIVED:
      return { ...state, isLoading: false, response: action.response };

    case types.PATCH_CAMPAIGNSTATE_FAILED:
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};

export default shopReducer;
