import React, { useState, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input,
  ListSubheader,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Constants from "../../../constants";
import ProductHelper from "../../../helpers/products";
import Footer from "./footer";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 170,
    maxWidth: 300,
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  checkbox: {
    color: "lightgray",
  },
}));

export default function DiscountCodeDetails({
  products,
  campaignInformation,
  setCampaignInformation,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  /*
   * forceUpdate -> Método para forzar un nuevo render del componente,
   * esto con el objetivo de refrescar los cambios que se hagan sobre las filas de la tabla.
   */
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const initialCampaignConfiguration = [
    {
      id: 0,
      productCodes: [],
      discountPercentage: null,
      period: "",
      featuredProduct: false,
    },
  ];
  const [singleCampaignColumnAdded, setSingleCampaignColumnAdded] =
    useState(false);
  const [configurations, setConfigurations] = useState(
    campaignInformation?.configurations?.length
      ? campaignInformation?.configurations
      : initialCampaignConfiguration
  );
  const [productAvailable] = useState(
    ProductHelper.removeInactiveProducts(products)
  );

  const handleClickAddRow = () => {
    setConfigurations((previousState) => {
      let newState = [];
      newState = [...previousState];
      return newState;
    });
    setSingleCampaignColumnAdded(true);
  };
  const handleClickDeleteRow = () => {
    let newState = [
      {
        id: 0,
        productCodes: [],
        discountPercentage: null,
        period: "",
        featuredProduct: false,
      },
    ];
    setConfigurations(newState);
    setSingleCampaignColumnAdded(false);
    document.getElementById(`discountPercentage${newState.length - 1}`).value =
      null;
  };

  const handleChangeProperty = (property, value, index) => {
    setConfigurations((previousState) => {
      let newState = previousState;
      newState[index][property] = value;
      return newState;
    });
    forceUpdate();
  };

  const handleChangeProducts = (productCode, index) => {
    if (!productCode) return;
    setConfigurations((previousState) => {
      let newState = previousState;
      const productIndex =
        previousState[index].productCodes.indexOf(productCode);
      if (productIndex > -1) {
        newState[index].productCodes = previousState[index].productCodes.filter(
          (value) => value !== previousState[index].productCodes[productIndex]
        );
      } else {
        newState[index].productCodes.push(productCode);
      }
      return newState;
    });
    forceUpdate();
  };

  const makeRows = () => {
    return configurations.map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <p>{index + 1}</p>
        </TableCell>
        <TableCell>
          <FormControl className={classes.formControl}>
            <InputLabel
              id={`discount-code-products-label${index}`}
              shrink={true}
            >
              {t("shop.campaigns.create.form.discountCode.chooseProducts")}
            </InputLabel>
            <Select
              labelId={`discount-code-products-label${index}`}
              id={`discount-code-products-select${index}`}
              multiple
              value={row.productCodes}
              input={<Input />}
              renderValue={(codes) => {
                let productsSelectedName = [];
                codes.forEach((code) => {
                  const productInformation =
                    ProductHelper.getProductInformationByCode(products, code);
                  if (productInformation)
                    productsSelectedName.push(productInformation.name);
                });
                return productsSelectedName.join(", ");
              }}
            >
              {ProductHelper.groupProducts(productAvailable, true)?.map(
                (product) => (
                  <div>
                    <ListSubheader disableSticky={true}>
                      {product.name}
                    </ListSubheader>
                    {product.children.map((children) => (
                      <MenuItem
                        key={children.code}
                        data-code={children.code}
                        onClick={() =>
                          handleChangeProducts(children.code, index)
                        }
                      >
                        <Checkbox
                          checked={row.productCodes?.includes(children.code)}
                        />
                        <ListItemText primary={children.name} />
                      </MenuItem>
                    ))}
                  </div>
                )
              )}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <TextField
            id={`discountPercentage${index}`}
            label={t("shop.campaigns.create.form.discountAbbreviated")}
            variant="standard"
            type="number"
            onChange={(event) => {
              if (event.currentTarget.value <= 100) {
                handleChangeProperty(
                  Constants.EVENTS_PROPERTY_NAMES.discountPercentage,
                  event.currentTarget.value,
                  index
                );
              }
            }}
            value={row.discountPercentage}
          />
        </TableCell>
        <TableCell className={classes.button}>
          {!singleCampaignColumnAdded ? (
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={() => handleClickAddRow(index)}
              disabled={!(row.productCodes.length && row.discountPercentage)}
            >
              {t("shop.campaigns.create.form.add")}
            </Button>
          ) : (
            <IconButton
              variant="outlined"
              color="primary"
              aria-label="delete"
              onClick={() => handleClickDeleteRow()}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <ThemeProvider theme={Constants.SIIGO_THEME}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("shop.campaigns.create.form.counting")}</TableCell>
              <TableCell>
                {t("shop.campaigns.create.form.discountCode.product")}
              </TableCell>
              <TableCell>{t("shop.campaigns.create.form.discount")}</TableCell>
              <TableCell>{t("shop.campaigns.create.form.action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{makeRows()}</TableBody>
        </Table>
      </TableContainer>
      <Footer
        campaignInformation={{
          ...campaignInformation,
          configurations: configurations,
        }}
        setCampaignInformation={setCampaignInformation}
      />
    </ThemeProvider>
  );
}
