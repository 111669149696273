import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './internalRequests.scss';

const InternalRequestsComponent = (props) => {
  useEffect(() => {
    // componentDidMount logic here
  }, []);

  return (
    <div>
      <div id="single-spa-application:internalRequests" className="internalRequests"></div>
    </div>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InternalRequestsComponent);
