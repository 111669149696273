import React, { Component } from 'react';
import { Bar } from "react-chartjs-2";
import DataTime from "../../utils/currentTime";


const data = {
    labels: ['Premium', 'Promo Agosto', 'Emprendedor', 'Independiente', 'Personalzados'],
    datasets: [
        {
            label: 'Ventas mensuales por plan',
            backgroundColor: '#97bf19',
            borderColor: '#97bf19',
            borderWidth: 1,
            hoverBackgroundColor: '#97bf19',
            hoverBorderColor: '#FFBB00',
            data: [25000000, 22500000, 20000000, 1000000, 21000000, 35000000]
        }
    ]
};

class PlanReport extends Component {

    constructor(props){
        super(props);
        this.state = {
            filterType: 'Dinero',
            filterMonth: DataTime.locale.month
        }
    }

    render(){
        const options = {
            maintainAspectRatio: false,
            responsive: false,
            fullWidth: true,
            legend: {
                position: 'right',
            }
        };
        return (
            <div className="full-card report">
                <div className="card-header">
                    <div className="card-title">
                        <h4 className="text light-gray">Ventas por plan</h4>
                    </div>
                    <div className="card-ddl">
                        <div className="input-option">
                            <span></span>
                        </div>
                    </div>
                    <div className="card-ddl">
                        <div className="field">
                            <input type="text" name="filter-time" defaultValue={ this.state.filterMonth } disabled/>
                            <i className="arrow down"></i>
                        </div>
                        <div className="input-option">
                            <span></span>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="card is-4">
                        <Bar
                            width={700}
                            height={300}
                            data={data}
                            options={ options }
                        />
                    </div>
                    <div className="card is-4 resume-report">
                        <div className="box green border">
                            <h5 className="text light-gray">Total ventas</h5>
                            <h6 className='text green'>20.000.000</h6>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PlanReport;
