import React, { Component } from 'react';

import './withoutresult.scss';

class WithoutResult extends Component {

    constructor(props) {
	    super(props);
    }

    render(){
        return(
            <>
                <div className='container siiger'>
                <div className='scard'>
                    <div className='row align-center'>
                        <img className="img-without" src={ this.props.img } alt='Siiger' />                            
                    </div>            
                    <hr className="siiger"/>      
                    <div className='row align-center'>
                        <h3 className="msg-info">{ this.props.msg }  </h3>
                    </div>
                </div>
                </div>
            </>
        )
    }
}

export default WithoutResult;