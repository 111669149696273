let config={
    marketModal:{
        citysOptions:
            [
                {option:"Santiago de Chile",value:"Santiago de Chile"}
            ]
    },
    company:{
        btnComplements:{
            visible:true
        },
        btnPlanConverter:{
            visible:true
        },
        usersAcces:{
            visible:true
        },
        pos:{
            visible:true
        },
        payrollEmployees:{
            visible:true
        },
        factBase:{
            visible:true
        },
        factAditional:{
            visible:true
        },
        cardDocNE:{
            visible:false
        },
        cardDocFE:{
            visible:false
        }
        
    }
}

export default config;