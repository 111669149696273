import * as types from '../actions';

const recurrentPaymentReportReducer = (state = { isLoading: false }, action = null) => {
    
    switch (action.type) {
        case types.GET_RECURRENTPAYMENTS:
            return { ...state, isLoading: true };

        case types.GET_RECURRENTPAYMENTS_RECEIVED:
            return { ...state, isLoading: false, payments: action.data }

        case types.GET_RECURRENTPAYMENTS_FAILED:
            return { ...state, isLoading: false, payments: action.error }

        default:
            return state;
    }
};

export default recurrentPaymentReportReducer;