import * as types from '../actions';

const notificationsReducer = (state = { isLoading: false }, action = null) => {
	switch (action.type) {
		case types.GET_NOTIFICATIONS:
			return { ...state, isLoading: true };
		case types.NOTIFICATIONS_RECEIVED:
			return {
				...state,
				isLoading: false,
				notifications: action.data
			};
		case types.NOTIFICATIONS_REQUEST_FAILED:
			return { ...state, isLoading: false, notifications: action.error };

		case types.CREATE_NOTIFICATION:
			return { ...state, isLoading: true };
		case types.CREATE_NOTIFICATION_RECEIVED:
			return {
				...state,
				isLoading: false,
				notification: action.data
			};
		case types.CREATE_NOTIFICATION_REQUEST_FAILED:
			return { ...state, isLoading: false, notification: action.error };

		case types.EDIT_NOTIFICATION:
			return { ...state, isLoadingEdit: true };
		case types.EDIT_NOTIFICATION_RECEIVED:
			return {
				...state,
				isLoadingEdit: false,
				notificationEdit: action.data
			};
		case types.EDIT_NOTIFICATION_REQUEST_FAILED:
			return { ...state, isLoadingEdit: false, notificationEdit: action.error };

		case types.DELETE_NOTIFICATION:
			return { ...state, isLoadingDel: true };
		case types.DELETE_NOTIFICATION_RECEIVED:
			return {
				...state,
				isLoadingDel: false,
				notificationDel: action.data
			};
		case types.DELETE_NOTIFICATION_REQUEST_FAILED:
			return { ...state, isLoadingDel: false, notificationDel: action.error };

		case types.GET_ACCOUNTS:
			return { ...state, isLoading: true };
		case types.ACCOUNTS_RECEIVED:
			return {
				...state,
				isLoading: false,
				accounts: action.data
			};
		case types.ACCOUNTS_REQUEST_FAILED:
			return { ...state, isLoading: false, accounts: action.error };

		case types.SEND_EMAIL:
			return { ...state, isLoadingEmail: true };
		case types.SEND_EMAIL_RECEIVED:
			return {
				...state,
				isLoadingEmail: false,
				email: action.data
			};
		case types.SEND_EMAIL_REQUEST_FAILED:
			return { ...state, isLoadingEmail: false, email: action.error };

		case types.GET_ACCOUNTANTS:
			return { ...state, isLoading: true };
		case types.GET_ACCOUNTANTS_RECEIVED:
			return {
				...state,
				isLoading: false,
				accountants: action.data
			};
		case types.GET_ACCOUNTANTS_FAILED:
			return {
				...state,
				isLoading: false,
				accountants: action.error
			};

		case types.SEND_MASSIVE_EMAIL_ACCOUNTANT:
			return { ...state, isLoadingMassive: true };
		case types.SEND_MASSIVE_EMAIL_ACCOUNTANT_RECEIVED:
			return {
				...state,
				isLoadingMassive: false,
				massiveResendEmail: action.data
			};
		case types.SEND_MASSIVE_EMAIL_ACCOUNTANT_FAILED:
			return {
				...state,
				isLoadingMassive: false,
				massiveResendEmail: action.error
			};


		case types.MODIFY_ACCOUNTANT:
			return { ...state, isLoadingModify: true };
		case types.MODIFY_ACCOUNTANT_RECEIVED:
			return {
				...state,
				isLoadingModify: false,
				modifyAccountant: action.data
			};
		case types.MODIFY_ACCOUNTANT_FAILED:
			return {
				...state,
				isLoadingModify: false,
				modifyAccountant: action.error
			};

		case types.SEND_EMAIL_ACCOUNTANT:
			return { ...state, isLoadingEmail: true };
		case types.SEND_EMAIL_ACCOUNTANT_RECEIVED:
			return {
				...state,
				isLoadingEmail: false,
				email: action.data
			};
		case types.SEND_EMAIL_ACCOUNTANT_FAILED:
			return {
				...state,
				isLoadingEmail: false,
				email: action.error
			};

		case types.SEND_EXCEL_OBJECT:
			return { ...state, isLoading: true };
		case types.SEND_EXCEL_OBJECT_RECEIVED:
			return {
				...state,
				isLoading: false,
				excelData: action.data
			};
		case types.SEND_EXCEL_OBJECT_FAILED:
			return {
				...state,
				isLoading: false,
				excelData: action.error
			};

		default:
			return state;
	}
};

export default notificationsReducer;
