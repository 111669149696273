import React, { useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { SiigoInputAtom } from '@siigo/siigo-input-atom-react';
import { SiigoSelectAtom } from '@siigo/siigo-select-atom-react';
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import FormDataAuth from "./formDataAuth";

// Validations
import { validateDataAuthForm } from './validations';

const FormDataGroup = ({ isLoading, dataGroups, actions, groupCreated, t }) => {

    const [redirectBack, setRedirectBack] = useState(false);
    const [options, setOptions] = useState([]);
    const [newGroup, setNewGroup] = useState("");
    const [contiunue, setContiunue] = useState(false);
    const [viewFormNewGroup, setViewFormNewGroup] = useState(false);
    const [viewFormNewAuth, setViewFormNewAuth] = useState(false);
    const [groupSelected, setGroupSelected] = useState("");
    const [errorFormAuth, setErrorFormAuth] = useState(false);

    const [authData, setAuthData] = useState({
        method: '',
        consumerKey: '',
        consumerSecret: '',
        accessToken: '',
        tokenSecret: ''
    });

    useEffect(() => {
        if(dataGroups && dataGroups?.data){
            setOptions(dataGroups.data.map(item => (
                { label: item.name, value: item._id }
            )));
        }
    }, [dataGroups]);

    useEffect(() => {
        if(groupCreated !== null){
            setViewFormNewGroup(false);
            setOptions([
                ...options,
                {
                    label: groupCreated?.name,
                    value: groupCreated?._id
                }
            ]);

            setTimeout(() => {
                setGroupSelected(groupCreated?._id);
            }, 1000);
        }
    }, [groupCreated]);
    

    const addNewGroup = () => {

        if(viewFormNewAuth){
            const resultValidated = validateDataAuthForm(authData);
            if(!resultValidated){
                setErrorFormAuth(true);
                return;
            }
        }

        setErrorFormAuth(false);
        
        if(viewFormNewAuth){
            const resultValidated = validateDataAuthForm(authData);
            if(!resultValidated){
                setErrorFormAuth(true);
                return;
            }
        }

        setErrorFormAuth(false);

        // Crear
        actions.addDataSource({name: newGroup, configAuth: authData});

        // actions.getDataSource({});
    }

    const getGroupName = groupId => {
        const result = options.filter(item => item.value === groupId);
        const [ item ] = result;
        return item.label
    }

    return (
        <>
            {redirectBack ? (
                <Redirect 
                    to={{
                        pathname: "/datareport"
                    }}
                />
            ) : null}
            {contiunue ? (
                <Redirect 
                    to={{
                        pathname: "/datasource/create-form",
                        state: { 
                            groupId: groupSelected,
                            groupName: getGroupName(groupSelected)
                        }
                    }}
                />
            ) : null}
            <div className='row'>
                <div className='column2' style={{ display: "flex", justifyContent: "space-between" }}>
                    <SiigoButtonAtom
                        ariaLabel="click here"
                        color="primary-blue"
                        icon="fas fa-solid fa-arrow-left"
                        iconPosition="start"
                        onClick={() => setRedirectBack(true)}
                        size="m"
                        text="Regresar"
                        type="button"
                    />
                    <SiigoButtonAtom
                        ariaLabel="click here"
                        color="primary-green"
                        icon="fas fa-solid fa-arrow-right"
                        iconPosition="start"
                        onClick={() => setContiunue(true)}
                        size="m"
                        text="Continuar"
                        type="button"
                        disabled={groupSelected.trim() === ""}
                    />
                </div>
            </div>
            <div className='row mt-4'>
                <div className='column2'>
                    {viewFormNewGroup ? (
                        <SiigoInputAtom
                            style={{width: '100%'}}
                            align="left"
                            id="nameReport"
                            label="Nuevo Grupo"
                            name="newGroup"
                            onchange={e => setNewGroup(e.target.value)}
                            requiredMessage={t('dataSourceReport.errorValidation.requiredMessage')}
                            type="text"
                            value={newGroup}
                            required
                        />
                    ) : (
                    <>
                        <SiigoSelectAtom
                            style={{width: '100%'}}
                            options={options}
                            name="group"
                            errorMessage="Error."
                            id="type"
                            label="Grupo"
                            onChangeData={e => setGroupSelected(e.target.value)}
                            value={groupSelected}
                        />
                    </>
                    )}
                    
                </div>
                <div className='column2' style={{ display: "flex" }}>
                    <SiigoButtonAtom
                        ariaLabel="click here"
                        color={viewFormNewGroup ? "primary-green" : "primary-blue"}
                        icon={viewFormNewGroup ? "fas fa-solid fa-check" : "fas fa-solid fa-plus"}
                        iconPosition="start"
                        onClick={viewFormNewGroup ? () => addNewGroup() : () => setViewFormNewGroup(true)}
                        size="m"
                        type="button"
                        loading={isLoading}
                        id="validateButton"
                        disabled={viewFormNewGroup && newGroup.trim() === "" ? true : false}
                    />

                    {viewFormNewGroup ? (
                        <>
                            <SiigoButtonAtom
                                ariaLabel="click here"
                                iconPosition="start"
                                onClick={() => setViewFormNewAuth(!viewFormNewAuth)}
                                size="m"
                                id="ConfigAuth"
                                type="button"
                                loading={isLoading}
                                text="Configurar Auth"
                            />

                            <SiigoButtonAtom
                                ariaLabel="click here"
                                color="tertiary"
                                icon="fas fa-solid fa-times"
                                iconPosition="start"
                                onClick={() => {setViewFormNewGroup(false)}}
                                size="m"
                                type="button"
                            />
                        </>
                    ) : null}
                </div>
            </div>

            {viewFormNewAuth? 
            <>
                <FormDataAuth 
                    authData={authData}
                    setAuthData={setAuthData}
                    errorFormAuth={errorFormAuth}
                    t={t}
                />
            </> 
            : null}
        </>
    );
}
 
export default withRouter(FormDataGroup);