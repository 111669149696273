import { getPlanesComplements } from '../modalComplement/functionData'
import { productListCol, productListMex, LICENCE_TYPE, MODULE_ENUM, ALLOWED_NUMBER_OF_CASHIERS_FE, ALLOWED_NUMBER_OF_CASHIERS, PLANTYPE_FE } from '../plandata'

export const COUNTRYS = {
    COLOMBIA: {
        CODE: "co",
        ISO_CODE: 170,
        ABBREVIATION: "COL"
    },
    MEXICO: {
        CODE: "mx",
        ISO_CODE: 487,
        ABBREVIATION: "MEX"
    },
    ECUADOR: {
        CODE: "ec",
        ISO_CODE: 218,
        ABBREVIATION: "ECU"
    },
    PERU: {
        CODE: "pe",
        ISO_CODE: 604
    },
    CHILE: {
        CODE: "cl",
        ISO_CODE: 152
    },
}

export const getCountryAbbreviation = (countryCode) => {
    return Object.values(COUNTRYS).find((_country) => _country.CODE == countryCode)?.ABBREVIATION
}

export const disableDropdownControl = (createCompanySelectEnable, showPlanTypeList) => {
    let isDisable = true
    if (showPlanTypeList === true) {
        isDisable = false
        return isDisable
    }

    if (!createCompanySelectEnable || createCompanySelectEnable === "false") {
        isDisable = true
        return isDisable
    }

    return isDisable
}

export const returnProductsListByCountry = (country) => country == COUNTRYS.MEXICO.CODE ? productListMex : productListCol;

export const getLicense = (arr1, arr2) => {

    let ser = '';
    for(var i=0; i<arr1.length; i++){
        for(var j=0; j<arr2.length; j++){
			if(arr1[i].ItemCode == arr2[j].code){
			    ser = arr1[i].ItemCode;
				break;
			}
        }
    }

    return ser;
}

export const isPlanSubsTypeAvailable = (plan) => {
    const availableLicenceTypes = [
        LICENCE_TYPE.SIIGO_NUBE_INDEPENDIENTE.value,
        LICENCE_TYPE.SIIGO_NUBE_EMPRENDEDOR.value,
        LICENCE_TYPE.SIIGO_NUBE_PREMIUM.value,
        LICENCE_TYPE.SIIGO_FE_PRO.value,
        LICENCE_TYPE.CONTADOR.value,
        LICENCE_TYPE.SIIGO_NUBE_GESTION_PREMIUM.value,
        LICENCE_TYPE.SIIGO_NUBE_GESTION_INICIO.value,
        LICENCE_TYPE.SIIGO_NUBE_GESTION_INICIO_AVANZADO.value,
        LICENCE_TYPE.SIIGO_NUBE_FACTURACION.value,
        LICENCE_TYPE.SIIGO_NUBE_FACTURACION_DUO.value,
    ];
    return availableLicenceTypes.includes(parseInt(plan));
};


export const getCountryISOCode = (country) => {
    switch (country) {
        case COUNTRYS.COLOMBIA.CODE:
            return COUNTRYS.COLOMBIA.ISO_CODE
        case COUNTRYS.MEXICO.CODE:
            return COUNTRYS.MEXICO.ISO_CODE
        case COUNTRYS.ECUADOR.CODE:
            return COUNTRYS.ECUADOR.ISO_CODE
        case COUNTRYS.PERU.CODE:
            return COUNTRYS.PERU.ISO_CODE
        case COUNTRYS.CHILE.CODE:
            return COUNTRYS.CHILE.ISO_CODE
        default:
            return COUNTRYS.COLOMBIA.ISO_CODE
    }
}

export const getAddonComplements = (addOns) => {
    for (let addOn of addOns) {
        let planDetails;

        if (addOn.documentBase) {
            planDetails = getPlanesComplements(addOn.documentBase, true, addOn.type)
        } else {
            planDetails = getPlanesComplements(addOn.module, false, addOn.type);
        }

        if (planDetails && planDetails.length > 0) {
            addOn.namePlan = planDetails[0].name || "";
        }
    }

    return addOns;
}

export const cashiersLimitExceeded = (planType, module, additionalCashiers) => {
    if (planType === PLANTYPE_FE && module === MODULE_ENUM.POS_WEB_INITIAL && additionalCashiers > ALLOWED_NUMBER_OF_CASHIERS_FE.POS_WEB_INITIAL) {
        return { exceeded: true, limit: ALLOWED_NUMBER_OF_CASHIERS_FE.POS_WEB_INITIAL };
    }
    if (planType === PLANTYPE_FE && module === MODULE_ENUM.POS_WEB_ADVANCE && additionalCashiers > ALLOWED_NUMBER_OF_CASHIERS_FE.POS_WEB_ADVANCE) {
        return { exceeded: true, limit: ALLOWED_NUMBER_OF_CASHIERS_FE.POS_WEB_ADVANCE };
    }
    if (planType !== PLANTYPE_FE && module === MODULE_ENUM.POS_WEB_ADVANCE && additionalCashiers > ALLOWED_NUMBER_OF_CASHIERS) {
        return { exceeded: true, limit: ALLOWED_NUMBER_OF_CASHIERS };
    }
    return { exceeded: false, limit: 0 };
};

export const VALID_POS_WEB_CASHIERS = /^[0-9]{1,3}$/;

export const ALLOWED_ADMINISTRATOR_FOR_USER_ROLE = [0, 2]