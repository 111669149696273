import config from "../config";
import { getCookie } from "./cookies";

export function getCurrentDocumentBalanceUrl() {
    const url =  config.flagNewUrlPackage === 'true'
        ? config.newUrlPackage
        : config.urlPackage;
    const isAuth  =  config.flagNewUrlPackage === 'true'
        ? {
            'Authorization': `Bearer ${getCookie('access_token_nube')}`
        }
        : undefined
    return {
        url: url,
        auth: isAuth
    }
}