import './modal.scss';
import { SiigoInputAtom } from '@siigo/siigo-input-atom-react';
import { SiigoPanelModalMolecule } from "@siigo/siigo-panel-modal-molecule-react";
import React, { useState } from "react";
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import { changeUserCodeSchema } from './validations';
import { changeUserEmail } from './services';
import ModalResponse from '../../layout/modals/modalResponse';
import { StatusCodes } from 'http-status-codes';
import Loader from '../../tools/loader';
import ModalConfirmation from '../../layout/modals/modalConfirmation';

const ModalChangeUserEmail = ({ t, country, showModal, handleCloseModal, company, user, setFieldValue, setCompanyUser }) => {
    const [state, setState] = useState({
        userCode: "",
        validField: true,
        errorMessage: "",
        saveButtonDisabled: true,
        modalKey: 0,
        isSendingRequest: false,
    });

    const handleUserChange = (e) => {
        const { name, value } = e.target;
        changeUserCodeSchema(user.email).validate({ [name]: value })
            .then(() => setState({ ...state, [name]: value, validField: true, errorMessage: "", saveButtonDisabled: false }))
            .catch(error => setState({ ...state, [name]: value, validField: false, errorMessage: error.message, saveButtonDisabled: true }));
    };

    const handleSubmit = async () => {
        const { isConfirmed } = await ModalConfirmation(
            `${t('market.companyUsers.modal.changeUserEmail.popup.title')}`,
            `${t('market.companyUsers.modal.changeUserEmail.popup.accept')}`,
            `${t('market.companyUsers.modal.changeUserEmail.popup.cancel')}`,
            `<p>${t('market.companyUsers.modal.changeUserEmail.popup.text', {user: state.userCode})}</p>`,
        );
        if (!isConfirmed) return;
        setState({ ...state, isSendingRequest: true });
        const response = await changeUserEmail(country, user.email, state.userCode, user.companyKey, company.TenantId);
        if (response.success && response.status === StatusCodes.OK && (response.data.status || response.data.success)) {
            setFieldValue("email", state.userCode);
            setCompanyUser({ ...user, email: state.userCode });
            handleModalClose();
            ModalResponse({
                title: t("market.companyUsers.modal.changeUserEmail.success.title"),
                text: response?.isMultiUser ? t("market.companyUsers.modal.changeUserEmail.success.texMultiUser", {user: user.email}) : t("market.companyUsers.modal.changeUserEmail.success.text"),
                res: StatusCodes.OK,
            });
            return;
        }

        if (response.success && response.status === StatusCodes.OK && (!response.data.status || !response.data.success)) {
            setState({ ...state, validField: false, errorMessage: response.data.msg, saveButtonDisabled: true, isSendingRequest: false });
            ModalResponse({
                text: response.data.msg,
                res: StatusCodes.BAD_REQUEST,
            });
            return;
        }

        if (!response.success) {
            setState({ ...state, validField: false, errorMessage: response.data, saveButtonDisabled: true, isSendingRequest: false });
            ModalResponse({
                title: t("market.companyUsers.alerts.editError.title"),
                text: t("market.companyUsers.alerts.editError.text"),
                res: StatusCodes.BAD_REQUEST,
            });
            return;
        }
    };

    const handleModalClose = () => {
        setState({ userCode: "", validField: true, errorMessage: "", saveButtonDisabled: true, modalKey: state.modalKey + 1, isSendingRequest: false });
        handleCloseModal();
    };

    return (
        <SiigoPanelModalMolecule
            key={state.modalKey}
            openModal={showModal}
            onClosePanelModal={handleModalClose}
            title={t("market.companyUsers.modal.changeUserEmail.title")}
            className="change-user-email"
        >
            <div className="content">
                <p>{t("market.companyUsers.modal.changeUserEmail.description")} <strong>{user.email}</strong></p>
            </div>
            <div className="inputs">
                <SiigoInputAtom
                    type="text"
                    id="userCode"
                    align="left"
                    name="userCode"
                    label={t("market.companyUsers.modal.changeUserEmail.placeHolderUserCode")}
                    value={state.userCode}
                    onInput={handleUserChange}
                    required={true}
                    requiredMessage={t("market.companyUsers.modal.changeUserEmail.requiredInput")}
                    validField={state.validField}
                    errorMessage={state.errorMessage}
                />
            </div>
            <div className="actions">
                <SiigoButtonAtom
                    text={t("market.companyUsers.buttons.cancel")}
                    size="m"
                    color="secondary"
                    onClick={handleModalClose}
                />
                {state.isSendingRequest ? <Loader className="loader"/> : (
                    <SiigoButtonAtom
                        text={t("market.companyUsers.buttons.save")}
                        size="m"
                        disabled={state.saveButtonDisabled}
                        onClick={handleSubmit}
                    />
                )}
            </div>
        </SiigoPanelModalMolecule>
    );
};

export default ModalChangeUserEmail;