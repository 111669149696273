import React, { Component } from 'react';
import Avatar from '../../../assets/icons/avatar.png';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/icons/siigo-backOffice.svg';
import './menu.scss';
import { getCookie, setCookie } from '../../../utils/cookies';
import { getMenuUser } from '../../../actions/users/usersAction';
import { connect } from 'react-redux';
import config from '../../../config.js';

const MENU_ACCESS = 'menuAccess';
let menuAc = [];
let itemChecked = [];
class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClose: true,
            menuAccess: []
        };
    }

    componentDidMount() {
        this.props.dispatch(getMenuUser(getCookie('sub')));
    }

    fillPermisos = permisos => {
        if (permisos.length > 0) {
            let menuAccess = localStorage.getItem(MENU_ACCESS);
            if (!menuAccess || menuAccess == '[]')
                localStorage.setItem(MENU_ACCESS, JSON.stringify(permisos));
        }
    };

    fillAccess = access => {
        if (access.length > 0)
            localStorage.setItem('compA', JSON.stringify(access));
    };

    switchMenu = () => {
        this.props.validMenu("5%");
        this.setState({
            isClose: !this.state.isClose
        });
    };

    closeMenu = (e, element) => {
        let radio = document.querySelector('input[type=radio][name=' + element + ']:checked');
        if (radio != null) {
            radio.checked = false;
        }
    };

    clickOption = e => {
        const menuName = e.currentTarget.parentNode?.firstChild?.name;
        const menuId = e.currentTarget.parentNode?.firstChild?.id;
        const firstChild = e.currentTarget.nextSibling?.firstChild?.firstChild;
        const nextSibling = e.currentTarget.nextSibling?.firstChild?.firstChild.nextSibling;
        if (itemChecked[menuName] &&
            itemChecked[menuName] === menuId) {
            document.querySelector("input[type=radio][name=" + menuName + "][id=" + menuId + "]").checked = false;
            e.preventDefault();
            itemChecked[menuName] = 0;
        } else if (!e.currentTarget.parentNode?.firstChild?.checked) {
            itemChecked[menuName] = menuId;
            if (nextSibling == null && firstChild != null) {
                e.currentTarget.nextSibling?.firstChild?.firstChild.click();
            }
        }

        this.setState({
            isClose: true
        });

        this.props.validMenu('');
    };

    fillMenu = (menuL, classUL, parent) => {
        let items = 0;
        let classNext = '';
        if (classUL === 'nav__list') {
            classNext = 'group-list';
        } else if (classUL === 'group-list') {
            classNext = 'sub-group-list';
        }
        if (menuL) {
            return (
                <ul className={classUL}>
                    {menuL.map((menuItem, index) => {
                        menuItem.child.sort( (a,b) => a.order - b.order );
                        if (menuItem.path !== '#') {
                            menuAc.push(menuItem.path);
                            const name =
                                menuItem.path.slice(-1) == 's'
                                    ? menuItem.path.slice(0, -1)
                                    : menuItem.path;
                            menuAc.push(name + '/create');
                            menuAc.push(name + '/edit');
                        }
                        if (menuItem.order === -1 || parent === -1) {
                            if (menuItem.order === -1) {
                                setCookie('configuration', JSON.stringify(menuItem), 1);
                            }
                            if (menuItem.child.length > 0) {
                                this.fillMenu(menuItem.child, classNext, menuItem.order)
                            }
                        } else {
                            items = items + 1;
                            return (
                                <li className={this.props.route.path === menuItem.path ? 'active' : null} key={parent + "_" + items}>
                                    {menuItem.path === '#' ? (
                                        <input id={'item' + (parent + "_" + items)} name={"itemCheck" + classUL} type="radio" hidden />
                                    ) : null}
                                    {menuItem.path === '#' ? (
                                        <label onClick={e => this.clickOption(e)} htmlFor={'item' + (parent + "_" + items)}>
                                            {menuItem.icon && classUL === 'nav__list' ? (<i className={' siicon ' + menuItem.icon}></i>) :
                                                classUL === 'nav__list' ? <i className=' siicon icon-folder' ></i> : <i className=" siicon icon-arrow-rigth"></i>}
                                            <span>{menuItem.name}</span>
                                        </label>
                                    ) : (
                                        <Link onClick={e => this.closeMenu(e, "itemCheck" + classUL)} to={menuItem.path}>
                                            <span>{menuItem.name}</span>
                                        </Link>
                                    )}
                                    {menuItem.child.length > 0
                                        ? this.fillMenu(menuItem.child, classNext, items)
                                        : null}
                                </li>
                            );
                        }
                    })}
                </ul>
            );
        } else {
            return [];
        }
    };

    render() {
        let MenuList = [];

        if (this.props.response.utils.hasOwnProperty('menu')) {
            if (this.props.response.utils.menu.data.length > 0) {
                MenuList = this.props.response.utils.menu.data;
                this.fillAccess(MenuList);
            }
        }
        MenuList.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
        return (
            <div className={this.state.isClose ? 'side-menu' : 'side-menu  is-close'}>
                <div className="header-menu">
                    <div className="avatar">
                        {getCookie('avatar') !== '' &&
                            getCookie('avatar') !== 'undefined' ? (
                            <img
                                src={config.url + 'auth/avatar/' + getCookie('avatar')}
                                alt="avatar"
                            />
                        ) : (
                            <img src={Avatar} alt="avatar" />
                        )}
                        <span className="nameAvatar">{getCookie('name')}</span>
                    </div>
                    <div className="hamburguer" onClick={this.switchMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="nav-menu">
                    <nav className="nav" role="navigation">
                        {this.fillMenu(MenuList, 'nav__list', 0)}
                        {this.fillPermisos(menuAc)}
                    </nav>
                </div>
                <div className="bottom-logo">
                    <Link to={'//www.siigo.com'} target={'_blank'}>
                        <img src={Logo} alt="logo" />
                    </Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = response => ({
    response
});
export default connect(mapStateToProps)(Menu);
