
import React, { Component } from 'react';
import { SiigoButtonIconAtom } from "@siigo/siigo-button-icon-atom-react";
import { Link, Redirect } from 'react-router-dom'

class Sheadmodal extends Component {
    constructor(props) {
        super(props);
    }

    render(){

        return(
            <>
                <div className='row subsheader subsheader-modal'>   
                    <div className='col-1'>     
                        <Link to={ this.props.urlReturn } >
                            <SiigoButtonIconAtom
                                ariaLabel="ariaLabel"
                                color="tertiary"
                                floatingPosition="top-left"
                                href= { this.props.urlReturn } 
                                icon="fas fa-angle-left"   
                                name=""
                                size="xl"
                                type="button"
                            />
                        </Link>
                    </div>

                    <div className='col width-100'>
                        <label><b>{ this.props.caption } </b></label>
                    </div>
                </div>
            </>
        )
    }
}

export default Sheadmodal;