import React from 'react';
import { connect } from 'react-redux';
import './style.scss';

export default class ConfigList extends React.Component {
    render() {

        return this.props.resources.map((val, idx) => (
            <div key={idx + "resource"}>
                <div className="grid">
                    <div className="col grid2">
                        <div className="col-p input-container-accountant">
                            <label>Título</label>
                            <input
                                type="text"
                                name="title"
                                data-id={idx}
                                id={'title-' + idx}
                                value={this.props.resources[idx].title}
                                onChange={this.props.change}
                                placeholder=""
                                className="inputFilter customEnterText"
                                onFocus={(e) => this.props.focusInput(e)}
                                onBlur={(e) => this.props.blurInput(e)}
                                disabled={this.props.access}
                            />
                        </div>
                        <div className="col-p input-container-accountant">
                            <label>Nombre</label>
                            <input
                                type="text"
                                name="nameConfig"
                                data-id={idx}
                                id={'nameConfig-' + idx}
                                value={this.props.resources[idx].name}
                                onChange={this.props.change}
                                placeholder=""
                                className="inputFilter customEnterText"
                                onFocus={(e) => this.props.focusInput(e)}
                                onBlur={(e) => this.props.blurInput(e)}
                                disabled={this.props.access}
                            />
                        </div>

                        <div className="col-p input-container-accountant">
                            <label>Valor</label>
                            <input
                                type="text"
                                name="valueConfig"
                                data-id={idx}
                                id={'valueConfig-' + idx}
                                value={this.props.resources[idx].value}
                                onChange={this.props.change}
                                placeholder=""
                                className="inputFilter customEnterText"
                                onFocus={(e) => this.props.focusInput(e)}
                                onBlur={(e) => this.props.blurInput(e)}
                                disabled={this.props.access}
                            />
                        </div>

                        <div className="col-p input-container-accountant">
                            <label>Seleccione el Estado</label>
                            <select
                                name="state"
                                data-id={idx}
                                id={'state-' + idx}
                                value={this.props.resources[idx].state}
                                onChange={this.props.change}
                                className="slFilter customEnterText"
                                disabled={this.props.access}

                            >
                                <option value="">Ninguno</option>
                                <option className="successState" value="Active">Activo</option>
                                <option className="errorState" value="Inactive">Inactivo</option>
                            </select>
                        </div>
                        <div className="col-p input-container-accountant">
                            <label>Configuración (JSON)</label>
                            <textarea
                                type="text"
                                name="config"
                                rows="6"
                                data-id={idx}
                                id={'config-' + idx}
                                value={this.props.resources[idx].config ? this.props.resources[idx].config : ""}
                                onChange={this.props.change}
                                placeholder=""
                                className="inputFilter customEnterText"
                                onFocus={(e) => this.props.focusInput(e)}
                                onBlur={(e) => this.props.blurInput(e, false)}
                                disabled={this.props.access}
                            />
                        </div>
                        <div className="col-p input-container-accountant">
                            <label>Promociones (JSON)</label>
                            <textarea
                                type="text"
                                name="promo"
                                rows="6"
                                data-id={idx}
                                id={'promo-' + idx}
                                value={this.props.resources[idx].promo ? this.props.resources[idx].promo : ""}
                                onChange={this.props.change}
                                placeholder=""
                                className="inputFilter customEnterText"
                                onFocus={(e) => this.props.focusInput(e)}
                                onBlur={(e) => this.props.blurInput(e, false)}
                                disabled={this.props.access}
                            />
                        </div>
                    </div>
                    <div style={{ paddingTop: "50%", paddingBottom: "30%", paddingLeft: "20%" }} className="col-c">
                        <button
                            className="button yellow"
                            type="button"
                            data-id={idx}
                            value="remove"
                            onClick={this.props.remove}
                            disabled={this.props.access}
                        >
                            <i className="fa fa-trash"></i> Eliminar
                        </button>
                    </div>
                </div>
                <hr className="sep" />
            </div>
        ));
    }
}
