import React from 'react';
import InternalRequestsComponent from '../../components/internalRequests/index';

const InternalRequestsContainer = (props) => {
  return (
    <div>
      <InternalRequestsComponent parentPath={props.path} />
    </div>
  );
};

export default InternalRequestsContainer;
