import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/es';
import './style.scss';
import { formatDate, parseDate } from 'react-day-picker/moment';

export default class DynamicFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.setState({ ...this.props })
    }

    filterDataReport(e) {
        e.preventDefault()
        this.props.handleFilterReport();
    }

    handleFromChange(day, key) {

        let fromDate = new Date(day);
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        fromDate.setMilliseconds(0);
        this.setState({ [key]: fromDate });
        this.props.handleChangeInput({ [key]: fromDate.toISOString() });
    }

    handleToChange(day, key) {
        let toDate = new Date(day);
        toDate.setHours(23);
        toDate.setMinutes(59);
        toDate.setSeconds(59);
        toDate.setMilliseconds(999);
        this.setState({ [key]: toDate });
        this.props.handleChangeInput({ [key]: toDate.toISOString() });
    }

    handleChangeInput(e) {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
        this.props.handleChangeInput({ [e.target.name]: e.target.value });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.state) {
            this.setState({ ...this.setState, ...nextProps });
        }
    }

    renderDatePicker(label, paramName) {
        const from = this.state[`from_${paramName}`];

        const to = this.state[`to_${paramName}`];

        const modifiers = { start: from, end: to };

        return (

            <div key={paramName}>
                <label style={{ color: "#737373", marginBottom: "2%" }}>Filtrar por: {label || paramName}</label>

                <div className="InputFromTo">
                    <div className="itemSFDate">
                        <DayPickerInput
                            inputProps={{ className: "inputDatePicker datePickerFilter" }}
                            value={from}
                            key={`from_${paramName}`}
                            ref={from => { this.fromRef = from }}
                            placeholder="Desde"
                            format="LL"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                                selectedDays: [from, { from, to }],
                                disabledDays: { after: to },
                                toMonth: to,
                                modifiers,
                                numberOfMonths: 1,
                                onDayClick: () => this.toReft.getInput().focus(),
                                localeUtils: MomentLocaleUtils,
                                locale: "es"
                            }}
                            onDayChange={(day) => this.handleFromChange(day, `from_${paramName}`)}

                        />
                        <i className="arrow down"></i>
                    </div>
                    <div className="itemSFDate">
                        <DayPickerInput
                            inputProps={{ className: "inputDatePicker datePickerFilter" }}
                            ref={to => (this.toReft = to)}
                            value={to}
                            key={`to_${paramName}`}
                            placeholder="Hasta"
                            format="LL"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                                selectedDays: [from, { from, to }],
                                disabledDays: { before: from },
                                modifiers,
                                month: from,
                                fromMonth: from,
                                numberOfMonths: 1,
                                localeUtils: MomentLocaleUtils,
                                locale: "es"
                            }}
                            onDayChange={(day) => { this.handleToChange(day, `to_${paramName}`); }}
                        />
                        <i className="arrow down"></i>
                    </div>
                </div>
            </div>

        )
    }

    renderInputFilter(paramLabel, paramName) {
        return (
            <div key={paramName} className="input-container-accountant column4">
                <input
                    className="inputFilter"
                    id={paramName}
                    name={paramName}
                    type="text"
                    placeholder={"Buscar por: " + (paramLabel || paramName).replace("~", ", ")}
                    onChange={e => { this.handleChangeInput(e) }}
                    value={this.state[paramName] ? this.state[paramName] : ""}
                    maxLength="50"
                />
            </div>
        );
    }

    cleanFilter() {
        const filterConfig = this.state.fieldFilter;
        const arrayClean = {};

        filterConfig.forEach(item => {
            if (item.type === "date") {
                arrayClean[`from_${item.value}`] = null;
                arrayClean[`to_${item.value}`] = null;
            } else {
                arrayClean[`${item.keys ? item.keys.join("~") : item.value}`] = null;
            }
        });

        this.setState(arrayClean);
        this.props.handleChangeInput(arrayClean)
    }

    renderFilterBloq() {
        const filterConfig = this.state.fieldFilter;
        return (
            <div >
                <div className="row divFilter">
                    {
                        filterConfig.filter(item => item.type === "date").map(item => this.renderDatePicker(item.label, item.value))
                    }
                </div>
                <div className="row  divFilter">

                    {
                        filterConfig.filter(item => item.type !== "date").map(item => this.renderInputFilter(item.label, item.keys ? item.keys.join("~") : item.value))
                    }

                    <div className="column4">
                        <div className="row">
                            <div className="column2">
                                <button className="green-fill btnBuscar" id="submitFilterButton" type="submit">
                                    <div className="row">
                                        <div className="column2">
                                            <span className="siicon icon-search searchMarket"></span>
                                        </div>
                                        <div className="column2">
                                            <span>Buscar</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div title="Limpiar Filtros" className="column2">
                                <button
                                    onClick={() => { this.cleanFilter() }}
                                    className="orange-fill"
                                    type="button"
                                >
                                    Limpiar
                                </button>
                            </div>
                            {/* Boton para enviar exportar a Micro */}
                            { this.props.viewExportBtn ? (
                                <div title="Exportar" className="column2">
                                <button
                                    className="green-fill btnBuscar"
                                    id="submitExportButton"
                                    type="submit"
                                    onClick={() => this.props.handleDownload()}
                                > 
                                    <div className="row">
                                        <div className="column2">
                                            <span className="siicon icon-excel-white searchMarket"></span>
                                        </div>
                                        <div className="column2">
                                            <span>Exportar</span>
                                        </div>
                                    </div>
                                </button>
                            </div> ) : null }
                        </div>
                    </div>
                </div>
            </div>


        )
    }

    render() {
        return (
            <>
                {this.state.fieldFilter ?
                    <form onSubmit={(e) => this.filterDataReport(e)} style={{ width: "100%" }} >
                        {this.renderFilterBloq()}

                    </form>
                    : null}
            </>
        )
    }

}