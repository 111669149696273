import React from "react";
import { useTranslation } from "react-i18next";
import SearchForm from "./form/search";
import CampaignList from "./list";
import { makeStyles } from "@material-ui/core";
import InformationalFooter from "../informationalFooter";
import { getCampaigns } from "../../../../actions/shop/campaign";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  headerSection: {
    marginBottom: 30,
    "& > p": {
      marginBottom: 30,
      fontSize: 13
    }
  },
  footerSection: {
    marginTop: 30,
  },
}));

function mapStateToProps(state) {
  return {
    campaigns: state.shopReducer.campaigns,
  };
}

function mapDispatchToProps(dispatch) {
  return { getCampaignsAction: bindActionCreators(getCampaigns, dispatch) };
}

function CampaignManagement(props) {
  const { campaigns } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const footerContent = {
    text: {
      content: t("shop.campaigns.management.footer.information.content"),
      list: {
        children: [
          {
            text: t(
              "shop.campaigns.management.footer.information.list.children.first"
            ),
          },
          {
            text: t(
              "shop.campaigns.management.footer.information.list.children.second"
            ),
          },
        ],
      },
      size: 12,
    },
  };

  return (
    <div>
      <header className={classes.headerSection}>
        <h5>{t("shop.campaigns.management.title")}</h5>
        <p className={classes.headerSection.description}>{t("shop.campaigns.management.description")}</p>
        <SearchForm class={classes.root} />
      </header>
      <div>
        <CampaignList />
      </div>
      <div className={classes.footerSection}>
        {campaigns?.data?.length > 0 && (
          <InformationalFooter content={footerContent} />
        )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignManagement);
