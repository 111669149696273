import React, { Component } from 'react';
//import AuroraTokens from '../../components/auroraTokens';
import AuroraTokensComponent from '../../components/auroraTokens';


class AuroraTokensContainer extends Component {
  render() {
    return (
      <div>
        <AuroraTokensComponent />
      </div>
    );
  }
}

export default AuroraTokensContainer;