import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import APILocal from "../services/api";

const messageErrorBase = "<strong>Por favor contactar a un administrador</strong> para resolver el problema.";
const TIMEOUT_ERROR = "TIMEOUT_ERROR";
const TYPE_CON_MICRO = 2;

export function* getDataSource(action) {
	const api = APILocal.create();
	try {
		const { params } = action;
		let datasource = yield call(api.dataSource.dataSourceResponse, params);
		yield put({ type: types.DATASOURCE_RECEIVED, data: datasource });
	} catch (err) {
		yield put({ type: types.DATASOURCE_REQUEST_FAILED, err });
	}
}

export function* addDataSource(action) {
	const api = APILocal.create();
	try {
		const { params } = action;
		let datasource = yield call(api.dataSource.addDataSourceResponse, params);
		yield put({ type: types.ADD_DATASOURCE_RECEIVED, data: datasource });
	} catch (err) {
		yield put({ type: types.ADD_DATASOURCE_REQUEST_FAILED, err });
	}
}

export function* getProperties(action) {
	const api = APILocal.create();
	try {	
		const { params } = action;
		let datasource = yield call(api.dataSource.propertiesResponse, params);
		
		const is_timeout = isTimeoutError(datasource);
		
		if(is_timeout){
			const extraMsg = params.typeConnection === TYPE_CON_MICRO ? "El microservicio" : "La colección de mongo"; 
			const error = `${extraMsg} ha demorado mucho en dar respuesta. ${messageErrorBase}`;
			yield put({ type: types.PROPERTIES_REQUEST_FAILED, data: { error } });
			return;
		}
		
		if(datasource.status === 500){
			const extraMsg = params.typeConnection === TYPE_CON_MICRO ? "columnas del microservicio" : "colecciones de mongo"; 
			const error = `No se pudieron consultar las ${extraMsg} ${messageErrorBase}`;

			yield put({ type: types.PROPERTIES_REQUEST_FAILED, data: { error } });
		}else{
			yield put({ type: types.PROPERTIES_RECEIVED, data: datasource });
		}
	} catch (err) {
		yield put({ type: types.PROPERTIES_REQUEST_FAILED, err });
	}
}

export function* getCollection(action) {
	const api = APILocal.create();
	try {	
		const { params } = action;
		let datasource = yield call(api.dataSource.collectionResponse, params);
		
		if(datasource.status === 500){
			const error = `No se pudieron consultar las columnas de la colección de Mongo ${messageErrorBase}`
			yield put({ type: types.COLLECTION_REQUEST_FAILED, data: { error } });
		}else{
			yield put({ type: types.COLLECTION_RECEIVED, data: datasource });
		}

	} catch (err) {
		yield put({ type: types.COLLECTION_REQUEST_FAILED, err });
	}
}

export function* saveCollection(action) {
	const api = APILocal.create();
	try {
		const { params } = action;
		let datasource = yield call(api.dataSource.saveDataResponse, params);

		if(datasource.status === 500){
			const error = `Algo salió mal con la creación del reporte. ${messageErrorBase}`;
			yield put({ type: types.SAVE_DATA_REQUEST_FAILED, data: { error } });
		}else{
			yield put({ type: types.SAVE_DATA_RECEIVED, data: datasource });
		}
	} catch (err) {
		yield put({ type: types.SAVE_DATA_REQUEST_FAILED, err });
	}
}

export function* getReport(action) {
	const api = APILocal.create();
	try {	
		const { params } = action;
		let datasource = yield call(api.dataSource.getReportResponse, params);
		yield put({ type: types.REPORT_DATA_RECEIVED, data: datasource });
	} catch (err) {
		yield put({ type: types.REPORT_DATA_REQUEST_FAILED, err });
	}
}


export function* getDetailtDataSource(action){
	const api = APILocal.create();
	try {
		const { id } = action;
		const datasourceDetail = yield call(api.dataSource.getDetailDataSource, id);
		yield put({ type: types.GET_DETAIL_DATASOURCE_RECEIVED, data: datasourceDetail.data })
	} catch (error) {
		yield put({ type: types.GET_DETAIL_DATASOURCE_FAILED, error });
	}
}

export function* editDataSource(action){
	const api = APILocal.create();
	try {
		const { id, data } = action;
		const datasourceDetail = yield call(api.dataSource.editDataSource, id, data);
		if(datasourceDetail.status === 500){
			yield put({ type: types.EDIT_DATASOURCE_FAILED });
		}else{
			yield put({ type: types.EDIT_DATASOURCE_RECEIVED, data: datasourceDetail.data })
		}
	} catch (error) {
		yield put({ type: types.EDIT_DATASOURCE_FAILED, error });
	}
}

function isTimeoutError(response){
	return response?.problem === TIMEOUT_ERROR;
}