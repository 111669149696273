import React, { Component } from 'react';
import SurveyList from '../../components/surveys';
import Layout from '../layout';

class SurveysContainer extends Component {
  render() {
    return (
      <div>

      <section >
          <div className="section">
            <SurveyList parentPath={this.props.path} />
          </div>
        </section>

      </div>
    );
  }
}

export default SurveysContainer;
