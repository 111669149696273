import React, { Fragment, useContext, useEffect, useState } from "react";
import { rolUsersCompany, rolUsersCompanyEnum } from "./plandata";
import { SiigoDataTableMolecule } from "@siigo/siigo-data-table-molecule-react";
import * as MarketActions from "../../actions/market/marketAction";
import * as UsersDomainActions from "../../actions/usersDomain/usersDomainAction";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import ModalResponse from "../layout/modals/modalResponse";
import { connect } from "react-redux";
import { getAccess } from "./../../utils/access";
import { ACCESS } from "./access";
import ModalDelete from '../layout/modals/modalDelete';
import { COUNTRYS } from "./utils";
import CountryContext from "../../context/countries-context";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from 'prop-types';
import { uuidV4Generator } from "../../utils/randomizer";
import { useTranslation } from "react-i18next";
import { StatusCodes } from "http-status-codes";

const arrayData = (users = []) => {
  let usersArray = Array.isArray(users) ? users : (users?.data ? [users.data] : []);
  usersArray = usersArray?.map((user) => {
    user.roleName = rolUsersCompany[user.role] || "NA";
    user.isAdministrator = user.isAdmin === 1 ? "Sí" : "No";
    user.statusText = user.status ? "Activo" : "Inactivo";
    return user;
  });
  sessionStorage.setItem("users", JSON.stringify(users));
  return users;
};

const UsersManagement = (props) => {
  const [companyPrincipal] = useState(JSON.parse(localStorage.getItem("Company")));
  const history = useHistory();
  const location = useLocation();
  const countryContext = useContext(CountryContext);
  const { t } = useTranslation();

  useEffect(() => {
    switch (countryContext?.country) {
      case COUNTRYS.MEXICO.CODE:
        props?.actions.getUsersUsersDomain(companyPrincipal?.CloudTenantConnectionUser.split("_")[1])
        break;
      case COUNTRYS.COLOMBIA.CODE:
        props?.actions.getUsersCompanyMarket({
          data: props.id,
          meta: {
            country: countryContext.country,
            requestId: uuidV4Generator(),
          }
        })
        break;
      default:
        break;
    }
  }, [countryContext?.country]);

  useEffect(() => {
    if (props?.users?.length) localStorage.setItem("users", JSON.stringify(props.users));
  }, [props?.users])

  useEffect(() => {
    if (countryContext?.isCountryChanged()) {
      countryContext.setCountryChanged(false);
      window.location.href = "/market";
    }
  }, [countryContext]);

  useEffect(() => {
    initDataTable();
  }, [props.users, companyPrincipal]);

  const initDataTable = () => {
    const table = document.getElementsByTagName("siigo-data-table-molecule")[0];
    table?.setDefaultTemplate({
      actions: (value, args) => {
        return getActionEdit(value, args);
      },
    });
  };

  const createUserAction = (event) => {
    event.preventDefault();
    sessionStorage.removeItem("userEdit");
    const adminUrl = history.location.pathname;
    sessionStorage.removeItem("userEdit");
    history.push({
      pathname: `/company/${props.id}/user/create`,
      state: { from: location.state?.from, detail: location.state?.detail, company: companyPrincipal, adminUrl: adminUrl }
    })
  }

  const getActionEdit = (value, args) => {
    // Create elements html
    const buttonEdit = document.createElement("siigo-button-atom");
    const buttonDelete = document.createElement("siigo-button-atom");

    const divContainerActions = document.createElement("div");


    // Boton eliminar
    if (getAccess("/market", ACCESS.Users_Management_delete)) {
      buttonDelete.text = "Eliminar";
      buttonDelete.color = "primary-red";
      buttonDelete.size = "m";
      buttonDelete.addEventListener("click", function (e) {
        //Validacion para que no elimine un administrador
        const usersData = JSON.parse(sessionStorage.getItem("users"));
        if (args.item.role === rolUsersCompanyEnum.WITH_ACCESS && args.item.isAdmin === 1) {
          const existsOtherAdmins = usersData.some((currentUser) => currentUser.isAdmin === 1 && currentUser.role === rolUsersCompanyEnum.WITH_ACCESS && currentUser.userCode !== args.item.userCode && currentUser.status);
          if (!existsOtherAdmins) {
            return ModalResponse({
              text: t("market.companyUsers.validations.atLeastOneAdmin"),
              res: StatusCodes.INTERNAL_SERVER_ERROR,
            });
          }
        }
        args.id = companyPrincipal.UserNameKey;
        args.CloudTenantID = companyPrincipal.CloudTenantID;
        ModalDelete({
          actionDelete: (countryContext?.country === COUNTRYS.COLOMBIA.CODE) ? props.actions.deleteUsersCompanyMarket : props.actions.deleteUserUsersDomain,
          id: args,
          dataText: { title: 'Eliminación', text: "¿Confirmas que el usuario que deseas eliminar es " + args.item.userCode + "?" }
        })
      })
      divContainerActions.appendChild(buttonDelete);
    }

    if (getAccess("/market", ACCESS.Users_Management_edit)) {
      // Boton Editar
      buttonEdit.text = "Editar";
      buttonEdit.color = "primary-green";
      buttonEdit.size = "m";
      buttonEdit.addEventListener("click", function (e) {
        sessionStorage.setItem("userEdit", JSON.stringify(args.item));
        const adminUrl = history.location.pathname;
        history.push({
          pathname: `/company/${props.id}/user/edit`,
          state: { from: location.state?.from, detail: location.state?.detail, company: companyPrincipal, adminUrl: adminUrl }
        })
      });
      divContainerActions.appendChild(buttonEdit);
    }

    //Estilos del div de los botones
    divContainerActions.style.display = "flex";
    divContainerActions.style.width = "100%";
    divContainerActions.style.justifyContent = "space-around";

    return divContainerActions;
  };
  const allowedUsersAcces = (usersData) => {
    if (usersData && Array.isArray(usersData) && companyPrincipal?.AllowedUsers) {
      const rolAcess = 0;
      const isAdministrator = 1;
      return usersData.filter(data => data.role === rolAcess || data.isAdmin === isAdministrator).length >= companyPrincipal?.AllowedUsers;
    }
  }
  const handleBackClick = () => {
    const previusUrl = location.state?.detail;
    history.push({
      pathname: previusUrl,
      state: { from: location.state?.from, company: companyPrincipal }
    });
  };

  return (
    <Fragment>
      <div
        className="uxDetailDataFilterTitle flex-init"
        style={{ textAlign: "left", marginBottom: "30px" }}
      >
        <div className='header-company-detail sub-title'>
          <div className="sub-title">
            <a className="link-back" onClick={handleBackClick}>
              <i className="siicon icon-atras"></i>
              <span>{" Atrás | Usuarios " + companyPrincipal.CloudTenantCompanyKey}</span>
            </a>
          </div>
        </div>
        {getAccess("/market", ACCESS.Users_Management_create) &&
          <div className="button-create">
            <siigo-button-atom
              color="primary-green"
              onClick={createUserAction}
              name="create"
              size="m"
              text="Crear usuario"
              type="button"
            ></siigo-button-atom>
          </div>
        }
      </div>

      <SiigoDataTableMolecule
        onInit={initDataTable}
        select-rows={false}
        reorder={false}
        headers={[
          {
            text: "Correo de acceso",
            key: "userCode",
            iconType: "",
            type: "string",
            sortable: true,
            align: "center",
          },
          {
            text: "Estado",
            key: "statusText",
            iconType: "",
            type: "string",
            sortable: true,
            align: "center",
          },
          {
            text: "Es administrador",
            key: "isAdministrator",
            iconType: "",
            type: "string",
            sortable: true,
            align: "center",
          },
          {
            text: "Tipo de usuario",
            key: "roleName",
            iconType: "",
            type: "string",
            sortable: true,
            align: "center",
          },
          {
            text: "Acciones",
            key: "actions",
            iconType: "",
            type: "string",
            align: "center",
          },
        ]}
        data={props.users && arrayData(props.users)}
      />
    </Fragment >
  );
};

UsersManagement.propTypes = {
  id: PropTypes.string.isRequired,
  actions: PropTypes.object,
  users: PropTypes.array
}

const mapStateToProps = (state) => ({
  error: (state?.market) ? state.market.err : state.usersDomain.err,
  isLoading: (state?.market) ? state.market.isLoading : state.usersDomain.isLoading,
  users: (state?.market && state?.market?.users) ? state.market.users : state.usersDomain.users,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...MarketActions, ...UsersDomainActions }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UsersManagement));
