import charts from './svg/siigo-indicadores.svg';
import user from './svg/siigo-usuario.svg';
import flecha_abajo from './svg/siigo-flecha-abajo.svg';
import orden from './svg/siigo-filtro-orden.svg';
import editar from './svg/siigo-editar.svg';
import eliminar from './svg/siigo-eliminar.svg';
import mas from './svg/siigo-mas.svg';
import cerrar from './svg/siigo-cerrar.svg';
import colombia from './svg/colombia.svg';
import peru from './svg/peru.svg';
import chile from './svg/chile.svg';
import ecuador from './svg/ecuador.svg';
import mexico from './svg/mexico.svg';

const svgs = {
  // Para este ejemplo usaremos tres íconos de redes sociales
  charts: charts,
  user: user,
  flecha_abajo: flecha_abajo,
  orden: orden,
  editar: editar,
  eliminar: eliminar,
  mas: mas,
  cerrar: cerrar,
  peru: peru,
  chile: chile,
  colombia: colombia,
  default: null,
  ecuador: ecuador,
  mexico: mexico
};

export default svgs;
