export const errorCodeEnum = {
  // Límite máximo de registros
  limitRecordsError: "MSMS01",
  // Formato no soportado
  unsupportedFormatError: "MSMS02",
  // Registros duplicados
  duplicateRecordsError: "MSMS03",
  // Token inválido
  invalidTokenError: "MSMS04",
  // Estructura inválida
  invalidFileStructureError: "MSMS05",
};