import React from 'react'
import { getAccess } from '../../utils/access';
import { ACCESS } from './access';
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import { useTranslation } from 'react-i18next';
const PATH_COMPONENT = '/market'

const CardDocuments = ({ title, acronym, documentsbase, documentsAditionals }) => {
    const { t } = useTranslation();

    return (
        <div className='companyDetail-documents'>
            <p className='title-documents'>{title}</p>
            <div className='content'>
                {documentsbase && documentsbase.title &&
                    <div className='num-documents'>
                        <p>{documentsbase.title}</p>
                        <div className='row'>
                            <span>{documentsbase.documentsQuantity}</span>
                        </div>
                    </div>
                }
                {documentsAditionals && documentsAditionals.title &&
                    <div className='documents-aditionals'>
                        <div className='title'>
                            <p>{documentsAditionals?.title}</p>
                            {documentsAditionals?.tooltip &&
                                <div className="tooltip">
                                    <span className="tooltiptext">{documentsAditionals?.tooltip}</span>
                                    <div className="icon-tooltip">
                                        <label className="siicon icon-question">
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className='row'>
                            <span>{documentsAditionals.documentsQuantity}</span>
                            {getAccess(PATH_COMPONENT, ACCESS['Show_details_' + acronym]) &&
                                <SiigoButtonAtom
                                    className='right-buttons'
                                    color="secondary"
                                    text={t('market.companyDetail.billingDocuments.viewDetail')}
                                    onClick={(e) => documentsAditionals.viewDetails(e)}
                                />
                            }
                            {getAccess(PATH_COMPONENT, ACCESS['Add_pack_' + acronym]) &&
                                <SiigoButtonAtom
                                    className='right-buttons'
                                    color="secondary"
                                    text={t('market.companyDetail.billingDocuments.addDocuments', { value: acronym })}
                                    onClick={(e) => documentsAditionals.addDocuments(e)}
                                />
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}


export default CardDocuments;