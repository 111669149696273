import React, { Component } from 'react';
import NotificationList from '../../components/notificationAS';

class NotificationContainer extends Component {
  render() {
    return (
      <div>
        <NotificationList parentPath={this.props.path}/>
      </div>
    );
  }
}

export default NotificationContainer;
