import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './w2cRules.scss';
import * as Five9Actions from '../../../actions/five9/five9Rules';
import { bindActionCreators } from 'redux';
import TableReport from '../../tableReport';
import ModalRules from './modalRules'
import { listHeadersRules, statusActions } from '../util'
import DeleteModal from "../deleteModal/deleteModal";
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import { ACCESS } from '../access';
import { getAccess } from './../../../utils/access';
import { SiigoNotificationGrowlAtom } from "@siigo/siigo-notification-growl-atom-react";

const GET_DATA = 1;
const INSERT_ACTION = 2;
const UPDATE_ACTION = 3;
const SOURCE_RULES = "SOURCE_RULES";
const NOTIFICATION = "five9Notification";


class w2cRules extends Component {

	constructor(props) {
		super(props);
		this.state = {
			listRules: [],
			showDeleteModal: false,
			dataToDelete: null,
			ruleSelect: {},
			showModal: false,
			actionSelected: INSERT_ACTION,
			showNotification: false,
			detailNotification: {}
		}
		this.showDelete = this.showDelete.bind(this);
	}

	changeStateModal = () => {
		return this.state.showModal ? this.setState({ showModal: false }) : this.setState({ showModal: true })
	}

	loadRuleInformation = () => {
		this.props.actions.getRulesFive9({ action: GET_DATA });
	}

	loadCountryInformation = () => {
		this.props.actions.getCountryFive9({ action: GET_DATA });
	}

	preLoadInformation = (data) => {
		let tempList = [...this.state.listRules];
		tempList.push(data);
		this.setState({ listRules: tempList });
	}

	notificationControl = (option) => {
		const res = statusActions[option].success
		this.setState({ detailNotification: res })
	}
	componentDidMount() {
		this.loadRuleInformation();
		this.loadCountryInformation();
		const notification = localStorage.getItem(NOTIFICATION);
		if (notification) {
			this.notificationControl(notification)
			this.showNotification();
			localStorage.setItem(NOTIFICATION, "")
		}
	}

	showDelete = (active = false) => {
		if (active) return this.setState({ showDeleteModal: true });
		return this.setState({ showDeleteModal: false });
	}

	showNotification = (control = false) => {
		if (control) return this.setState({ showNotification: !control });
		return setTimeout(() => {
			this.setState({ showNotification: !control });
		}, 0);
	}
	setupDataToDelete = (data) => {
		this.setState({ dataToDelete: data });
	}
	launchNotificationUnauthorized = () => {
		this.notificationControl("unauthorized_user")
		this.showNotification();
		this.setState({ showNotification: false })
		return null;
	}

	tableActions = {
		btnDropDown: "btn-dropdown",
		type: "btn-dropdown",
		label: "Editar",
		btnOptions: [
			{
				label: "Eliminar",
				id: 1,
			}
		],
		clickBtn:
			getAccess(this.props.parentPath, ACCESS.edit_rule) ? (
				(item) => {
					if (item._id) {
						this.setState({ actionSelected: UPDATE_ACTION, ruleSelect: item });
						this.changeStateModal();
					}
				}

			) : (
				(item) => {
					this.launchNotificationUnauthorized("unauthorized_user");
				}

			),
		clickOption: (item, data) => {
			let deletePermission = getAccess(this.props.parentPath, ACCESS.delete_rule);
			/**
			 * Se asigna la posición 0 ya que el contexto del componente siempre retorna un 
			 * array de una unica posición con la información de la opción seleccionada
			 */
			let [detail] = data?.detail;
			switch (detail?.id) {
				case 1:
					if (deletePermission) {
						this.setupDataToDelete(item._id)
						this.showDelete(true)
					} else {
						this.launchNotificationUnauthorized("unauthorized_user");
					}
					break;
				default:
					break
			}
		}
	};

	deleteItem(data) {
	}
	showModalComplement = () => {
		this.setState({ actionSelected: INSERT_ACTION, ruleSelect: {} });
		this.changeStateModal();
	}

	notificationMessage = (message = null, time = 5000) =>
		message && (
			<SiigoNotificationGrowlAtom
				className="notification-box"
				timeout={time}
				notification={message}
				position={"bottom-right"}
			/>
		);

	transformDataToTable = (listRules, listCountries) => {
		if (listRules && Array.isArray(listRules) && listCountries && Array.isArray(listCountries)) {
			return listRules.map(rule => {
				let nameCountry = listCountries.find(country => country._id === rule.country || country.country === rule.country);
				rule.country = nameCountry ? nameCountry.country : "País inexistente"; //rule.country;
				rule.status = rule.status === "true" || rule.status === 'Activo' ? "Activo" : "Inactivo";
				return rule;
			});
		}
	}

	render() {
		let { listRules, listCountries } = this.props;
		this.transformDataToTable(listRules?.data?.data, listCountries?.data?.data);
		return (
			<div>
				<div>
					<div className="container-button-add"></div>
					< ModalRules props={this.props} changeStateModal={this.changeStateModal} showModal={this.state.showModal} loadRuleInformation={this.loadRuleInformation} data={this.state.ruleSelect} action={this.state.actionSelected} listCountries={listCountries} />
					<div className="title-w2c">W2C Reglas</div>
					<div className="container-button-add">
						{getAccess(this.props.parentPath, ACCESS.create_rule) &&
							<SiigoButtonAtom
								color="primary-green"
								size="l"
								type="button"
								text="Crear regla"
								icon="fas fa-plus"
								onClick={this.showModalComplement}
							/>
						}
					</div>
					<div className="subtitle">Gestiona las reglas para Five9.</div>

				</div>
				<br />
				{listRules &&
					<div className="divTable">
						<TableReport
							columns={listHeadersRules} // Data columns to table
							tabs={false} //Enable tabs
							tabsData={[]} //Load data to tabs check option on tabsData const
							headerCounterTab={true} //Counter tab option
							btnActions={this.tableActions}
							actionButtons={true} //Enable buttons (edit, delete, copy, open)
							pageSize={10} // Enable page size
							pagination={true} //Enable pagination (true, false)
							paginationNav={'arrows'} //Nav options (arrow, dot)
							paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
							maxSizePagination={6} //Size pagination
							defaultPaginationNumber={1} //Current pagination when start app
							paginationGrouped={10} //Grouped by page
							detailRow={false} //Open column detail with left arrow
							data={listRules?.data?.data} //Data for table
						/>
					</div>
				}
				{this.state.showDeleteModal &&
					<DeleteModal showDeleteModal={this.state.showDeleteModal} showDelete={this.showDelete} source={SOURCE_RULES} data={this.state.dataToDelete} deleteAction={this.props} />
				}

				{this.state.showNotification &&
					this.notificationMessage([this.state.detailNotification], 5000)
				}
			</div>
		)
	}

}


w2cRules.propTypes = {
	actions: PropTypes.object.isRequired,
	listRules: PropTypes.object,
	error: PropTypes.object,
};

const mapStateToProps = state => ({
	listRules: state.five9.rules,
	error: state.five9.err,
	isLoading: state.five9.isLoading,
	isCorrect: state.five9.isCorrect,
	listCountries: state.five9.countries
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Five9Actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(w2cRules);