import React, { Component } from 'react';
import CloudLog from '../../components/cloudLog';

class CloudLogContainer extends Component {
  render() {
    return (
      <div>
        <CloudLog parentPath={this.props.path}/>
      </div>
    );
  }
}

export default CloudLogContainer;
