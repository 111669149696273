import React, { Component } from 'react';
import AccountStatementList from '../../components/accountStatement';

class AccountStatementContainer extends Component {
  render() {
    return (
      <div>
        <AccountStatementList parentPath={this.props.path}/>
      </div>
    );
  }
}

export default AccountStatementContainer;
