import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import CountryContext from '../../context/countries-context';
import { withTranslation } from 'react-i18next';
import Loader from '../tools/loader';
import ModalResponse from '../layout/modals/modalResponse';
import { uuidV4Generator } from "../../utils/randomizer";
import { emailGetCompanies } from '../../actions/market/marketAction';
import { SiigoDataTableMolecule } from '@siigo/siigo-data-table-molecule-react';
import { SiigoPaginatorMolecule } from '@siigo/siigo-paginator-molecule-react';
import planes from './planes';
import { useHistory, useLocation  } from 'react-router-dom';

const SearchEmail = ({
	t,
	dispatch,
	parentPath
}) => {

	const marketState = useSelector((_state) => _state.market);
	const countryContext = useContext(CountryContext);
	const [searchEmail, setSearchEmail] = useState('');
	const [searchEmailData, setSearchEmailData] = useState([]);
	const [filtersCompanies, setFiltersCompanies] = useState();
	const siigoSearchDataTable = useRef();
	const history = useHistory();
	const location = useLocation();
	const [paginationParams, setPaginationParams] = useState({
		page: 1,
		pageDocuments: 10,
		totalDocuments: 0
	})
	const [headers] = useState([
		{
			key: "CompanyFullName",
			text: "Nombre empresa",
			type: "string",
			align: "center",
			sortable: true
		},
		{
			key: "planType",
			text: "Tipo",
			type: "string",
			align: "center",
			sortable: true
		},
		{
			key: "Serial",
			text: "Serial",
			type: "string",
			align: "center",
			sortable: true
		},
		{
			key: "UserEmail",
			text: "Email",
			type: "string",
			align: "center",
			sortable: true
		},
		{
			key: "actions",
			text: "Acciones",
			align: "center",
			width: "30%",
			type: "string",
			sortable: false,
		},
	]);

	useEffect(() => {
		if (location.state?.email) {
			setSearchEmail(location.state.email);
				setTimeout(
					function () {
						document.getElementById('btnSearchCompany').click();
					},
					100
				);
		}
	}, [location.state])

	const handleInputChange = (e) => {
		setSearchEmail(e.target.value);
	}

	const validEmailStructure = (email) => {

		const emailRegex = /^[a-zA-Z0-9-_.]{1,}@([a-zA-Z0-9-_.]+\.)+[a-zA-Z]{2,}$/;
		if (email === '') {
			return { valid: false, message: t("market.searchEmail.validations.required") };
		}

		if (!emailRegex.test(email)) {
			return { valid: false, message: t("market.searchEmail.validations.noValid") };
		}

		if(email.length > 100){
			return { valid: false, message: t("market.searchEmail.validations.noValid") };
		}
		return { valid: true };
	};

	const searchCompanies = () => {
		let companyEmail = searchEmail;
		const validation = validEmailStructure(companyEmail);

		if (!validation.valid) {
			ModalResponse({
				title: t("market.searchEmail.titles.modalErrorTitle"),
				text: validation.message,
				res: 404,
			});
			return;
		}

		const params = {
			data: {
				email: companyEmail,
			},
			meta: {
				country: countryContext?.country,
				requestId: uuidV4Generator(),
			}
		};

		dispatch(emailGetCompanies(params));
	}

	const selectedPage = (event) => {
		event.preventDefault();
		const params = {
			pageNumber: (event?.detail?.limit / event?.detail?.size),
			pageDocuments: event?.detail?.size
		}
		if (params.pageNumber != paginationParams.page || params.pageDocuments != paginationParams.pageDocuments) {
			setPaginationParams({ page: params.pageNumber, pageDocuments: params.pageDocuments });
			setFiltersCompanies({
				...filtersCompanies,
				limit: (event?.detail?.limit - event?.detail?.offset),
				offset: event?.detail?.offset
			});
		}
	}

	const redirectToCompany = (item) => {
		history.push({
			pathname: `/company/${item.CloudTenantID}`,
			state: { company:item, email: searchEmail,from: location.pathname}
		});
	};

	const generateActionButton = (_params) => {
		const buttonViewDetail = document.createElement("siigo-button-atom");
		buttonViewDetail.text = t('market.searchEmail.titles.btnGetDetails');
		buttonViewDetail.color = "secondary";
		buttonViewDetail.size = "m";
		buttonViewDetail.onclick = () => redirectToCompany(_params?.item);
		return buttonViewDetail
	}

	useEffect(() => {
		searchEmailData?.length &&
			siigoSearchDataTable?.current?.setDefaultTemplate({
				actions: (value, _params) => generateActionButton(_params),
			});
	}, [searchEmailData]);

	useEffect(() => {
		if (marketState.emailCompanies) {
			const updatedData = marketState.emailCompanies.map(company => ({
				...company,
				planType: planes[company.PlanType] || company.planType
			}));
			setSearchEmailData(updatedData);

			if (updatedData.length === 0) {
				ModalResponse({
					title: t("market.searchEmail.titles.modalErrorTitle"),
					text: t("market.searchEmail.validations.noResults"),
					res: 404,
				});
			}
		}
	}, [marketState.emailCompanies]);

	useEffect(() => {
		if (countryContext?.isCountryChanged()) {
			countryContext.setCountryChanged(false);
			dispatch(emailGetCompanies({}));
		}
	}, [countryContext]);

	useEffect(() => {
		if (marketState.emailCompanies?.total) {
			setPaginationParams({
				...paginationParams,
				totalDocuments: marketState?.emailCompanies?.total
			});
		}
	}, [marketState?.emailCompanies]);

	return (
		<div>
			<div>
				<h1 style={{marginTop:'0px', fontSize: '30px'}}>{t('market.searchEmail.headers.head1')}</h1>
				<h4 style={{marginTop:'0px'}}>{t('market.searchEmail.headers.head2')}</h4>
			</div>
			<div style={{ display: 'flex', width: '50%', justifyContent: 'space-between', marginTop: '25px' }}>
				<input
					type="text"
					defaultValue={searchEmail}
					onChange={handleInputChange}
					name="email"
					id="email"
					placeholder={t("market.form.placeHolderEmail")}
					style={{ width: '60%' }}
				/>
				<button id="btnSearchCompany" className="blue-fill" style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={searchCompanies}>
					<span className="siicon icon-search searchMarket"></span>
					<span>{t("market.form.buttonSearch")}</span>
				</button>
			</div>
			{marketState?.isLoadingEmailCompanies && <Loader full />}

			<div style={{ marginTop: '30px' }}>
				<div className='table-company-logs'>
					<SiigoDataTableMolecule
						className='logs-table'
						headers={headers}
						data={searchEmailData}
						dataTableMessage={t('market.companyLogs.table.notData')}
						select-rows={false}
						sortable
						reorder={false}
						ref={siigoSearchDataTable}
						actions={{
							actions: (value, _params) => generateActionButton(_params),
						}}
					/>
					<div className='table-pagination'>
						<SiigoPaginatorMolecule
							collectionSize={paginationParams.totalDocuments}
							currentPage={paginationParams.page}
							dropdownPosition={{
								control: {
									type: 'select'
								},
							}}
							onChanged={(event) => selectedPage(event)}
						/>
					</div>
				</div>

			</div>
		</div>
	)
}

SearchEmail.propTypes = {
	emailCompanies: PropTypes.object,
};
export default connect()(withTranslation()(SearchEmail));