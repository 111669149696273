import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { loginUserAction } from '../../actions/auth/authenticationsActions';
import { setCookie } from '../../utils/cookies';

import './auth.scss';

class Login extends Component {
    onHandleLogin = (event) => {
        event.preventDefault();

        let email = event.target.email.value;
        let password = event.target.password.value;

        const data = {
            email, password
        };

        this.props.dispatch(loginUserAction(data));
    }

    componentDidMount() {
        document.title = 'React Login';
    }

    render() {
        let isSuccess, message;

        if (this.props.response.login.hasOwnProperty('response')) {
            isSuccess = this.props.response.login.response.success;
            message = this.props.response.login.response.message;

            if (isSuccess) {
                setCookie('token', this.props.response.login.response.token, 1);
            }
        }

        return (
            <div>
                {!isSuccess ? <div>{message}</div> : <Redirect to='dashboard' />}
                <form onSubmit={this.onHandleLogin}>
                    <div className="input-container">
                        <i className="sii sii-user icon"/>
                        <input type="email" name="email" id="email" placeholder="Correo"/>
                    </div>
                    <div className="input-container">
                        <button>Recuperar</button>
                    </div>
                    <div className="input-container text-cont">
                        <p>Ingresa con tu <Link to='login' className="link-ms">cuenta</Link></p>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (response) => ({response});

export default connect(mapStateToProps)(Login);
