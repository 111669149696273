import React from "react";
import CampaignForm from "./form";

function CampaignCreate() {
  return (
    <>
      <CampaignForm />
    </>
  );
}

export default CampaignCreate;
