import React, { Component } from 'react';
import InvoiceList from '../../components/contracts/reportInvoice';

class InvoicesContainer extends Component {
	render() {
		return (
			<div>
				<InvoiceList parentPath={this.props.path}/>
			</div>
		);
	}
}

export default InvoicesContainer;
