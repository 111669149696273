
import apisauce from 'apisauce';
import ProductListSynergy from './services/ProductListSynergy';

const create = (baseURL = getEndPoint('synergyProducts')) => {

  const api = apisauce.create({
    
    baseURL,
    
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'blob'
    },
  
    timeout: 60000
  });
  const productListSynergy = new ProductListSynergy(api);
  //  return Api
  return {
    setHeader: (key, value) => api.setHeader(key, value),
    removeHeader: key => api.deleteHeader(key),
    productListSynergy
  };
};

export default {
  create
};


function getEndPoint(name) {
  const endPoints = JSON.parse(localStorage.getItem('configInst'));
  console.log('endPoints:: ', endPoints);
  for (let x = 0; x < endPoints.length; x++) {
    if (endPoints[x].name == name) {
      return endPoints[x].url;
    }
  }
}