import React, { useState, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input,
  ListSubheader,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Constants from "../../../constants";
import ProductHelper from "../../../helpers/products";
import Footer from "./footer";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 170,
    maxWidth: 300,
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  checkbox: {
    color: "lightgray"
  },
}));

export default function ComboDetails({
  products,
  campaignInformation,
  setCampaignInformation,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  /*
   * forceUpdate -> Método para forzar un nuevo render del componente,
   * esto con el objetivo de refrescar los cambios que se hagan sobre las filas de la tabla.
   */
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const initialCampaignConfiguration = [
    {
      id: 0,
      productCodes: [],
      discountPercentage: null,
      period: "",
      numberOfInstallment: "",
      installmentMinimumValue: "",
      featuredProduct: false
    },
  ];
  const [configurations, setConfigurations] = useState(
    campaignInformation?.configurations?.length
      ? campaignInformation?.configurations
      : initialCampaignConfiguration
  );
  const [productAvailable, _] = useState(ProductHelper.removeInactiveProducts(products));

  const handleClickAddRow = (index) => {
    setConfigurations((previousState) => {
      let newState = [];
      newState = [
        ...previousState,
        {
          id: index + 1,
          productCodes: [],
          discountPercentage: null,
          period: "",
          numberOfInstallment: "",
          installmentMinimumValue: "",
          featuredProduct: false
        },
      ];
      return newState;
    });
  };
  const handleClickDeleteRow = (index) => {
    let newState = [],
      counter = 0;
    setConfigurations((previousState) => {
      previousState.forEach((element) => {
        if (element.id !== previousState[index].id) {
          newState.push({
            id: counter,
            productCodes: element.productCodes,
            discountPercentage: element.discountPercentage,
            period: element.period,
            numberOfInstallment: element.numberOfInstallment,
            installmentMinimumValue: element.installmentMinimumValue,
            featuredProduct: element.featuredProduct
          });
          counter++;
        }
      });
      return newState;
    });
    document.getElementById(`discountPercentage${newState.length - 1}`).value =
      null;
  };

  const handleChangeProperty = (property, value, index) => {
    setConfigurations((previousState) => {
      let newState = previousState;
      newState[index][property] = value;
      return newState;
    });
    forceUpdate();
  };

  const handleChangeProducts = (productCode, index) => {
    if (!productCode) return;
    setConfigurations((previousState) => {
      let newState = previousState;
      const productIndex =
        previousState[index].productCodes.indexOf(productCode);
      if (productIndex > -1) {
        newState[index].productCodes = previousState[index].productCodes.filter(
          (value) => value !== previousState[index].productCodes[productIndex]
        );
      } else {
        newState[index].productCodes.push(productCode);
      }
      return newState;
    });
    forceUpdate();
  };

  const makeRows = () => {
    return configurations.map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <p>{index + 1}</p>
        </TableCell>
        <TableCell>
          <FormControl className={classes.formControl}>
            <InputLabel id={`combo-products-label${index}`} shrink={true}>
              {t("shop.campaigns.create.form.combo.chooseProducts")}
            </InputLabel>
            <Select
              labelId={`combo-products-label${index}`}
              id={`combo-products-select${index}`}
              multiple
              value={row.productCodes}
              input={<Input />}
              renderValue={(codes) => {
                let productsSelectedName = [];
                codes.forEach((code) => {
                  const productInformation =
                    ProductHelper.getProductInformationByCode(products, code);
                  if (productInformation)
                    productsSelectedName.push(productInformation.name);
                });
                return productsSelectedName.join(", ");
              }}
            >
              {ProductHelper.groupProducts(productAvailable, true)?.map(
                (product) => (
                  <div>
                    <ListSubheader disableSticky={true}>
                      {product.name}
                    </ListSubheader>
                    {product.children.map((children) => (
                      <MenuItem
                        key={children.code}
                        data-code={children.code}
                        onClick={() =>
                          handleChangeProducts(children.code, index)
                        }
                      >
                        <Checkbox
                          checked={row.productCodes?.includes(children.code)}
                        />
                        <ListItemText primary={children.name} />
                      </MenuItem>
                    ))}
                  </div>
                )
              )}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <TextField
            id={`discountPercentage${index}`}
            label={t("shop.campaigns.create.form.discountAbbreviated")}
            variant="standard"
            type="number"
            onChange={(event) => {
              if (event.currentTarget.value <= 100) {
                handleChangeProperty(
                  "discountPercentage",
                  event.currentTarget.value,
                  index
                );
              }
            }}
            value={row.discountPercentage}
          />
          </TableCell>
          {
            ![Constants.CAMPAIGN_DESTINATIONS.SIIGO_COM.VALUE].includes(campaignInformation.destination) &&
              <>
                <TableCell>
                  <TextField
                    select
                    label={t("shop.campaigns.create.form.period")}
                    value={row.period}
                    className={classes.formControl}
                  >
                    {Constants.CAMPAIGN_PERIOD_ARRAY.map((option) => (
                      <MenuItem
                        key={option.VALUE}
                        value={option.VALUE}
                        onClick={() =>
                          handleChangeProperty("period", option.VALUE, index)
                        }
                      >
                        {option.LABEL}
                      </MenuItem>
                    ))}
                  </TextField>
                </TableCell>
                <TableCell>
                  <TextField
                    id={`numberOfInstallment${index}`}
                    label={t("shop.campaigns.create.form.installments")}
                    variant="standard"
                    type="number"
                    disabled={
                      row.period !== Constants.CAMPAIGN_PERIOD.INSTALLMENTS.VALUE
                    }
                    onChange={(event) =>
                      handleChangeProperty(
                        "numberOfInstallment",
                        event.currentTarget.value,
                        index
                      )
                    }
                    value={row.numberOfInstallment}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id={`installmentMinimumValue${index}`}
                    label={t("shop.campaigns.create.form.value")}
                    variant="standard"
                    type="number"
                    disabled={
                      row.period !== Constants.CAMPAIGN_PERIOD.INSTALLMENTS.VALUE
                    }
                    onChange={(event) =>
                      handleChangeProperty(
                        "installmentMinimumValue",
                        event.currentTarget.value,
                        index
                      )
                    }
                    value={row.installmentMinimumValue}
                  />
                </TableCell>
              </>
          }
          <TableCell>
            <Checkbox
              className={classes.checkbox}
              checked={row.featuredProduct}
              onClick={() =>
                handleChangeProperty(
                  Constants.EVENTS_PROPERTY_NAMES.featuredProduct,
                  !row.featuredProduct,
                  index
                )
              }
            />
          </TableCell>
          <TableCell className={classes.button}>
            {index + 1 === configurations.length ? (
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={() => handleClickAddRow(index)}
                disabled={
                  !(
                    row.productCodes.length &&
                    row.discountPercentage &&
                    (row.period.length ||
                      ([Constants.CAMPAIGN_DESTINATIONS.SIIGO_COM.VALUE].includes(
                        campaignInformation.destination
                      )))
                  )
                }
              >
                {t("shop.campaigns.create.form.add")}
              </Button>
            ) : (
              <IconButton
                variant="outlined"
                color="primary"
                aria-label="delete"
                onClick={() => handleClickDeleteRow(index)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </TableCell>
      </TableRow>
    ));
  };

  return (
    <ThemeProvider theme={Constants.SIIGO_THEME}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("shop.campaigns.create.form.counting")}</TableCell>
              <TableCell>{t("shop.campaigns.create.form.combo.product")}</TableCell>
              <TableCell>{t("shop.campaigns.create.form.discount")}</TableCell>
              {
                ![Constants.CAMPAIGN_DESTINATIONS.SIIGO_COM.VALUE].includes(campaignInformation.destination) &&
                  <>
                    <TableCell>{t("shop.campaigns.create.form.period")}</TableCell>
                    <TableCell>{t("shop.campaigns.create.form.numberOfInstallments")}</TableCell>
                    <TableCell>{t("shop.campaigns.create.form.minValueForInstallments")}</TableCell>
                  </>
              }
              <TableCell>{t("shop.campaigns.create.form.featuredProduct")}</TableCell>
              <TableCell>{t("shop.campaigns.create.form.action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{makeRows()}</TableBody>
        </Table>
      </TableContainer>
      <Footer
        campaignInformation={{
          ...campaignInformation,
          configurations: configurations,
        }}
        setCampaignInformation={setCampaignInformation}
      />
    </ThemeProvider>
  );
}
