import React from "react"
import Sheader from "../slayout/sheader/sheader"
import '../siigers.scss';
import '../assets/fontawesome/css/fontawesome.css';
import '../assets/fontawesome/css/solid.css';
 
class Slayout extends React.Component  {

    constructor(props) {
        super(props);  
    }

    render(){
        return ( 
            <div>
                <Sheader />                
            </div>
        );
   }
}


// export default Slayout;
export default Slayout;