// a library to wrap and simplify api calls
import apisauce from 'apisauce';
import Market from './services/market';
import { getCookie } from '../../utils/cookies';
import PayrollPackage from './services/PayrollPackage';

// our "constructor"
const create = () => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    // base URL is read from the "constructor"

    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'content-type': 'application/json'
    },
    // 4 minutes timeout due to provisioning issues with Monolith...
    timeout: 240000
  });

  const market = new Market(api);
  const payrollPackage = new PayrollPackage(api);
  //  return Api
  return {
    setHeader: (key, value) => api.setHeader(key, value),
    removeHeader: key => api.deleteHeader(key),
    // a list of the API functions from step 2
    market,
    payrollPackage
  };
};

// let's return back our create method as the default.
export default {
  create
};
