import * as types from '../actions'

const processNSNreducer = (state = { isLoading: false }, action = null) => {
    switch (action.type) {
        case types.PROCESS_FILE_NSN:
            return { ...state, isLoading: true }
        case types.PROCESS_FILE_NSN_RECEIVED:
            return { ...state, isLoading: false, data: action.data }
        case types.PROCESS_FILE_NSN_FAILED:
            return { ...state, isLoading: false, data: action.error }        
        case types.SEND_EXCEL_OBJECT:
            return { ...state, isLoading: true }
        case types.SEND_EXCEL_OBJECT_RECEIVED:
            return { ...state, isLoading: false, excel: action.data }
        case types.SEND_EXCEL_OBJECT_FAILED:
            return { ...state, isLoading: false, excel: action.error }
        default:
            return state
    }
}

export default processNSNreducer