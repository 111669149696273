import getEndPoint from "../../../utils/endpoints";
export default function (api) {
  return {
    getProducts: (data) =>
      api.get(`${getEndPoint("shop")}/products/get?country=${data?.country}`),
    postCampaign: (data) =>
      api.post(`${getEndPoint("shop")}/campaign/post`, data),
    getCampaigns: (data) => {
      const code = data?.code ? `&code=${encodeURIComponent(data?.code)}` : "";
      const type = data?.type ? `&type=${encodeURIComponent(data?.type)}` : "";
      const destination = data?.destination
        ? `&destination=${encodeURIComponent(data?.destination)}`
        : "";
      let request = api.get(
        `${getEndPoint("shop")}/campaigns/get?${destination}${type}${code}`
      );
      if (data?.pageNumber > 0 && data?.pageDocuments > 0 && !data?.code) {
        request = api.get(
          `${getEndPoint("shop")}/campaigns/get?type=${
            data?.type
          }${code}&pageNumber=${data?.pageNumber}&pageDocuments=${
            data?.pageDocuments
          }`
        );
      }
      return request;
    },
    patchCampaignState: (data) =>
      api.patch(
        `${getEndPoint("shop")}/campaign/state/patch/${data?.id}`,
        data
      ),
  };
}
