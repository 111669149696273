let config={
    marketModal:{
        citysOptions:
            [
                {option:"Bogotá",value:"Bogotá"},
                {option:"Cali",value:"Cali"},
                {option:"Medellín",value:"Medellín"}
            ]
    },
    company:{
        btnComplements:{
            visible:true
        },
        btnPlanConverter:{
            visible:true
        },
        usersAcces:{
            visible:true
        },
        pos:{
            visible:true
        },
        payrollEmployees:{
            visible:true
        },
        factBase:{
            visible:true
        },
        factAditional:{
            visible:true
        },
        cardDocNE:{
            visible:true
        },
        cardDocFE:{
            visible:true
        }
    }
}

export default config;
