import * as types from '../index';

export const getCloudLog = params => {
  return { type: types.GET_CLOUD_LOGS, params };
};

export const sendExcelObject = params => {
  return { type: types.SEND_EXCEL_OBJECT, params };
};

