import * as types from '../actions';

const initialState = {
    isLoading: false,
    isLoadingNewGroup: false,
    isLoadingProperties: false,
    isLoadingCollection: false,
    isLoadingCreatedData: false,
    error: false,
    messageError: "",   
    successSave: false,
    editSuccess: false,
    dataGroups: [],
    groupCreated: null,
    dataProperties: [],
    dataCollection: [],
    detailDatasource: null
}
const datasourceReducer = (state = initialState, action = null) => {

switch (action.type) {
    
    //get properties
    case types.GET_PROPERTIES:
        return { ...state, isLoadingProperties: true};
    case types.PROPERTIES_RECEIVED:
        return {...state, isLoadingProperties: false, isLoadingCollection: false, dataProperties: action.data?.data, error: false, messageError: ""};
    case types.PROPERTIES_REQUEST_FAILED:
        return {...state, isLoadingProperties: false, error: true, messageError: action.data.error};

    //Get Data groups
    case types.GET_DATASOURCE:     
        return { ...state, isLoading: true};
    case types.DATASOURCE_RECEIVED:
        return { ...state, isLoading: false, dataGroups: action.data, error: false, messageError: ""};
    case types.DATASOURCE_REQUEST_FAILED:
        return { ...state, isLoading: false, dataGroups: action.data.data };
    
    case types.ADD_DATASOURCE:
        return { ...state, isLoadingNewGroup: true }
    case types.ADD_DATASOURCE_RECEIVED: 
        return { ...state, isLoadingNewGroup: false, groupCreated: action.data.data }
    case types.ADD_DATASOURCE_REQUEST_FAILED:
        return { ...state, isLoading: false, error: true }

    //Get Collections
    case types.GET_COLLECTION:     
        return { ...state, isLoadingCollection: true};
    case types.COLLECTION_RECEIVED:
        return {...state, isLoadingCollection: false, isLoadingProperties: false, dataCollection: action.data.data, error: false, messageError: ""};
    case types.COLLECTION_REQUEST_FAILED:
        return { ...state, isLoadingCollection: false, error: true, messageError: action.data.error};
        
    //Get Save data
    case types.SAVE_DATA:     
        return { ...state, isLoadingCreatedData: true};
    case types.SAVE_DATA_RECEIVED:
        return {...state, isLoadingCreatedData: false, successSave: true, dataProperties: [], dataCollection: [], error:false, messageError:""};
    case types.SAVE_DATA_REQUEST_FAILED:
        return { ...state, isLoadingCreatedData: false, error: true, messageError: action.data.error};
    
        //Get Save data
    case types.REPORT_DATA:     
        return { ...state, isLoadingCollection: true, successSave: false, dataProperties: [], dataCollection: [], editSuccess: false};
    case types.REPORT_DATA_RECEIVED:
        return {...state, isLoadingCollection: false, reportData: action.data};
    case types.REPORT_DATA_REQUEST_FAILED:
        return { ...state, isLoadingCollection: false, reportData: action.data.data };
    
    case types.GET_DETAIL_DATASOURCE:
        return {...state, isLoading: true}
    case types.GET_DETAIL_DATASOURCE_RECEIVED:
        return { ...state, isLoading: false, detailDatasource: action.data }
    case types.GET_DETAIL_DATASOURCE_FAILED:
        return { ...state, isLoading: false, error: true }
    
    case types.EDIT_DATASOURCE:
        return { ...state, isLoadingCreatedData: true }
    case types.EDIT_DATASOURCE_RECEIVED:
        return { ...state, isLoadingCreatedData: false, editSuccess: true }
    case types.EDIT_DATASOURCE_FAILED:
        return { ...state, error: true}
    default:
        return state;
}
};

export default datasourceReducer;
