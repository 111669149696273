import * as types from "../index";

export const getProducts = (params) => {
  return { type: types.GET_PRODUCTS, params };
};

export const postCampaign = (params) => {
  return { type: types.POST_CAMPAIGN, params };
};

export const getCampaigns = (params) => {
  return { type: types.GET_CAMPAIGNS, params };
};

export const patchCampaignState = (params) => {
  return { type: types.PATCH_CAMPAIGNSTATE, params };
};
