import mexico from './configs/mexico.js';
import colombia from './configs/colombia.js';
import peru from './configs/peru.js';
import chile from './configs/chile.js';
import ecuador from './configs/ecuador.js';

const countriesConfig = {
    mx: mexico,
    co: colombia,
    pe : peru,
    cl: chile,
    ec : ecuador
};

export default countriesConfig