import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api';

export function* getCountries() {
  const api = API.create();
  try {
    let countries = yield call(api.countries.getCountries);
    yield put({ type: types.COUNTRIES_RECEIVED, data: countries });
  } catch (err) {
    yield put({ type: types.COUNTRIES_REQUEST_FAILED, err });
  }
}

export function* getConfig(action) {
  const api = API.create();
  let { idInst } = action;
  try {
    let config = yield call(api.countries.getConfig, idInst);
    yield put({ type: types.CONFIG_RECEIVED, data: config });
  } catch (err) {
    yield put({ type: types.CONFIG_REQUEST_FAILED, err });
  }
}
