import config from '../../../config';
export default function (api) {
  return {
    getAccountant: params => api.get('getAccountant', params),
    modifyAccountant: params => api.post('modifyAccountant', params),
    resendMassiveEmails: params => api.post('resendMassiveEmail', params),
    sendEmail: params => api.get('resendAccountantEmail', { id: params.id, email: params.email }),
    sendExcelObject: params => api.post('convert/file', params),
    authExcel: params => api.post('jwtPostExcel', params)
  };
}
