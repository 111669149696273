import React, { Component } from 'react';
import General from '../../components/reports/general';
import PlanReport from '../../components/reports/plan';
import SalesReport from '../../components/reports/sales';

class DashboardContainer extends Component {
  render() {
    return (
      <div className="content">
        <General />
        <PlanReport />
        <SalesReport />
      </div>
    );
  }
}

export default DashboardContainer;
