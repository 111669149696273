import React, { Component } from 'react';
import BlackListEmail from '../../components/blackListEmail';

class BlackListEmailContainer extends Component {
  render() {
    return (
      <div>
        <BlackListEmail parentPath={this.props.path}/>
      </div>
    );
  }
}

export default BlackListEmailContainer;
