import * as types from '../index';

export const getConfigReport = (params) => {
  return { type: types.GET_CONFIG_GENERAL_REPORT, params };
};

export const filterDataBySource = (params) => {
  return { type: types.FILTER_DATA_BY_SOURCE, params };
};

export const downloadReportDataBySource = params => {
  return { type: types.DOWNLOAD_REPORT_DATA_BY_SOURCE, params };
}