import * as types from '../index';

export const getNotification = () => {
  return { type: types.GET_NOTIFICATIONS };
};

export const createNotification = params => {
  return { type: types.CREATE_NOTIFICATION, params };
};

export const editNotification = params => {
  return { type: types.EDIT_NOTIFICATION, params };
};

export const deleteNotification = id => {
  return { type: types.DELETE_NOTIFICATION, id };
};
