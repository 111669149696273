import React, { Component } from 'react';
import Market from '../../components/market';

class MarketContainer extends Component {

	render() {	
		return (			
			<div>
				<Market parentPath={this.props.path}/>
			</div>
		);
	}
}

export default MarketContainer;
