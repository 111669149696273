import React, { Component } from 'react';

export const subscriptionPeriodicity = {
    monthly: "Mensual",
    weekly: "Semanal",
    yearly: "Anual"
}

export const subscriptionActive = {
    "1": "Activa",
    "0": "Cancelada",
    "true": "Activa",
    "false": "Cancelada",
}

export const subscriptionRecurrent = {
    "true": "Si",
    "false": "No",
    "1": "Si",
    "0": "No",
}

export const ActiveClasses = {
    "1": 'successState',
    "0": 'errorState',
    "true": 'successState',
    "false": 'errorState',
}

export class SubscriptionStates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render(){
        
        var state = this.renderStates();

        return (
            <span className={state.clase === undefined ? "" : state.clase}>
                {state.value === undefined ? "N/A" : state.value}
            </span>
        );
    }


    renderStates() {
        
        var selector = this.props.selector;
        var subState = this.props.subState;
        var valueSelector = this.props.valueSelector;

        var resp = {
            value: "",
            clase: "",
        };

        selector = selector === undefined ? "" : selector;
        valueSelector = valueSelector === undefined ? "N/A" : String(valueSelector);

        if (subState) {

            switch (selector) {
                case "isActive":
                    resp.clase = valueSelector === "N/A" ? "" : ActiveClasses[valueSelector];
                    resp.value = valueSelector === "N/A" ? valueSelector : subscriptionActive[valueSelector];
                    break;
                case "periodicity":
                    resp.value = valueSelector === "N/A" ? valueSelector : subscriptionPeriodicity[valueSelector];
                    break;
                case "isRecurrentPay":
                    resp.value = valueSelector === "N/A" ? valueSelector : subscriptionRecurrent[valueSelector];
                    break;
                default:
                    resp.value = valueSelector;
            }

        }
        else {
            resp.value = valueSelector;
        }

        return resp;

    }


}

