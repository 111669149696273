import React, { Component } from 'react';
import UsersList from "../../components/users";

class SettingsContainer extends Component {
    render() {
        return (
            <div className={'container'}>
                <div className='col'></div>
                <div className="col">
                    <UsersList />
                </div>
            </div>
        )
    }
}

export default SettingsContainer;
