import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { checkCookie} from '../utils/cookies';
import Layout from '../containers/layout';
import Loader from '../components/tools/loader';

const MENU_ACCESS = 'menuAccess';
let section = React.createRef();

function PrivateRoute({ component: Component, ...rest }) {
    const [cargaComponent, setCargaComponent] = useState(false);
    const [validMenu, setValidMenu] = useState('');
    const changeContainer = value => {
        if(validMenu.trim() === ''){
            setTimeout(() => {
                setValidMenu(value);
            }, 200);
        }else{
            setValidMenu('');
        }
    }
    
    return (
        <Route
            {...rest}
            render={props =>
                checkCookie() !== null ? (
                    <div>
                        <Layout route={rest} validMenu={changeContainer} />
                        <section className={'container'} style={{paddingLeft: validMenu}}>
                            <div ref={section} id="sectionContent" className="section">
                                {localStorage.getItem(MENU_ACCESS) || cargaComponent ? (                                  
                                    localStorage.getItem(MENU_ACCESS)?.includes(rest.path) ? (                                     
                                        <Component {...rest} />
                                    ) : (
                                        <p>
                                            No cuenta con los permisos para acceder al componente{' '}
                                            <b>{rest.path.slice(1)}</b>, por favor comuníquese con el
                                            administrador.
                                        </p>
                                    )
                                ) : (
                                    <div>
                                        <div className="hide">
                                            {setTimeout(
                                                function() {
                                                    setCargaComponent(true);
                                                }.bind(this),
                                                2000
                                            )}
                                        </div>
                                        <Loader />
                                    </div>
                                )}
                            </div>
                        </section>
                    </div>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;
