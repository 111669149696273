import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as PlansActions from '../../actions/plans/plansActions';
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import Loader from "../tools/loader";
import TableReport from "../tableReport";

const tabsData ={
    filterCol:'type',
    data: [{
        name: 'Todos',
        filterName: 'all',
        color: 'yellow-light'
    },
    {
        name: 'General',
        filterName: 'general',
        color: 'azure'
    },
    {
        name: 'Personalizado',
        filterName: 'personalizado',
        color: 'green'
    },
    {
        name: 'Promociones',
        filterName: 'promo',
        color: 'yellow'
    }]
};

const columns = [
    {
        name: 'Nombre del plan',
        selector: 'plan',
        sortable: true,
    },
    {
        name: 'Tipo de plan',
        selector: 'type',
        sortable: true,
        filterDDL: true
    },
    {
        name: 'Estado',
        selector: 'status'
    }
];

class PlanList extends Component {

    componentDidMount() {
        this.props.actions.getPlans();
    }

    render() {
        const { error, isLoading, planList } = this.props;
        return (
            <div>
                { error && <h4>Error: {error.response.data}</h4> }
                {isLoading ?
                    <Loader/>
                    : (
                        <div>
                            <TableReport
                                columns={columns}// Data columns to table
                                tabs={true}//Enable tabs
                                tabsData={tabsData}//Load data to tabs check option on tabsData const
                               headerCounterTab={true}//Counter tab option
                                actionButtons={true}//Enable buttons (edit, delete, copy, open)
                                pageSize={10}// Enable page size
                                pagination={true} //Enable pagination (true, false)
                                paginationNav={'arrows'}//Nav options (arrow, dot)
                                paginationPosition={'bottom'}//Pagination position (Bottom, top, both)
                                maxSizePagination={6}//Size pagination
                                defaultPaginationNumber={1}//Current pagination when start app
                                paginationGrouped={10}//Grouped by page
                                detailRow={false}//Open column detail with left arrow
                                data={planList.data}//Data for table
                            />
                        </div>
                    )
                }
            </div>
        )
    }
}

PlanList.propTypes = {
    actions: PropTypes.object.isRequired,
    planList: PropTypes.object,
    error: PropTypes.object,
};

const mapStateToProps = state => ({
    planList: state.plans.plans,
    error: state.plans.err,
    isLoading: state.plans.isLoading
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(PlansActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PlanList);
