import * as types from '../actions';

const utilsReducer = (state = { isLoading: true }, action = null) => {
  switch (action.type) {
    case types.GET_MENU_USER_RECEIVED:
      return { ...state, isLoading: false, menu: action.data };
    case types.GET_MENU_USER_FAILED:
      return { ...state, isLoading: false, menu: action.error };
    default:
      return state;
  }
};
export default utilsReducer;
