import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api';

export function* createDependence(action) {
  const api = API.create();
  try {
    const { dep } = action;
    const dependenceDetail = yield call(api.dependences.createDependence, dep); //.then(response => response.data);
    yield put({
      type: types.CREATE_DEPENDENCE_SUCCESS,
      data: dependenceDetail
    });
  } catch (err) {
    yield put({ type: types.CREATE_DEPENDENCE_FAILED, err });
  }
}

export function* updateDependence(action) {
  const api = API.create();
  try {
    const { dep, msisdn } = action;
    const dependenceDetail = yield call(
      api.dependences.updateDependence,
      dep,
      msisdn
    ); //.then(response => response.data);
    yield put({
      type: types.UPDATE_DEPENDENCE_SUCCESS,
      data: dependenceDetail
    });
    //yield put({ type: types.GET_DEPENDENCES });
  } catch (err) {
    yield put({ type: types.UPDATE_DEPENDENCE_FAILED, err });
  }
}

export function* deleteDependence(action) {
  const api = API.create();
  const { msisdn } = action;
  try {
    console.log('entro en delete sagas');
    const response = yield call(api.dependences.deleteDependence, msisdn);
    yield put({ type: types.DELETE_DEPENDENCE_SUCCESS, data: response });
    yield put({ type: types.GET_DEPENDENCES });
  } catch (err) {
    yield put({ type: types.DELETE_DEPENDENCE_FAILED, err });
  }
}

export function* getDependences() {
  const api = API.create();
  try {
    let dependences = yield call(api.dependences.dependences);
    yield put({ type: types.DEPENDENCES_RECEIVED, data: dependences });
  } catch (err) {
    yield put({ type: types.DEPENDENCES_REQUEST_FAILED, err });
  }
}

export function* getDependence(action) {
  const api = API.create();
  try {
    const { msisdn } = action;
    let dependences = yield call(api.dependences.dependenceDetail, msisdn);
    yield put({ type: types.DEPENDENCE_RECEIVED, data: dependences });
  } catch (err) {
    yield put({ type: types.DEPENDENCE_REQUEST_FAILED, err });
  }
}
