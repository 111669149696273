import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCookie } from '../../utils/cookies';
import config from '../../config.js';
import './auth.scss';
import { jwtDecode } from "jwt-decode";
import {isMobile} from 'react-device-detect';
import { GetOrCreateSiiger } from '../siigers/helpers/SiigerHelper';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSuccess: null,
      url_api: config.url + 'auth/azureadoauth2'
    };
  }

  componentDidMount() {
    document.title = 'BackOffice Siigo';

    if (this.props.token) {
      try {
      setCookie('isSuccess', true, 1);
      setCookie('token', this.props.token, 1);
      const user = jwtDecode(this.props.token);
      setCookie('email', user.username, 1);
      setCookie('sub', user.sub, 1);
      setCookie('name', user.name, 1);
      setCookie('avatar', user.avatar, 1);
      setCookie('access_token_nube', this.props.cloudToken ?? '', 1);
      localStorage.setItem('idInst', '');     
        if (isMobile) {
          this.setState({ redirectTo: '/siigers' });                  
        }else{
          this.setState({ redirectTo: '/market'});
        }
      this.setState({ isSuccess: true });
    }
      catch (error)
      {
         console.error(error);
         this.setState({ isSuccess: null });
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.isSuccess !== null ? <Redirect to={this.state.redirectTo} /> : false}
        <form>
          <div className="input-container text-cont">
            <p>
              <span className="spanLogin">
                Ingresar con tu cuenta de{' '}
                <i className="siicon icon-microsoft"></i> Microsoft
              </span>
              <a className="a-button blue" href={this.state.url_api}>
                INGRESAR
              </a>
            </p>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = response => ({ response });

export default connect(mapStateToProps)(Login);