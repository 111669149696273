import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/index';
import config from '../config.js';

export function* getConfigOrigins(action){
    const api = API.create();

    try {
        const { params } = action;
        const respConfigOrig = yield call(api.auroraConfigOrigins.getConfigOrigins, params);
        yield put({ type: types.GET_ORIGINS_RECEIVED, data: respConfigOrig });
    } catch (error) {
        yield put({ type: types.GET_ORIGINS_FAILED, error });
    }
}

export function* getConfigOrigin(action){
    const api = API.create();

    try {
        const { params } = action;
        const respConfigOrig = yield call(api.auroraConfigOrigins.getConfigOrigin, params);
        yield put({ type: types.GET_ORIGIN_RECEIVED, data: respConfigOrig });
    } catch (error) {
        yield put({ type: types.GET_ORIGIN_FAILED, error });
    }
}

export function* createConfigOrigin(action){
    const api = API.create();

    try {
        const { params } = action;
        const respConfigOrig = yield call(api.auroraConfigOrigins.createConfigOrigin, params);
        yield put({ type: types.CREATE_ORIGIN_SUCCESS, data: respConfigOrig });
    } catch (error) {
        yield put({ type: types.CREATE_ORIGIN_FAILED, error });
    }
}

export function* updateConfigOrigin(action){
    const api = API.create();

    try {
        const { params } = action;
        const respConfigOrig = yield call(api.auroraConfigOrigins.updateConfigOrigin, params);
        yield put({ type: types.UPDATE_ORIGIN_SUCCESS, data: respConfigOrig });
    } catch (error) {
        yield put({ type: types.UPDATE_ORIGIN_FAILED, error });
    }
}

