import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as auroraSubscriptionActions from '../../actions/auroraSubscription/auroraSubscriptionActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import Modal from '../layout/modals/detailSubscription/index';
import './style.scss';
import SearchFilter from './searchFilter';
import { getCookie } from '../../utils/cookies';
import { SubscriptionStates, subscriptionPeriodicity, subscriptionRecurrent, subscriptionActive } from './states';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';
import { getTemplate } from "./mails/updatePaymentMethod";
import { AxiosSubscription } from "./services";
import { AxiosUpdateSubscription } from "./serviceUpdate";
import config from '../../config';
import { formatDate, parseDate } from 'react-day-picker/moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';


const columns = [
	{
		name: 'Fecha de Suscripcion',
		selector: 'date',
		sortable: true
	},
	{
		name: 'Identificación',
		selector: 'payerDocument',
		sortable: true,
		filterDDL: true
	},
	{
		name: 'Correo',
		selector: 'payerEmail',
		sortable: true
	},
	{
		name: 'Nombre',
		selector: 'payerName',
		sortable: true
	},
	{
		name: 'RUC',
		selector: 'companyID',
		sortable: true
	},
	{
		name: 'ID Primer Pago',
		selector: 'originalTransactionId',
		sortable: true
	},
	{
		name: 'N° Cotización',
		selector: 'productId',
		sortable: true
	},
	{
		name: "Valor de Suscripción",
		selector: "recurrentValue",
		sortable: true
	},
	{
		name: 'Estado',
		selector: 'isActive',
		sortable: true,
		subState: true
	},
];


let downloadEnable = false;
let deleteSubscriptioFlag = false;
let accessDownload = false;
let accessDisable = false;
let downloading = false;
let accessEditPM = false;
class AuroraSubscription extends Component {
	constructor(props) {
		super(props);
		this.setFilterValue = this.setFilterValue.bind(this);
		this.filtrarSubscriptions = this.filtrarSubscriptions.bind(this);
		this.onHandleDownload = this.onHandleDownload.bind(this);
		this.cleanFilter = this.cleanFilter.bind(this);
		this.queryCurrentDaySubscriptions = this.queryCurrentDaySubscriptions.bind(this);
		this.getSubscriptionParams = this.getSubscriptionParams.bind(this);

		this.state = {
			disableBtnDelete: false,
			subscription: {},
			fromDate: undefined,
			toDate: undefined,
			showEditCard: false,
			openEditCard: false,
			mailEdit: "",
			link: "",
			pin: "",
			datos: [],
			isLoading: true,
			download: false,
			disableEmail: false,
			filter: undefined,
			updateSuscritpData:{
				changeTab: false,
			}
		};
		
	}

	showModalDetail = (e) => {
		this.setState({ showDetail: !this.state.showDetail });
	};

	showModalEditCreditCard = (e) => {
		this.setState({ showEditCard: !this.state.showEditCard, openEditCard: !this.state.openEditCard, updateSuscritpData: {} });
	};

	showModalDeleteSubscription = (e) => {
		this.setState({ showDelete: !this.state.showDelete });
	}

	componentDidMount() {
		document.title = "Gestor de Suscripciones";
		accessDisable = getAccess(this.props.parentPath, ACCESS.disable_subscription);
		accessDownload = getAccess(this.props.parentPath, ACCESS.download_excel);
		accessEditPM = getAccess(this.props.parentPath, ACCESS.edit_payment_method);
		this.queryCurrentDaySubscriptions();
		
	}

	deleteSubscription = () => {
		this.setState({ disableBtnDelete: true });
		this.props.actions.deleteSubscription({ id: this.state.subscription.id, payerCountry: this.state.subscription.payerCountry , companyID: this.state.subscription.companyID, subscriptionID: this.state.subscription.hashData });
		deleteSubscriptioFlag = true;
	}

	btnDeleteSubscription = (data) => {
		if (data.isActive === undefined || String(data.isActive) === "1" || String(data.isActive) === "true" || String(data.isActive) === "Activa") {
			this.setState({ subscription: data, showDelete: true, openDelete: true });
		}
		else {
			ModalResponse({
				title: '¡Suscripción Eliminada!',
				text: 'Esta Suscripción ya fue Eliminada',
				res: 404
			});
		}

	}

	queryCurrentDaySubscriptions = () => {
		let currentDateFrom = new Date();
		currentDateFrom.setHours(0);
		currentDateFrom.setMinutes(0);
		currentDateFrom.setSeconds(0);
		currentDateFrom.setMilliseconds(0);

		let currentDateTo = new Date();
		currentDateTo.setHours(23);
		currentDateTo.setMinutes(59);
		currentDateTo.setSeconds(59);
		currentDateTo.setMilliseconds(999);

		this.getSubscriptionParams(currentDateFrom, currentDateTo, null);
	}

	getSubscriptionParams(from, to, filter) {
		if (this.validItem(from) && this.validItem(to)) {
			let query = {
				data: {
					date: { "$gt": from.toISOString(), "$lt": to.toISOString()},
					"isRecurrentPay": 1
				},
				sort: { "date": -1 }
			};
			if (this.validItem(filter)) {
				query.data["$or"] = this.getORquery(filter);
			}
			this.props.actions.getSubscriptions(query);

		}
	}

	validItem(value) {
		return value !== "" && value !== null && value !== undefined;
	}

	getORquery(filter) {
		return [
			{ originalTransactionId: { "$regex": ".*" + filter + ".*", "$options": "i" } },
			{ originalReferenceCode: { "$regex": ".*" + filter + ".*", "$options": "i" } },
			{ companyID: { "$regex": ".*" + filter + ".*", "$options": "i" } },
			{ productId: { "$regex": ".*" + filter + ".*", "$options": "i" } },
			{ payerName: { "$regex": ".*" + filter + ".*", "$options": "i" } },
			{ payerDocument: { "$regex": ".*" + filter + ".*", "$options": "i" } },
			{ payerEmail: { "$regex": ".*" + filter + ".*", "$options": "i" } },
		];
	}

	filtrarSubscriptions = (e = null) => {
		if (e !== null) {
			e.preventDefault();
		}

		let query = {
			data: {"isRecurrentPay": 1},
			sort: { "date": -1 }
		}

		if (this.validItem(this.state.filter) || (this.validItem(this.state.toDate) && this.validItem(this.state.fromDate))) {
			if (this.validItem(this.state.filter)) {
				query.data["$or"] = this.getORquery(this.state.filter);
			}
			if (this.validItem(this.state.fromDate)) {
				query.data.date = { ...query.data.date, $gt: this.state.fromDate };
			}
			if (this.validItem(this.state.toDate)) {
				query.data.date = { ...query.data.date, $lt: this.state.toDate };
			}
			this.props.actions.getSubscriptions(query);
		} else {
			ModalResponse({
				title: '¡Hey!',
				text: 'Debe ingresar al menos un filtro',
				res: 404
			});
		}
	};

	cleanFilter = e => {
		e.preventDefault();
		this.setState({ fromDate: undefined, toDate: undefined, filter: undefined });
		this.queryCurrentDaySubscriptions();
	};

	showDetail = data => {
		this.setState({ subscription: data, showDetail: true, openDetail: true });
	};

	showEditCreditCard = (data) => {
		try {
			var pin = this.getPINvalue();
			var str = { "originalReferenceCode": data.originalReferenceCode, recurrentValue: data.recurrentValue, originalTransactionId: data.originalTransactionId, pin: pin, dateCreation: new Date().toISOString() }
			var enlace = config.urlAuroraBase + "loginUpdateTC?data=" + this.stringToBase64(JSON.stringify(str));
			this.setState({ subscription: data, mailEdit: data.payerEmail, showEditCard: true, openEditCard: true, link: enlace, pin: pin });
		} catch (error) {
			console.log(error);
			ModalResponse({
				title: "Error al Generar token de autenticación.",
				text: "Error Interno.",
				res: 400
			});
		}

	}

	sendMail(e) {
		e.preventDefault();

		if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.mailEdit)) {
			ModalResponse({
				title: "El correo electrónico ingresado no es válido.",

				res: 400
			});
			return;
		}
		this.setState({ disableEmail: true });
		var template = getTemplate(this.state.subscription.payerName, this.state.link, this.state.pin, this.state.subscription.originalTransactionId, this.state.subscription.productId);

		var body = {
			"From": {
				"Email": "cotizaciones@siigo.com",
				"Name": "SIIGO S.A."
			},

			"To": [
				{
					"Email": this.state.mailEdit,
					"Name": this.state.subscription.payerName
				}
			],
			"Subject": "Solicitud de Cambio de Método de Pago.",
			"HTMLPart": template,
		}

		AxiosSubscription.sendMail(body, null, false).then(resp => {
			this.setState({ disableEmail: false });
			if (resp?.data?.Messages[0]?.Status === "success") {
				ModalResponse({
					title: "Correo electrónico enviado exitosamente.",
					res: 200
				});
			} else {
				ModalResponse({
					title: "Error al enviar correo electrónico.",
					res: 400
				});
			}

		}).catch(error => {
			console.log("Envio de mail resp ERROR=> ");
			console.log(error);
			this.setState({ disableEmail: false });
			ModalResponse({
				title: "Error al enviar correo electrónico.",
				res: 400
			});
		});

	}

	createLogActivity(object) {
		let aditionalData = { canceled_id: object._id }

		let params = {
			nameComponent: "Gestor de Suscripciones",
			nameUser: getCookie('name'),
			action: "Cancel Subscription",
			date: (new Date()).toISOString(),
			aditionalData: JSON.stringify(aditionalData),
		}

		this.props.actions.createLog(params);
	}


	handleDate = (data) => {
		let fromDate = undefined;
		let toDate = undefined;
		if (data.from !== undefined) {
			fromDate = new Date(data.from);
			fromDate.setHours(0);
			fromDate.setMinutes(0);
			fromDate.setSeconds(0);
			fromDate.setMilliseconds(0);
		}
		if (data.to !== undefined) {
			toDate = new Date(data.to);
			toDate.setHours(23);
			toDate.setMinutes(59);
			toDate.setSeconds(59);
			toDate.setMilliseconds(999);
		}
		this.setState({ fromDate, toDate }, function () { this.getSubscriptionParams(this.state.fromDate, this.state.toDate, this.state.filter) });
		return { from: fromDate, to: toDate };
	}


	focusInput = (e) => {
		e.preventDefault();
		e.currentTarget.placeholder = '';
		e.currentTarget.classList.remove("inputError");
		e.currentTarget.classList.remove("inputOk");
		e.currentTarget.classList.add('inputFocus');
		if (e.currentTarget.previousSibling) {
			e.currentTarget.previousSibling.classList.remove('inputError');
			e.currentTarget.previousSibling.classList.remove('inputOk');
			e.currentTarget.previousSibling.classList.add('labelModal');
		}
	};

	blurInput = (e) => {
		e.preventDefault();
		if (e.currentTarget.value === '') {
			e.currentTarget.classList.remove("inputOk");
			e.currentTarget.classList.add('inputError');
			if (e.currentTarget.previousSibling) {
				e.currentTarget.previousSibling.classList.remove('labelOk');
				e.currentTarget.previousSibling.classList.add('labelError');
			}
		} else {
			e.currentTarget.classList.remove("inputError");
			e.currentTarget.classList.add('inputOk');
			if (e.currentTarget.previousSibling) {
				e.currentTarget.previousSibling.classList.remove('labelError');
				e.currentTarget.previousSibling.classList.add('labelOk');
			}
		}
	};



	renderModalDataBasic = (data) => {
		return (
			<div className="uxDetailContainer">
				<div>
					<b><span className="labelUxDetail" style={{ margin: "15px" }}>Nombre del Cliente:	</span></b> <span id="uxDetailProfile">{data.payerName ? data.payerName : "N/A"}</span>
				</div>
				<div>
					<b><span className="labelUxDetail" style={{ margin: "15px" }}>Identificación:	</span></b> <span id="uxDetailId">{data.payerDocument ? data.payerDocument : "N/A"}</span>
				</div>
				<div>
					<b><span className="labelUxDetail" style={{ margin: "15px" }}>Correo Electrónico:	</span></b> <span id="uxDetailName">{data.payerEmail ? data.payerEmail : "N/A"}</span>
				</div>
				<div>
					<b><span className="labelUxDetail" style={{ margin: "15px" }}>Telefono:	</span></b> <span id="uxDetailLastName">{data.payerPhone ? data.payerPhone : "N/A"}</span>
				</div>

				<div>
					<b><span className="labelUxDetail" style={{ margin: "15px" }}>Ciudad:	</span></b> <span id="uxDetailCity">{data.payerCity ? data.payerCity : "N/A"}</span>
				</div>
				<div>
					<b><span className="labelUxDetail" style={{ margin: "15px" }}>Dirección:	</span></b> <span id="uxDetailDepartment">{data.payerAddress ? data.payerAddress : "N/A"}</span>
				</div>
				<div>
					<b><span className="labelUxDetail" style={{ margin: "15px" }}>País:	</span></b> <span id="uxDetailCountry">{data.payerCountry ? data.payerCountry : "N/A"}</span>
				</div>
				<div>
					<b><span className="labelUxDetail" style={{ margin: "15px" }}>Número de Tarjeta:	</span></b> <span id="uxDetailCelphone">{data.ccNumber ? data.ccNumber : "N/A"}</span>
				</div>
				<div>
					<b><span className="labelUxDetail" style={{ margin: "15px" }}>RUC:	</span></b> <span id="uxDetailCelphone">{data.companyID ? data.companyID : "N/A"}</span>
				</div>

			</div>
		);
	};

	renderModalDataSubscription = (data) => {
		return (
			<div className="uxDetailContainer">
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>ID de Registro Aurora:	</span></b> <span id="uxDetailProduct">{data.id ? data.id : "N/A"}</span>
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>ID de Suscripción:	</span></b>      
					<div  style={{ marginLeft: "15px" }}>
						<div><span id="uxDetailProduct">{data.hashData ? data.hashData.substring(0,50) : "N/A"}</span> </div>
						<div><span id="uxDetailProduct" >{data.hashData ? data.hashData.substring(50,data.hashData.length) : "N/A"}</span> </div>
					</div>
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>ID Aurora Primer Pago:	</span></b> <span id="uxDetailProduct">{data.originalReferenceCode ? data.originalReferenceCode : "N/A"}</span>
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>ID Transacción Primer Pago:	</span></b> <span id="uxDetailProduct">{data.originalTransactionId ? data.originalTransactionId : "N/A"}</span>
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}> N° Cotización:	</span></b> <span id="uxDetailSource">{data.productId ? data.productId : "N/A"}</span>
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>Fecha de Registro de Suscripción:	</span></b> <span id="uxDetailSerial">{data.date ? data.date : "N/A"}</span>
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>Medio de Pago:	</span></b> <span id="uxDetailRefer">{data.paymentMethodSelected ? data.paymentMethodSelected : "N/A"}</span>
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>Es pago Recurrente:	</span></b> <SubscriptionStates subState={true} selector={"isRecurrentPay"} valueSelector={data.isRecurrentPay} />
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>Token de Suscripción:	</span> </b><span id="uxDetailAlliesId">{data.kushkiTokenSubscription ? data.kushkiTokenSubscription : "N/A"}</span>
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>Estado de Suscripción:	</span> </b> <SubscriptionStates subState={true} selector={"isActive"} valueSelector={data.isActive} />
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>Periodicidad:	</span> </b> <SubscriptionStates subState={true} selector={"periodicity"} valueSelector={data.periodicity} />
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>Valor a Pagar:	</span></b>  <span id="uxDetailSeller">{data.recurrentValue ? data.recurrentValue : "N/A"}</span>
				</div>
				<div>
					<b><span class="labelUxDetail" style={{ margin: "15px" }}>Fecha del Primer Pago:	</span> </b><span id="uxDetailSeller">{data.startPay ? data.startPay : "N/A"}</span>
				</div>
				<div>
					<b>	<span class="labelUxDetail" style={{ margin: "15px" }}>Fecha del último Pago:	</span> </b> <span id="uxDetailSeller">{data.endPay ? data.endPay : "N/A"}</span>
				</div>
				{data.deleteAt ?
					<div>
						<b>	<span class="labelUxDetail" style={{ margin: "15px" }}>Fecha de Eliminación:	</span> </b> <span id="uxDetailSeller">{data.deleteAt ? data.deleteAt : "N/A"}</span>
					</div> : null
				}
			</div>
		);
	};

	copyText = (text, label, e) => {
		e.preventDefault();
		var aux = document.createElement('input');
		aux.setAttribute('value', text);
		document.body.appendChild(aux);
		aux.select();
		document.execCommand('copy');
		document.body.removeChild(aux);
		ModalResponse({
			title: label + ' copiado correctamente.',
			text: 'Copiar ' + label,
			res: 200
		});
	};

	handleChangeInput(e) {
		e.preventDefault();
		this.setState({ [e.target.name]: e.target.value });
	}
	handleChangeSuscript(e) {
		e.preventDefault();
		this.setState({updateSuscritpData:{ ...this.state.updateSuscritpData,[e.target.name]: e.target.value }});
	}

	setFilterValue(filter) {
		this.setState({ filter: filter });
	}

	stringToBase64(text) {
		return Buffer.from(text).toString("base64");
	}

	getPINvalue() {
		var pin = "";
		for (let i = 0; i < 4; i++) {
			pin += String(Math.floor((Math.random() * 9)));
		}
		return pin;
	}

	monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth() + 1;
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	handleEditSuscriptDate = (data) => {
		let newEndDate = undefined;
		if (data !== undefined) {
			newEndDate = new Date(data);
			newEndDate.setHours(23);
			newEndDate.setMinutes(59);
			newEndDate.setSeconds(59);
			newEndDate.setMilliseconds(999);
		}
		this.setState({ updateSuscritpData:{...this.state.updateSuscritpData,newEndDate} })
	}

	handleChangeTab(e) {
		e.preventDefault()
		let change = true
		change = change != this.state.updateSuscritpData.changeTab
		this.setState({ updateSuscritpData:{...this.state.updateSuscritpData,changeTab: change} })
	}

	handleUpdateSuscription = async (e) => {
		e.preventDefault()
		const { endPay, nameProductRecurrent, recurrentValue } = this.state.subscription
		let newProduct = this.state.updateSuscritpData.newProduct || undefined
		let newValue = parseFloat(this.state.updateSuscritpData.newValue) || null
		let newEndDate = this.parseDate(this.state.updateSuscritpData.newEndDate) || undefined

		const UpdateSuscription = {
			referenceSuscription: this.state.subscription.id,
			plan: newProduct,
			value: newValue,
			endDate: newEndDate
		}

		let validNewPlanName = newProduct && (newProduct !== nameProductRecurrent)
		let validNewValue = newValue && (newValue !== recurrentValue)
		let validNewEndDate = newEndDate && (newEndDate !== endPay)
		
		if( validNewPlanName || validNewValue || validNewEndDate){
			
			let invalidAmounts = newValue && (3 > newValue || newValue > 1000)
			let invalidProductName = newProduct && (newProduct.length < 5 || newProduct.length > 150)
			
			if(invalidAmounts){
				ModalResponse({
					title: "Verifica el monto",
					text: "el valor debe estar entre 3 - 1000 USD",
					res: 400
				});
				return
			}
			if(invalidProductName){
				ModalResponse({
					title: "Verifica la información",
					text: "el nombre del plan de la suscripción debe ser de mínimo 5 a máximo 150 caracteres",
					res: 400
				});
				return
			}
			this.setState({updateSuscritpData:{...this.state.updateSuscritpData,loadingResp: true}})
			await AxiosUpdateSubscription.updateSuscription(UpdateSuscription).then(resp => {
				if (resp.status === 201 && resp.data) {
					this.setState({updateSuscritpData:{...this.state.updateSuscritpData,successMsg: resp.data.message}})
					ModalResponse({
						title: "Suscripción actualizada",
						text: "Por favor cierra esta ventana y refresca la consulta",
						res: 200
					});
				} else {
					this.setState({updateSuscritpData:{...this.state.updateSuscritpData,notUpdated: true}})
					ModalResponse({
						title: "¡Atención!",
						text:"No se logró actualizar la suscripción, intenta de nuevo por favor",
						res: 400
					});
				}
			}).catch(error => {
				console.log("UpdateSuscription error => ", error);
				this.setState({updateSuscritpData:{...this.state.updateSuscritpData,notUpdated: true}})
				ModalResponse({
					title: "Ocurrió un error",
					text: "Por favor contacta a soporte técnico",
					res: 400
				});
			});
		}else{
			ModalResponse({
				title: "¡Hey!",
				text: "Debes actualizar al menos un dato",
				res: 400
			});
		}
	}

	parseDate(date) {
		if (typeof date === 'string') {
			const [year, month, day] = date.split('-').map(Number);
			return new Date(year, month - 1, day);
		} else if(date !== undefined){
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		}else{
			return ''
		}
	}

	renderEditSuscription() {

		let newEndDate = this.parseDate(this.state.subscription.endPay)

		return (
			<>
				<div class="uxDetailData">
					<div className="uxDetailDataFilterContainer">
						<div className="uxDetailDataFilter">
							<br />
							<div style={{ textAlign: "center" }}>
								<b><span >Puedes editar todos o solo uno de los parámetros de la suscripción</span></b>
							</div>
							<br />
							<br />
							<div className="gridModalEditSuscript">
								<div className="item1Modal">
									<label><b>Fecha fin de la suscripción :</b></label>
								</div>
								<div className="item2Modal2" >
									<DayPickerInput
										inputProps={{ className: "inputDatePicker updateDatePickerFilter" }}
										value={!this.state.updateSuscritpData.newEndDate ? newEndDate : this.state.updateSuscritpData.newEndDate}
										format="LL"
										formatDate={formatDate}
										parseDate={parseDate}
										dayPickerProps={{
											firstDayOfWeek: 1,
											selectedDays: newEndDate,
											disabledDays: { before: newEndDate },
											isValidDay: typeof newEndDate !== 'undefined',
											localeUtils: MomentLocaleUtils,
											locale: 'es'
										}}
										onDayChange={this.handleEditSuscriptDate}
									/>
								</div>
							</div>
							<br />
							<div className="gridModalEditSuscript">
								<div className="item1Modal">
									<label><b>Valor de la suscripción :</b></label>
								</div>
								<div className="item2ModalSuscript">
									<input
										className="inputFilter"
										id="newValue"
										name="newValue"
										type="number"
										defaultValue={this.state.subscription.recurrentValue}
										onChange={(e) => { this.handleChangeSuscript(e) }}
										onKeyDown={(e) => {
											if (
											  e.key === "e" ||
											  e.key === "E" ||
											  e.key === "+" ||
											  e.key === "-" 
											) {
											  e.preventDefault();
											}
										  }}
									/>
								</div>
							</div>
							<br />
							<div className="gridModalEditSuscript" >
								<div className="item1Modal">
									<label><b>Nombre plan de suscripción :</b></label>
								</div>
								<div className="item2ModalSuscript">
									<input
										className="inputFilter"
										id="newProduct"
										name="newProduct"
										type="text"
										defaultValue={this.state.subscription.nameProductRecurrent}
										onChange={(e) => { this.handleChangeSuscript(e) }}
									/>
								</div>
							</div>
							
							<div className="updateBtnSection">
								{this.state.updateSuscritpData.loadingResp && !this.state.updateSuscritpData.notUpdated ? <b><span >{( this.state.updateSuscritpData.successMsg && "Suscripción actualizada") || "Esperando respuesta..." }</span></b>
								:<button className="btnAddP" onClick={this.handleUpdateSuscription}>{"Actualizar"}</button>}
							</div>
							<br />
							<br />
						</div>
					</div>
				</div >
			</>
		)
	}

	tabsEditSuscriptionData() {
		let selectedEdit = <button className={!this.state.updateSuscritpData.changeTab ? "selectedTab":"unSelectedTab"} disabled={this.state.updateSuscritpData.changeTab} onClick={(e) => { this.handleChangeTab(e) }}>{"Editar Suscripción"}</button>
		let selectedUpdatePayTC = <button className={this.state.updateSuscritpData.changeTab ? "selectedTab":"unSelectedTab"} disabled={!this.state.updateSuscritpData.changeTab} onClick={(e) => { this.handleChangeTab(e) }}>{"Actualizar Tarjeta de Crédito"}</button>
		return (
			<>
				<div className="selectEditSection">
					{selectedEdit}
					{selectedUpdatePayTC}
				</div>
				{this.state.updateSuscritpData.changeTab ? this.renderEditSuscription() : this.renderModalEditCreditCard()}
			</>
		)
	}

	renderModalEditCreditCard() {

		return (
		<>
			<div>
				<div class="uxDetailData">
					<div className="uxDetailDataFilterContainer">
						<div className="uxDetailDataFilter">
							<br />
							<div style={{ textAlign: "center" }}>
								<b><span >Este Enlace tiene una vigencia de 24 horas</span></b>
							</div>
							<br />
							<br />
							<div className="gridModalEditCC" style={{ textAlign: "center" }}>
								<div className="item1Modal">
									<label><b>Correo:</b></label>
								</div>
								<div className="item2Modal">
									<input
										className="inputFilter"
										id="mailEdit"
										name="mailEdit"
										type="text"
										placeholder=""
										onFocus={(e) => this.focusInput(e)}
										onBlur={(e) => this.blurInput(e)}
										value={this.state.mailEdit}
										onChange={(e) => { this.handleChangeInput(e) }}
									/>
								</div>
								<div>
									<button style={{ opacity: this.state.disableEmail ? "0.5" : "1.0" }} disabled={this.state.disableEmail} onClick={(e) => { this.sendMail(e) }} className="btnAddP">
										{this.state.disableEmail ? "Enviando..." : "Enviar"}
									</button>
								</div>

							</div>
							<br />
							<div className="gridModalEditCC" style={{ textAlign: "center" }}>
								<div className="item1Modal">
									<label><b>Enlace:</b></label>
								</div>
								<div className="item2Modal">

									<input
										className="inputFilter"
										id="link"
										name="linl"
										type="text"
										placeholder=""
										onFocus={(e) => this.focusInput(e)}
										onBlur={(e) => this.blurInput(e)}
										disabled={true}
										value={this.state.link}
									/>
								</div>
								<div>
									<button className="btnAddP" onClick={(e) => { this.copyText(this.state.link, "Enlace", e) }}>
										Copiar
                					</button>
								</div>

							</div>
							<br />
							<div className="gridModalEditCC2" style={{ textAlign: "center" }}>
								<div className="item1Modal">
									<label><b>PIN:</b></label>
								</div>
								<div className="item2Modal2">

									<input
										className="inputFilter"
										id="pin"
										name="pin"
										type="text"
										placeholder=""
										onFocus={(e) => this.focusInput(e)}
										onBlur={(e) => this.blurInput(e)}
										disabled={true}
										value={this.state.pin}
									/>
								</div>
								<div>
									<button className="btnAddP" onClick={(e) => { this.copyText(this.state.pin, "PIN", e) }}>
										Copiar
                					</button>
								</div>

							</div>
							<br />
							<br />
						</div>
					</div>
				</div>
			</div>
		</>
		);
	}



	renderModalDelete() {
		return (
			<div>
				<div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
					<h4 >¿Esta seguro de que desea eliminar la suscripción de la tarjeta del Señor(a) <b style={{ color: "red" }}>{this.state.subscription.payerName}</b>?</h4>
				</div>
				<div className="uxDetailContainer" style={{ textAlign: "center" }}>

					<div >
						<span class="labelUxDetail">Se le enviará  al cliente una notificación acerca de la eliminación de su suscripción al siguiente correo: <b>{this.state.subscription.payerEmail}</b></span>
					</div>
					<br />
					<button
						style={{ margin: "20px", opacity: this.state.disableBtnDelete ? "0.5" : "1.0" }}
						disabled={this.state.disableBtnDelete}
						className="orange-fill"
						type="button"
						onClick={this.deleteSubscription}
					>Eliminar
                </button>
					<button
						style={{ margin: "20px", opacity: this.state.disableBtnDelete ? "0.5" : "1.0" }}
						className="red-fill"
						disabled={this.state.disableBtnDelete}
						type="button"
						onClick={this.showModalDeleteSubscription}
					>Cancelar
                </button>
				</div>
			</div >
		);
	}

	onHandleDownload = (datos) => {
		this.setState({ download: true });
		if (datos.length > 0) {
			downloadEnable = true;
			downloading = true;

			let extCols = [
				{
					name: 'ID Aurora Primer Pago',
					selector: 'originalReferenceCode',
				},
				{
					name: 'Fecha del Primer Pago',
					selector: 'startPay',
				},
				{
					name: 'Fecha del último Pago',
					selector: 'endPay',
				},
				{
					name: 'Fecha de Eliminación',
					selector: 'deleteAt',
				},
				{
					name: 'Recurrencia',
					selector: 'isRecurrentPay',
				},
				{
					name: 'Periodicidad',
					selector: 'periodicity',
				}

			]
			let copyColumns = [...columns, ...extCols];

			let columnsReq = copyColumns.map(function (column) {
				let columnAux = [];
				columnAux.title = column.name;
				columnAux.id = column.selector;
				return JSON.stringify(Object.assign({}, columnAux));
			}).join();

			let datosString = datos.map(function (dataItem) {

				var strReplace = (value, replace) => {
					var r = (value === undefined || value === '' || value === null) ? replace : value;
					return r;
				}

				let data = {};
				data = {
					"_id": strReplace(dataItem._id, "N/A"),
					"date": strReplace(dataItem.date, "N/A"),
					"payerDocument": strReplace(dataItem.payerDocument, "N/A"),
					"payerName": strReplace(dataItem.payerName, "N/A"),
					"payerEmail": strReplace(dataItem.payerEmail, "N/A"),
					"companyID": strReplace(dataItem.companyID, "N/A"),
					"originalReferenceCode": strReplace(dataItem.originalReferenceCode, "N/A"),
					"originalTransactionId": strReplace(dataItem.originalTransactionId, "N/A"),
					"productId": strReplace(dataItem.productId, "N/A"),
					"isActive": strReplace(subscriptionActive[dataItem.isActive], "N/A"),
					"isRecurrentPay": strReplace(subscriptionRecurrent[dataItem.isRecurrentPay], "N/A"),
					"periodicity": strReplace(subscriptionPeriodicity[dataItem.periodicity], "N/A"),
					"recurrentValue": strReplace(dataItem.recurrentValue, "N/A"),
					"startPay": strReplace(dataItem.startPay, "N/A"),
					"endPay": strReplace(dataItem.endPay, "N/A"),
					"deleteAt": strReplace(dataItem.deleteAt, "N/A"),
				}


				return JSON.stringify(Object.assign({}, data));
			}).join();

			let reqExcel = {
				"columns": JSON.parse(`[${columnsReq}]`),
				"data": JSON.parse(`[${datosString}]`),
				"typeFile": "csv"
			};

			this.props.actions.sendExcelObject(reqExcel);

		} else {
			this.setState({ download: false });
			ModalResponse({
				title: 'Descargar excel',
				text: 'No hay datos para descargar',
				res: 404,
			});
		}
	};

	downloadExcel = (data) => {

		var blob = new Blob([atob(data)], { encoding: "UTF-8", type: 'text/plain;charset=UTF-8' });
		if (window.navigator.msSaveBlob) {
			window.navigator.msSaveOrOpenBlob(blob, 'exportData_RecurrentPayment' + new Date().toDateString() + '.csv');
		}
		else {
			var a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(blob, { type: "text/plain;charset=UTF-8" });
			a.download = "exportData_RecurrentPayment" + new Date().toDateString() + ".csv";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}

	render() {
		let { error, isLoading, subscriptions, deleteResponse, excelData } = this.props;

		const btnActions = {
			btnDeleteSubscription: accessDisable ? this.btnDeleteSubscription.bind(this) : null,
			btnShowDetail: this.showDetail.bind(this),
			btnShowEditCreditCard: accessEditPM ? this.showEditCreditCard.bind(this) : null,
		}

		let datos = [];

		
		if (subscriptions) {
			subscriptions.data.subscriptionList = subscriptions.data.subscriptionList.map((element) => {				
				if(element.suscriptionID){
					element.subscriptionID = element.suscriptionID;
					delete element.suscriptionID;
				}
				return element;
			});

			if (subscriptions.status === 201 || subscriptions.status === 200) {
				datos = subscriptions.data.subscriptionList;
			}
		}
		

		if (excelData) {
			if (excelData.status === 200) {
				if (downloadEnable) {
					this.downloadExcel(excelData.data);
					this.setState({ download: false });
					downloadEnable = false;
					downloading = false;
				}
			}
			else {
				if (downloading) {
					this.setState({ download: false });
					ModalResponse({
						title: 'Error al Descarga Excel!',
						text: 'La descarga del Excel ha fallado. Comuníquece con el Administrador.',
						res: 404
					});
				}
				downloading = false;
				downloadEnable = false;
			}
			downloading = false;
			downloadEnable = false;
		}

		if (deleteSubscriptioFlag) {
			if (deleteResponse) {
				if (deleteResponse.status === 201) { //kuski 201: success case
					var respdataHandler = deleteResponse.data;
					if (respdataHandler.status) {
						var respAurora = respdataHandler;
						if (respAurora.status === true) {
							ModalResponse({
								title: 'Eliminar Suscripción',
								text: "Suscripción Eliminada con Exito.",
								res: 200,
							});
							const subs = datos.find(item => item.id === this.state.subscription.id);
							subs.isActive = "0";
							this.createLogActivity(subs);

						} else {
							ModalResponse({
								title: 'Eliminar Suscripción',
								text: "Ha ocurrido un problema, inténtelo más tarde",
								res: 404
							});
						}
					} else {
						ModalResponse({
							title: 'Eliminar Suscripción',
							text: "Ha ocurrido un problema, inténtelo más tarde",
							res: 404
						});
					}
				} else {
					ModalResponse({
						title: 'Eliminar Suscripción',
						text: "Ha ocurrido un problema, inténtelo más tarde",
						res: 404
					});
				}
				deleteSubscriptioFlag = false;
				this.setState({ disableBtnDelete: false });
				this.showModalDeleteSubscription();
			}
		}



		return (
			<div>
				<div>
					<h3>Gestor de Suscripciones</h3>
					<SearchFilter
						handleDate={this.handleDate}
						from={this.state.fromDate}
						to={this.state.toDate}
						filter={this.state.filter}
						disablebDownload={accessDownload}
						downloading={this.state.download}
						datos={datos}
						focusInput={this.focusInput}
						blurInput={this.blurInput}
						filtrarSubscriptions={this.filtrarSubscriptions}
						onHandleDownload={this.onHandleDownload}
						setFilterValue={this.setFilterValue}
						cleanFilter={this.cleanFilter}
					/>

				</div>
				{error && <h4>Error: {error.response.data}</h4>}
				{(isLoading) ? (
					<div style={{ padding: "10em" }}>
						<Loader />
					</div>
				) : (
					<div>

						<div className="divTable coustomTab">
							<TableReport
								columns={columns} // Data columns to table
								tabs={false} //Enable tabs
								tabsData={[]} //Load data to tabs check option on tabsData const
								headerCounterTab={true} //Counter tab option
								btnActions={btnActions}
								actionButtons={true} //Enable buttons (edit, delete, copy, open)
								pageSize={50} // Enable page size
								pagination={true} //Enable pagination (true, false)
								paginationNav={'arrows'} //Nav options (arrow, dot)
								paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
								maxSizePagination={6} //Size pagination
								defaultPaginationNumber={1} //Current pagination when start app
								paginationGrouped={10} //Grouped by page
								detailRow={false} //Open column detail with left arrow
								data={datos} //Data for table
							/>
						</div>
						<Modal
							onClose={this.showModalDetail}
							show={this.state.showDetail}
							title="Detalles de la Suscripción"
							open={this.state.openDetail}
						>
							<div class="uxDetailData">

								<div className="uxDetailDataFilterContainer">
									<div className="uxDetailDataFilter">
										<div className="uxDetailDataFilterTitle">
											<h3>Datos del Cliente</h3>
										</div>
										{this.renderModalDataBasic(this.state.subscription)}
									</div>

									<div class="uxDetailDataFilter">
										<div class="uxDetailDataFilterTitle">
											<h3>Datos de Suscripción</h3>
										</div>
										{this.renderModalDataSubscription(this.state.subscription)}
									</div>
								</div>
							</div>


						</Modal>
						<Modal
							onClose={this.showModalDeleteSubscription}
							show={this.state.showDelete}
							title="Eliminar Suscripción"
							open={this.state.openDelete}>
							{this.renderModalDelete()}
						</Modal>

						<Modal
							onClose={this.showModalEditCreditCard}
							show={this.state.showEditCard}
							open={this.state.openEditCard}>
							{this.tabsEditSuscriptionData()}
						</Modal>

					</div>
				)}
			</div>
		);
	}
}

AuroraSubscription.propTypes = {
	actions: PropTypes.object.isRequired,
	subscriptions: PropTypes.object,
	error: PropTypes.object,
	deleteResponse: PropTypes.object,
	log: PropTypes.object,
	excelData: PropTypes.object,
};

const mapStateToProps = state => ({
	subscriptions: state.auroraSubscription.subscriptions,
	error: state.auroraSubscription.error,
	isLoading: state.auroraSubscription.isLoading,
	isLoadingModify: state.auroraSubscription.isLoadingDelete,
	deleteResponse: state.auroraSubscription.deleteResponse,
	log: state.logs.log,
	excelData: state.synergy.excelData,

});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(auroraSubscriptionActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(AuroraSubscription);
