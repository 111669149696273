import * as types from '../actions';

const statePaymentReportReducer = (state = { isLoading: false }, action = null) => {

    switch (action.type) {
        case types.GET_STATEPAYMENTS:
            return { ...state, isLoading: true };

        case types.GET_STATEPAYMENTS_RECEIVED:
            return { ...state, isLoading: false, payments: action.data }

        case types.GET_STATEPAYMENTS_FAILED:
            return { ...state, isLoading: false, payments: action.error }
            
        case types.GET_PAYMENTS_CLEARED:
            return { ...state, isLoading: false, payments: undefined }

        default:
            return state;
    }
};

export default statePaymentReportReducer;