import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as activationManagementAction from '../../actions/activationManagement/activationManagementAction';
import { bindActionCreators } from 'redux';
import Modal from './modal';
import "./style.scss";
import { errorCodeEnum } from './enums';
import config from '../../config';

class ActivationManagementComponent extends Component {
  constructor(props) {
  super(props);
    this.state = {
      file: null,
      loadingFile: false,
      isDisableButtons: false
    };

    this.errorCodes = new Set(Object.values(errorCodeEnum));
    this.template = config.activationManagementTemplate ?? '#';
	}

  componentWillReceiveProps(nextProps){
    if(nextProps.buttonAction){
      this.setState({
        loadingFile: true,
        isDisableButtons: true
      });
    }
  }

  componentDidUpdate() {
    let { t, error, fileResponse, menuActivated } = this.props;

    if (fileResponse && this.state.loadingFile) {
      this.manageFileResponse(menuActivated, fileResponse, t);
      }
    if (error) {
      this.manageError(menuActivated, error, t);
    }
  }

  clearForm() {
    this.setState({
      file: null,
      loadingFile: false,
      isDisableButtons: false
    });

    document.getElementById("upfile").value = "";
  }

  manageError(menuActivated, error, t) {
    const errorText = menuActivated
      ? t("ActivationToolManagement.modalInfo.errorLoadingFileActivate")
      : t("ActivationToolManagement.modalInfo.errorLoadingFileDisable");

    Modal({
      dataText: {
        title: t("ActivationToolManagement.modalInfo.error"),
        text: errorText,
        confirmButtonText: t("ActivationToolManagement.modalInfo.understood"),
        type: 'error'
      }
    });

    this.clearForm();
    this.props.actions.clearMenuActivateState();
  }

  verifyProcessErros(fileResponse) {
    // TODO - Refactor with MS Errors response
    const invalidFileError = 'Object reference not set to an instance of an object.';

    if(fileResponse?.data?.includes('DuplicateKey')) {
      return errorCodeEnum.duplicateRecordsError;
    } else if(fileResponse?.Errors && fileResponse?.Errors[0]?.Detail) {
      return errorCodeEnum.invalidTokenError;
    } else if(fileResponse.data == invalidFileError) {
      return errorCodeEnum.invalidFileStructureError;
    }

    return fileResponse.data;
  }

  manageFileResponse(menuActivated, fileResponse, t) {
    fileResponse.data = this.verifyProcessErros(fileResponse);

    const isMSErrorCode = this.errorCodes.has(fileResponse?.data) && !fileResponse?.success;

    isMSErrorCode
      ? this.openPopupErrorResponse(t, fileResponse?.data)
      : this.openPopupSuccessResponse(t, menuActivated);

    this.clearForm();
    this.props.actions.clearMenuActivateState();
  }

  openPopupSuccessResponse(t, menuActivated) {
    const menuActivatedTitle = menuActivated
      ? t("ActivationToolManagement.modalInfo.menuActivated")
      : t("ActivationToolManagement.modalInfo.menuDisabled");

    const menuActivatedText = menuActivated
      ? t("ActivationToolManagement.modalInfo.menuActivatedText")
      : t("ActivationToolManagement.modalInfo.menuDisabledText");

    Modal({
      dataText: {
        title: menuActivatedTitle,
        text: menuActivatedText,
        confirmButtonText: t("ActivationToolManagement.modalInfo.understood"),
        type: 'success'
      }
    });
  }

  openPopupErrorResponse(t, errorCode) {
    Modal({
      dataText: {
        title: t("ActivationToolManagement.modalInfo.error"),
        text: t(`ActivationToolManagement.msErrors.${errorCode}`),
        confirmButtonText: t("ActivationToolManagement.modalInfo.understood"),
        type: 'error'
      }
    });
  }

  render(){
    let { t } = this.props;

    const openModal = () =>{
      Modal({
        dataText: {
          title: t("ActivationToolManagement.modalInfo.error"),
          text: t("ActivationToolManagement.modalInfo.noFileSelected"),
          confirmButtonText: t("ActivationToolManagement.modalInfo.understood"),
          type: 'error'
        }
      });
    }

    const handleActivate = () => {
      if (this.state.file) {
        validateFile(true);
      } else {
        openModal();
      }
    }

    const handleInactivate = () => {
      if (this.state.file) {
        validateFile(false);
      } else {
        openModal();
      }
    }

  const changeHandler = (event) => {
    this.setState({file: event.target.files[0]})
  }

  const validateFile = (enableMenu) => {
    if(this.state.file.name.indexOf('.xlsx') !== -1 || this.state.file.name.indexOf('.xls') !== -1){
      manageAction(enableMenu);
    } else {
      Modal({
        dataText: {
          title: t("ActivationToolManagement.modalInfo.uploadedFile"),
          text: t("ActivationToolManagement.modalInfo.onlyExcelFiles"),
          confirmButtonText: t("ActivationToolManagement.modalInfo.understood"),
          type: 'error'
        }
      });
      this.clearForm();
    }
  }

  const manageAction = (enableMenu) => {
    Modal({
      actionDelete: enableMenu
        ? this.props.actions.saveMenuActivate
        : this.props.actions.removeMenuActivate,
      id: this.state.file,
      dataText: {
        title: '',
        text: enableMenu
        ? t("ActivationToolManagement.modalInfo.activeMessage")
        : t("ActivationToolManagement.modalInfo.inactiveMessage"),
        showCancelButton: true
      }
    });
  }

    return (
      <div className="ActivationManagementComponent">
        <h1> {t("ActivationToolManagement.titleToolActivation")} </h1>
        <p> {t("ActivationToolManagement.stepOne")}
          <a href={this.template}>{t("ActivationToolManagement.template")} </a>
        </p>
        <p> {t("ActivationToolManagement.stepTwo")} </p>
        <p> {t("ActivationToolManagement.stepTree")} </p>
        <div className="upload">
            <input
                type="file"
                id="upfile"
                name="upfile"
                accept='.xlsx, .xls'
                onChange={(e)=> changeHandler(e)} />
                  {this.state.loadingFile ? (<h3>Cargando Archivo ...</h3>): null}
        </div>
        <div className="actions-buttons">
            <siigo-button-atom
                className="siigobutton save-button"
                color="primary"
                size="l"
                text="Activar"
                type="button"
                disabled={this.state.isDisableButtons}
                onClick={handleActivate}
            >
            </siigo-button-atom>
            <siigo-button-atom
                class="siigobutton save-button"
                color="primary"
                size="l"
                text="Desactivar"
                disabled={this.state.isDisableButtons}
                onClick={handleInactivate}
            ></siigo-button-atom>
        </div>
      </div>
    )
  }
}

ActivationManagementComponent.propTypes = {
	actions: PropTypes.object.isRequired,
  error: PropTypes.object
};

const mapStateToProps = (state) => ({
  file: state.activationManagement.file,
  fileResponse: state.activationManagement.fileResponse,
  error: state.activationManagement.error,
  menuActivated: state.activationManagement.menuActivated,
  buttonAction: state.activationManagement.buttonAction
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(activationManagementAction, dispatch),
  });

export default  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActivationManagementComponent));