import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import svgs from '../../layout/icons';
import '../table-report.scss';

class HeaderTable extends Component {
	render() {
		const { columns, actionButtons, btnActions, data } = this.props;
		return (
			<thead>
				<tr>
					{btnActions.ckSend ? (
						<th>
							<div className="slAll">
								<div>Todo</div>
								<div>
									<input
										id="ckAll"
										className="ckTableTh"
										type="checkbox"
										onClick={e => btnActions.ckAllItem(e, data)}
									/>
								</div>
							</div>
						</th>
					) : null}
					{(btnActions.chMassiveActions && btnActions.chMassiveActions !== false && btnActions.chMassiveActions !== null) ? (
						<th>
							<div className="slAll">
								<div>Seleccionar</div>
							</div>
						</th>
					) : null}
					{columns.map((col, key) => (
						<th key={key}>
							<Link
								className={col.sortable ? 'sort' : null}
								to={'#'}
								onClick={
									col.sortable
										? () => this.props.dynamicSort(col.selector)
										: null
								}
							>
								{col.name}
								{col.sortable ? (
									<img className="icon-orden" src={svgs['orden']} alt="" />
								) : null}
							</Link>
						</th>
					))}
					{actionButtons ? (
						<th>
							<Link className="actions" to={'#'}>
								Acciones
              </Link>
						</th>
					) : null}
				</tr>
			</thead>
		);
	}
}
export default HeaderTable;
