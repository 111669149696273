import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/indexGeneral';
import config from '../config.js';

export function* getShortedUrl(action) {
    const api = API.create(config.shortenURL);
    try {
        const { params } = action;
        let shortedURL = yield call(api.aurora.shortedURL, params);
        yield put({ type: types.GET_SHORTED_URL_RECEIVED, data: shortedURL });
    } catch (error) {
        yield put({ type: types.GET_SHORTED_URL_FAILED, error });
    }
}
export function* cleargetShortedUrl() {
    yield put({ type: types.GET_SHORTED_URL_CLEARED });
}