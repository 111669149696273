function getEndPoint(name) {
    const endPoints = JSON.parse(localStorage.getItem('configInst'));
    for (let x = 0; x < endPoints.length; x++) {
        if (endPoints[x].name == name) {
            return endPoints[x].url;
        }
    }
}

export default function (api) {
    return {
        addPayrollPackage: (params) => api.post(getEndPoint('addPayrollPackage'),{ ...params }, {}),
        getPayrollBalance: (params) => api.get(getEndPoint('getPayrollBalance') + `?companyKey=${ params }`),
        getPayrollPurchase: (params) => api.get(getEndPoint('getPayrollPurchase')+ `?companyKey=${ params }`),
        deletePayrollPackage: (params) => api.post(getEndPoint('deletePayrollPackage'),{ ...params }, {}),
    };
}
