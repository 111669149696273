import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import DataTime from "../../utils/currentTime";

const dataA = {
    labels: [
        'Vendido',
        'Total',
    ],
    datasets: [{
        data: [10000000, 20000000],
        backgroundColor: [
            '#97bf19',
            '#c3c3c3',
        ],
        hoverBackgroundColor: [
            '#97bf19',
            '#c3c3c3'
        ]
    }]
};

const dataB = {
    labels: [
        'Pendientes',
        'Total',
    ],
    datasets: [{
        data: [7500000, 20000000],
        backgroundColor: [
            '#00aaff',
            '#c3c3c3',
        ],
        hoverBackgroundColor: [
            '#00aaff',
            '#c3c3c3'
        ]
    }]

};

const dataC = {
    labels: [
        'Declinadas',
        'Total',
    ],
    datasets: [{
        data: [2500000, 20000000],
        backgroundColor: [
            '#f7274e',
            '#c3c3c3',
        ],
        hoverBackgroundColor: [
            '#f7274e',
            '#c3c3c3'
        ]
    }]

};

class General extends Component {

    constructor(props){
        super(props);
        this.state = {
            filterType: 'Dinero',
            filterMonth: DataTime.locale.month
        }
    }

    render() {
        const options = {
            maintainAspectRatio: false,
            responsive: false,
            legend: {
                position: 'left',
                labels: {
                    boxWidth: 10
                }
            }
        };

        return (
            <div className="full-card report">
                <div className="card-header">
                    <div className="card-title">
                        <h4 className="text light-gray">Ventas generales</h4>
                    </div>
                    <div className="card-ddl">
                        <div className="field">
                            <input type="text" name="filter-type" defaultValue={ this.state.filterType } disabled />
                            <i className="arrow down"></i>
                        </div>
                        <div className="input-option">
                            <span></span>
                        </div>
                    </div>
                    <div className="card-ddl">
                        <div className="field">
                            <input type="text" name="filter-time" defaultValue={ this.state.filterMonth } disabled/>
                            <i className="arrow down"></i>
                        </div>
                        <div className="input-option">
                            <span></span>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="card is-4">
                        <Doughnut data={dataA} options={options} width={230}/>
                        <h5 className="text light-gray">Ventas efectivas</h5>
                    </div>
                    <div className="card is-4">
                        <Doughnut data={dataB} options={options} width={230}/>
                        <h5 className="text light-gray">Ventas pendientes</h5>
                    </div>
                    <div className="card is-4">
                        <Doughnut data={dataC} options={options} width={230}/>
                        <h5 className="text light-gray">Ventas pendientes</h5>
                    </div>
                    <div className="card is-4 resume-report">
                        <div className="box green border">
                            <h5 className="text light-gray">Total ventas</h5>
                            <h6 className='text green'>20.000.000</h6>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default General;
