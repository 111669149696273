import React from 'react';
import { getGroups } from '../../actions/groups/groupAction';
import { connect } from 'react-redux';

class Groups extends React.Component {
  componentWillMount() {
    this.props.dispatch(getGroups());
  }
  render() {
    const { groups } = this.props;
    let grs = [];
    if (groups) {
      grs = groups.data;
    }
    console.log('this.props.groups:: ', this.props.groups);
    return this.props.groupsUser.map((val, idx) => (
      <div className="grid" key={idx}>
        <div className="col-p input-container ">
          <select
            name="group"
            data-id={idx}
            id={'group-' + idx}
            value={this.props.groupsUser[idx]}
            onChange={this.props.change}
          >
            <option value="-9">Seleccionar Grupo</option>
            {grs.map((gr, index) => {
              return (
                <option key={index} value={gr._id}>
                  {gr.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-c">
          <button
            className="button yellow"
            type="button"
            data-id={idx}
            value="remove"
            onClick={this.props.remove}
          >
            <i className="fa fa-trash"></i> Eliminar
          </button>
        </div>
      </div>
    ));
  }
}

const mapStateToProps = state => ({
  groups: state.groups.groups,
  error: state.groups.err,
  isLoading: state.groups.isLoading
});
export default connect(mapStateToProps)(Groups);
