import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from "../services/api";

const api = API.create();

export function* createCustomer(action) {
    try {
        const { customer } = action;
        const customerDetail = yield call(api.customers.createCustomer, customer).then(response => response.data);
        yield put({ type: types.CREATE_CUSTOMER_SUCCESS, data: customerDetail });
    } catch (err) {
        yield put({ type: types.CREATE_CUSTOMER_FAILED, err});
    }
}

export function* updateCustomer (action) {
    try {
        const { customer, msisdn } = action;
        const customerDetail = yield call(api.customers.updateCustomer, msisdn, customer).then(response => response.data);
        yield put({ type: types.UPDATE_CUSTOMER_SUCCESS, data: customerDetail });
    } catch (err) {
        yield put( { type: types.UPDATE_CUSTOMER_FAILED, err})
    }
}

export function* deleteCustomer (action) {
    const { msisdn } = action;
    try {
        const response = yield call(api.customers.deleteCustomer, msisdn).then(response => response.data);
        yield put({ type: types.DELETE_CUSTOMER_SUCCESS, response });
    } catch (err) {
        yield put( { type: types.DELETE_CUSTOMER_FAILED, err})
    }
}

export function* getCustomers() {
    try {
        let customers = yield call(api.customers.customers);
        yield put({ type: types.CUSTOMERS_RECEIVED,  data: customers })
    } catch (err) {
        yield put({ type: types.CUSTOMERS_REQUEST_FAILED, err })
    }
}

export function* getCustomer(action) {
    try {
        const { msisdn } = action;
        let customers = yield call(api.customers.customerDetail, msisdn);
        yield put({ type: types.CUSTOMER_RECEIVED,  data: customers })
    } catch (err) {
        yield put({ type: types.CUSTOMER_REQUEST_FAILED, err })
    }
}
