import React from 'react';
import Swal from 'sweetalert2';

const Modal = ({ actionDelete = () => {}, id = null, dataText = {}}) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'button green',
      cancelButton: 'button red'
    },
    buttonsStyling: false
  });

  swalWithBootstrapButtons
    .fire({
      title: dataText.title,
      text: dataText.text,
      type: dataText.type ? dataText.type : 'warning',
      showCancelButton: dataText.showCancelButton,
      confirmButtonText: dataText.confirmButtonText ? dataText.confirmButtonText : 'Sí',
      cancelButtonText: dataText.cancelButtonText ? dataText.cancelButtonText : 'No',
      reverseButtons: true
    })
    .then(result => {
      if (result.value && id != null) {
        actionDelete(id);
      }
    });
    return <div></div>;
};

export default Modal;
