import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { registerUserAction } from '../../actions/auth/authenticationsActions';

import './auth.scss';

class Register extends Component {
    onHandleRegistration = (event) => {
        event.preventDefault();

        let name = event.target.name.value;
        let email = event.target.email.value;
        let password = event.target.password.value;

        const data = {
            name, email, password
        };

        this.props.dispatch(registerUserAction(data));
    }

    componentDidMount() {
        document.title = 'React Login';
    }

    render() {
        let message, isSuccess;

        if (this.props.response.register.hasOwnProperty('response')) {
            isSuccess = this.props.response.register.response.success;
            message = this.props.response.register.response.message;
        }

        return (
            <div>
                {!isSuccess ? <div>{message}</div> : <Redirect to='login' />}
                <form onSubmit={this.onHandleRegistration}>
                    <h3>REGISTER</h3>
                    <div className="input-container">
                        <i className="sii sii-user icon"/>
                        <input type="text" name="name" id="name" placeholder="Username" />
                    </div>
                    <div className="input-container">
                        <i className="sii sii-user icon"/>
                        <input type="email" name="email" id="email" placeholder="Email" />
                    </div>
                    <div className="input-container">
                        <i className="sii sii-lock icon"/>
                        <input type="password" name="password" id="password" placeholder="Password"/>
                    </div>
                    <div>
                        <button>Register</button>
                    </div>
                </form>
                <p>Already have account? <Link to='login'>Login here</Link></p>
            </div>
        )
    }
}

const mapStateToProps = (response) => ({
    response
});

export default connect(mapStateToProps)(Register);
