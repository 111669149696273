import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import React, { Component } from 'react';
import { getCookie } from '../../../utils/cookies';
import { countryOptions, statesOptions } from '../data/data';
import { SiigerReservation } from '../helpers/SiigerHelper';
import Sheadmodal from '../slayout/sheader/sheadermodal';
import SiigerAlertMessages from '../sshared/SiigerMessages/siigerAlertMessages';
import SviajePerfil from './sviajesperfil';

class SviajesReservas extends Component {

    constructor(props) {
	    super(props);
        let _hospedador = this.props.location.state;
        let _travel = this.props.location.searchFilter;
        

        this.state = {
            hospedador: _hospedador,
            travel: _travel,
            waiting: false,
            viewStatus: false,
            statusPlace: 0
        }
    }    

    componentDidMount() {
        let _email = getCookie('email');

        //yaTieneReservasPendientes 
    }

    ConfirmarReserva(){
        this.setState({        
            waiting: true,   
            viewStatus: false,            
        },() => {
            let _email = getCookie('email')
            let _statusPlace = 0;
           
            console.log("reservation hospedador: " + (this.state.hospedador != undefined));
            console.log("reservation travel: " + (this.state.travel != undefined)); 

            if ((this.state.hospedador == undefined)||(this.state.travel == undefined)){
                this.setState({                             
                    waiting: false,   
                    viewStatus: true,                    
                    statusPlace: 99,
                });   
            }else{
                SiigerReservation(_email, this.state.hospedador, this.state.travel, 1).then((response)  => {                
                    this.setState({                             
                        waiting: false,   
                        viewStatus: true,                    
                        statusPlace: 1, //reservada
                    });                  
                }).catch(function (error) {
                    console.log(error);
                    this.setState({                             
                        waiting: false,   
                        viewStatus: true,                    
                        statusPlace: 0, //algo paso, no se si quedo. 
                    });                  
                });
            }  
        });
    }


    render(){

        function GetStateDescripcion(s)
        {
            let objIndex = statesOptions.findIndex((obj => obj.id == s));
            let Ciudad = (statesOptions[objIndex] == undefined) ? '' : statesOptions[objIndex].label;    
            return Ciudad;
        }
        
        function GetPaisDescripcion(s)
        {
            let objIndex = countryOptions.findIndex((obj => obj.id == s));
            let Pais = (countryOptions[objIndex] == undefined) ? '' : countryOptions[objIndex].label;    
            return Pais;
        } 

        return(
            <>
                <Sheadmodal urlReturn="/siigers/explorar" caption="Seguir buscando..."/>

                <div className='container siiger'>

                <SviajePerfil { ...this.state.hospedador } hideActions={ true } />

                {( this.state.waiting
                    ? <></>
                    : <></>
                )}

                {(  (!this.state.waiting && this.state.viewStatus)
                    ?   <>
                            <div className='scard'>
                                {{
                                    99:<SiigerAlertMessages title="Ups!" msgType="warning" msg="Por favor haga la búsqueda nuevamente" />,
                                    0:<SiigerAlertMessages title="Ups!" msgType="warning" msg="Upds, verifica el estado de tu reserva" />,
                                    1:<SiigerAlertMessages title="Reserva" msgType="success" msg="Tu reserva se ha completado con éxito" /> 
                                }[this.state.statusPlace]}
                            </div>
                        </>

                    :   <div className='scard'>         

                            {( (this.state.travel == undefined)
                                ? <></>
                                : <>
                                    <div className='row'>
                                        <label><b>Lugar: </b></label><label>{ " " + GetPaisDescripcion(this.state.travel.tciudad)  + ", " + GetStateDescripcion(this.state.travel.tciudad) }</label>
                                    </div>
                                    <div className='row'>
                                        <label><b>Fecha: </b></label><label>{ this.state.travel.tdesde } - { this.state.travel.thasta } </label>
                                    </div>
                                    <div className='row'>
                                        <label><b>Cant. huéspedes: </b></label><label>{ this.state.travel.tcantidad } </label>
                                    </div>
                                    
                                    <hr className='siiger'></hr>
                                   </>
                            )}

                            <SiigoButtonAtom
                                id='searchBuscarViajesButton'
                                color="primary-green"
                                size="l"
                                type="button"
                                text="Confirmar reserva"
                                icon="fas"     
                                onClick = {() => this.ConfirmarReserva()}                               
                            />                 
                        </div>                        
                )}

                </div>
            </>
        )
    }
}

export default SviajesReservas;