import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/index';

export function* getConfigReport(action){
    const api = API.create();

    try {
        const { params } = action;
        const respConfigReport = yield call(api.generalReport.getConfigReport, params);
        yield put({ type: types.GET_CONFIG_GENERAL_REPORT_RECEIVED, data: respConfigReport });
    } catch (error) {
        yield put({ type: types.GET_CONFIG_GENERAL_REPORT_FAILED, error });
    }
}

export function* filterDataBySource(action){
    const api = API.create();

    try {
        const { params } = action;
        const respConfigReport = yield call(api.generalReport.filterDataBySource, params);
        yield put({ type: types.FILTER_DATA_BY_SOURCE_RECEIVED, data: respConfigReport });
    } catch (error) {
        yield put({ type: types.FILTER_DATA_BY_SOURCE_RECEIVED, error });
    }
}

export function* downloadReportDataBySource(action){
    const api = API.create();

    try {
        const { params } = action;
        const respDownloadData = yield call(api.generalReport.downloadReportDataBySource, params);
        yield put({ type: types.DOWNLOAD_REPORT_DATA_BY_SOURCE_RECEIVED, data: respDownloadData });
    } catch (error) {
        yield put({ type: types.DOWNLOAD_REPORT_DATA_BY_SOURCE_FAILED, error });
    }
}