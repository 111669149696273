import React from 'react';
import PropTypes from 'prop-types';
import * as SurveyAction from '../../actions/surveys/surveyActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import axios from 'axios'
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import moment from 'moment';
import 'moment/locale/es';
import './logs.scoped.scss';
import config from '../../config.js';
const surveysUrl = config.surveysUrl
const dateFormat = 'YYYY-MM-DD';



const stateDefinitions = {
  available: {
    color: null,
    label: 'Available'
  },
  enquire: {
    color: '#ffd200',
    label: 'Enquire'
  },
  unavailable: {
    selectable: false,
    color: '#78818b',
    label: 'Unavailable'
  }
};


const columns = [
  {
    name: 'Nombre',
    selector: 'nombre',
    sortable: true,
    filterDDL: true
  },
  {
    name: 'Asesor',
    selector: 'asesor',
    sortable: true
  },


  {
    name: 'P1',
    selector: 'p1',
    sortable: true,
    filterDDL: true
  },

  {
    name: 'P1',
    selector: 'p2',
    sortable: true,
    filterDDL: true
  },

  {
    name: 'P3',
    selector: 'p3',
    sortable: true,
    filterDDL: true
  },

  {
    name: 'Fecha',
    selector: 'date',
    sortable: true,
    filterDDL: true
  }
];



let deleteDep = false;
class LogList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      unfileredLogs: [],
      filteredLogs: [],
      visibleModal: false,
      searchUser: '',
      surveys: [],
      customSurveys: [],
      promedio: 0,
      showDetailsModal: false,
      searchDateRange: [
        '',
        ''
      ],
      searchTipoAccion: 'Default',
      value: '',
      states: '',
      showHelperBusqueda: true,
      isLoading: true
    };
  }

  componentDidMount() {

    this.setState({
      isLoading: true
    })


    axios.get(surveysUrl + 'getAllSurveys').then(response => {

      if (response.data.length == 0) {
        this.setState({
          surveys: response.data,
          isLoading: false
        })
  
      }else {
        this.setState({
          surveys: response.data,
          isLoading: false
        })
  
        this.calculateProm(response.data)
      }
      

    }).catch(error => {

      this.setState({
        isLoading: false
      })

    });


  }

  calculateProm = (data) => {

    if (data.length > 0) {
      var temp = []
      var sum = 0;

      for (let index = 0; index < data.length; index++) {
        let promInd = (parseInt(data[index].p1) + parseInt(data[index].p2)) / 2;
        temp.push(promInd)

      }

      for (var i = 0; i < temp.length; i++) {
        sum += parseInt(temp[i]); //don't forget to add the base
      }

      var avg = (sum / temp.length) * 10;

      avg = Math.round(avg * 10) / 10;

      this.setState({
        promedio: avg
      })

    } else {
      this.setState({
        promedio: 0
      })
    }
  }

  showModal = () => {
    this.setState({
      visibleModal: true
    });
  };

  hideModal = () => {
    this.setState({
      visibleModal: false
    });
  };


  callCustomSurveys = () => {


    if (
      (this.state.searchDateRange[0] || this.state.searchDateRange[1]) == ''
    ) {
      ModalResponse({
        title: 'Consultar encuestas',
        text:
          'Debes de escoger el rango de fechas para buscar.',
        res: 404
      });
    } else {
      this.setState({
        isLoading: true
      })

      const parametros = this.state.searchDateRange

      axios.post(surveysUrl + 'getCustomSurveys', { parametros }).then(response => {

        if (response.data == 'No existen encuestas') {

          
          this.setState({
            surveys: [],
            isLoading: false
          })
        } else {

          this.calculateProm(response.data)
          this.setState({
            surveys: response.data,
            isLoading: false
          })

          
        }

      }).catch(error => {

        this.setState({
          isLoading: false
        })

      });
    }




  }

  changeSearchUser = e => {
    const valor = e.target.value;

    this.setState({
      searchUser: valor
    });
  };

  changeSearchTipoAccion = e => {
    const tipo = e.target.value;

    this.setState({
      searchTipoAccion: tipo
    });
  };

  changeDateRange = e => {
    const dateRange1 = document.getElementById('date1').value;
    const dateRange2 = document.getElementById('date2').value;

    this.setState({
      searchDateRange: [dateRange1, dateRange2]
    });
  };

  customLogSearch = () => {
    if (
      (this.state.searchDateRange[0] || this.state.searchDateRange[1]) == '' &&
      this.state.searchTipoAccion == 'Default' &&
      this.state.searchUser == ''
    ) {
      ModalResponse({
        title: 'Consultar logs',
        text:
          'Debes de tener <b>al menos</b>  un valor entre  <b>nombre</b> , <b>rango de fechas</b>  o <b>tipo de acción</b>  para poder buscar personalizadamente.',
        res: 404
      });
    } else {
      const parametros = {
        searchDateRange: this.state.searchDateRange,
      };

      this.props.actions.getQueryLogs(parametros);

      this.state.showHelperBusqueda = false;
    }
  };

  cleanFilters = () => {
    window.location.reload();
  };

  handleSelect(range, states) {

    this.setState({
      value: range,
      states: states
    });
  }

  onChange = date => this.setState({ date });

  showLog = aditionalData => {

    this.setState({
      showDetailsModal: true

    })


    ModalResponse({
      text: aditionalData,
      res: 800
    });


  };

  render() {

    const btnActions = {
      //btnShow: this.showLog
    };

    const { error } = this.props;
    let datos = [];
    datos = this.state.surveys


    moment.locale('es');


    return (
      <div>
        {error && <h4>Error: {error.response.data}</h4>}
        {this.state.isLoading ? (
          <Loader></Loader>
        ) : (
            <div className="use-antd">
              <h3>Encuestas Siigo</h3>


              <div>
                <br></br>
                <h5>Búsqueda especializada:</h5>
              </div>

              {
                <div className="gridSurveySearch">


                  <div className="columna2 ml-4">
                    {this.state.searchDateRange[0] == '' ||
                      this.state.searchDateRange[1] == '' ? (
                        <div className="fila">
                          <input
                            type="date"
                            id="date1"
                            onChange={this.changeDateRange.bind(this)}
                          ></input>
                          <input
                            type="date"
                            id="date2"
                            onChange={this.changeDateRange.bind(this)}
                          ></input>
                        </div>
                      ) : (
                        <div className="fila">
                          <input
                            type="date"
                            id="date1"
                            value={this.state.searchDateRange[0]}
                            onChange={this.changeDateRange.bind(this)}
                          ></input>
                          <input
                            type="date"
                            id="date2"
                            value={this.state.searchDateRange[1]}
                            onChange={this.changeDateRange.bind(this)}
                          ></input>
                        </div>
                      )}
                  </div>

                  <div className="columna1 ml-4 mt-1 siigoInput ">
                    <button
                      type="button"
                      className="green-fill mr-2"
                      onClick={this.callCustomSurveys}
                    >
                      <span className="siicon icon-search searchMarket"></span>
                      <span>Buscar</span>
                    </button>

                    <button
                      type="button"
                      className="orange-fill"
                      onClick={this.cleanFilters}
                    >
                      Limpiar
                  </button>


                  </div>

                  <div className="columna1 ml-4 mt-1 siigoInput ">



                  </div>



                </div>
              }



              {datos.length <= 0 ? (
                <div className="logsCenter">
                  <h4 className="blue-h4">
                    No existen encuestas con los parámetros actules.
                </h4>

                  {this.showHelperBusqueda ? (
                    <div>
                      <p>
                        Nota: si desea Logs de una fecha diferente a la de{' '}
                        <b> hoy </b>(por defecto), por favor use la Búsqueda
                        personalizada.
                    </p>
                    </div>
                  ) : (
                      <div></div>
                    )}
                </div>
              ) : (

                  <div>
                    <br></br>

                    <h3>Promedio de recomendación de Siigo: {this.state.promedio} % </h3>
                    <br></br>

                    <TableReport
                      columns={columns} // Data columns to table
                      tabs={false} //Enable tabs
                      tabsData={[]} //Load data to tabs check option on tabsData const
                      headerCounterTab={true} //Counter tab option
                      btnActions={btnActions}
                      actionButtons={true} //Enable buttons (edit, delete, copy, open)
                      pageSize={50} // Enable page size
                      pagination={true} //Enable pagination (true, false)
                      paginationNav={'arrows'} //Nav options (arrow, dot)
                      paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
                      maxSizePagination={6} //Size pagination
                      defaultPaginationNumber={1} //Current pagination when start app
                      paginationGrouped={50} //Grouped by page
                      detailRow={false} //Open column detail with left arrow
                      data={datos} //Data for table
                    />
                  </div>



                )}
            </div>
          )}


      </div>
    );
  }
}

LogList.propTypes = {
  actions: PropTypes.object.isRequired,
  logList: PropTypes.object,
  error: PropTypes.object,
  filteredLogs: PropTypes.object
};

const mapStateToProps = state => ({
  filteredLogs: state.logs.filteredLogs,
  logList: state.logs.logs,
  error: state.logs.err,
  isLoading: state.logs.isLoading,
  isLoadingDel: state.logs.isLoadingDel
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(SurveyAction, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LogList);
