import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/indexGeneral';
import config from '../config.js';

export function* getPayments(action) {
    const api = API.create(config.urlDataHandler);
    try {
        const { params } = action;
        api.setHeader('token', "stark:iidkju===dk0");
        let orders = yield call(api.auroraSubscription.getOrder, params);
        //console.log('result datahandler');
        //console.log(orders);
        yield put({ type: types.GET_RECURRENTPAYMENTS_RECEIVED, data: orders });
    } catch (error) {
        yield put({ type: types.GET_RECURRENTPAYMENTS_FAILED, error });
    }
}

export function* sendExcelObject(action) {
	console.log("Excel");
	const api = API.create(config.urlExcelApi);
	try {
		const { params } = action;
		//console.log(params);
		//console.log('params-saga::: ', params);
		let auth = yield call(api.accountant.authExcel, { username: 'admin', password: 'admin' });
		//console.log("Auth data");
		//console.log(auth);
		api.setHeader('authorization', auth.data.token);
		let excel = yield call(api.accountant.sendExcelObject, params);
		//console.log("excel result");
		//console.log(excel);
		yield put({ type: types.SEND_EXCEL_OBJECT_RECEIVED, data: excel });
	} catch (err) {
		yield put({ type: types.SEND_EXCEL_OBJECT_FAILED, err });
	}
}