import { Complementos, COMPLEMENTS_TYPES_ENUM } from '../plandata';

const PLUS_COMPLEMENT = 1
const PRO_COMPLEMENT = 2
const SIIGO_CLOUD = 3

const unLimitedDocumentComplements = [PLUS_COMPLEMENT, PRO_COMPLEMENT, SIIGO_CLOUD]

export const getPlanesComplements = (value, isLite, type = 0) => {
  if (isLite && type === COMPLEMENTS_TYPES_ENUM.NOMINA) {
    return filterItems(Complementos, type, value);
  }

  if (type === COMPLEMENTS_TYPES_ENUM.POW_WEB) {
    return Complementos.find( comp => comp.value == type)?.planesComplemento.filter(plan => plan.valueSelected === value);
  }

  if (unLimitedDocumentComplements.includes(value)) {
    return Complementos.map((comp) => comp?.planesComplemento)[0]?.filter(comp => comp?.valueSelected === value);
  }

  return [];
}
export const getComplements = () => {
  return Array.isArray(Complementos) && Complementos;
}

function filterItems(complementos, valueParent, documentBase) {
  return complementos.reduce((acc, complemento) => {
      const filteredItems = complemento.planesComplemento.filter(item => 
          item.valueParent === valueParent && item.documentBase === documentBase
      );
      return acc.concat(filteredItems);
  }, []);
}