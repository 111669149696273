const planes = {
  1: 'Mundo anterior',
  5: 'Siigo Nube',
  6: 'Contador',
  7: 'Solo facturación',
  10: 'Nomina',
  11: 'Solo Nómina',
  12: 'Siigo Nube Independiente',
  13: 'Siigo Nube Emprendedor',
  14: 'Siigo Nube Premium',
  15: 'Siigo FE PRO',
  16: 'Siigo POS',
  17: 'Siigo Nube Avanzado',
  21: 'Siigo Nube Gestión Inicio',
  22: 'Siigo Nube Gestión Avanzado',
  20: 'Siigo Nube Facturación',
};

export default planes;
