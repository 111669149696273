const stateCompany = Object.freeze({
    inactive: "0",
    readyOnly: "1",
    active: "2",
});

const printState = {
    "1": 'Solo consulta',
    "2": 'Activo',
    "0": 'Inactivo'
};

const selectStates = {
    0: "Inactivo",
    1: "Activo",
    2: "Solo consulta"
}

const PAYROLL_UPDATE_TYPE = {
    MANUAL: 1,
    AUTOMATIC: 2,
}

const PAYROLL_COMPANY_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
    READONLY: 2,
}

const getStateCompany = (portafolioStatus, programStatus) => {
    if (portafolioStatus) {
        if (programStatus && programStatus === 2) {
            return stateCompany.readyOnly;
        } else if (programStatus === 1) {
            return stateCompany.active;
        } else if (programStatus === 0) {
            return stateCompany.inactive;
        }
    } else {
        return stateCompany.inactive;
    }
}

const payrollStatusConverter = (pCompanyStatus) => {
    let payrollStatus = PAYROLL_COMPANY_STATUS.INACTIVE;

    switch (pCompanyStatus) {
        case stateCompany.active:
            payrollStatus = PAYROLL_COMPANY_STATUS.ACTIVE;
            break;
        case stateCompany.readyOnly:
            payrollStatus = PAYROLL_COMPANY_STATUS.READONLY;
            break;
        default:
            payrollStatus = PAYROLL_COMPANY_STATUS.INACTIVE;
    }

    return payrollStatus;
}
module.exports = { stateCompany,selectStates, getStateCompany, printState, payrollStatusConverter, PAYROLL_UPDATE_TYPE, PAYROLL_COMPANY_STATUS };