import * as types from '../actions';

const userReducer = (state = { isLoading: true }, action = null) => {
  switch (action.type) {
    case types.GET_USERS:
      return { ...state, isLoading: true };
    case types.USERS_RECEIVED:
      return { ...state, isLoading: false, users: action.data };
    case types.USERS_REQUEST_FAILED:
      return { ...state, isLoading: false, users: action.error };
    case types.GET_USER:
      return { ...state, isLoading: true };
    case types.USER_RECEIVED:
      return { ...state, isLoading: false, user: action.data };
    case types.USER_REQUEST_FAILED:
      return { ...state, isLoading: false, user: action.error };
    case types.GET_USER_PERFIL:
      return { ...state, isLoading: true };
    case types.USER_PERFIL_RECEIVED:
      return { ...state, isLoading: false, user: action.data };
    case types.USER_PERFIL_REQUEST_FAILED:
      return { ...state, isLoading: false, user: action.error };
    case types.SET_USER_AVATAR:
      return { ...state, isLoading: true };
    case types.USER_AVATAR_RECEIVED:
      return { ...state, isLoading: false, userAvatar: action.data };
    case types.USER_AVATAR_REQUEST_FAILED:
      return { ...state, isLoading: false, user: action.error };
    case types.CREATE_USER:
      return { ...state, isLoading: true };
    case types.CREATE_USER_SUCCESS:
      return { ...state, isLoading: false, user: action.data };
    case types.CREATE_USER_FAILED:
      return { ...state, isLoading: false, user: action.error };
    case types.UPDATE_USER:
      return { ...state, isLoading: true };
    case types.UPDATE_USER_SUCCESS:
      return { ...state, isLoading: false, user: action.data };
    case types.UPDATE_USER_FAILED:
      return { ...state, isLoading: false, user: action.error };
    case types.DELETE_USER:
      return { ...state, isLoadingDel: true };
    case types.DELETE_USER_SUCCESS:
      return { ...state, isLoadingDel: false, user: action.data };
    case types.DELETE_USER_FAILED:
      return { ...state, isLoadingDel: false, user: action.error };
    default:
      return state;
  }
};

export default userReducer;
