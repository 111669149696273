import React, { Component } from 'react';
import Perfil from '../../components/users/perfil';
import Layout from '../layout';
import { checkCookie } from '../../utils/cookies';
import { Redirect } from 'react-router-dom';

class PerfilContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const id = this.props.match.params.id;
    return (
      <div>
        {checkCookie() !== null ? (
          <div>
            <Layout route={'/user'} />
            <section className={'container'}>
              <div className="section">
                <Perfil id={id} />
              </div>
            </section>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: this.props.location }
            }}
          />
        )}
      </div>
    );
  }
}

export default PerfilContainer;
