const config = {
  url: process.env['REACT_APP_API_URL'],
  urlHubspot: process.env['REACT_APP_API_HUBSPOT'],
  urlProvisioning: process.env['REACT_APP_API_PROVISIONING'],
  surveysUrl: process.env['REACT_APP_SURVEYS_LOCAL_API'],
  urlNotificationAS: process.env['REACT_APP_API_NOTIFICATION_AS'],
  createCompanySelectEnable: process.env["REACT_APP_CREATE_COMPANY_SELECT_ENABLE"],
  urlAurora: process.env['REACT_APP_API_AURORA'],
  cod_notificacion: process.env['REACT_APP_CODE_NOT'],
  cod_account: process.env['REACT_APP_CODE_ACC'],
  cod_email: process.env['REACT_APP_CODE_EMAIL'],
  cod_accountant: process.env['REACT_APP_CODE_ACCOUNTANT'],
  cod_email_accountant: process.env['REACT_APP_CODE_EMAIL_ACCOUNTANT'],
  shortenURL: process.env['REACT_APP_SHORTEN_URL'],
  urlExcelApi: process.env['REACT_APP_API_EXCEL'],
  urlDataHandler: process.env['REACT_APP_API_DATAHANDLER'],
  urlPaymentManager: process.env['REACT_APP_API_PAYMENT_MANAGER'],
  auroraServices: process.env['REACT_APP_AURORA_SERVICES'],
  auroraCreds: process.env['REACT_APP_AURORA_CREDS'],
  urlMiddleware: process.env['REACT_APP_API_MIDDLEWARE'],
  urlPackage: process.env['REACT_APP_API_PACKAGE'],
  newUrlPackage: process.env['REACT_APP_NEW_API_PACKAGE'],
  flagNewUrlPackage: process.env['REACT_APP_FLAG_NEW_API_PACKAGE'],
  planesPack: process.env['REACT_APP_DATA_PLANES_PACK'] ? JSON.parse(process.env['REACT_APP_DATA_PLANES_PACK']) : {"planes": [7,15], "type":[3]},
  planesPackERP: process.env['REACT_APP_DATA_PLANES_PACK_ERP'] ? JSON.parse(process.env['REACT_APP_DATA_PLANES_PACK_ERP']) : {"planes": [12,13,14], "type":[0]},
  urlAuroraService: process.env['REACT_APP_API_AURORA_SERVICE'],
  urlMailProvider: process.env['REACT_APP_API_PROVIDER'],
  pathSendMail: process.env["REACT_APP_SENDMAIL"],
  pathUpdateSuscription: process.env["REACT_APP_UPDATE_SUSCRIPTION"],
  pathGetSuscription: process.env["REACT_APP_GET_SUSCRIPTION"],
  pathGetToken: process.env["REACT_APP_GET_TOKEN"],
  urlAuroraBase: process.env['REACT_APP_API_AURORA_BASE'],
  urlFive9GetCountry: process.env['REACT_APP_FIVE9_API_GET_COUNTRIES'],
  urlMailActions: process.env['REACT_APP_MAIL_ACTIONS'],
  siigoResponseUrl: process.env['REACT_APP_SIIGO_RESPONSE_URL'],
  currency: process.env['REACT_APP_CURRENCY'],
  productListCO: process.env['REACT_APP_CO_PRODUCTLIST'],
  origins: process.env['REACT_APP_ORIGINS'],
  activationManagementToken: process.env['REACT_APP_ACTIVATION_MANAGEMENT_TOKEN'],
  activationManagementTemplate: process.env['REACT_APP_ACTIVATION_MANAGEMENT_TEMPLATE'],
  wifGenericCreateCompany: process.env['REACT_APP_WIF_GENERIC'],
  createCompanyWifGeneric: process.env['REACT_APP_CREATE_WIF_GENERIC'],
  mfAccountantPortal: process.env['REACT_APP_MF_ACCOUNTANT_PORTAL'],
  userAuthValue: process.env['REACT_APP_USER_AUTH_VALUE'],
  flagProductionEnvironment: process.env['REACT_APP_FLAG_PRODUCTION_ENVIRONMENT'],
  reactAuthClientId: process.env['REACT_APP_AUTH_CLIENT_ID'],
  reactAuthAuthority: process.env['REACT_APP_AUTH_AUTHORITY'],
  reactAppSiigerApi: process.env['REACT_APP_SIIGER_API'],
  reactAppSiigerApiKey: process.env['REACT_APP_SIIGER_API_KEY'],
  reactAppSiigerDataSource: process.env['REACT_APP_SIIGER_DATA_SOURCE'],
  reactAppSiigerDatabase: process.env['REACT_APP_SIIGER_DATA_BASE'],
  reactAppSiigerCollection: process.env['REACT_APP_SIIGER_COLLECTION'],
  reactAppSiigerMailproviderApi: process.env['REACT_APP_SIIGER_MSPROVIDER_EMAIL']
};
export default config;
