import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as DataSourceActions from '../../actions/dataSource/dataSourceActions';
import Table from './table';
import svgs from '../layout/icons';
import { Link } from 'react-router-dom';


class DataReportGroups extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reportData: []
		};
	}

	componentDidMount() {
    	this.props.actions.reportData()	
	}

	render() {	
    const {reportData, isLoading} = this.props 
	let data = []

    if(!isLoading && reportData){
		 if(reportData.data){
			data=reportData.data
		 }
    }

		return (
			<>
				{data.length ===0 ? (
					<div>
						Sin Reportes configurados actualmente
					</div>
					) : ("")
				}
				<Link to="/datasource/create" className="a-button green-fill">
					<img className="icon-img icon-mas" src={svgs['mas']} alt="" />
					Crear Reporte
				</Link>
				<div className="row mt-4">
					<h2 style={{color: "rgb(0, 157, 255)"}}>Reportes Datasource</h2>
				</div>
				<div className="row mt-4">
					<Table data = {data}/>
				</div>
			</>
		);
	}
}

DataReportGroups.propTypes = {
	actions: PropTypes.object.isRequired,
	reportData: PropTypes.object,
	error: PropTypes.object,
	planUpdate: PropTypes.object,
	modifyCompany: PropTypes.object,
};

const mapStateToProps = (state) => ({
	error: state.datasource.err,
	isLoading: state.datasource.isLoading,
	reportData: state.datasource.reportData,
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(DataSourceActions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(DataReportGroups), withRouter(DataReportGroups));