import React, { Component } from 'react';
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import PropTypes from 'prop-types';
import * as generalReportActions from '../../actions/generalReport/generalReportActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import DynamicFilter from './dynamicFilter';
import './style.scss';
import { getCookie } from '../../utils/cookies';
import { CONSTVALUES } from '../datasource/data';


class GeneralReport extends Component {
    constructor(props) {
        super(props);
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleFilterReport = this.handleFilterReport.bind(this);
        this.state = {
            isLoadingGetConfig: true,
            isLoadingFilter: false,
            errorGetConfig: null,
            name: null,
            errorFilter: null,
            configReport: null,
            filterReport: null,
            configData: null,
            datos: [],
            pageSize: 50,
            pageCountServer: 0,
            pageNumber: 1,
        };
        this.setCurrentPage = this.setCurrentPage.bind(this);
    }

    componentDidMount() {
        this.getConfigReport();
    }

    getConfigReport() {
        this.setState({ isLoadingGetConfig: true })
        this.props.actions.getConfigReport({ id: this.props.id });
    }


    handleChangeInput(item) {
        this.setState({ ...item });
    }

    processResponseGetConfig() {
        const configData = this.state.configReport;
        if (configData) {
            if (configData.data && (configData.status === 200 || configData.status === 201)) {
                this.setState({ configData: configData.data, isLoadingGetConfig: false });
            } else {
                console.log("ERROR => ", configData);
                this.modalAlert('¡Error!', 'Se ha producido un error al tratar de cargar los datos. Comuníquese con el Administrador.', 404);
            }
        }
    }

    processResponseFilter() {
        const filterReport = this.state.filterReport;
        if (filterReport) {
            if (filterReport.data && (filterReport.status === 200 || filterReport.status === 201)) {
                this.cleanData(filterReport.data.data)
                this.setState({ datos: filterReport.data.data, isLoadingFilter: false, pageCountServer: filterReport.data.pageCountServer });
            } else {
                console.log("ERROR => ", filterReport);
                this.modalAlert('¡Error!', 'Se ha producido un error al tratar de cargar los datos. Comuníquese con el Administrador.', 404);
            }
        }
    }

    cleanData(data) {
        data.forEach(obj => {
            for (let key in obj) {
                if (typeof obj[key] === "object") {
                    for (let clave in obj[key]) {
                        obj[clave] = obj[key][clave];
                    }
                }
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.id !== prevProps.id) {
            this.getConfigReport();
        }

        if (this.props.errorGetConfig !== this.state.errorGetConfig) {
            this.setState({ errorGetConfig: this.props.errorGetConfig }, () => {
                if (this.state.errorGetConfig) {
                    console.log("ERROR => ", this.state.errorGetConfig);
                    this.modalAlert('¡Error!', 'Se ha producido un error al tratar de cargar los datos. Comuníquese con el Administrador.', 404);
                }
            });
        }
        if (this.props.errorFilter !== this.state.errorFilter) {
            this.setState({ errorFilter: this.props.errorFilter }, () => {
                if (this.state.errorFilter) {
                    console.log("ERROR => ", this.state.errorFilter);
                    this.modalAlert('¡Error!', 'Ha ocurrido un problema, inténtelo más tarde o comuníquese con el Administrador.', 404);
                }
            });
        }
        if (this.props.configReport !== this.state.configReport) {
            this.setState({ configReport: this.props.configReport }, () => this.processResponseGetConfig())
        }
        if (this.props.filterReport !== this.state.filterReport) {
            this.setState({ filterReport: this.props.filterReport }, () => this.processResponseFilter())
        }
        if (this.state.pageNumber !== prevState.pageNumber) {
            this.handleFilterReport();
        }
    }

    modalAlert(title, text, res) {
        ModalResponse({ title, text, res, });
    }

    getFilterData() {
        const configObj = Object.assign([], this.state.configData.fieldFilter);
        const validElements = [];
        configObj.forEach(item => {
            const newItem = {}
            let validValue;
            if (item.type === "date") {
                const fromDate = this.state[`from_${item.value}`];
                const toDate = this.state[`to_${item.value}`];
                newItem["fromDate"] = fromDate;
                newItem["toDate"] = toDate;
                newItem["key"] = item.value
                newItem["type"] = item.type
                validValue = toDate && fromDate;
            } else {
                newItem["value"] = this.state[`${item.value}`]
                newItem["key"] = item.value
                newItem["type"] = item.type
                validValue = this.state[`${item.value}`];
            }
            if (Boolean(validValue)) validElements.push(newItem);
        });

        return validElements;
    }

    handleFilterReport() {
        const validElements = this.getFilterData();
        const filterData = this.state.configData.fieldFilter;

        if (validElements.length === 0 && filterData.length >= 1) {
            this.modalAlert("Hey!", "Debes ingresar al menos un filtro de busqueda.", 404);
            this.setCurrentPage(1);
            return;
        }
        this.setState({ isLoadingFilter: true });
        this.props.actions.filterDataBySource({ id: this.props.id, data: validElements, projectionFields: this.state.configReport.data.projectionFields, pageSize: this.state.pageSize, pageNumber: this.state.pageNumber });
    }

    handlerDownloadReport() {
        const validElements = this.getFilterData();
        const filterData = this.state.configData.fieldFilter;

        if (validElements.length === 0 && filterData.length >= 1) {
            this.modalAlert("Hey!", "Debes ingresar al menos un filtro de busqueda para exportar el reporte.", 404);
            return;
        }
        
        const data = {
            email: getCookie('email'),            
            name: getCookie('name'),
            reportId: this.props.id,
            filters: validElements
        };
        
        this.modalAlert(`Hey ${data.name}!`, `Te enviaremos reporte de Excel al correo ${data.email}`, 800);
        
        setTimeout(() => {            
            this.props.actions.downloadReportDataBySource(data);
        }, 1000);
    }

    setCurrentPage(page) {
        this.setState({
            pageNumber: page
        })
    }
    render() {

        let {
            downloadData,
            errorDownloadReport,
            isLoadingDownload,
            successDownload
        } = this.props;

        if (successDownload) {
            this.modalAlert(
                'Exito',
                downloadData?.data?.result,
                200
            );
        }
        return (
            <>
                {this.state.errorGetConfig ? JSON.stringify(this.state.errorGetConfig) : null}
                {this.state.isLoadingGetConfig ?
                    <div style={{ padding: "10em" }}>
                        <Loader />
                    </div> : null}
                {!this.state.isLoadingGetConfig && this.state.configData && !this.state.errorGetConfig ?
                    <>
                        <div>
                            <h3>{this.state.configData.nameReport || "Reporte General"}</h3>
                            <DynamicFilter
                                handleChangeInput={this.handleChangeInput.bind(this)}
                                fieldFilter={this.state.configData.fieldFilter}
                                handleFilterReport={this.handleFilterReport.bind(this)}
                                cleanFilter={this.getConfigReport.bind(this)}
                                handleDownload={this.handlerDownloadReport.bind(this)}
                                viewExportBtn={this.state.configData.exportsType?.excel}
                                isLoadingDownload={isLoadingDownload}
                            />
                        </div>
                        {this.state.isLoadingFilter ?
                            <div style={{ padding: "10em" }}>
                                <Loader />
                            </div> : null}
                        {this.state.configData.columns && !this.state.isLoadingFilter ?
                            <div className="divTable">
                                {this.state.configData.typeConnection === CONSTVALUES.MICROSERVICE ? (
                                    <div className='row mt-4'>
                                        <div className='column2'>
                                            <h3>Página actual: {this.state.pageNumber}</h3>
                                        </div>
                                        <div className='column2'>
                                            <div className='row'>
                                                {this.state.pageNumber > 1 ? (
                                                    <div className='column2'>
                                                        <SiigoButtonAtom
                                                            ariaLabel="click here"
                                                            color="primary-blue"
                                                            icon="fas fa-solid fa-arrow-left"
                                                            iconPosition="start"
                                                            size="m"
                                                            text="Anterior"
                                                            type="button"
                                                            onClick={() => {
                                                                this.setCurrentPage(this.state.pageNumber - 1);
                                                                this.handleFilterReport.bind(this);
                                                            }}
                                                        />
                                                    </div>
                                                ): null}
                                                <div className='column2'>
                                                    <SiigoButtonAtom
                                                        ariaLabel="click here"
                                                        color="primary-blue"
                                                        icon="fas fa-solid fa-arrow-right"
                                                        iconPosition="end"
                                                        size="m"
                                                        text="Siguiente"
                                                        onClick={() => {
                                                            this.setCurrentPage(this.state.pageNumber + 1);
                                                            this.handleFilterReport.bind(this);
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <TableReport
                                    columns={this.state.configData.columns} // Data columns to table
                                    tabs={false} //Enable tabs
                                    tabsData={[]} //Load data to tabs check option on tabsData const
                                    headerCounterTab={true} //Counter tab option
                                    btnActions={{}}
                                    actionButtons={false} //Enable buttons (edit, delete, copy, open)
                                    pageSize={this.state.pageSize} // Enable page size
                                    pagination={true} //Enable pagination (true, false)
                                    pageCountServer={this.state.pageCountServer}
                                    serverPagination={true}
                                    serverRenderPagination={this.setCurrentPage}
                                    startingPage={this.state.pageNumber}
                                    paginationNav={'arrows'} //Nav options (arrow, dot)
                                    paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
                                    maxSizePagination={6} //Size pagination
                                    defaultPaginationNumber={1} //Current pagination when start app
                                    paginationGrouped={10} //Grouped by page
                                    detailRow={false} //Open column detail with left arrow
                                    data={this.state.datos} //Data for table
                                />
                            </div>
                            : null}
                    </>
                    : null}
            </>
        );

    }

}


GeneralReport.propTypes = {
    actions: PropTypes.object.isRequired,
    configReport: PropTypes.object,
    filterReport: PropTypes.object,
    errorFilter: PropTypes.object,
    errorGetConfig: PropTypes.object,
};

const mapStateToProps = state => ({
    configReport: state.generalReportReducer.configReport,
    errorGetConfig: state.generalReportReducer.errorGetConfig,
    isLoadingGetConfig: state.generalReportReducer.isLoadingGetConfig,

    filterReport: state.generalReportReducer.filterReport,
    errorFilter: state.generalReportReducer.errorFilter,
    isLoadingFilter: state.generalReportReducer.isLoadingFilter,

    downloadData: state.generalReportReducer.downloadData,
    errorDownloadReport: state.generalReportReducer.errorDownloadReport,
    isLoadingDownload: state.generalReportReducer.isLoadingDownload,
    successDownload: state.generalReportReducer.successDownload,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(generalReportActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralReport);

