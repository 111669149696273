import React, { Component } from 'react';
import NotificationEdit from '../../components/notificationAS/edit';
import Layout from '../layout';
import { checkCookie } from '../../utils/cookies';
import { Redirect } from 'react-router-dom';

class NotificationEditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const id = this.props.match.params.id;
    return (
      <div>
        {checkCookie() !== null ? (
          <div>
            <Layout route={'/notifiactiona'} />
            <section className={'container'}>
              <div className="section">
                <NotificationEdit id={id} parentPath={this.props.path} />
              </div>
            </section>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: this.props.location }
            }}
          />
        )}
      </div>
    );
  }
}

export default NotificationEditContainer;
