import React, { Component } from 'react';
import SearchEmail from '../../components/market/searchEmail';

class SerchEmailContainer extends Component {

	render() {	
		return (			
			<div>
				<SearchEmail parentPath={this.props.path}/>
			</div>
		);
	}
}

export default SerchEmailContainer;
