import * as types from '../actions';

const cloudLogReducer = (state = { isLoading : false}, action = null) => {

    switch (action.type) {
        case types.GET_CLOUD_LOGS:
            return { ...state, isLoading: true };            

        case types.CLOUD_LOGS_RECIVED:
            return { ...state, isLoading: false, cloudLogs: action.data }

        case types.CLOUD_LOGS_FAILED:
            return { ...state, isLoading: false, err: action.error }
        
        default:
            return state;
    }
};

export default cloudLogReducer;