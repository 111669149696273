import * as types from '../actions';

const five9Reducer = (state = { isLoading: false }, action = null) => {
  switch (action.type) {
    /**COUNTRY */
    case types.GET_FIVE9_COUNTRY:
      return { ...state, isLoading: true };
    case types.GET_FIVE9_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.data
      };
    case types.GET_FIVE9_COUNTRY_FAILED:
      return { ...state, isLoading: false, countries: action.error };

    case types.DELETE_FIVE9_COUNTRY:
    case types.EDIT_FIVE9_COUNTRY:
    case types.ADD_FIVE9_COUNTRY:
      return { ...state, isLoading: true };

    case types.DELETE_FIVE9_COUNTRY_SUCCESS:
    case types.EDIT_FIVE9_COUNTRY_SUCCESS:
    case types.ADD_FIVE9_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.data,
        isCorrect: action.isCorrect
      };
    case types.DELETE_FIVE9_COUNTRY_FAILED:
    case types.EDIT_FIVE9_COUNTRY_FAILED:
    case types.ADD_FIVE9_COUNTRY_FAILED:
      return { ...state, isLoading: false, countries: action.error };

    /**RULES */

    case types.GET_FIVE9_RULES:
      return { ...state, isLoading: true };
    case types.GET_FIVE9_RULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rules: action.data
      };
    case types.GET_FIVE9_RULES_FAILED:
      return { ...state, isLoading: false, rules: action.error };

    case types.DELETE_FIVE9_RULES:
    case types.EDIT_FIVE9_RULES:
    case types.ADD_FIVE9_RULES:
      return { ...state, isLoading: true };

    case types.DELETE_FIVE9_RULES_SUCCESS:
    case types.EDIT_FIVE9_RULES_SUCCESS:
    case types.ADD_FIVE9_RULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rules: action.data,
        isCorrect: action.isCorrect
      };
    case types.DELETE_FIVE9_RULES_FAILED:
    case types.EDIT_FIVE9_RULES_FAILED:
    case types.ADD_FIVE9_RULES_FAILED:
      return { ...state, isLoading: false, rules: action.error };

    default:
      return state;
  }
};

export default five9Reducer;
