import React, { Component } from 'react';
import LinkAurora from '../../components/linkAurora';

class LinkAuroraContainer extends Component {
  render() {
    return (
      <div>
        <LinkAurora parentPath={this.props.path} />
      </div>
    );
  }
}

export default LinkAuroraContainer;
