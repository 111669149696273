import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/es';
import { formatDate, parseDate } from 'react-day-picker/moment';

export default class DatePicker extends React.Component {
	constructor(props) {
		super(props);
		this.handleFromChange = this.handleFromChange.bind(this);
		this.handleToChange = this.handleToChange.bind(this);
		this.state = {
			from: this.props.from ? this.props.from : undefined,
			to: this.props.to ? this.props.to : undefined,
			test: undefined,
			locale: 'es',
		};
	}

	componentWillReceiveProps(nextProps) {
    if (nextProps.from !== this.state.from) {
      this.setState({ from: nextProps.from });
    }
    if (nextProps.to !== this.state.to) {
      this.setState({ to: nextProps.to });
    }
    
  }

	handleFromChange(from) {
		const setHoursToFrom = new Date(from).setHours(0,0,1)
		this.setState({ setHoursToFrom });
		this.props.handleDate({ from: setHoursToFrom, to: this.state.to });
	}

	handleToChange(to) {
		const setHoursToTo = new Date(to).setHours(23,59,59)
		this.setState({ to: setHoursToTo });
		this.props.handleDate({ from: this.state.from, to: setHoursToTo });
	}

	render() {
		const { from, to } = this.state;
		const modifiers = { start: from, end: to };
		return (
			<div className="InputFromTo">
				<DayPickerInput
					inputProps={{ className: "inputDatePicker" }}
					value={from}
					placeholder="Fecha desde"
					format="LL"
					formatDate={formatDate}
					parseDate={parseDate}
					dayPickerProps={{
						selectedDays: [from, { from, to }],
						disabledDays: { after: to },
						toMonth: to,
						modifiers,
						numberOfMonths: 1,
						onDayClick: () => this.to.getInput().focus(),
						localeUtils: MomentLocaleUtils,
						locale: this.state.locale
					}}
					onDayChange={this.handleFromChange}

				/>{' '}
        —{' '}
				<span className="InputFromTo-to">
					<DayPickerInput
						inputProps={{ className: "inputDatePicker" }}
						ref={el => (this.to = el)}
						value={to}
						placeholder="Fecha hasta"
						format="LL"
						formatDate={formatDate}
						parseDate={parseDate}
						dayPickerProps={{
							selectedDays: [from, { from, to }],
							disabledDays: { before: from },
							modifiers,
							month: from,
							fromMonth: from,
							numberOfMonths: 1,
							localeUtils: MomentLocaleUtils,
							locale: this.state.locale
						}}
						onDayChange={this.handleToChange}
					/>
				</span>
			</div>
		);
	}
}