import { object, string, number, bool } from 'yup';
import { COUNTRYS, returnProductsListByCountry } from '../utils';

const formCompanySchemaCo = (countryCode, t) => {
    return object().shape({
        license: string(),
        nit: string().min(5, t("market.modal.formCompany.genericErrors.min", { value: 5 })).max(12, t("market.modal.formCompany.genericErrors.max", { value: 12 })).matches(/^(?!0)\d*[1-9]\d*$/, t("market.modal.formCompany.nit.errors.match")).required(t("market.modal.formCompany.genericErrors.required")),
        company: string().min(3, t("market.modal.formCompany.genericErrors.min", { value: 3 })).max(50, t("market.modal.formCompany.genericErrors.max", { value: 50 })).matches(/^[a-zA-Z0-9Ññ& ]*$/, t("market.modal.formCompany.company.errors.match")).required(t("market.modal.formCompany.genericErrors.required")),
        nameCompany: string().min(3, t("market.modal.formCompany.genericErrors.min", { value: 3 })).max(50, t("market.modal.formCompany.genericErrors.max", { value: 50 })).matches(/^[a-zA-Z0-9Ññ& ]*$/, t("market.modal.formCompany.nameCompany.errors.match")).required(t("market.modal.formCompany.genericErrors.required")),
        emailCompany: string().email('Comprueba el E-mail').max(100, t("market.modal.formCompany.genericErrors.max", { value: 100 })).matches(/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+\.[a-zA-Z0-9-_.]+$/, t("market.modal.formCompany.emailCompany.errors.match")).required(t("market.modal.formCompany.genericErrors.required")),
        phoneCompany: string().min(8, t("market.modal.formCompany.genericErrors.min", { value: 8 })).max(15, t("market.modal.formCompany.genericErrors.max", { value: 15 })).matches(/^[0-9]+$/, t("market.modal.formCompany.phoneCompany.errors.match")).required(t("market.modal.formCompany.genericErrors.required")),
        employee: string(),
        pos: string()
    })
};

const formCompanySchemaMx = (countryCode, t) => {
    const allowedLicenses = returnProductsListByCountry(countryCode).map((license) => license.code);
    return object().shape({
        license: string().oneOf(allowedLicenses, t("market.modal.formCompany.license.errors.onOff")).required(t("market.modal.formCompany.license.errors.required")),
        nit: string().min(5, t("market.modal.formCompany.genericErrors.min", { value: 5 })).max(13, t("market.modal.formCompany.genericErrors.max", { value: 13 })).matches(/^(?![0-9])[a-zA-ZÑñ&0-9]+$/, t("market.modal.formCompany.nit.errors.match")).required(t("market.modal.formCompany.genericErrors.required")),
        company: string().min(3, t("market.modal.formCompany.genericErrors.min", { value: 3 })).max(50, t("market.modal.formCompany.genericErrors.max", { value: 50 })).matches(/^[a-zA-Z0-9Ññ& ]*$/, t("market.modal.formCompany.company.errors.match")).required(t("market.modal.formCompany.genericErrors.required")),
        nameCompany: string().min(3, t("market.modal.formCompany.genericErrors.min", { value: 3 })).max(50, t("market.modal.formCompany.genericErrors.max", { value: 50 })).matches(/^[a-zA-Z0-9Ññ& ]*$/, t("market.modal.formCompany.nameCompany.errors.match")).required(t("market.modal.formCompany.genericErrors.required")),
        emailCompany: string().email('Comprueba el E-mail').max(100, t("market.modal.formCompany.genericErrors.max", { value: 100 })).matches(/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+\.[a-zA-Z0-9-_.]+$/, t("market.modal.formCompany.emailCompany.errors.match")).required(t("market.modal.formCompany.genericErrors.required")),
        phoneCompany: string().min(8, t("market.modal.formCompany.genericErrors.min", { value: 8 })).max(15, t("market.modal.formCompany.genericErrors.max", { value: 15 })).matches(/^[0-9]+$/, t("market.modal.formCompany.phoneCompany.errors.match")).required(t("market.modal.formCompany.genericErrors.required")),
        employee: string()
    })
};

export const returnFormCompanyByCountry = (countryCode, t) => countryCode == COUNTRYS.MEXICO.CODE ? formCompanySchemaMx(countryCode, t) : formCompanySchemaCo(countryCode, t);

const formCompanyDetailSchemaCo = (countryCode, t) => {
    return object().shape({
        plan: object(),
        marketVersion: string(),
        companyKey: string(),
        date: string(),
        PrepaidPlanType: number(),
        state: string(),
        active: bool(),
        inactivationDate: string(),
        userFullName: string(),
        userEmail: string(),
        welcomeEmail: bool(),
        name: string(),
        quoteNumber: string(),
        users: string(),
        payrollUsers: string(),
        spaceAvailable: string(),
        nit: string(),
        workflowInstanceCode: string(),
        pos: string(),
        serial: string(),
    })
};

const formCompanyDetailSchemaMx = (countryCode, t) => {
    return object().shape({
        plan: object(),
        marketVersion: string(),
        companyKey: string(),
        date: string(),
        PrepaidPlanType: number(),
        state: string(),
        active: bool(),
        inactivationDate: string(),
        userFullName: string(),
        userEmail: string(),
        welcomeEmail: bool(),
        name: string(),
        quoteNumber: string(),
        users: string(),
        payrollUsers: string(),
        spaceAvailable: string(),
        nit: string(),
        workflowInstanceCode: string(),
        serial: string(),
    })
};

export const returnFormCompanyDetailByCountry = (countryCode, t) => countryCode == COUNTRYS.MEXICO.CODE ? formCompanyDetailSchemaMx(countryCode, t) : formCompanyDetailSchemaCo(countryCode, t);