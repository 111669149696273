import { put, call } from 'redux-saga/effects';
import * as types from '../actions'
import API from "../services/api";

const api = API.create();

export function* registerSaga(payload) {
    try {
        const response = yield call(api.auth.register, payload);
        yield [
            put({ type: types.REGISTER_USER_SUCCESS, response })
        ];
    } catch(error) {
        yield put({ type: types.REGISTER_USER_ERROR, error });
    }
}

export function* loginSaga(payload) {
    try {
        const response = yield call(api.auth.login, payload);
        yield [
            put({ type: types.LOGIN_USER_SUCCESS, response })
        ];
    } catch(error) {
        yield put({ type: types.LOGIN_USER_ERROR, error })
    }
}
