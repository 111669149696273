import React, { Component } from 'react';
import Login from '../../components/auth/login';
import SiigoLogo from '../../assets/img/siigo-backOffice.svg';
import './auth.css';

class AuthLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let token = this.props.match.params.token;
    let cloudToken = this.props.match.params.cloudToken;
    return (
      <div className="icommerce">
        <header className="ic-header">
          <div className="logoContainer">
            <img src={SiigoLogo} alt="Siigo iCommerce" />
          </div>
          <Login token={token} cloudToken={cloudToken} />
        </header>
      </div>
    );
  }
}

export default AuthLogin;
