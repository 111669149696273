import axios from "axios";
import { COUNTRYS } from "../utils";
import { getCookie } from "../../../utils/cookies";
import { convert2AlphaCodeTo3AlphaCodeCountry } from "../../../sagas/marketSaga";
import { uuidV4Generator } from "../../../utils/randomizer";
import config from "../../../config";

export const changeUserEmail = async (country, oldEmail, newEmail, companyKey, tenantId) => {
    switch (country) {
        case COUNTRYS.COLOMBIA.CODE:
            return await changeUserEmailCol(COUNTRYS.COLOMBIA.CODE, oldEmail, newEmail, companyKey);

        case COUNTRYS.MEXICO.CODE:
            return await changeUserEmailMex(COUNTRYS.MEXICO.CODE, oldEmail, newEmail, tenantId);

        default:
            return { success: false, status: 500, data: "No se ha seleccionado un país válido" };
    }
};

const validateEmail = async (country, email) => {
    try {
        const options = {
            method: 'POST',
            url: `${config.url}bridge`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('token')}`,
                'backoffice-service-country': convert2AlphaCodeTo3AlphaCodeCountry(country),
                'backoffice-service-name': 'valid-change-user-email',
                'backoffice-request-id': uuidV4Generator(),
            },
            data: { oldEmail: email },
        };
        const response = await axios.request(options);
        return { success: true, data: await response.data };
    } catch (error) {
        return { success: false, data: error.message };
    }
};

const changeUserEmailCol = async (country, oldEmail, newEmail, companyKey) => {
    try {
        const validate = await validateEmail(country, oldEmail);
        if (!validate.success && !validate.data.status) return validate;
        const options = {
            method: 'POST',
            url: `${config.url}bridge`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('token')}`,
                'backoffice-service-country': convert2AlphaCodeTo3AlphaCodeCountry(country),
                'backoffice-service-name': 'change-user-email',
                'backoffice-request-id': uuidV4Generator(),
            },
            data: {
                companyKey: companyKey,
                oldEmail: oldEmail,
                newEmail: newEmail,
                isMultiUser: validate.data.isMultiUser,
                isAccountant: validate.data.isAccountant,
                userLicenceType: validate.data.userLicenceType,
                changeCompanyAll: false
            }
        };
        const response = await axios.request(options);
        return { success: true, status: response.status, isMultiUser: validate.data.isMultiUser, data: await response.data };
    } catch (error) {
        return { success: false, status: 500, isMultiUser: false, data: error.message };
    }
}

const changeUserEmailMex = async (country, oldEmail, newEmail, tenantId) => {
    try {
        const validate = await validateEmail(country, oldEmail);
        if (!validate.success && !validate.data.status) return validate;
        const options = {
            method: 'POST',
            url: `${config.url}bridge`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('token')}`,
                'backoffice-service-country': convert2AlphaCodeTo3AlphaCodeCountry(country),
                'backoffice-service-name': 'change-user-email',
                'backoffice-request-id': uuidV4Generator(),
            },
            data: {
                TenantId: tenantId,
                UserLogin: oldEmail,
                NewUserLogin: newEmail,
            }
        };
        const response = await axios.request(options);
        return { success: true, status: response.status, isMultiUser: validate.data.isMultiUser, data: await response.data };
    } catch (error) {
        return { success: false, status: 500, isMultiUser: false, data: error.message };
    }
};