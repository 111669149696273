import * as types from '../index';

export const getAccountants = params => {
  return { type: types.GET_ACCOUNTANTS, params };
};

export const sendEmail = params => {
  return { type: types.SEND_EMAIL_ACCOUNTANT, params };
};

export const resendMassiveEmails = params => {
  return { type: types.SEND_MASSIVE_EMAIL_ACCOUNTANT, params};
};

export const sendExcelObject = params => {
  return { type: types.SEND_EXCEL_OBJECT, params };
};

export const modifyAccountant = params => {
  return { type: types.MODIFY_ACCOUNTANT, params };
}
