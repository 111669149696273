import * as types from '../actions';

const blackListEmailReducer = (state = { isLoading: false }, action = null) => {    

    console.log(action);

    switch (action.type) {
        case types.GET_BLACK_LIST_EMAIL:
            return { ...state, isLoading: true };

        case types.GET_BLACK_LIST_EMAIL_RECEIVED:
            return { ...state, isLoading: false, emails: action.emails}

        case types.GET_BLACK_LIST_EMAIL_FAILED:
            return { ...state, isLoading: false, emails: action.error }

        // Delete

        case types.DELETE_BLACK_LIST_EMAIL:
            return { ...state, isLoading: true };

        case types.DELETE_BLACK_LIST_EMAIL_RECEIVED:
            return { ...state, isLoading: false, remove: action.remove }

        case types.DELETE_BLACK_LIST_EMAIL_FAILED:
            return { ...state, isLoading: false, remove: action.error }

        default:
            return state;
    }
};

export default blackListEmailReducer;