import * as types from '../actions';

const generalReportReducer = (state = { isLoading: true }, action = null) => {
    switch (action.type) {
        case types.GET_CONFIG_GENERAL_REPORT:
            return { ...state, isLoadingGetConfig: true };
        case types.GET_CONFIG_GENERAL_REPORT_RECEIVED:
            return { ...state, isLoadingGetConfig: false, configReport: action.data };
        case types.GET_CONFIG_GENERAL_REPORT_FAILED:
            return { ...state, isLoadingGetConfig: false, errorGetConfig: action.error };

        case types.FILTER_DATA_BY_SOURCE:
            return { ...state, isLoadingFilter: true };
        case types.FILTER_DATA_BY_SOURCE_RECEIVED:
            return { ...state, isLoadingFilter: false, filterReport: action.data };
        case types.FILTER_DATA_BY_SOURCE_FAILED:
            return { ...state, isLoadingFilter: false, errorFilter: action.error };
        

        case types.DOWNLOAD_REPORT_DATA_BY_SOURCE:
            return { ...state, isLoadingDownload: true }
        case types.DOWNLOAD_REPORT_DATA_BY_SOURCE_RECEIVED:
            return { ...state, isLoadingDownload: false, downloadData: action.data, successDownload: true };
        case types.DOWNLOAD_REPORT_DATA_BY_SOURCE_FAILED:
            return { ...state, isLoadingDownload: false, errorDownloadReport: action.error, successDownload: false }
        default:
            return state
    }
}

export default generalReportReducer