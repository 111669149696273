import React, { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SiigoInputAtom } from '@siigo/siigo-input-atom-react';
import './modal.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import Swal from 'sweetalert2'
import { getCookie } from '../../../../utils/cookies';
const UPDATE_ACTION = 3;
const RULES_MODULE = "Administración five9, módulo reglas";
const UPDATE_REGISTRY_LOG = "Actualizar reglas";
const INSERT_REGISTRY_LOG = "Insertar reglas";
const SOURCE_COUNTRY_SELECT = "country";
const SOURCE_STATE_SELECT = "state";

const Modal = ({ setShowModal, props, loadRuleInformation, data = {}, action, listCountries }) => {

    const SIGNAL_STATUS = "SIGNAL_STATUS"
    const SIGNAL_COUNTRY = "SIGNAL_COUNTRY"
    const [formData, setFormData] = useState({
        keyName_w2c: data.rule_name ? data.rule_name : "",
        key_w2c: data.key ? data.key : "",
        parameter_w2c: data.parameter ? data.parameter : "",
        country_w2c: data.country ? data.country : "",
        skill_w2c: data.skill ? data.skill : "",
        status_w2c: data.status ? data.status : "Activo",
    })
    const [validateForm, setValidateForm] = useState({
        isKeyName_w2c: data.rule_name ? true : false,
        isKey_w2c: data.key ? true : false,
        isParameter_w2c: data.parameter ? true : false,
        isCountry_w2c: data.country ? true : false,
        isSkill_w2c: data.skill ? true : false,
        isStatus_w2c: true,
    })

    const [formMessage] = useState({
        tittle: action === UPDATE_ACTION ? "Editar regla" : "Crear regla",
        subTittle: action === UPDATE_ACTION ? "Edita la información para esta regla" : "Completa la información para agregar una nueva regla.",
        cancelButton: "Cancelar",
        sendActionButton: action === UPDATE_ACTION ? "Guardar" : "Crear"
    })
    const [isButtonActive, setIsButtonActive] = useState(action !== UPDATE_ACTION)
    useEffect(() => {
        setIsButtonActive(!isAllFieldsComplete(formData))
    });


    const handleSubmit = (e) => {
        e.preventDefault();
        if (isAllFieldsComplete(validateForm)) {
            let tempData = getFormData(action);
            let addResponse = props.actions.addRulesFive9(tempData);
            if (addResponse) {
                loadRuleInformation(tempData.data);
                setShowModal();
                const setQuery = action === UPDATE_ACTION ? "updateRule" : "addRule";
                const actionRegistryLog = action === UPDATE_ACTION ? UPDATE_REGISTRY_LOG : INSERT_REGISTRY_LOG;
                updateLog(RULES_MODULE, getCookie('name'), new Date(), actionRegistryLog, formUpdateLogData(tempData, action === UPDATE_ACTION));
                localStorage.setItem("five9Notification", setQuery);
                //Adiciona reload dado que es necesario realizar refactor de table para que recargue estados
                //Nota: Horas invertidas intentando ajustar el componente table : 12 horas 
                //Si intentas hacer un refactor y no te funciona, aumentar el contador de horas!!
                //pdta: el componente de table migrará a DSM
                setTimeout(() => {
                    window.location.replace(window.location.href);
                }, 200)
            }
        } else {
            //mensaje de obligatoriedad de campos
            Swal.fire({
                title: 'Error',
                text: 'Todos los campos son obligatorios',
                icon: 'error',
            })
        }
    }

    const formUpdateLogData = (tempData, isUpdate) => {
        return isUpdate ? "Antiguo registro: " + JSON.stringify(data) + ". Nuevo registro: " + JSON.stringify(tempData) : JSON.stringify(tempData);
    }

    const updateLog = (nameComponent, nameUser, date, action, aditionalData) => {
        let data = {
            nameComponent,
            nameUser,
            date,
            action,
            aditionalData,
        };

        props.actions.setLog(data);
    };

    const isAllFieldsComplete = (properties) => {
        return Object.values(properties).filter(val => !val).length === 0;
    }

    const getFormData = (idAction) => {
        let idCountry = listCountries?.data?.data.filter(country => country.country === formData.country_w2c || country._id === formData.country_w2c );
        if(Array.isArray(idCountry) && idCountry.length > 0){
            idCountry = idCountry[0]._id;
        }
        return {
            action: idAction,
            id: action === UPDATE_ACTION ? data._id : "",
            data: {
                rule_name: formData.keyName_w2c,
                key: formData.key_w2c,
                parameter: formData.parameter_w2c,
                country: idCountry,
                skill: formData.skill_w2c,
                status: formData.status_w2c === 'Inactivo' || formData.status_w2c === 'false' ? 'false': 'true'
            }
        }
    }

    const onTextChanged = (e) => {
        e.preventDefault();
        const text = e.target.value;
        const isEmpty = text.length === 0;
        if (e.target.classList.length < 2) return false;
        switch (e?.target?.classList[0]) {
            case 'keyName_w2c':
                setFormData(oldState => ({ ...oldState, keyName_w2c: text }))
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isKeyName_w2c: false }));
                return setValidateForm(oldState => ({ ...oldState, isKeyName_w2c: true }));
            case 'key_w2c':
                setFormData(oldState => ({ ...oldState, key_w2c: text }))
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isKey_w2c: false }));
                setValidateForm(oldState => ({ ...oldState, isKey_w2c: true }))
                return;
            case 'parameter_w2c':
                setFormData(oldState => ({ ...oldState, parameter_w2c: text }))
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isParameter_w2c: false }))
                setValidateForm(oldState => ({ ...oldState, isParameter_w2c: true }))
                return;
            case 'skill_w2c':
                setFormData(oldState => ({ ...oldState, skill_w2c: text }))
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isSkill_w2c: false }))
                setValidateForm(oldState => ({ ...oldState, isSkill_w2c: true }))
                return;
            case 'status_w2c':
                setFormData(oldState => ({ ...oldState, status_w2c: text }))
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isStatus_w2c: false }))
                setValidateForm(oldState => ({ ...oldState, isStatus_w2c: true }))
                return;
            default:
                return false
        }
    }

    const getValueBeforeSelect = (source) => {
        switch (source) {
            case SOURCE_COUNTRY_SELECT:
                if (formData.country_w2c) {
                    let countrySelected = listCountries?.data?.data.filter(country => country.country == formData.country_w2c || country._id == formData.country_w2c);
                    return Array.isArray(countrySelected) && countrySelected.length > 0 ? countrySelected[0]._id : "";
                }
                return "";
            case SOURCE_STATE_SELECT:
                if (formData.status_w2c) {
                    return formData.status_w2c === "Inactivo" || formData.status_w2c === "false" ? false : true;
                }
                return "";
            default:
                return "true";
        }
    }

    const handleOptions = (e) => {
        const sourceEvent = e.target.id;
        const sourceData = e.target.value;
        const isEmpty = sourceData.length === 0;
        switch (sourceEvent) {
            case SIGNAL_COUNTRY:
                setFormData(oldState => ({ ...oldState, country_w2c: sourceData }));
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isCountry_w2c: false }));
                setValidateForm(oldState => ({ ...oldState, isCountry_w2c: true }));
                return
            case SIGNAL_STATUS:
                setFormData(oldState => ({ ...oldState, status_w2c: sourceData }))
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isStatus_w2c: false }));
                setValidateForm(oldState => ({ ...oldState, isStatus_w2c: true }));
                return
            default:
                break
        }

    }

    return (
        <div className="modal-parent modal-w2c">
            <div className="container-notification">
                <Fragment>
                    <div className="container-content">

                    </div>
                </Fragment>
            </div>
            <div className="modal" id="modal">
                <div className="h2">
                    <span>{formMessage.tittle}</span>{' '}
                    <FontAwesomeIcon
                        onClick={() => setShowModal()}
                        icon={faTimes}
                        className="icon-close closeModal"
                    />
                </div>
                <div className="content">
                    <hr />
                    <Fragment>
                        <div className="container-content-w2c">
                            <p>{formMessage.subTittle}</p>
                        </div>
                        <form /* onClick={handleSubmit} */>
                            <div className="container-main-input">
                                <SiigoInputAtom
                                    value={formData.keyName_w2c}
                                    type="text"
                                    label={"Nombre regla"}
                                    required={true}
                                    className="keyName_w2c"
                                    onchange={onTextChanged}
                                />
                            </div>
                            <div className="group-input">
                                <div className="group-input-left">
                                    <SiigoInputAtom
                                        value={formData.key_w2c}
                                        type="text"
                                        label={"Llave"}
                                        required={true}
                                        className="key_w2c"
                                        onchange={onTextChanged}
                                    />
                                </div>
                                <div className="group-input-right">
                                    <SiigoInputAtom
                                        value={formData.parameter_w2c}
                                        type="text"
                                        label={"Parámetro"}
                                        required={true}
                                        className="parameter_w2c"
                                        onchange={onTextChanged}
                                    />
                                </div>
                            </div>
                            <div className="group-input">
                                <div className="group-input-left">
                                    <label className="label-state">País</label>
                                    <label className="label-state mandatory-style">*</label>
                                    <select
                                        id={SIGNAL_COUNTRY}
                                        name="statusSelectCountry"
                                        className="statusSelectCountry"
                                        onChange={(e) => handleOptions(e)}
                                        value={getValueBeforeSelect(SOURCE_COUNTRY_SELECT)}
                                    >
                                        <option value="" disabled hidden>País</option>
                                        {listCountries?.data?.data.map((country, key) => {
                                            return <option key={country._id} value={country._id}>{country.country}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="group-input-right end-align">
                                    <SiigoInputAtom
                                        value={formData.skill_w2c}
                                        type="text"
                                        label={"Skill"}
                                        required={true}
                                        className="skill_w2c"
                                        onchange={onTextChanged}
                                    />
                                </div>
                            </div>
                            <div className="group-input">
                                <div className="group-input-left only-one">
                                    <label className="label-state">Estado</label>
                                    <label className="label-state mandatory-style">*</label>
                                    <select
                                        id={SIGNAL_STATUS}
                                        name="statusSelect"
                                        className="statusSelect"
                                        onChange={(e) => handleOptions(e)}
                                        value={getValueBeforeSelect(SOURCE_STATE_SELECT)}
                                      >
                                        <option value="" disabled hidden>Estado</option>
                                        <option value="true">Activo</option>
                                        <option value="false">Inactivo</option>
                                    </select>
                                </div>
                            </div>
                            <div className="container-buttons">
                                <div className="float-lf">
                                    <SiigoButtonAtom
                                        color="tertiary"
                                        name="configButton"
                                        size="m"
                                        text={formMessage.cancelButton}
                                        type="button"
                                        className="cancelButton"
                                        onclick={() => setShowModal(false)}
                                    />
                                </div>
                                {
                                    isButtonActive ? (
                                        <div className="float-lf">
                                            <SiigoButtonAtom
                                                color="primary"
                                                name="configButton"
                                                size="m"
                                                text={formMessage.sendActionButton}
                                                type="button"
                                                className="addButton"
                                                disabled={isButtonActive}
                                                onclick={(e) => handleSubmit(e)}
                                            />
                                        </div>
                                    ) : (
                                        <div className="float-lf">
                                            <SiigoButtonAtom
                                                color="primary"
                                                name="configButton"
                                                size="m"
                                                text={formMessage.sendActionButton}
                                                type="button"
                                                className="addButton"
                                                onclick={(e) => handleSubmit(e)}
                                            />
                                        </div>
                                    )
                                }

                            </div>
                        </form>
                    </Fragment>
                </div>
            </div>
        </div>
    );
}

export default Modal;