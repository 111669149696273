function getEndPoint(name) {
  const endPoints = JSON.parse(localStorage.getItem('configInst')) || [];
  if (endPoints.length) {
    return endPoints?.find(e => e.name == name);
  }
}

export default function (api) {
  const baseUrl = getEndPoint('menuActivate')?.url ?? '';

  return {
    saveMenuActivate: (params) => api.post(`${baseUrl}/ActivateFromFile`, params),
    removeMenuActivate: (params) => api.post(`${baseUrl}/RemoveModuleFromFile`, params)
  };
}