import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Pagination extends Component {

    constructor(props, context) {
        super(props, context);
        const { startingPage, data, pageSize, serverPagination, pageCountServer } = this.props;
        let pageCount = serverPagination ? parseInt(pageCountServer / pageSize) : parseInt(data.length / pageSize);
        if (pageCountServer && (pageCountServer % pageSize > 0)) {
            pageCount++
        } else if (!pageCountServer && (data.length % pageSize > 0)) {
            pageCount++;
        }
        this.state = {
            currentPage: startingPage ? startingPage : 1,
            pageCount: pageCount,
            pageData: this.props.data,
            lastPage: null,
            currentPagData: null
        };

        this.createPaginatedData();
    }

    createControls = (currentPage) => {
        let controls = [];
        let flagPoint = true;
        const pageCount = this.state.pageCount;
        const initPage = (pageCount >= 3) ? 3 : pageCount;
        const endPage = (pageCount >= 3) ? pageCount - initPage : pageCount;
        const twoBefore = currentPage - 2;
        const twoAfter = currentPage + 2;
        for (let i = 1; i <= pageCount; i++) {
            if (i <= initPage || i > endPage || (i > twoBefore && i <= twoAfter)) {
                const baseClassName = 'pagination-controls__button';
                const activeClassName = i === this.state.currentPage ? `${baseClassName}--active` : '';
                controls.push(
                    <li className={`${baseClassName} ${activeClassName}`} key={i}>
                        <Link to={'#'} onClick={() => this.setState({
                            currentPage: i
                        }, this.createPaginatedData)}>
                            {i}
                        </Link>
                    </li>
                );
                flagPoint = true;
            } else if (flagPoint) {
                controls.push(
                    <li>
                        <span>...</span>
                    </li>
                );
                flagPoint = false;
            }
        }
        return (<ul>
            {controls}
        </ul>);
    };

    createPaginatedData = () => {
        const { currentPage, pageData } = this.state;
        const { pageSize, serverPagination } = this.props;
        const upperLimit = currentPage * pageSize;
        if(serverPagination){
            this.props.setCurrentPage(currentPage);
        } else{
            this.props.pageData(pageData.slice((upperLimit - pageSize), upperLimit), pageSize);
        }
    };

    render() {
        const { paginationNav, pageSize, serverPagination } = this.props;
        return (pageSize < this.state.pageData.length || serverPagination ?
            <div className={'pagination'}>
                <Link to={'#'} className={'back'}
                    onClick={this.state.currentPage > 1 ? () => this.setState({
                        currentPage: this.state.currentPage - 1
                    }, this.createPaginatedData) : null} >
                    {paginationNav === 'arrows' || paginationNav === null ?
                        <i className="arrow azure left"></i> :
                        <i className="dots"></i>
                    }
                </Link>
                {this.createControls(this.state.currentPage)}
                <Link to={'#'} className={'next'}
                    onClick={this.state.currentPage <= this.state.pageCount ? () => this.setState({
                        currentPage: this.state.currentPage === this.state.pageCount ? this.state.currentPage : this.state.currentPage + 1
                    }, this.createPaginatedData) : null} >
                    {paginationNav === 'arrows' || paginationNav === null ?
                        <i className="arrow azure right"></i> :
                        <i className="dots"></i>
                    }
                </Link>
            </div> : null
        );
    }
}

export default Pagination;
