import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as GroupActions from '../../actions/groups/groupAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalDelete from '../layout/modals/modalDelete';
import ModalResponse from '../layout/modals/modalResponse';
import svgs from '../layout/icons';

const columns = [
	{
		name: 'Nombre del grupo',
		selector: 'name',
		sortable: true
	},
	{
		name: 'Descripción',
		selector: 'description',
		sortable: true,
		filterDDL: true
	},
	{
		name: 'Dependencia',
		selector: 'dependence',
		sortable: true,
		filterDDL: true
	}
];
let deleteDep = false;
class GroupList extends Component {
	componentDidMount() {
		this.props.actions.getGroups();
	}

	checkDelete = id => {
		ModalDelete({
			actionDelete: this.props.actions.deleteGroup,
			id: id
		});
		deleteDep = true;
	};

	render() {
		let { error, isLoading, isLoadingDel, groupList, resDelete } = this.props;
		const btnActions = {
			btnEdit: '/group/edit/',
			btnDelete: this.checkDelete
		};
		let datos = [];
		let status = 0;
		let text = '';
		if (groupList) {
			if (groupList.data.error === 'Forbidden') {
				return <div>No tiene permiso para este componente</div>;
			}
			datos = groupList.data;
		}
		if (resDelete && deleteDep && !isLoadingDel) {
			if (resDelete.status === 200) {
				text = 'La dependencia ha sido eliminada';
			} else if (resDelete.status === 404) {
				text =
					'No fue posible eliminar el grupo, es posible que este asignado a un usuario';
			}
			deleteDep = false;
			status = resDelete.status;
		}
		return (
			<div>
				{!isLoadingDel && status > 0 && text !== '' ? (
					<ModalResponse title={'Eliminado!'} text={text} res={status} />
				) : null}
				{error && <h4>Error: {error.response.data}</h4>}
				{isLoading ? (
					<Loader />
				) : (
					<div>
						<h3>Grupos</h3>
						<a href="/group/create" className="a-button green-fill">
							<img className="icon-img icon-mas" src={svgs['mas']} alt="" />
							Crear
						</a>
						<TableReport
							columns={columns} // Data columns to table
							tabs={false} //Enable tabs
							tabsData={[]} //Load data to tabs check option on tabsData const
							headerCounterTab={true} //Counter tab option
							btnActions={btnActions}
							actionButtons={true} //Enable buttons (edit, delete, copy, open)
							pageSize={10} // Enable page size
							pagination={true} //Enable pagination (true, false)
							paginationNav={'arrows'} //Nav options (arrow, dot)
							paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
							maxSizePagination={6} //Size pagination
							defaultPaginationNumber={1} //Current pagination when start app
							paginationGrouped={10} //Grouped by page
							detailRow={false} //Open column detail with left arrow
							data={datos} //Data for table
						/>
					</div>
				)}
			</div>
		);
	}
}

GroupList.propTypes = {
	actions: PropTypes.object.isRequired,
	groupList: PropTypes.object,
	error: PropTypes.object
};

const mapStateToProps = state => ({
	groupList: state.groups.groups,
	resDelete: state.groups.group,
	error: state.groups.err,
	isLoading: state.groups.isLoading,
	isLoadingDel: state.groups.isLoadingDel
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(GroupActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
