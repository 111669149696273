import * as types from '../actions';

const dependenceReducer = (state = { isLoading: true }, action = null) => {
  switch (action.type) {
    case types.GET_DEPENDENCES:
      return { ...state, isLoading: true };
    case types.DEPENDENCES_RECEIVED:
      return { ...state, isLoading: false, dependences: action.data };
    case types.DEPENDENCES_REQUEST_FAILED:
      return { ...state, isLoading: false, dependences: action.error };
    case types.GET_DEPENDENCE:
      return { ...state, isLoading: true };
    case types.DEPENDENCE_RECEIVED:
      return { ...state, isLoading: false, dependence: action.data };
    case types.DEPENDENCE_REQUEST_FAILED:
      return { ...state, isLoading: false, dependence: action.error };
    case types.CREATE_DEPENDENCE:
      return { ...state, isLoading: true };
    case types.CREATE_DEPENDENCE_SUCCESS:
      return { ...state, isLoading: false, dependence: action.data };
    case types.CREATE_DEPENDENCE_FAILED:
      return { ...state, isLoading: false, dependence: action.error };
    case types.UPDATE_DEPENDENCE:
      return { ...state, isLoadingUp: true };
    case types.UPDATE_DEPENDENCE_SUCCESS:
      return { ...state, isLoadingUp: false, dependence: action.data };
    case types.UPDATE_DEPENDENCE_FAILED:
      return { ...state, isLoadingUp: false, dependence: action.error };
    case types.DELETE_DEPENDENCE:
      return { ...state, isLoadingDel: true };
    case types.DELETE_DEPENDENCE_SUCCESS:
      return { ...state, isLoadingDel: false, dependence: action.data };
    case types.DELETE_DEPENDENCE_FAILED:
      return { ...state, isLoadingDel: false, dependence: action.error };
    default:
      return state;
  }
};

export default dependenceReducer;
