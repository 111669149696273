import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api';
import APILocal from '../services/api/index';



export function* getLogs() {
	const api = API.create();
	try {
		let logs = yield call(api.logs.logs);
		yield put({ type: types.LOGS_RECEIVED, data: logs });
	} catch (err) {
		yield put({ type: types.LOGS_REQUEST_FAILED, err });
	}
}


export function* getQueryLogs(action) {
	const api = API.create();
	try {
		const { params } = action;
		let logs = yield call(api.logs.getQueryLogs, params)

		console.log("Inicio de log sagas");
		console.log(logs);
		console.log("Fin de log sagas");
		
		yield put({ type: types.GET_QUERY_LOGS_RECEIVED, data: logs })
	} catch (err) {
		yield put({ type: types.GET_QUERY_LOGS_FAILED, err })
	}
}

export function* createLogs(action) {
	const api = API.create();
	try {
		const { params } = action;
		//console.log("createLogs params => ", params);
		const logDetail = yield call(api.logs.createLog, params); 
		//console.log("createLogs resp => ", logDetail);
		yield put({ type: types.CREATE_LOG_SUCCESS, data: logDetail,});
	} catch (err) {
		yield put({ type: types.CREATE_LOG_FAILED, err });
	}
}
