import React, { Component } from 'react';
import Modal from './indexDetail';
import ModalResponse from '../../layout/modals/modalResponse';
import Loader from '../../tools/loader';
import TableReport from '../../tableReport';
import { getAccess } from '../../../utils/access';
import { ACCESS } from '../access';
const PATH_COMPONENT = '/market'

const columnsAtrib = [
  {
    name: 'Nombre',
    selector: 'PackageName',
  },
  {
    name: 'Fecha',
    selector: 'PurchaseDate',
  },
  {
    name: 'Cantidad',
    selector: 'DocumentsQuantity',
  },
  {
    name: '# de tarea',
    selector: 'Observations',
  },

];
export default class ModalPackageDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      packages: [],
      isLoadingPack: false,
      isLoadingPackDelete: false,
      packs: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    //showConsole('this.props PAYMENTS', this.props);
    if (nextProps.showModal !== this.state.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }
    if (nextProps.packages.length !== this.state.packages.length) {
      this.setState({ packages: nextProps.packages });
    }

    if (nextProps.isLoadingPack != this.state.isLoadingPack) {
      this.setState({ isLoadingPack: nextProps.isLoadingPack });
    }

    if (nextProps.isLoadingPackDelete !== this.state.isLoadingPackDelete) {
      this.setState({ isLoadingPackDelete: nextProps.isLoadingPackDelete });
    }

    if (nextProps.packs.length != this.state.packs.length) {
      this.setState({ packs: nextProps.packs });
    }
  }

  showDelete = (e, data) => {
    e.preventDefault();
    if (this.props.acronym && !getAccess(PATH_COMPONENT, ACCESS['Remove_packs_' + this.props.acronym])) {
      return ModalResponse({
        title: 'Oops',
        text: 'No tienes permisos para eliminar este elemento, contáctese con el administrador',
        res: 500,
      });
    }
    let datos = {
      ProductBase: (data.ProductBase) ? true : false,
      CompanyKey: data.CompanyKey,
      PackageID: data.PackageID,
    };
    this.props.deletePackage(e, datos)
  };

  filterHistoryActual = () => {
    const REFERENCE_CODE_MANUAL = 'Ajuste manual';
    let lastChangeManual = 0;
    for (let x = 0; x < this.props.packages.length; x++) {
      if (this.props.packages[x].ReferenceCode == REFERENCE_CODE_MANUAL) {
        lastChangeManual = x
      }
    }
    let packageAvalible = (lastChangeManual != 0) ? this.props.packages.slice(lastChangeManual + 1, this.props.packages.length) : this.props.packages;
    packageAvalible[0].ProductBase = true;
    return packageAvalible;
  }


  /**
   * Método utilizado para validar si el plan que se muestra en el diálogo puede eliminarse 
   * para lo cual, se agrega campo deletePkg para que el componente de la tabla pueda pintar o no el botón de eliminar el plan 
   * @return {Array} Retorna arreglo de objetos añadiendo la propiedad deletePkg en true o false
   */
  addDeleteStatus = () => {
    if (Array.isArray(this.state.packages) && Array.isArray(this.props.packs)) {
      let packageAvalible = this.filterHistoryActual()
      let data = [];
      packageAvalible.forEach(val => {
        let obj = { ...val };
        if (this.props.packs.findIndex(pack => pack.Code === val.PackageID) > -1 ||
          this.props.packs.findIndex(pack => pack.Quantity === val.DocumentsQuantity) > -1) {
          obj['deletePkg'] = true;
          //Se valida si es un paquete eliminado (si la cantidad de documentos es negativa)
          if (val.DocumentsQuantity < 0) {
            obj.deletePkg = false;
            //Se realiza la búsqueda del paquete eliminado en los paquetes que retorna el servicio para la empresa para quitarlos del listado
            let index = data.findIndex(val2 => val2.PackageID === val.PackageID && val2.deletePkg && val2.ProductBase != true)
            if (index > -1) {
              data[index].deletePkg = false;
            }
          } else if (this.isInitialPackage(obj)) {
            obj.deletePkg = false;
            obj['isInitialPackage'] = true;
          }
          data.push(obj);
        } else {
          obj['deletePkg'] = false;
          data.push(obj);
        }
      });
      //Se realiza filtro para mostrar unicamente la información de paquete inicial y paquetes que se puedan eliminar
      return data.filter(value => value.deletePkg || value.isInitialPackage);
    }
    return this.state.packages;
  }

  /**
   * Función que ayuda a validar si el paquete enviado es un paquete inicial de la empresa (si es un paquete inicial no se debería permitir eliminar)
   * @param {Object} objectValue Objeto que debe tener la propiedad ReferenceCode o Observations, pues son las propiedades que se toman para determinar si es un paquete inicial
   * @return {boolean} valor true si es un paquete inicial y false si no es un paquete inicial
   */
  isInitialPackage = (objectValue) => {
    const CODE_INITIAL_PACK = 'Paquete inicial';
    const REFERENCE_INITIAL = 'inicial';
    if (objectValue.ReferenceCode && objectValue.Observations) {
      if (objectValue.ReferenceCode === CODE_INITIAL_PACK || objectValue.Observations === CODE_INITIAL_PACK) {
        return true;
      } else if (objectValue.ReferenceCode.indexOf(REFERENCE_INITIAL) >= 0 || objectValue.Observations.indexOf(REFERENCE_INITIAL) >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  render() {
    const btnActions = {
      btnDeletePacket: this.showDelete,
      chMassiveActions: false,
    };
    return (
      <Modal
        onClose={this.props.hideModalPackage}
        title="Paquetes adicionados"
        show={this.state.showModal}
        getPackageUpdate={this.props?.getPackageUpdate}
        loading={this.state.isLoadingPack}
      >
        {this.state.packages.length > 0 ? (
          <div className="divTableModal">
            <TableReport
              columns={columnsAtrib} // Data columns to table
              tabs={false} //Enable tabs
              tabsData={[]} //Load data to tabs check option on tabsData const
              headerCounterTab={true} //Counter tab option
              btnActions={btnActions}
              actionButtons={true} //Enable buttons (edit, delete, copy, open)
              pageSize={10} // Enable page size
              pagination={false} //Enable pagination (true, false)
              paginationNav={'arrows'} //Nav options (arrow, dot)
              paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
              maxSizePagination={6} //Size pagination
              defaultPaginationNumber={1} //Current pagination when start app
              paginationGrouped={10} //Grouped by page
              detailRow={false} //Open column detail with left arrow
              data={this.state.packages && this.props.packs ? this.addDeleteStatus() : this.state.packages} //Data for table
              dataPack={this.state.packs}
            />
            {this.isLoadingPackDelete ? <Loader /> : null}
          </div>
        ) : (
          <div>{this.state.sourceData}</div>
        )}
      </Modal>
    );
  }
}
