import * as types from '../index';

export const getUsers = () => {
  return { type: types.GET_USERS };
};

export const getUser = msisdn => {
  return { type: types.GET_USER, msisdn };
};
export const getPerfil = msisdn => {
  return { type: types.GET_USER_PERFIL, msisdn };
};
export const setAvatar = (avatar, msisdn) => {
  return { type: types.SET_USER_AVATAR, msisdn, avatar };
};

export const createUser = plan => {
  return { type: types.CREATE_USER, plan };
};

export const updateUser = (user, msisdn) => {
  return { type: types.UPDATE_USER, msisdn, user };
};

export const deleteUser = msisdn => {
  return { type: types.DELETE_USER, msisdn };
};

export const getMenuUser = msisdn => {
  return { type: types.GET_MENU_USER, msisdn };
};
