import * as types from '../index';

export const getInvoices = params => {
	return { type: types.GET_INVOICES, params };
};

export const resendInvoiceEmail = params => {
	return { type: types.RESEND_INVOICE_EMAIL, params}
}


export const sendExcelObject = params => {
	return { type: types.SEND_EXCEL_OBJECT, params };
  };