import axios from 'axios';
import config from '../../config';
import { getCookie } from '../../utils/cookies';

export class AxiosUpdateSubscription {

    static instanceAxios = axios.create({
        baseURL: config.url,
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
        },
        timeout: 100000
    });

    static getConfigAxios = (configAxios = {}, auth = true) => {
        if (configAxios === null || configAxios === undefined || typeof configAxios !== 'object') return {};
        configAxios.headers = auth ? { Authorization: 'bearer ' + getCookie('token'), ...configAxios.headers } : configAxios.headers;
        return configAxios;
    }
    
    static updateSuscription = async (obj, configAxios = {}, auth = true) => {
        return await AxiosUpdateSubscription.instanceAxios.post(config.pathUpdateSuscription, obj, AxiosUpdateSubscription.getConfigAxios(configAxios, auth));        
    }
    static getSuscription = async (query, configAxios = {}, auth = true) => {
        return await AxiosUpdateSubscription.instanceAxios.post(config.pathGetSuscription, query, AxiosUpdateSubscription.getConfigAxios(configAxios, auth));        
    }
}