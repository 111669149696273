import * as types from '../actions';

const groupReducer = (state = { isLoading: true }, action = null) => {
  switch (action.type) {
    case types.GET_GROUPS:
      return { ...state, isLoading: true };
    case types.GROUPS_RECEIVED:
      return { ...state, isLoading: false, groups: action.data };
    case types.GROUPS_REQUEST_FAILED:
      return { ...state, isLoading: false, groups: action.error };
    case types.GET_GROUP:
      return { ...state, isLoading: true };
    case types.GROUP_RECEIVED:
      return { ...state, isLoading: false, group: action.data };
    case types.GROUP_REQUEST_FAILED:
      return { ...state, isLoading: false, group: action.error };
    case types.CREATE_GROUP:
      return { ...state, isLoading: true };
    case types.CREATE_GROUP_SUCCESS:
      return { ...state, isLoading: false, group: action.data };
    case types.CREATE_GROUP_FAILED:
      return { ...state, isLoading: false, group: action.error };
    case types.UPDATE_GROUP:
      return { ...state, isLoadingUp: true };
    case types.UPDATE_GROUP_SUCCESS:
      return { ...state, isLoadingUp: false, group: action.data };
    case types.UPDATE_GROUP_FAILED:
      return { ...state, isLoadingUp: false, group: action.error };
    case types.DELETE_GROUP:
      return { ...state, isLoadingDel: true };
    case types.DELETE_GROUP_SUCCESS:
      return { ...state, isLoadingDel: false, group: action.data };
    case types.DELETE_GROUP_FAILED:
      return { ...state, isLoadingDel: false, group: action.error };
    default:
      return state;
  }
};

export default groupReducer;
