import React from 'react';
import { getComponents } from '../../actions/components/componentActions';
import { connect } from 'react-redux';
import './permisos.scss';

let compsData = [];
class Permisos extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			helper: true
		}

		this.onChangeComp = this.onChangeComp.bind(this)

	}

	componentWillMount() {
		this.props.dispatch(getComponents());
	}


	updateHelper() {
		this.setState({ helper: !this.state.helper })
	}


	onChangeComp(e) {

		const find = compsData.findIndex(k => k.component == e.target.getAttribute("data-id"));
		const accessId = Number(e.target.getAttribute('data-per-id'))
		//compsData[find].access.indexOf(accessId) != -1 ? compsData[find].access.splice(accessId - 1, 1) : compsData[find].access.push(accessId);
		if (compsData[find].access == undefined) compsData[find].access = [];
		compsData[find].access.indexOf(accessId) != -1 ? compsData[find].access = compsData[find].access.filter((n) => { return n != accessId }) : compsData[find].access.push(accessId);
		this.updateHelper()
	}



	render() {

		const { components, isNew } = this.props;
		let comps = [];
		if (components) {
			comps = components.data;
		}

		compsData = this.props.permisos;
		
		return compsData.map((val, idx) => (
			<div key={idx}>
				<div className="grid">
					<div className="col grid2">
						<div className="col-p input-container">
							<select
								name="component"
								data-id={idx}
								id={'component-' + idx}
								value={compsData[idx].component}
								onChange={this.props.change}
							>
								<option value="-9">Seleccionar Componente</option>
								{comps.map((comp, index) => {
									return (
										<option key={index} value={comp._id}>
											{comp.name}
										</option>
									);
								})}
							</select>
						</div>
						<div className="col-p input-container">
							<input
								type="text"
								name="name"
								data-id={idx}
								id={'name-' + idx}
								value={compsData[idx].name}
								onChange={this.props.change}
								placeholder="Nombre"
							/>
						</div>
						{!isNew ? (
							<div className="col-p input-container">
								{comps.map((comp, value) => {
									return comp._id == val.component && comp?.permissions ? (
										<div>
											{ comp.permissions.map((per, perVal) => {
												return (
													<div className="accessPermissionContainer">
														<input id={comp._id + per.id} type="checkbox" data-per-id={per.id} data-id={comp._id} onChange={this.onChangeComp} checked={val.access?.indexOf(per.id) != -1 ? true : false}></input>
														<label for={comp._id + per.id}>{per.name}</label>
													</div>
												)
											})}
										</div>
									) : null
								})}

							</div>
						) : null}
					</div>
					<div className="col-c">
						<button
							className="button yellow"
							type="button"
							data-id={idx}
							value="remove"
							onClick={this.props.remove}
						>
							<i className="fa fa-trash"></i> Eliminar
						</button>
					</div>
				</div>
			</div>
		));
	}
}

const mapStateToProps = state => ({
	components: state.components.components,
	error: state.components.err,
	isLoading: state.components.isLoading
});
export default connect(mapStateToProps)(Permisos);
