const listHeadersCoutry = [
    { name: 'País', selector: 'country', sortable: true },
    { name: 'Código país', selector: 'country_code', sortable: true },
    { name: 'Indicativo', selector: 'indicative', sortable: true },
    { name: 'Estado', selector: 'stateCountry', sortable: true },
    { name: 'Skill por defecto', selector: 'skill', sortable: true }
];

const listHeadersRules = [
    { name: 'Nombre regla', selector: 'rule_name', sortable: true, filterDDL: true, money: false },
    { name: 'Llave', selector: 'key', sortable: true, filterDDL: true, money: false },
    { name: 'Parámetro', selector: 'parameter', sortable: true, filterDDL: true, money: false },
    { name: 'País', selector: 'country', sortable: true, filterDDL: true, money: false },
    { name: 'Skill', selector: 'skill', sortable: true, filterDDL: true, money: false },
    { name: 'Estado', selector: 'status', sortable: true, filterDDL: true, money: false }
];

//const stateOptionsModal = [{"id":1,"label":"Activo","value":true,"selected":true},{"id":2,"label":"Inacvtivo","value":false,"selected":false}];
const stateOptionsModal = [{ id: 1, label: "Activo", value: true, selected: true }, { id: 2, label: "Inactivo", value: false, selected: false }];

const statusActions =
{
    addRule: {
        success: {
            id: 1,
            title: "Nueva regla agregada",
            text: "La regla ha sido creada exitosamente",
            type: "success"
        },
        error: {
            id: 1,
            title: "Ocurrio un error",
            text: "La regla no ha sido creado exitosamente",
            type: "error"
        },
    },
    updateRule: {
        success: {
            id: 1,
            title: "La regla ha sido editada",
            text: "La regla ha sido editada exitosamente",
            type: "success"
        },
        error: {
            id: 1,
            title: "Ocurrio un error",
            text: "La regla no ha sido editada exitosamente",
            type: "error"
        },
    },
    deleteRule: {
        success: {
            id: 1,
            title: "Regla eliminada",
            text: "La regla ha sido eliminada exitosamente",
            type: "success"
        },
        error: {
            id: 1,
            title: "Ocurrio un error",
            text: "La regla no ha sido eliminada exitosamente",
            type: "error"
        },
    },
    addCountry: {
        success: {
            id: 1,
            title: "Nuevo país agregado",
            text: "El país ha sido agregado exitosamente",
            type: "success"
        },
        error: {
            id: 1,
            title: "Ocurrio un error ",
            text: "El país no ha sido agregado exitosamente",
            type: "error"
        },
    },
    updateCountry: {
        success: {
            id: 1,
            title: "País editado",
            text: "El país ha sido editado exitosamente",
            type: "success"
        },
        error: {
            id: 1,
            title: "Ocurrio un error",
            text: "El país no ha sido editado exitosamente",
            type: "error"
        },
    },
    deleteCountry: {
        success: {
            id: 1,
            title: "País eliminado",
            text: "El país ha sido eliminado exitosamente",
            type: "success"
        },
        error: {
            id: 1,
            title: "Ocurrio un error",
            text: "El país no ha sido eliminado exitosamente",
            type: "error"
        },
    },
    unauthorized_user: {
        success: {
            id: 1,
            title: "Usuario no autorizado",
            text: "Usted no cuenta con permisos suficientes. Por favor contactar el administrador.",
            type: "error"
        },
    }
}

module.exports = { listHeadersCoutry, listHeadersRules, stateOptionsModal,  statusActions }