// a library to wrap and simplify api calls
import apisauce from 'apisauce';
import MailActions from './services/MailActions';
import config from '../../config';

const create = () => {

  const api = apisauce.create({    
    headers: {
      'Cache-Control': 'no-cache',
      'content-type': 'application/json'
    },
    // 30 second timeout...
    timeout: 30000
  });

  const mailActions = new MailActions(api);
  //  return Api
  return {
    setHeader: (key, value) => api.setHeader(key, value),
    removeHeader: key => api.deleteHeader(key),
    // a list of the API functions from step 2
    mailActions
  };
};

// let's return back our create method as the default.
export default {
  create
};
