// a library to wrap and simplify api calls
import apisauce from 'apisauce';
import Auth from './services/Auth';
import Plans from './services/Plans';
import Dependences from './services/Dependences';
import Components from './services/Components';
import Groups from './services/Groups';
import Customer from './services/Customers';
import AuroraConfigOriginsServices from './services/AuroraConfigOrigins';
import GeneralReport from './services/generalReport';
import User from './services/Users';
import Campaigns from './services/Campaign';
import Log from './services/Log';
//import Survey from './services/Survey'
import Countries from './services/Countries';
import config from '../../config.js';
import { getCookie } from '../../utils/cookies';
import ActivationManagement from './services/ActivationManagement';
import DataSource from './services/DataSource';
// our "constructor"
const create = (baseURL = config.url) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'content-type': 'multipart/form-data',
      'content-type': 'application/json',
      Authorization: 'bearer ' + getCookie('token')
    },
    // 30 second timeout...
    timeout: 30000
  });

  const auth = new Auth(api);
  const plans = new Plans(api);
  const customers = new Customer(api);
  const users = new User(api, getCookie('token'));
  const dependences = new Dependences(api);
  const components = new Components(api);
  const countries = new Countries(api);
  const groups = new Groups(api);
  const logs = new Log(api);
  const campaigns = new Campaigns(api);
  const activatedMenu = new ActivationManagement(api);
  const auroraConfigOrigins = new AuroraConfigOriginsServices(api);
  const generalReport = new GeneralReport(api);
  const dataSource = new DataSource(api);
  //  return Api
  return {
    setAuthToken: token => api.setHeader('Authorization', `bearer ${token}`),
    setAuth: auth => api.setHeader('auth', auth),
    removeAuthToken: () => api.deleteHeader('Authorization'),
    // a list of the API functions from step 2
    auth,
    plans,
    customers,
    users,
    dependences,
    components,
    groups,
    countries,
    logs,
    campaigns,
    auroraConfigOrigins,
    generalReport,
    dataSource,
    activatedMenu
  };
};

// let's return back our create method as the default.
export default {
  create
};
