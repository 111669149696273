import React, {Fragment, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from 'axios';
import config from '../../../config';
import {getAccess} from '../../../utils/access';
import {ACCESS} from '../access';
import {getPlanesComplements} from './functionData';
import {SiigoNotificationGrowlAtom} from "@siigo/siigo-notification-growl-atom-react";
import SearchComplements from "./searchComplements";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlug, faTimes} from '@fortawesome/free-solid-svg-icons';
import {ModalComplementsStates} from "./modalComplementsStates";
import {EditPayrollComplementCard} from "./editPayrollComplementCard/editPayrollComplementCard";
import {optionState, COMPLEMENTS_TYPES, ALLOWED_PLANT_TYPES_POS_WEB, COMPLEMENTS_TYPES_ENUM, PLANTYPE_FE, DEFAULT_POS_WEB_NAMES} from "../plandata";
import { getAddonComplements } from '../utils';
import ModalConfirmation from '../../layout/modals/modalConfirmation';
import { getCookie } from "../../../utils/cookies";
import { useContext } from "react";
import CountryContext from "../../../context/countries-context";
import { convert2AlphaCodeTo3AlphaCodeCountry } from "../../../sagas/marketSaga";
import { uuidV4Generator } from "../../../utils/randomizer";

const PATH_COMPONENT = '/market'

const ModalComplements = ({showModal, setshowModal, provisioningToken, ComplementRequest, getAddons}) => {
  const [complementOptions, setComplementOptions] = useState(ModalComplementsStates.INDEX);
  const [complements, setComplements] = useState(!ComplementRequest && ComplementRequest.length === 0 ? "" : ComplementRequest);
  const [notificationShow, setNotificationShow] = useState(false);
  const [messageNotification, setMessageNotification] = useState({});
  const [complementPlanEdit, setComplementPlanEdit] = useState("");
  const countryContext = useContext(CountryContext);

  const {t} = useTranslation();


  if (!showModal) return null;

  const addComplement = () => {
    setComplementOptions(ModalComplementsStates.ADD_COMPLEMENT);
  }

  const showNotification = () => {
    setNotificationShow(true);
    setInterval(function () {
      setNotificationShow(false);
    }, 5000)
  }

  const editComplementService = async (tenantId, data) => {
    try {
      const options = {
        method: 'put',
        url: `${config.url}bridge?tenantId=${tenantId}`,
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('token')}`,
          'backoffice-service-country': convert2AlphaCodeTo3AlphaCodeCountry(countryContext.country),
          'backoffice-service-name': 'addons-service',
          'backoffice-request-id': uuidV4Generator(),
        },
        data: data
      }
      const response = await axios(options);
      if (response.status === 200) {
        let addOns = await getAddons(provisioningToken);
        if (addOns?.data?.length && Array.isArray(addOns?.data)) {
          let addOnsView = JSON.parse(JSON.stringify(addOns.data));
          addOnsView = getAddonComplements(addOnsView);
          setComplements(addOnsView);
        } else {
          setComplements([]);
        }
        const notification = {
          title: "Complemento agregado",
          description: `El complemento ${data.name} ha sido agregado exitosamente.`
        }
        setComplementOptions(ModalComplementsStates.INDEX);
        showNotification();
        setMessageNotification(notification)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getDataComplement = async (data) => {
    let dataLocal = JSON.parse(localStorage.getItem("Company"));
    data.tenantId = dataLocal.CloudTenantConnectionUser.split("SIIGOCloudTenant_")[1];
    data.action = "create-complement";
    data.companyKey = dataLocal.CloudTenantCompanyKey;
    data.planType = dataLocal.PlanType;

    await sendComplement(data)
  }

  const sendComplement = async (dataComplement) => {
    try {
      await axios({
        method: 'post',
        url: `${config.url}bridge`,
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('token')}`,
          'backoffice-service-country': convert2AlphaCodeTo3AlphaCodeCountry(countryContext.country),
          'backoffice-service-name': 'addons-service',
          'backoffice-request-id': uuidV4Generator(),
        },
        data: dataComplement
      })
      let addOns = await getAddons(provisioningToken);
      if (addOns?.data?.length && Array.isArray(addOns?.data)) {
        let addOnsView = JSON.parse(JSON.stringify(addOns.data));
        addOnsView = getAddonComplements(addOnsView);
        setComplements(addOnsView);
      } else {
        setComplements([]);
      }
      let notification = null;
      if (dataComplement.action === "create-complement") {
        notification = {
          title: "Complemento agregado",
          description: `El complemento ${dataComplement.name} ha sido agregado exitosamente.`


        }
      } else {
        notification = {
          title: "Complemento eliminado",
          description: `El complemento ${dataComplement.name} ha sido eliminado exitosamente.`
        }
      }
      setComplementOptions(ModalComplementsStates.INDEX);
      showNotification();
      setMessageNotification(notification)
    } catch (error) {
      console.error(error)
    }
  }


  const removeComplement = async (comp) => {
    let dataLocal = JSON.parse(localStorage.getItem("Company"));
    if (comp.type === COMPLEMENTS_TYPES_ENUM.POW_WEB && dataLocal.PlanType == PLANTYPE_FE) return;

    const { isConfirmed } = await ModalConfirmation(
      `${t('removeComplement')}`,
      `${t('acceptRemoveComplement')}`,
      `${t('cancelRemoveComplement')}`,
      `<p>${t('confirmRemoveComplement')}</p>`,
    )

    if (!isConfirmed) return;
    let dataComplement = {
      name: comp.name,
      plan: comp.namePlan,
      value: comp.module,
      valueParent: comp.type,
      tenantId: dataLocal.CloudTenantConnectionUser.split("SIIGOCloudTenant_")[1],
      action: "delete-complement",
      companyKey: dataLocal.CloudTenantCompanyKey,
      documentBase: comp.documentBase,
      planType: dataLocal.PlanType,
      posActiveCashiers: comp.posActiveCashiers
    }
    await sendComplement(dataComplement);
  }

  const heightModal = () => {
    const element = document.querySelector(".modal-complements .content");
    element.style.height = "380px";
  }

  const configComplement = (comp) => {
    let plan;
    // Obtener si es plan lite o pro, plus
    if (comp.documentBase) {
      if (comp.type === COMPLEMENTS_TYPES_ENUM.NOMINA) {
        plan = getPlanesComplements(comp.documentBase, true, comp.type);
      }
      if (comp.type === COMPLEMENTS_TYPES_ENUM.POW_WEB) {
        plan = getPlanesComplements(comp.module, true, comp.type);
      }
    } else {
      plan = getPlanesComplements(comp.module, false, comp.type);
    }
    // temporal fix for addons
    if(!plan || plan.length === 0) {
      if(comp.type === COMPLEMENTS_TYPES_ENUM.NOMINA){
        plan = getPlanesComplements(24, true, COMPLEMENTS_TYPES_ENUM.NOMINA)
      }
      if(comp.type === COMPLEMENTS_TYPES_ENUM.POW_WEB){
        plan = getPlanesComplements(300, true, COMPLEMENTS_TYPES_ENUM.POW_WEB)
      }
    }
    plan = {
      ...plan[0],
      posActiveCashiers: {
        baseCashiers: comp?.posActiveCashiers?.baseCashiers,
        aditionalCashiers: comp?.posActiveCashiers?.aditionalCashiers
      }
    }
    setComplementPlanEdit(plan)
  }
  const getState = (subState) => {
    let newArray = optionState.filter(option => option.value == (subState ? subState : (subState == 0 ? subState : 1)));
    return newArray[0].name;
  }
  const allowedComplementsNumber = (complements) => {
    const companyInfo = JSON.parse(localStorage.getItem('Company'));
    return ALLOWED_PLANT_TYPES_POS_WEB.includes(companyInfo.PlanType) ? 2 : 1;
  };  

  const disbleDeleteButton = (comp) => {
    const dataLocal = JSON.parse(localStorage.getItem("Company"));
    if (comp.type === COMPLEMENTS_TYPES_ENUM.POW_WEB && dataLocal.PlanType == PLANTYPE_FE) return true;
  }
  
  const getAddonName = (addOn) => {
    if (addOn.type == COMPLEMENTS_TYPES_ENUM.POW_WEB) {
      return DEFAULT_POS_WEB_NAMES[addOn.module] || addOn.name
    }
    return addOn.namePlan
  }

  return (
    <div className="modal-parent modal-complements">
      <div className="container-notification">
        {notificationShow ?
          <SiigoNotificationGrowlAtom
            changed={function noRefCheck() {
            }}
            notification={[
              {
                id: 2,
                text: `<p>${messageNotification.description}</p>`,
                title: messageNotification.title,
                type: 'success'
              }
            ]}
            timeout={6000}
          />
          :
          null
        }

      </div>
      <div className="modal" id="modal">
        <div className="h2">
          <span>Complementos</span>{' '}
          <FontAwesomeIcon
            onClick={() => {
              setshowModal(false)
              setComplementOptions(ModalComplementsStates.INDEX);
            }}
            icon={faTimes}
            className="icon-close closeModal"
          />
        </div>
        <div className="content">
          <div className='line-component-complement'></div>
          {!complementOptions &&
            <Fragment>
              <div className="container-content">
                <p>Gestiona los complementos para esta empresa.</p>
                {getAccess(PATH_COMPONENT, ACCESS.Add_complement_NE) && (
                  !complements || complements.length < allowedComplementsNumber() ?
                    <button
                      type="button"
                      value="Agregar"
                      className="add-button"
                      onClick={() => addComplement()}
                    >Agregar
                    </button>
                    :
                    <button
                      type="button"
                      value="Agregar"
                      className="disabled-button"
                      disabled
                    >Agregar
                    </button>
                )}
              </div>
              {!complements || complements.length === 0 ?
                <div className="container-not-components">
                  <FontAwesomeIcon
                    icon={faPlug}
                  />
                  <span>
                    Aún no has agregado complementos en esta empresa.
                  </span>
                </div>
                :
                complements.map((comp, key) => (
                  <div key={key} className="container-components">
                    <div className="component">
                      <p>{COMPLEMENTS_TYPES[comp.type]}</p>
                      <span>{getAddonName(comp)}</span>
                    </div>
                    <div className="component status">
                      <p>{t("state")}</p>
                      <span>{getState(comp.subState)}</span>
                    </div>
                    <div className="container-buttton-component">
                      {getAccess(PATH_COMPONENT, ACCESS.Edit_complement_NE) &&
                        <div className="button">
                          <div className="delete" onClick={() => {
                            configComplement(comp)
                            setComplementOptions(ModalComplementsStates.EDIT_COMPLEMENT);
                            heightModal();
                          }}>
                            <p>{t("edit")}</p>
                          </div>
                        </div>
                      }
                      {getAccess(PATH_COMPONENT, ACCESS.Remove_complement_NE) &&
                        <div className={`button ${disbleDeleteButton(comp) ? 'disabled' : ''}`}>
                          <div className="delete" onClick={() => removeComplement(comp)}>
                            <p>{t("delete")}</p>
                          </div>
                        </div>
                      }
                    </div>

                  </div>
                ))
              }
            </Fragment>
          }
          {complementOptions === ModalComplementsStates.ADD_COMPLEMENT &&
            <SearchComplements
              setDataComplement={getDataComplement}
              complements={complements}
              compPlanEdit={complementPlanEdit ? complementPlanEdit : ""}
            />
          }
          {complements?.length > 0 && complementOptions === ModalComplementsStates.EDIT_COMPLEMENT &&
            <EditPayrollComplementCard
              editComplementService={editComplementService}
              complements={complements}
              compEdit={complementPlanEdit}
            />
          }
        </div>
      </div>
    </div>

  );
}

export default ModalComplements;
