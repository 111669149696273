const date = new Date();
const locale = 'es-co';

const DataTime = {
    locale: {
        month: date.toLocaleString( locale, { month: 'long' })
    }
};

export default DataTime;
