import * as types from '../actions';

const planReducer = ( state = { isLoading: true }, action= null)  => {

    switch (action.type) {
        case types.GET_PLANS:
            return { ...state, isLoading: true };
        case types.PLANS_RECEIVED:
            return { ...state, isLoading: false, plans: action.data };
        case types.PLANS_REQUEST_FAILED:
            return { ...state, isLoading: false, plans: action.error };
        case types.GET_PLAN:
            return { ...state, isLoading: true };
        case types.PLAN_RECEIVED:
            return { ...state, isLoading: false, plan: action.data };
        case types.PLAN_REQUEST_FAILED:
            return { ...state, isLoading: false, plan: action.error };
        case types.CREATE_PLAN:
            return { ...state, isLoading: true };
        case types.CREATE_PLAN_SUCCESS:
            return { ...state, isLoading: false, plan: action.data };
        case types.CREATE_PLAN_FAILED:
            return { ...state, isLoading: false, plan: action.error };
        case types.UPDATE_PLAN:
            return { ...state, isLoading: true };
        case types.UPDATE_PLAN_SUCCESS:
            return { ...state, isLoading: false, plan: action.data };
        case types.UPDATE_PLAN_FAILED:
            return { ...state, isLoading: false, plan: action.error };
        case types.DELETE_PLAN:
            return { ...state, isLoading: true };
        case types.DELETE_PLAN_SUCCESS:
            return { ...state, isLoading: false, plan: action.data };
        case types.DELETE_PLAN_FAILED:
            return { ...state, isLoading: false, plan: action.error };
        default:
            return state;
    }
};

export default planReducer;
