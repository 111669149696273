import React from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import data from './sample-data';
import DataTime from "../../utils/currentTime";
// TODO HERE CHANGES

const TextField = styled.input`
  height: 32px;
  width: 300px;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  padding: 16px;

  &:hover {
    cursor: pointer;
  }
`;

const Filter = ({ onFilter }) => (
    <TextField
        id="search"
        type="search"
        role="search"
        placeholder="Buscador"
        onChange={e => onFilter(e.target.value)}
    />
);

const columns = [
    {
        name: 'Title',
        selector: 'title',
        sortable: true,
    },
    {
        name: 'Director',
        selector: 'director',
        sortable: true,
    },
    {
        name: 'Genres',
        selector: 'genres',
        sortable: true
    },
    {
        name: 'Year',
        selector: 'year',
        sortable: true,
    }
];

const BasicTable = () => {

    const currentMonth = DataTime.locale.month;
    const [filterText, setFilterText] = React.useState('');
    const filteredItems = data.filter(item => item.title.includes(filterText));

    return (
        <div className="full-card">
            <div className="card-header">
                <div className="card-title">
                    <h4 className="text light-gray">Últimas ventas</h4>
                </div>
                <div className="card-ddl">
                    <div className="input-option">
                        <span></span>
                    </div>
                </div>
                <div className="card-ddl">
                    <div className="field">
                        <input type="text" name="filter-time" defaultValue={currentMonth} disabled/>
                        <i className="arrow down"></i>
                    </div>
                    <div className="input-option">
                        <span></span>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <DataTable
                    noHeader
                    columns={columns}
                    data={filteredItems}
                    subHeader
                    pagination
                    subHeaderComponent={<Filter onFilter={ value => setFilterText(value)} />}
                />
            </div>
        </div>
    );
};

export default BasicTable;
