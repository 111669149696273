import React from 'react';
import './modal.css';
import PropTypes from 'prop-types';
import ModalResponse from '../../layout/modals/modalResponse';
import {LICENCE_TYPE} from '../plandata';

export default class Modal extends React.Component {
  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  send = e => {    
    e.preventDefault();    

    const assignPlantypeMx = ()=> {
      const plan = Number(e.target.planModal.value);
      const foundPlan = Object.values(LICENCE_TYPE).find(subPlan => subPlan.value === plan);
      if (foundPlan) return foundPlan.value;
      return plan;
    }
    
    if (e.target.planModal.value == this.props.plan) {
      ModalResponse({
        title: 'Cambiar Plan',
        text: 'No ha seleccionado un plan diferente al actual',
        res: 404
      });
    } else if (e.target.planModal.value == '-9') {
      ModalResponse({
        title: 'Cambiar Plan',
        text: 'No ha seleccionado un plan',
        res: 404
      });
    } else {
      this.props.changePlan(e, this.props.plan, assignPlantypeMx(), this.props.country);
    }
  };

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div className="modal-parent">
        <div className="modal" id="modal">
          <div className="h2">
            <span>{this.props.title}</span>{' '}
            <i
              onClick={this.onClose}
              className=" siicon icon-close closeModal"
            ></i>
          </div>
          <form onSubmit={this.send}>
            <div className="content">{this.props.children}</div>
            <div className="actions">
              <button className="red btnCancelarModal" onClick={this.onClose}>
                Cancelar
              </button>
              <button type="submit" className="green-fill btnPagarModal ml20">
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};