import * as types from '../index';

export const getComponents = () => {
  return { type: types.GET_COMPONENTS };
};

export const getComponent = msisdn => {
  return { type: types.GET_COMPONENT, msisdn };
};

export const createComponent = comp => {
  return { type: types.CREATE_COMPONENT, comp };
};

export const updateComponent = (comp, msisdn) => {
  return { type: types.UPDATE_COMPONENT, msisdn, comp };
};

export const deleteComponent = msisdn => {
  return { type: types.DELETE_COMPONENT, msisdn };
};
