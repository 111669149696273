
export default function (api) {
  return {
    dataSourceResponse: () => api.get('groupsDatasource'),
    getReportResponse: () => api.get('groupsDatasource/getListDataSource'),
    addDataSourceResponse: (data) => api.post('groupsDatasource', data),
    propertiesResponse: (data) => api.post('dataSource/getProperties', data),
    collectionResponse: (data) => api.post('dataSource/getCollectionNameKey', data),
    saveDataResponse: (data) => api.post('dataSource', data),
    getDetailDataSource: id => api.get('dataSource' + `/${id}`),
    editDataSource: (id, data) => api.put('dataSource' + `/${id}`, data)
  };
}
