// a library to wrap and simplify api calls
import apisauce from 'apisauce';
import MarketHandler from './services/MarketHandler';
import { getCookie } from '../../utils/cookies';

// our "constructor"
const createHandler = () => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    // base URL is read from the "constructor"

    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'content-type': 'application/json'
    },
    // 4 minutes timeout due to monolith latency problems
    timeout: 240000
  });

  const marketHandler = new MarketHandler(api);

  //  return Api
  return {
    setHeader: (key, value) => api.setHeader(key, value),
    removeHeader: key => api.deleteHeader(key),
    // a list of the API functions from step 2
    marketHandler
  };
};

// let's return back our create method as the default.
export default {
    createHandler
};
