import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Box, Paper, Typography } from "@material-ui/core";
import Constants from "../constants";
import CampaignCreate from "./create/index";
import CampaignManagement from "./management/index";
import UrlHelper from "../helpers/url";

const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: "#fff",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function tabProperties(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Campaign() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(history?.location?.search);
  const tabIdValue = queryParams.get(
    Constants.PARAMETER_NAME_FOR_CAMPAIGN_TABS
  );

  let tabToOpen = 0;
  for (const property in Constants.CAMPAIGN_TABS) {
    if (Constants.CAMPAIGN_TABS[property].LABEL === tabIdValue)
      tabToOpen = Constants.CAMPAIGN_TABS[property].VALUE;
  }
  const [currentTabId, setCurrentTabId] = useState(tabToOpen);

  const handleChangeTabs = (_, newValue) => {
    setCurrentTabId(newValue);
    let tabToKeep = Constants.CAMPAIGN_TABS.CREATE.LABEL;
    for (const property in Constants.CAMPAIGN_TABS) {
      if (Constants.CAMPAIGN_TABS[property].VALUE === newValue)
        tabToKeep = Constants.CAMPAIGN_TABS[property].LABEL;
    }

    let queryParamsParsed = UrlHelper.getParsedQueryParams(
      Constants.SEARCH_CAMPAIGN_INPUTS_FORM,
      queryParams
    );
    queryParamsParsed[Constants.PARAMETER_NAME_FOR_CAMPAIGN_TABS] = tabToKeep;
    Object.entries(queryParamsParsed).forEach(([key, value]) => {
      queryParams.set(key, value);
    });
    history.push({
      search: queryParams.toString(),
    });
  };

  return (
    <ThemeProvider theme={Constants.SIIGO_THEME}>
      <Paper square>
        <Tabs
          value={currentTabId}
          onChange={handleChangeTabs}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label={t("shop.campaigns.tab.create")} {...tabProperties(0)} />

          <Tab
            label={t("shop.campaigns.tab.management")}
            {...tabProperties(1)}
          />
        </Tabs>
      </Paper>
      <TabPanel value={currentTabId} index={0} className={classes.background}>
        <CampaignCreate />
      </TabPanel>

      <TabPanel value={currentTabId} index={1} className={classes.background}>
        <CampaignManagement />
      </TabPanel>
    </ThemeProvider>
  );
}

export default Campaign;
