import React, { Component } from 'react';
import PlanList from '../../components/plans'

class PlansContainer extends Component {
    render() {
        return (
            <div>
                <PlanList parentPath={this.props.path} />
            </div>
        )
    }
}

export default PlansContainer;
