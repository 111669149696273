function getProductInformationByCode(products, code) {
  return products.find((product) => product.code === code);
}

function removeProductsWithoutConfigurations(products) {
  return products.filter((product) => product.configurations?.length);
}

function removeInactiveProducts(products) {
  return products.filter((product) => product.state !== "inactive");
}

function removeProductsWithoutPriceOrChildren(products) {
  return products.filter((product) => product.price || product.children?.length);
}

function getChildrenInformation(
  products,
  children,
  excludeProductsWhitoutLicence = false
) {
  let childInfo,
    childrenData = [],
    onlyChildren = [];

  /**
   * Se implementa filtro para evitar mostrar
   * en la lista productos con el mismo código.
   */
  children.forEach(_child => {
    if (!onlyChildren.find(_onlyChild => _onlyChild.code == _child.code))
      onlyChildren.push(_child)
  });
  for (let index = 0; index < onlyChildren.length; index++) {
    childInfo = products.find(
      (product) => product.code === onlyChildren[index].code
    );

    if (!childInfo || (excludeProductsWhitoutLicence && !childInfo.license))
      continue;

    childrenData.push(childInfo);
    childInfo = null;
  }
  return childrenData;
}

/*
 * Función para agrupar los productos hijos dentro de los padres.
 * Adicionalmente un parámetro para no incluir el padre si
 * alguno de sus hijos no tiene licencia.
 */
function groupProducts(products, excludeProductsWhitoutLicence = false) {
  let childrenInfo = [],
    groupedProducts = [];
  for (let index = 0; index < products.length; index++) {
    if (products[index].children?.length) {
      childrenInfo = getChildrenInformation(
        products,
        products[index].children,
        excludeProductsWhitoutLicence
      );
      if (!childrenInfo.length) {
        continue;
      } else {
        groupedProducts.push({
          name: products[index].name,
          code: products[index].code,
          parent: true,
          children: childrenInfo,
        });
      }
    }
  }
  return groupedProducts;
}

export default {
  getProductInformationByCode,
  getChildrenInformation,
  groupProducts,
  removeProductsWithoutConfigurations,
  removeInactiveProducts,
  removeProductsWithoutPriceOrChildren
};
