import * as types from '../actions';

const auroraConfigOriginsReducer = (state = { isLoading: false }, action = null) => {
    switch (action.type) {
        case types.GET_ORIGINS:
            return { ...state, isLoading: true };
        case types.GET_ORIGINS_RECEIVED:
            return { ...state, isLoading: false, configOrigins: action.data };
        case types.GET_ORIGINS_FAILED:
            return { ...state, isLoading: false, errorOrigins: action.error };
        case types.GET_ORIGIN:
            return { ...state, isLoading: true };
        case types.GET_ORIGIN_RECEIVED:
            return { ...state, isLoadingGet: false, configOrigin: action.data };
        case types.GET_ORIGIN_FAILED:
            return { ...state, isLoadingGet: false, errorOrigin: action.error };
        case types.CREATE_ORIGIN:
            return { ...state, isLoading: true };
        case types.CREATE_ORIGIN_SUCCESS:
            return { ...state, isLoadingCreate: false, createOrigin: action.data };
        case types.CREATE_ORIGIN_FAILED:
            return { ...state, isLoadingCreate: false, errorCreate: action.error };
        case types.UPDATE_ORIGIN:
            return { ...state, isLoadingUpdate: true };
        case types.UPDATE_ORIGIN_SUCCESS:
            return { ...state, isLoadingUpdate: false, updateOrigin: action.data };
        case types.UPDATE_ORIGIN_FAILED:
            return { ...state, isLoadingUpdate: false, errorUpdate: action.error };
        default:
            return state;
    }
}

export default auroraConfigOriginsReducer;