let nuevoSiiger = {
    name: "",
    picture: "",
    contact: {
      email: "",
      phone: '',
      direction: ''
    },
    job: 'Siigo SAS',
    division: 'siigo',
    enabled: true,
    isHost: false,
    host: {
      name: "",
      description: "",
      enabled: true,
      country: 0,
      state: 0,
      type: 1,
      pets: true,
      familyHost: 1,
      tentAllow: false,
      garage: false,    
      place: [
        {
          id: uuidv4(),
          peoplecount: 1,
          bedtype: 1,
          placetype: 1,
          enableFrom: "2023-06-01",
          enableUntil: "2023-12-31"
        }
      ],                                
    }       
}

export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function CreateSiiger()
{
    return nuevoSiiger;
}

export function AssingSiiger(s){
  let _nuevo = nuevoSiiger;
  _nuevo.name = s.name;
  _nuevo.job = s.job;
  _nuevo.division = s.division;
  _nuevo.enabled = s.enabled;
  _nuevo.isHost = s.isHost;

  _nuevo.contact = s.contact;
  _nuevo.host = s.host;
  
  _nuevo.booking = (s.booking == undefined) ? [] : s.booking;  
  
  return _nuevo;

}

