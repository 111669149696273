import React, { Component } from 'react';
import Menu from '../../components/layout/menu';
import TopBar from '../../components/layout/topBar';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title = 'Siigo BackOffice';
  }

  render() {
    return (
      <div className="section">
        <Menu route={this.props.route} validMenu={this.props.validMenu} />
        <TopBar path={this.props.route.path} />
      </div>
    );
  }
}

export default Layout;
