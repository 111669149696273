import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProducts } from "../../../../../actions/shop/campaign";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  Checkbox,
  Input,
  ListSubheader,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import Loader from "../../../../tools/loader";
import Constants from "../../../constants";
import ProductHelper from "../../../helpers/products";
import ErrorHelper from "../../../helpers/error";
import CampaignHelper from "../../../helpers/campaign";
import UpgradeDetails from "./upgradeDetails";
import ComboDetails from "./comboDetails";
import DiscountCodeDetails from "./discountCodeDetails";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    minWidth: 150,
    maxWidth: 300,
  },
  background: {
    backgroundColor: "#fff",
  },
  headerSection: {
    marginBottom: 30,
  },
}));

const FORM_FIELDS = {
  DESTINATION: "destination",
  TYPE: "type",
  CODE: "code",
  NAME: "name",
  START_DATE: "startDate",
  END_DATE: "endDate",
  PLACE: "place",
};

function mapStateToProps(state) {
  return {
    error: state.shopReducer.error,
    products: state.shopReducer.products,
    isLoading: state.shopReducer.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return { getProductsAction: bindActionCreators(getProducts, dispatch) };
}

function CampaignForm(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { campaign, getProductsAction, products, error } = props;

  const initialCampaignInformation = {
    type: campaign?.type || "",
    destination: campaign?.destination || "",
    place: campaign?.place || "",
    code: campaign?.code || "",
    name: campaign?.name || "",
    startDate: campaign?.date?.start
      ? moment(campaign?.date?.start).format("YYYY-MM-DDTHH:mm")
      : "",
    endDate: campaign?.date?.end
      ? moment(campaign?.date?.end).format("YYYY-MM-DDTHH:mm")
      : "",
    configurations: campaign?.configurations?.length
      ? CampaignHelper.replaceInitialConfigurationData(campaign?.configurations)
      : [],
    includeProductsSelected: campaign?.rules?.match?.include?.products || [],
    excludeProductsSelected: campaign?.rules?.match?.exclude?.products || [],
  };

  const [campaignInformation, setCampaignInformation] = useState(
    initialCampaignInformation
  );
  const [campaignType, setCampaignType] = useState(
    initialCampaignInformation.type
  );
  const [includeProductsSelected, setIncludeProductsSelected] = useState(
    initialCampaignInformation.includeProductsSelected
  );
  const [excludeProductsSelected, setExcludeProductsSelected] = useState(
    initialCampaignInformation.excludeProductsSelected
  );
  const [includeProducts, setIncludeProducts] = useState([]);
  const [excludeProducts, setExcludeProducts] = useState([]);
  const [includePlace, setIncludePlace] = useState(false);
  const [showSelectClients, setSelectClients] = useState(false);

  useEffect(() => {
    if (error) return;
    if (products && Array.isArray(products)) {
      const productsWithoutConfigurations =
        ProductHelper.removeProductsWithoutConfigurations(products);
      const includeGroupedProducts = ProductHelper.groupProducts(
        productsWithoutConfigurations,
        true
      );
      setIncludeProducts(includeGroupedProducts);
      setExcludeProducts(ProductHelper.groupProducts(products, true));
    } else getProductsAction({ country: "CO" });
  }, [getProductsAction, products, error]);

  useEffect(() => {
    if (
      validateCampaignInformation(campaignInformation) &&
      campaignInformation?.type !== Constants.CAMPAIGN_TYPES.DISCOUNT_CODE.VALUE
    )
      setIncludePlace(
        Object.values(Constants.CAMPAIGN_DESTINATIONS).find(
          (destination) =>
            destination.VALUE === campaignInformation?.destination
        )?.PLACE
      );
    else setIncludePlace(false);

    setSelectClients(
      Object.values(Constants.CAMPAIGN_DESTINATIONS).find(
        (destination) => destination.VALUE === campaignInformation?.destination
      )?.CLIENTS
    );
  }, [campaignInformation?.destination, campaignInformation?.type]);

  const validateCampaignInformation = (campaignInformation) => {
    return (
      campaignInformation?.type !== "" &&
      campaignInformation?.destination !== ""
    );
  };

  const validateCodeLength = (type, code) => {
    if (
      code?.length > Constants.MAX_INPUT_LENGTHS.TEN &&
      type === Constants.CAMPAIGN_TYPES.DISCOUNT_CODE.VALUE
    ) {
      const newCode = code?.slice(0, Constants.MAX_INPUT_LENGTHS.TEN);
      handleChangeInputsValue(FORM_FIELDS.CODE, newCode);
    }
  };

  const handleChangeInputsValue = (property, value) => {
    setCampaignInformation((previousState) => {
      let newState = previousState;
      newState[property] = value;
      return newState;
    });
    forceUpdate();
  };

  const handleChangeIncludeProducts = (productCode, setFunction) => {
    if (!productCode) return;

    setFunction((previousState) => {
      let newState = [];
      const productIndex = previousState.indexOf(productCode);
      if (productIndex > -1) {
        newState = previousState.filter(
          (value) => value !== previousState[productIndex]
        );
      } else {
        newState = [...previousState, productCode];
      }
      return newState;
    });
  };

  if (props.error) return ErrorHelper.errorDialog(t, props.error?.message);

  return !props.isLoading && products?.length > 0 ? (
    <ThemeProvider theme={Constants.SIIGO_THEME}>
      <div className={classes.headerSection}>
        <h5>{t("shop.campaigns.create.form.title")}</h5>
        <form noValidate autoComplete="off" className={classes.root}>
          <TextField
            select
            label={t("shop.campaigns.create.form.destination")}
            value={campaignInformation.destination}
            onChange={(event) => {
              handleChangeInputsValue(
                FORM_FIELDS.DESTINATION,
                event.target.value
              );
            }}
            className={classes.formControl}
          >
            {Object.values(Constants.CAMPAIGN_DESTINATIONS)?.map(
              (campaignDestination) => (
                <MenuItem
                  key={campaignDestination?.VALUE}
                  value={campaignDestination?.VALUE}
                >
                  {campaignDestination?.LABEL}
                </MenuItem>
              )
            )}
          </TextField>
          <TextField
            select
            label={t("shop.campaigns.create.form.type")}
            value={campaignInformation.type}
            onChange={(event) => {
              handleChangeInputsValue(FORM_FIELDS.TYPE, event.target.value);
              setCampaignType(event.target.value);
              validateCodeLength(event.target.value, campaignInformation?.code);
            }}
            className={classes.formControl}
          >
            {Object.values(Constants.CAMPAIGN_TYPES)?.map((campaignType) => (
              <MenuItem key={campaignType?.VALUE} value={campaignType?.VALUE}>
                {campaignType?.LABEL}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="campaign-code"
            label={t("shop.campaigns.create.form.code")}
            variant="standard"
            value={campaignInformation.code}
            onChange={(event) => {
              handleChangeInputsValue(FORM_FIELDS.CODE, event.target.value);
            }}
            inputProps={{
              maxLength:
                campaignInformation?.type ===
                Constants.CAMPAIGN_TYPES.DISCOUNT_CODE.VALUE
                  ? Constants.MAX_INPUT_LENGTHS.TEN
                  : Constants.MAX_INPUT_LENGTHS.TWENTY,
            }}
          />
          <TextField
            id="campaign-name"
            label={t("shop.campaigns.create.form.name")}
            variant="standard"
            value={campaignInformation.name}
            onChange={(event) => {
              handleChangeInputsValue(FORM_FIELDS.NAME, event.target.value);
            }}
            inputProps={{ maxLength: Constants.MAX_INPUT_LENGTHS.FIFTY }}
          />
          <TextField
            id="campaign-start-date"
            label={t("shop.campaigns.create.form.date.start")}
            type="datetime-local"
            value={campaignInformation.startDate}
            onChange={(event) => {
              handleChangeInputsValue(
                FORM_FIELDS.START_DATE,
                event.target.value
              );
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="campaign-end-date"
            label={t("shop.campaigns.create.form.date.end")}
            type="datetime-local"
            value={campaignInformation.endDate}
            onChange={(event) => {
              handleChangeInputsValue(FORM_FIELDS.END_DATE, event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: moment().format("YYYY-MM-DDTHH:mm"),
            }}
          />
          {includePlace && (
            <FormControl className={classes.formControl}>
              <InputLabel id="campaign-place-label">
                {t("shop.campaigns.create.form.place.title")}
              </InputLabel>
              <Select
                labelId="campaign-place-label"
                id="campaign-place-select"
                value={campaignInformation.place}
                onChange={(event) => {
                  handleChangeInputsValue(
                    FORM_FIELDS.PLACE,
                    event.target.value
                  );
                }}
                input={<Input />}
              >
                {Object.values(Constants.CAMPAIGN_PLACES)?.map(
                  (campaignPlace) => (
                    <MenuItem
                      key={campaignPlace?.VALUE}
                      value={campaignPlace?.VALUE}
                    >
                      {campaignPlace?.LABEL}
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText>
                {t("shop.campaigns.create.form.place.placeHolder")}
              </FormHelperText>
            </FormControl>
          )}
          {showSelectClients && (
            <>
              <FormControl className={classes.formControl}>
                <InputLabel id="campaign-include-products-label">
                  {t("shop.campaigns.create.form.customers.toInclude")}
                </InputLabel>
                <Select
                  labelId="campaign-include-products-label"
                  id="campaign-include-products-select"
                  multiple
                  value={includeProductsSelected}
                  input={<Input />}
                  renderValue={(codes) => {
                    let includeProductsSelected = [];
                    codes.forEach((code) => {
                      const productInformation =
                        ProductHelper.getProductInformationByCode(
                          products,
                          code
                        );
                      if (productInformation)
                        includeProductsSelected.push(productInformation.name);
                    });
                    return includeProductsSelected.join(", ");
                  }}
                >
                  {includeProducts?.map((product) => (
                    <div>
                      <ListSubheader disableSticky={true}>
                        {product.name}
                      </ListSubheader>
                      {product.children.map((children) => (
                        <MenuItem
                          key={children.code}
                          data-code={children.code}
                          onClick={() =>
                            handleChangeIncludeProducts(
                              children.code,
                              setIncludeProductsSelected
                            )
                          }
                        >
                          <Checkbox
                            checked={includeProductsSelected.includes(
                              children.code
                            )}
                          />
                          <ListItemText primary={children.name} />
                        </MenuItem>
                      ))}
                    </div>
                  ))}
                </Select>
                <FormHelperText>
                  {t(
                    "shop.campaigns.create.form.customers.whoCanSeeTheCampaign"
                  )}
                </FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="campaign-exclude-products-label">
                  {t("shop.campaigns.create.form.customers.toExclude")}
                </InputLabel>
                <Select
                  labelId="campaign-exclude-products-label"
                  id="campaign-exclude-products-select"
                  multiple
                  value={excludeProductsSelected}
                  input={<Input />}
                  renderValue={(codes) => {
                    let excludeProductsSelected = [];
                    codes.forEach((code) => {
                      const productInformation =
                        ProductHelper.getProductInformationByCode(
                          products,
                          code
                        );
                      if (productInformation)
                        excludeProductsSelected.push(productInformation.name);
                    });
                    return excludeProductsSelected.join(", ");
                  }}
                >
                  {excludeProducts?.map((product) => (
                    <div>
                      <ListSubheader disableSticky={true}>
                        {product.name}
                      </ListSubheader>
                      {product.children.map((children) => (
                        <MenuItem
                          key={children.code}
                          data-code={children.code}
                          onClick={() =>
                            handleChangeIncludeProducts(
                              children.code,
                              setExcludeProductsSelected
                            )
                          }
                        >
                          <Checkbox
                            checked={excludeProductsSelected.includes(
                              children.code
                            )}
                          />
                          <ListItemText primary={children.name} />
                        </MenuItem>
                      ))}
                    </div>
                  ))}
                </Select>
                <FormHelperText>
                  {t(
                    "shop.campaigns.create.form.customers.whoCanNotSeeTheCampaign"
                  )}
                </FormHelperText>
              </FormControl>
            </>
          )}
        </form>
      </div>
      <div className={classes.detailSection}>
        {campaignType === Constants.CAMPAIGN_TYPES.UPGRADE.VALUE && (
          <>
            <h5>{t("shop.campaigns.create.form.upgrade.title")}</h5>
            <Typography variant="subtitle2" gutterBottom>
              {[Constants.CAMPAIGN_DESTINATIONS.SIIGO_COM.VALUE].includes(
                campaignInformation.destination
              )
                ? t(
                    `shop.campaigns.create.form.upgrade.descriptions.${campaignInformation.destination}`
                  )
                : t("shop.campaigns.create.form.upgrade.descriptions.default")}
            </Typography>
            <Divider />
            <UpgradeDetails
              products={products}
              campaignInformation={{
                ...campaignInformation,
                includeProductsSelected: includeProductsSelected,
                excludeProductsSelected: excludeProductsSelected,
              }}
              setCampaignInformation={setCampaignInformation}
            />
          </>
        )}
        {campaignType === Constants.CAMPAIGN_TYPES.COMBO.VALUE && (
          <>
            <h5>{t("shop.campaigns.create.form.combo.title")}</h5>
            <Typography variant="subtitle2" gutterBottom>
              {[Constants.CAMPAIGN_DESTINATIONS.SIIGO_COM.VALUE].includes(
                campaignInformation.destination
              )
                ? t(
                    `shop.campaigns.create.form.combo.descriptions.${campaignInformation.destination}`
                  )
                : t("shop.campaigns.create.form.combo.descriptions.default")}
            </Typography>
            <Divider />
            <ComboDetails
              products={products}
              campaignInformation={{
                ...campaignInformation,
                includeProductsSelected: includeProductsSelected,
                excludeProductsSelected: excludeProductsSelected,
              }}
              setCampaignInformation={setCampaignInformation}
            />
          </>
        )}
        {campaignType === Constants.CAMPAIGN_TYPES.DISCOUNT_CODE.VALUE && (
          <>
            <h5>{t("shop.campaigns.create.form.discountCode.title")}</h5>
            <Typography variant="subtitle2" gutterBottom>
              {t(
                "shop.campaigns.create.form.discountCode.descriptions.default"
              )}
            </Typography>
            <Divider />
            <DiscountCodeDetails
              products={products}
              campaignInformation={{
                ...campaignInformation,
                includeProductsSelected: includeProductsSelected,
                excludeProductsSelected: excludeProductsSelected,
              }}
              setCampaignInformation={setCampaignInformation}
            />
          </>
        )}
      </div>
    </ThemeProvider>
  ) : (
    <Loader />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignForm);
