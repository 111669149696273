import React, { Component } from 'react';
import { SiigoAvatarAtom } from '@siigo/siigo-avatar-atom-react';
import { deleteCookie, getCookie } from '../../../../utils/cookies'; 
import config from '../../../../config';

class Anfitrionperfil extends Component {
    constructor(props) {
	    super(props);
        let _avatar = '';
        let _nombre = getCookie('name');
        let _ini = 'NN';

        const myArray = _nombre.split(" ");
        _ini = myArray[0][0] + myArray[myArray.length - 1][0]

        if (getCookie('avatar') != 'undefined'){
            _avatar = config.url + 'auth/avatar/' + getCookie('avatar')
        }

        this.state = {
            avatar: _avatar,
            nombre: _nombre,
            iniciales: _ini       
        }        
    }  
    
    render(){
        return(
            <>
                <div className='col-1'>

                {(this.state.avatar == '')  
                    ? <div><div className='avatar-circle avatar-circle-border'>{ this.state.iniciales } </div></div>  
                    :   <SiigoAvatarAtom
                            alt="imagen de ..."
                            image={this.state.avatar}
                            shape="circle"
                            size="s"
                        />
                } 
                </div>
                <div className='col width-100'>
                    <div className='alojamiento-duenio-descripcion'>
                        <h4><b>{ this.state.nombre }</b></h4>
                        {/* <label>Product VP</label>
                        <label>Investigación y Desarrollo</label> */}
                        <label>Siigo SAS</label>
                        <label>Siigo</label>
                        <label><b>5 (0)  </b><i className="fa-solid fa-star"></i></label>
                    </div>
                </div>
            </>
        )
    }
}

export default Anfitrionperfil