import React, { Component } from 'react';
import { getState, statesClass, humanStates } from './../../accountant/states';
import { SubscriptionStates } from './../../auroraSubscription/states';
import { PaymentStates } from './../../recurrentPaymentReport/states';
import { ProviderStates } from './../../auroraConfigProvider/states';
import { OriginsStates } from './../../auroraConfigOrigins/states';
import ButtonActions from '../components/buttonActions'
import { PlanType } from '../../market/plandata';

let state = {
	1: 'Activo',
	0: 'Inactivo',
};
let status = {
	true: 'Sincronizado',
	false: 'No sincronizado',
	undefined: 'No sincronizado',
	'': 'No sincronizado',
};

let statusSendEmail = {
	true: 'Enviada',
	false: 'No enviada',
	'': 'No enviada',
};

class BodyTable extends Component {
	render() {
		const {
			data,
			actionButtons,
			btnActions,
			tabsData,
			columns,
			dataPack,
			tooltip,
			pageSize
		} = this.props;
		//console.log('dataPack::::: ', dataPack);

		let data2 = [];
		if(data.length > pageSize){
		    data2 = data.slice(0, pageSize);
		}else{
			data2 = data;
		}

		return (
			<tbody>
				{data2.map((item, key) => {
					let deletePkg = item.deletePkg;

					return item[tabsData.filterCol] ? (

						tabsData.data.map((tb) => {
							const filterName = item[tabsData.filterCol] === tb.filterName
							return item[tabsData.filterCol] === tb.filterName ? (
								<tr key={key} className={`${tb.color} border-left`}>
									{columns.map((col, k) => {
										return (
											<td className="column" key={k}>
												{col.selector === 'status' &&
													typeof item.status === 'boolean' ? (
													<span
														style={{
															color: item.status ? '#41af4b' : '#f7274e',
														}}
													>
														{item.status ? 'Activo' : 'Inactivo'}
													</span>
												) : (
													<div>
														{col.selector === 'state' ? (
															<span>{state[item.state]}</span>
														) : (
															<div>
																{'SELECTOR:: ' + col.selector}
																{col.selector === tabsData.filterCol ? (
																	<span className={`${tb.color} text`}>
																		{tb.name}
																	</span>
																) : (
																	<span className="text item">
																		{item[col.selector]}
																	</span>
																)}
															</div>
														)}
													</div>
												)}
											</td>
										);
									})}
									{actionButtons ? (
										<td className="column">
											<div className="actions">
												<ButtonActions actionButtons={actionButtons} item={item} filterName={filterName} />
											</div>
										</td>
									) : null}
								</tr>
							) : null;
						})
					) : (
						<tr key={key} className={'border-left'}>
							{btnActions.ckSend ? (
								<td>
									<input
										className="ckTable"
										type="checkbox"
										name="chItem"
										onClick={(e) => btnActions.ckSend(e, item)}
									/>
								</td>
							) : null}
							{btnActions.chMassiveActions &&
								btnActions.chMassiveActions !== false &&
								btnActions.chMassiveActions !== null ? (
								<td>
									<input
										className="chResendEmail"
										type="checkbox"
										name="chResendEmail"
										onClick={(e) => btnActions.chMassiveActions(e, item)}
									/>
								</td>
							) : null}
							{columns.map((col, k) => {
								return (
									<td className="column" key={k}>
										{typeof item[col.selector] === 'object' &&
											item[col.selector] !== null ? (
											<div>
												{col.selector == 'groupUser' ? (
													<span>
														{item[col.selector].map((val, id) => {
															return item[col.selector].length - 1 == id
																? val.name
																: val.name + ', ';
														})}
													</span>
												) : (
													<span className="text item">
														{item[col.selector].hasOwnProperty('name')
															? item[col.selector][0].name
															: col.selector == 'date'
																? new Date(item[col.selector][0]).toLocaleString()
																: null}
													</span>
												)}
											</div>
										) : (
											<div>
												{col.selector.includes('.') ? (
													<span>
														{item['type'] == 'comment' &&
															item[col.selector2.split('.')[0]] != null ? (
															<span>
																{
																	item[col.selector2.split('.')[0]][
																	col.selector2.split('.')[1]
																	]
																}
															</span>
														) : item[col.selector.split('.')[0]] != null ?
															(col.hasOwnProperty("paymentState") ? (<PaymentStates selector={col.selector} paymentState={col.paymentState} valueSelector={item[col.selector.split('.')[0]][col.selector.split('.')[1]]} />) :
																(col.selector.split('.')[1] === "createdSubscription" ?
																	(<span >
																		{new Date(item[col.selector.split('.')[0]][col.selector.split('.')[1]]).toLocaleDateString()}
																	</span>) :
																	(
																		<span>
																			{
																				item[col.selector.split('.')[0]][col.selector.split('.')[1]]
																			}
																		</span>
																	))) : null}
													</span>
												) : (
													<span className="text item">														
														{col.selector == 'PlanType' ? (
															PlanType[item[col.selector]]
														) : (
															<span>
																{col.selector == 'Active' ? (
																	item.Active
																) : (
																	<span>
																		{col.hasOwnProperty('selectorState') ? (
																			<span
																				className={
																					statesClass[getState(item.state)]
																				}
																			>
																				{humanStates[getState(item.state)]}
																			</span>
																		) :
																			(

																				col.selector == 'reason' ? (
																					<div>
																						{item[col.selector]}
																						<div className="tooltip">
																							<span className="tooltiptext">{tooltip[item[col.selector]]}</span>
																							<div className="icon-tooltip">
																								<label className="siicon icon-question">
																								</label>
																							</div>
																						</div>

																					</div>
																				) : (col.hasOwnProperty("subState") ? (<SubscriptionStates selector={col.selector} subState={col.subState} valueSelector={item[col.selector]} />) :

																					(col.hasOwnProperty("providerState") ? (<ProviderStates selector={col.selector} providerState={col.providerState} valueSelector={item[col.selector]} />) :

																						(col.hasOwnProperty("originState") ? (<OriginsStates selector={col.selector} originState={col.originState} valueSelector={item[col.selector]} />) :

																							<span>
																								{col.selector === 'state' ? (
																									state[getState(item.state)]
																								) : (
																									<span>
																										{col.money
																											? new Intl.NumberFormat(
																												'de-DE'
																											).format(item[col.selector])
																											: col.selector == 'date' ||
																												col.selector == 'PurchaseDate'
																												? formatDateTime(
																													item[col.selector]
																												)
																												: col.selector == 'async'
																													? status[item[col.selector]]
																													: col.selector ==
																														'statusSendEmail'
																														? statusSendEmail[
																														item[col.selector]
																														]
																														: col.selector == 'PackageName' &&
																															item.hasOwnProperty('PackageID')
																															? (
																																dataPack.filter(element => element.Code == item['PackageID']).length > 0
																																? dataPack.filter(element => element.Code == item['PackageID'])[0]['Name']
																																: (
																																	dataPack.filter(element => element.Quantity == item['DocumentsQuantity']).length > 0
																																	? dataPack.filter(element => element.Quantity == item['DocumentsQuantity'])[0]['Name']
																																	: 'Indefinido'
																																)
																															)
																															: item[col.selector]}
																									</span>
																								)}
																							</span>
																						)))
																			)
																		}
																	</span>
																)}
															</span>
														)}
													</span>
												)}
											</div>
										)}
									</td>
								);
							})}
							{actionButtons && (
								<td className="column">
									<div className="actions">
										<ButtonActions actionButtons={actionButtons} item={item} filterName={false} deletePkg={deletePkg} btnActions={btnActions} />
									</div>
								</td>
							)}
						</tr>
					);
				})}
			</tbody>
		);
	}
}

function formatDateTime(date) {
	return new Date(date).toLocaleString('es', { timeZone: 'GMT', day: '2-digit', month: '2-digit', year: 'numeric' });
}

export default BodyTable;
