export const formatUserResponse = (users) => {
    return users.map((user) => {
        const { id, userLogin,companies } = user;
        const { basicInformation, systemParameters, isActive, tenantId } = companies[0];
        const { firstName, fullName, identification, lastName, phone } = basicInformation;
        return {
            id,
            userCode: userLogin,
            identification,
            firstName,
            lastName,
            fullName,
            email: userLogin,
            cellPhone: phone, 
            isAdmin: (systemParameters?.isAdministrator) ? 1 : 0,
            status: isActive,
            role: systemParameters?.userLicenseType,
            tenantId,
        };
    });
}