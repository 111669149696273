import React, { Component } from 'react';
import CreateDataSource from '../../components/datasource/datasource';
import Layout from '../layout';

class CreateDataSourceContainer extends Component {
  
	render() {
		
		let groupId;
		let groupName;
		try {
			groupId = this.props.location.state.groupId;
			groupName = this.props.location.state.groupName;
		} catch (error) {
			groupId = null;
			groupName = null;
		}

		return (	
			<div>
				<Layout route={'/datasource/create-form'} />
				<div>
					<section className={'container'}>
              			<div className="section">
							<CreateDataSource groupId={groupId} groupName={groupName}/>
						</div>
					</section>
				</div>
			</div>
		);
	}
}

export default CreateDataSourceContainer;
