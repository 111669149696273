export function getTemplate(name, link, pin, suscriptionId, productId) {

    let template = `    
    <!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="https://www.w3.org/1999/xhtml" style="width:600px; margin: 0 auto;">
    <head>
        <title>Siigo</title>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0 " />
        <link href="https://fonts.googleapis.com/css?family=Raleway&display=swap" rel="stylesheet" />
        <style>            
            .em_defaultlink a {
                color: inherit !important;
                text-decoration: none !important;
            }

            .em_full_wrap {
                margin: 25px auto;
                font-family: 'Raleway', sans-serif;
            }

            .pContainer p {
                width: 450px;
            }

            @media only screen and (min-width:481px) and (max-width:601px) {
                .em_main_table {
                    width: 100% !important;
                }
            }

            @media screen and (max-width: 480px) {
                .em_main_table {
                    width: 100% !important;
                }

                u+.em_body .em_full_wrap {
                    width: 100% !important;
                    width: 100vw !important;
                }
            }
        </style>
    </head>
    <body class="em_body" style="margin:0px; padding:0px;">
        <table class="em_full_wrap" valign="top" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
            <tbody>
                <tr>
                    <td valign="top" align="center">
                        <table class="em_main_table" style="width:600px;" width="600" cellspacing="0" cellpadding="0"
                            border="0" align="center">
                            <tbody>
                                <tr align="center">
                                    <td>
                                        <img src="https://www.siigo.com/wp-content/uploads/2020/01/thumbnail_image001.png"
                                            alt="Siigo SAS" align="center" />
                                    </td>
                                </tr>
                                <tr align="center">
                                    <td>
                                        <h2 style="color:#0af;font-size:30px;">Señor(a) <span>${name}</span></h2>
                                    </td>
                                </tr>
                                <tr align="center">
                                    <td style="color:#747474;font-size:16px" class="pContainer">
                                        
                                        <p>De acuerdo a su solicitud para la actualización de su tarjeta de crédito </p>
                                        <p>en la suscripción con ID: <b>${suscriptionId}</b> </p>
                                        <p>de la cotización: <b>N° ${productId}</b> </p>
                                        <p>se ha generado el siguiente Enlace: </p>
                                          
                                    </td>
                                </tr> 
                                <tr align="center">
                                    <td>
                                        <a style="text-decoration: none !important;
                                            font-size:18px;
                                            font-weight:bold;
                                            display: inline-block;
                                            width: 115px;
                                            height: 25px;
                                            background: #7ac23a;
                                            padding: 10px;
                                            text-align: center;
                                            border-radius: 5px;
                                            color: white;
                                            line-height: 25px" class="button" href="${link}" target="_blank">
                                            Abrir Enlace
                                        </a>
                                    </td>
                                </tr>  
                                <tr align="center">
                                    <td style="color:#747474;font-size:16px" class="pContainer">
                                        <p>con PIN de acceso: <b>${pin}</b> </p>
                                        <p><b>Recuerda que el link tiene una duración de 24 horas</b></p>
                                    </td>
                                </tr>                  
                                
                                <tr align="center">
                                    <td style="color:#0af;font-size:18px;font-weight:bold;">
                                        <p>Te esperamos.</br>¡Un abrazo!</p>
                                        <p><img src='https://siigostatics.azureedge.net/images/quotesSiigo/SIIGO-CONTIFICO.png' width='200' /></p>
                                    </td>
                                </tr>

                                <br/>
                                <br/>
                                <br/>

                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </body>
    </html>`;

    return template;
}