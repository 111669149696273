export const countryOptions = [
    { "id": 1, "label": "Colombia", "value": 1 },
    // { "id": 2, "label": "Ecuador",  "value": 2 },
    { "id": 3, "label": "México",   "value": 3 },
    // { "id": 4, "label": "Uruguay",  "value": 4 },
    // { "id": 5, "label": "Chile",    "value": 5 }
];

export const statesOptions = [
    //Colombia
    { "id": 1, "label": "Bogotá", "value": 1, "country": 1 },
    // { "id": 2, "label": "Medellín",  "value": 2, "country": 1 },

    //Ecuador
    // { "id": 3, "label": "Guayaquil", "value": 3, "country": 2 },
    // { "id": 4, "label": "Quito", "value": 4, "country": 2 },

    //Mexico
    // { "id": 5, "label": "Merida",   "value": 5, "country": 3 },
    { "id": 6, "label": "CDMX",  "value": 6, "country": 3 },

    //Uruguay
    // { "id": 7, "label": "Montevideo",   "value": 7, "country": 4 },

    //Chile 
    // { "id": 8, "label": "Santiago de Chile",   "value": 5 }
];

export const tipoAlojamientoOptions = [
    {   "id": 1, "label": "Apartamento", "value": 1 },
    {   "id": 2, "label": "Casa",        "value": 2 }
];

export const tipoAcomodacionOptions = [
    { "id": 1, "label": "Habitación individual", "value": 1 },
    { "id": 2, "label": "Habitación compartida", "value": 2 }
]

export const tipoDeCamaOptions = [
    { "id": 1, "label": "Cama simple", "value": 1 },
    { "id": 2, "label": "Cama doble", "value": 2 },
    { "id": 3, "label": "Cama inflable simple", "value": 3 },
    { "id": 4, "label": "Cama inflable doble", "value": 4 },
    { "id": 5, "label": "Sleeping bag", "value": 5 }
]

export const conQuienCompartoAlojamiento = [
    { "id": 1, "label": "Solamente yo", "value": 1 },
    { "id": 2, "label": "Vivo con mi familia", "value": 2 },
]