import React from "react";
import { SiigoInputAtom } from "@siigo/siigo-input-atom-react";
import { useTranslation } from "react-i18next";
import "./cashiers.scss";

export const Cashiers = ({ additionalCashiersValue, baseCashiersValue, onValuesChange }) => {
    const { t } = useTranslation();

    return (
        <div className="inputs-cashiers">
            <div className="cashier">
                <p>{t("additionalCashiers")}</p>
                <SiigoInputAtom
                    type='number'
                    name='additionalCashiers'
                    value={additionalCashiersValue}
                    required
                    minlength={1}
                    maxlength={3}
                    pattern="^[0-9]{1,3}$"
                    onInput={onValuesChange}
                />
            </div>
            <div className="cashier">
                <p>{t("baseCashiers")}</p>
                <SiigoInputAtom
                    type='number'
                    name='baseCashiers'
                    value={baseCashiersValue}
                    readonly
                />
            </div>
        </div>
    )
}