import React, { Component } from 'react';

import './siigerTag.scss'

class SiigerTag extends Component {

    constructor(props) {
        super(props);
    }

    render(){
        return(  
            <>
                {{
                    'success' : <div className='siiger-tag siiger-tag-success'><label className='siiger-tag-label'>{ this.props.text }</label></div>,
                    'waiting' : <div className='siiger-tag siiger-tag-waiting'><label className='siiger-tag-label'>{ this.props.text }</label></div>,
                    'refused' : <div className='siiger-tag siiger-tag-refused'><label className='siiger-tag-label'>{ this.props.text }</label></div>,
                    'neutral' : <div className='siiger-tag siiger-tag-neutral'><label className='siiger-tag-label'>{ this.props.text }</label></div>,
                    'finish'  : <div className='siiger-tag siiger-tag-finish'><label className='siiger-tag-label'>{ this.props.text }</label></div>,
                    'canceled': <div className='siiger-tag siiger-tag-canceled'><label className='siiger-tag-label'>{ this.props.text }</label></div>,
                    default   : <div className='siiger-tag siiger-tag-refused'><label className='siiger-tag-label'>{ this.props.text }</label></div>,
                }[this.props.type]}
            </>
        )
    }    
}

export default SiigerTag;