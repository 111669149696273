import React, { Component } from 'react';
import GroupCreate from '../../components/groups/create';

class GroupCreateContainer extends Component {
  render() {
    return (
      <div>
        <GroupCreate />
      </div>
    );
  }
}

export default GroupCreateContainer;
