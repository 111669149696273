let config={
    marketModal:{
        citysOptions:
            [
                {option:"Ciudad de México",value:"Ciudad de México"}
            ]
    },
    company:{
        btnComplements:{
            visible:false
        },
        btnPlanConverter:{
            visible:true
        },
        usersAcces:{
            visible:true
        },
        pos:{
            visible:false
        },
        payrollEmployees:{
            visible:false
        },
        factBase:{
            visible:false
        },
        factAditional:{
            visible:false
        },
        cardDocNE:{
            visible:false
        },
        cardDocFE:{
            visible:false
        }
    }
}

export default config;