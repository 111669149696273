// a library to wrap and simplify api calls
import apisauce from 'apisauce';
//import Auth from './services/Auth';
import Leads from './services/Leads';
import config from '../../config.js';
//import { getCookie } from '../../utils/cookies';

// our "constructor"
const create = (baseURL = config.urlHubspot) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'content-type': 'application/json'
    },
    // 30 second timeout...
    timeout: 30000
  });

  const leads = new Leads(api);
  //  return Api
  return {
    setHeader: (key, value) => api.setHeader(key, value),
    removeHeader: key => api.deleteHeader(key),
    // a list of the API functions from step 2
    leads
  };
};

// let's return back our create method as the default.
export default {
  create
};
