import React, { Component } from 'react';
import LogList from '../../components/logs';


class LogsContainer extends Component {
  render() {
    return (
      <div>
      
        <section >
          <div className="section">
            <LogList parentPath={this.props.path} />
          </div>
        </section>



      </div>
    );
  }
}

export default LogsContainer;
