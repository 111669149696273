import React, { useContext, useEffect, useState } from "react";
import ModalResponse from "../layout/modals/modalResponse";
import { ALLOWEB_ACCOUNTANT_USERS, rolUsersCompanyEnum, rolUsersCompanyMap } from "./plandata";
import mailProvider from "../../providers/mailProvider/mailProvider";
import * as MarketActions from "../../actions/market/marketAction";
import * as UsersDomainActions from "../../actions/usersDomain/usersDomainAction";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { ALLOWED_ADMINISTRATOR_FOR_USER_ROLE, COUNTRYS } from "./utils";
import CountryContext from "../../context/countries-context";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { returnFormUserByCountry } from "./model/formUsersModel";
import { useTranslation } from "react-i18next";
import { StatusCodes } from 'http-status-codes';
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import config from '../../config';
import { uuidV4Generator } from "../../utils/randomizer";
import { getCookie } from "../../utils/cookies";
import { convert2AlphaCodeTo3AlphaCodeCountry } from "../../sagas/marketSaga"
import Loader from '../tools/loader';
import { SiigoButtonAtom } from "@siigo/siigo-button-atom-react";
import ModalChangeUserEmail from "./modalChangeUserEmail";

const ACTIONS = {
  CREATE: "create",
  EDIT: "edit"
}

const FormUsers = ({ id, action, actions }) => {
  const marketState = useSelector((_state) => _state.market);
  const usersDomainState = useSelector((_state) => _state.usersDomain);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const companyInfo = JSON.parse(localStorage.getItem("Company"));
  const companyEdit = JSON.parse(sessionStorage.getItem("userEdit") || "{}");
  const [companyUser, setCompanyUser] = useState({
    identification: companyEdit.identification || "",
    firstName: companyEdit.firstName || "",
    lastName: companyEdit.lastName || "",
    email: companyEdit.userCode || "",
    cellPhone: companyEdit.cellPhone || "",
    role: companyEdit.role,
    status: typeof companyEdit.status === 'boolean' ? companyEdit.status : true,
    isAdmin: companyEdit.isAdmin === 1 ? true : false,
    cloudCompanyTenantID: companyInfo.CloudTenantID,
    companyKey: companyInfo.CloudTenantCompanyKey,
  });
  const [openModalUserEmail, setOpenModalUserEmail ] = useState(false);

  const countryContext = useContext(CountryContext);
  const [isResendMail, setResendMail] = useState();

  useEffect(() => {
    if (countryContext.isCountryChanged()) {
      countryContext.setCountryChanged(false);
      window.location.href = "/market";
    }
  }, [countryContext]);

  const restorePassword = async () => {

    if (!isResendMail) {
      setResendMail(true);

      let userEmail = companyEdit?.email?.trim();
      let userFullName = companyEdit?.firstName?.trim() + " " + companyEdit?.lastName?.trim();
      let bodyResendMail = {
        userEmail,
        sendWelcomeMail: true,
        userFullName
      };
      const params = {
        bodyResendMail,
        meta: {
          country: countryContext.country,
          requestId: uuidV4Generator(),
        }
      }
      const options = {
        method: 'post',
        url: `${config.url}bridge?companyKey=${companyInfo?.CloudTenantCompanyKey}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('token')}`,
          'backoffice-service-country': convert2AlphaCodeTo3AlphaCodeCountry(params.meta.country),
          'backoffice-service-name': 'resend-email',
          'backoffice-request-id': params.meta.requestId
        },
        data: params.bodyResendMail
      }

      const pass = await mailProvider.resetPassword(userEmail, options);
      if (pass) {
        setResendMail(false);
        ModalResponse({
          title: "¡Envío exitoso!",
          text:
            "Se ha enviado un correo a " + userEmail + " con la nueva contraseña",
          res: 200,
        });
      } else {
        setResendMail(false);
        ModalResponse({
          title: "Error al enviar",
          text: "No se pudo restablecer contraseña, por favor intente mas tarde",
          res: 500,
        });
      }
    }
  }

  useEffect(() => {
    if (marketState?.users?.statusCode === StatusCodes.BAD_REQUEST || usersDomainState?.users?.statusCode === StatusCodes.BAD_REQUEST) {
      ModalResponse({
        title: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.error.title") : t("market.companyUsers.alerts.editError.title"),
        text: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.error.text") : t("market.companyUsers.alerts.editError.text"),
        res: StatusCodes.BAD_REQUEST,
      });
    }

    if (marketState?.users?.statusCode === StatusCodes.OK && (marketState?.users?.data?.responseXml && !marketState?.users?.data?.additionalUserUpdate)) {
      ModalResponse({
        text: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.error.text") : marketState?.users?.data?.message || t("market.companyUsers.alerts.editError.text") ,
        res: StatusCodes.BAD_REQUEST,
      });
    }

    if ((marketState?.users?.statusCode === StatusCodes.OK && marketState?.users?.data?.responseXml && marketState?.users?.data?.additionalUserUpdate) || usersDomainState?.users?.statusCode === StatusCodes.OK && usersDomainState?.users?.data) {
      ModalResponse({
        title: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.success.title") : t("market.companyUsers.alerts.editSuccess.title"),
        text: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.success.text") : t("market.companyUsers.alerts.editSuccess.text"),
        res: StatusCodes.OK,
      });

      setTimeout(() => {
        sessionStorage.removeItem("users");
        history.push({
          pathname: `/users_management/${id}`,
          state: { from: location.state?.from, detail: location.state?.detail, company: location.state?.company }
        });
      }, 2000);
    }

  }, [marketState?.users, usersDomainState?.users]);

  const [renderUserRoles] = useState(() => {
    const currentUsers = JSON.parse(localStorage.getItem("users"));
    const roleCounts = currentUsers.reduce((counts, user) => {
      if (user.role === rolUsersCompanyEnum.WITH_ACCESS && user.status) {
        counts.AccessTotals += 1;
      }
      if (user.role === rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT && user.status) {
        counts.AccountantTotals += 1;
      }
      return counts;
    }, { AccessTotals: 0, AccountantTotals: 0 });

    return rolUsersCompanyMap.map((role, index) => {
      const isMexicoRestrictedRole = countryContext.country === COUNTRYS.MEXICO.CODE &&
        (role.id === rolUsersCompanyEnum.PAYROLL_MANAGER || role.id === rolUsersCompanyEnum.WITH_ACCESS_LITE);

      const isCreateAction = action.params.action === ACTIONS.CREATE;
      const isEditAction = action.params.action === ACTIONS.EDIT;

      const isAccessRoleExceedLimit = role.id === rolUsersCompanyEnum.WITH_ACCESS && roleCounts.AccessTotals >= companyInfo.AllowedUsers;
      const isAccountantRoleExceedLimit = role.id === rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT && roleCounts.AccountantTotals >= ALLOWEB_ACCOUNTANT_USERS;

      if (isMexicoRestrictedRole) {
        return null;
      }

      if (isCreateAction && (isAccessRoleExceedLimit || isAccountantRoleExceedLimit)) {
        return null;
      }

      if (isEditAction && (isAccessRoleExceedLimit || isAccountantRoleExceedLimit) && role.id !== companyUser.role) {
        return null;
      }

      return (
        <option key={index} value={role.id} className={`${role.id === 0 ? "successState" : "errorState"}`}>{role.name}</option>
      );
    });
  })

  const setClassInpoutError = (isTouched, field) => isTouched && field != undefined && 'inputError';

  const validateUser = (user) => {
    const currentUsers = JSON.parse(localStorage.getItem("users"));
    const userExistEmail = currentUsers.find((currentUser) => currentUser.email === user.email);
    const userExistIdentification = currentUsers.find((currentUser) => currentUser.identification === user.identification);
    if (userExistEmail) return { invalid: true, message: t("market.companyUsers.validations.userAlreadyExistsReason1") };
    if (userExistIdentification) return { invalid: true, message: t("market.companyUsers.validations.userAlreadyExistsReason2") };
    return { invalid: false };
  }

  const validateAtLeastOneAdmin = (user) => {
    const currentUsers = JSON.parse(localStorage.getItem("users"));
    const existsOtherAdmins = currentUsers.some((currentUser) => currentUser.isAdmin === 1 && currentUser.role === rolUsersCompanyEnum.WITH_ACCESS && currentUser.email !== user.email && currentUser.status);
    if (!user.status && !existsOtherAdmins) return { invalid: true, message: t("market.companyUsers.validations.atLeastOneAdmin") };
    if (companyUser.role === rolUsersCompanyEnum.WITH_ACCESS && !user.isAdmin && !existsOtherAdmins) return { invalid: true, message: t("market.companyUsers.validations.atLeastOneAdmin") };
    return { invalid: false };
  }

  const validateUserLimit = (user) => {
    const currentUsers = JSON.parse(localStorage.getItem("users"));
    const roleCounts = currentUsers.reduce((counts, user) => {
      if (user.role === rolUsersCompanyEnum.WITH_ACCESS && user.status && user.email !== companyUser.email) {
        counts.AccessTotals += 1;
      }
      if (user.role === rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT && user.status && user.email !== companyUser.email) {
        counts.AccountantTotals += 1;
      }
      return counts;
    }, { AccessTotals: 0, AccountantTotals: 0 });

    const isAccessRoleExceedLimit = user.role === rolUsersCompanyEnum.WITH_ACCESS && roleCounts.AccessTotals >= companyInfo.AllowedUsers;
    const isAccountantRoleExceedLimit = user.role === rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT && roleCounts.AccountantTotals >= ALLOWEB_ACCOUNTANT_USERS;

    if (isAccessRoleExceedLimit) return { invalid: true, message: "Has excedido el límite de usuarios con acceso activos" };
    if (isAccountantRoleExceedLimit) return { invalid: true, message: "Has excedido el límite de usuarios contadores activos" };
    return { invalid: false };
  }

  const handleFormSubmit = async (validateForm, handleSubmit, setSubmitting, setFieldTouched) => {
    const errors = await validateForm();
    if (Object.keys(errors).length) {
      setSubmitting(false);
      Object.keys(errors).forEach((field) => setFieldTouched(field, true));
      ModalResponse({
        title: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.formWithErrors.title") : t("market.companyUsers.alerts.formEditWithErrors.title"),
        text: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.formWithErrors.text") : t("market.companyUsers.alerts.formEditWithErrors.text"),
        res: StatusCodes.BAD_REQUEST,
      });
    } else {
      handleSubmit();
    }
  };

  const onSubmit = async (values) => {
    if (action.params.action === ACTIONS.CREATE) {
      const userValidation = validateUser(values);
      if (userValidation.invalid && action.params.action === ACTIONS.CREATE) {
        return ModalResponse({ title: t("market.companyUsers.validations.userAlreadyExists"), text: userValidation.message, res: StatusCodes.BAD_REQUEST });
      }
      if (countryContext.country === COUNTRYS.COLOMBIA.CODE) {
        actions?.createUsersCompanyMarket({ ...values, isAdmin: values.isAdmin ? 1 : 0, userCode: values.email });
      }
      if (countryContext.country === COUNTRYS.MEXICO.CODE) {
        actions?.createUserUsersDomain({ ...values });
      }
    }

    if (action.params.action === ACTIONS.EDIT) {
      const atLeastOneAdminValidation = validateAtLeastOneAdmin(values);
      if (atLeastOneAdminValidation.invalid) {
        return ModalResponse({ text: atLeastOneAdminValidation.message, res: StatusCodes.BAD_REQUEST });
      }
      const userLimitValidation = validateUserLimit(values);
      if (userLimitValidation.invalid) {
        return ModalResponse({ text: userLimitValidation.message, res: StatusCodes.BAD_REQUEST });
      }
      if (countryContext.country === COUNTRYS.COLOMBIA.CODE) {
        actions?.editUsersCompanyMarket({
          ...values,
          isAdmin: values.isAdmin ? 1 : 0,
          userCode: companyEdit.userCode,
          usernameKey: values.companyKey,
          CloudTenantID: values.cloudCompanyTenantID
        });
      }
      if (countryContext.country === COUNTRYS.MEXICO.CODE) {
        actions?.updateUserUsersDomain({ ...values });
      }
    }
  };

  const haveSpecificPropertiesChanged = (obj1, obj2) => {
    const properties = ["firstName", "lastName", "identification", "cellPhone", "role", "status", "isAdmin"];
    for (let property of properties) {
      if (obj1[property] !== obj2[property]) {
        return true;
      }
    }
    return false;
  };

  const redirectToAdminUrl = () => {
    history.push({
      pathname: location.state?.adminUrl,
      state: { from: location.state?.from, detail: location.state?.detail, company: location.state?.company}
    });
  };

  const handleBackClick = (currentValues) => (e) => {
    e.preventDefault();
    if (action.params.action === ACTIONS.CREATE) redirectToAdminUrl()
    if (action.params.action === ACTIONS.EDIT) {
      if (haveSpecificPropertiesChanged(companyUser, currentValues)) {
        Swal.fire({
          customClass: { confirmButton: 'button green', cancelButton: 'button red' },
          buttonsStyling: false,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "Sí",
          cancelButtonText: "No",
          reverseButtons: true,
          title: t("market.companyUsers.alerts.changeToSave.title"),
          text: t("market.companyUsers.alerts.changeToSave.text"),
        }).then((result) => {
          if (result.isConfirmed) redirectToAdminUrl()
        });
      } else {
        redirectToAdminUrl()
      }
    }
  }


  return (
    <Formik
      initialValues={companyUser}
      validationSchema={returnFormUserByCountry(countryContext?.country, t)}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values, isSubmitting, setFieldValue, validateForm, handleSubmit, setSubmitting, setFieldTouched }) => (
        <>
        <Form onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit(validateForm, handleSubmit, setSubmitting, setFieldTouched);
        }}>
          <div className="uxDetailDataFilterTitle" style={{ textAlign: "left" }}>
            <h3>
              <a href="#" onClick={handleBackClick(values)}><i className="siicon icon-atras"></i>{" Atrás |"}</a>{" "}
              {action.params.action === "create" ? t("market.companyUsers.texts.creationTitle") : t("market.companyUsers.texts.editTitle") + " [" + companyUser.email + "]"}{" "}
            </h3>
            <button type="submit" className="a-button green-fill" disabled={isSubmitting}>{t("market.companyUsers.buttons.save")}</button>
            {action.params.action === "create" && (
              <button
                type="button"
                className="a-button red-button"
                style={{ marginRight: "5px" }}
                onClick={redirectToAdminUrl}
              >
                {t("market.companyUsers.buttons.cancel")}
              </button>
            )}
          </div>

          <div className="grid-user-edit">
            <div className="row">
              <div className="column4">
                <div className="input-container">
                  <label className="labelOk">{t("market.companyUsers.form.placeHolderFirstName")}</label>
                  <Field placeholder={t("market.companyUsers.form.placeHolderFirstName")} type="text" name="firstName" className={`${setClassInpoutError(touched?.firstName, errors?.firstName)}`} />
                  <ErrorMessage className="error-message" name="firstName" component="span" />
                </div>
              </div>

              <div className="column4">
                <div className="input-container">
                  <label className="labelOk">{t("market.companyUsers.form.placeHolderLastName")}</label>
                  <Field placeholder={t("market.companyUsers.form.placeHolderLastName")} type="text" name="lastName" className={`${setClassInpoutError(touched?.lastName, errors?.lastName)}`} />
                  <ErrorMessage className="error-message" name="lastName" component="span" />
                </div>
              </div>

              <div className="column4">
                <div className="input-container">
                  <label className="labelOk">{t("market.companyUsers.form.placeHolderEmail")}</label>
                  <Field 
                    type="text" name="email" 
                    placeholder={t("market.companyUsers.form.placeHolderEmail")} 
                    className={`${setClassInpoutError(touched?.email, errors?.email)}`} 
                    disabled={action.params.action === ACTIONS.EDIT} 
                  />
                  <ErrorMessage className="error-message" name="email" component="span" />
                  {action.params.action === ACTIONS.EDIT && (values.role != rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT) && (
                    <div className="change-user-email">
                    <SiigoButtonAtom 
                      text={t("market.companyUsers.buttons.changeUserEmail")}
                      size="s"
                      onClick={() => setOpenModalUserEmail(true)}
                    />
                  </div>
                  )}
                </div>
              </div>

              <div className="column4">
                <div className="input-container">
                  {action.params.action === "create" ? (
                    <div>
                      <label style={{ color: "grey" }} className="labelOk">{t("market.companyUsers.form.placeHolderResetPassword")}</label>
                      <a href="javascript:;" alt={t("market.companyUsers.form.placeHolderResetPassword")} style={{ color: "grey", textDecoration: " underline" }} className="link-password">{t("market.companyUsers.form.placeHolderSendNewPassword")}</a>
                    </div>
                  ) : (
                    <div>
                      <label className="labelOk">{t("market.companyUsers.form.placeHolderResetPassword")}</label>
                      {(isResendMail ? <Loader className="loader" /> : <a href="javascriat:;" onClick={() => restorePassword()}
                        alt={t("market.companyUsers.form.placeHolderResetPassword")}
                        className="link-password">{t("market.companyUsers.form.placeHolderSendNewPassword")}
                      </a>)}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="column4">
                <div className="input-container">
                  <label className="labelOk">{t("market.companyUsers.form.placeHolderIdentification")}</label>
                  <Field placeholder={t("market.companyUsers.form.placeHolderIdentification")} type="text" name="identification" className={`${setClassInpoutError(touched?.identification, errors?.identification)}`} />
                  <ErrorMessage className="error-message" name="identification" component="span" />
                </div>
              </div>

              <div className="column4">
                <div className="input-container">
                  <label className="labelOk">{t("market.companyUsers.form.placeHolderCellphone")}</label>
                  <Field placeholder={t("market.companyUsers.form.placeHolderCellphone")} type="text" name="cellPhone" className={`${setClassInpoutError(touched?.cellPhone, errors?.cellPhone)}`} />
                  <ErrorMessage className="error-message" name="cellPhone" component="span" />
                </div>
              </div>

              <div className="column4">
                <div className="input-container">
                  <label className="labelOk">{t("market.companyUsers.form.placeHolderRole")}</label>
                  <Field as="select" name="role" className={`border-select ${setClassInpoutError(touched?.role, errors?.role)}`} onChange={(_event) => {
                    const { value } = _event.target;
                    setFieldValue('role', parseInt(value))
                    setFieldValue('isAdmin', false)
                  }}>
                    <option value="-9">Seleciona una opción</option>
                    {renderUserRoles}
                  </Field>
                  <ErrorMessage className="error-message" name="role" component="span" />
                </div>
                {ALLOWED_ADMINISTRATOR_FOR_USER_ROLE.includes(values.role) && (
                  <div className="ckeckcontainer">
                    <label className="labelOk labelcheckadmin" htmlFor="isAdmin">{t("market.companyUsers.form.placeHolderIsAdmin")}</label>
                    <Field type="checkbox" name="isAdmin" className="ckTable checkadmin" />
                  </div>
                )}
              </div>

              <div className="column4">
                <div className="input-container">
                  <label className="labelOk">{t("market.companyUsers.form.placeHolderStatus")}</label>
                  <Field as="select" name="status" className={`border-select ${setClassInpoutError(touched?.status, errors?.status)}`} onChange={(_event) => {
                    const { value } = _event.target;
                    setFieldValue('status', value === 'true' ? true : false)
                  }}>
                    <option value={true}>{t("market.companyUsers.form.placeHolderStatusActive")}</option>
                    {action.params.action === ACTIONS.EDIT && (
                      <option value={false}>{t("market.companyUsers.form.placeHolderStatusInactive")}</option>
                    )}
                  </Field>
                  <ErrorMessage className="error-message" name="status" component="span" />
                </div>
              </div>
            </div>
          </div>
        </Form>
        <ModalChangeUserEmail 
            t={t} 
            country={countryContext.country} 
            showModal={openModalUserEmail} 
            handleCloseModal={() => setOpenModalUserEmail(false)} 
            company={companyInfo}
            user={companyUser}
            setFieldValue={setFieldValue}
            setCompanyUser={setCompanyUser}
        />
        </>
      )}
    </Formik>
  );
}

const mapStateToProps = (state) => ({
  error: (state?.market) ? state.market.err : state.usersDomain.err,
  isLoading: (state?.market) ? state.market.isLoading : state.usersDomain.isLoading,
  users: (state?.market) ? state.market.users : state.usersDomain.users,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...MarketActions, ...UsersDomainActions }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FormUsers));
