import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dayjs from 'dayjs';
import * as MailActions from '../../actions/blackListEmail/blackListEmail';
import Loader from '../tools/loader';
import DatePicker from '../tools/datePickerControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import { SiigoInputAtom } from '@siigo/siigo-input-atom-react';
import TableReport from '../tableReport';
import ModalConfirmation from '../layout/modals/modalConfirmation';
import ModalResponse from '../layout/modals/modalResponse';
import './style.scss';
import { reasons, tableHeaders, toolTipReason, statesEnabledToRemove } from './enums';


let aux; // Change
class BlackListEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: '',
            toDate: '',
            email: '',
        };
        this.btnActions = {
            btnRemoveBlackListShow: true,
            btnRemoveBlackList: this.removeEmail
        }

        this.datePickerRef = React.createRef();

        this.deleteState = false;

        this.dateChanged = this.dateChanged.bind(this)
        this.searchEmail = this.searchEmail.bind(this)
        this.validateFields = this.validateFields.bind(this)
        this.removeEmailAux = this.removeEmailAux.bind(this);
        this.clearInputs = this.clearInputs.bind(this)
        this.setEmail = this.setEmail.bind(this)

        aux = this.removeEmailAux;
    }

    componentDidMount() {
        this.searchEmail(false);
    }


    validateFields() {
        let { fromDate, toDate, email } = this.state;

        if ((fromDate == '' || toDate == '') && email == '') {
            ModalResponse({
                title: 'Inválido',
                text: 'Debe seleccionar al menos un criterio de búsqueda.',
                res: 404
            });
            this.clearInputs();
            return false;
        }
        return true;
    }

    escapeSpecialChars(escapedEmailFilter) {
        escapedEmailFilter = escapedEmailFilter.replace(/%/g, '%25').replace(/&/g, '%26').replace(/#/g, '%23').replace(/\+/g, '%2B');
        return escapedEmailFilter;
    }

    searchEmail(validate = true, validateEmpty = true) {
        let { fromDate, toDate, email } = this.state;
        let fromDateAux = '', toDateAux = '';
        if ((validate && fromDate != '' && toDate != '') || !validate) {
            const startDate = (validate && fromDate != '' && toDate != '') ? new Date(fromDate) : new Date();
            const endDate = (validate && fromDate != '' && toDate != '') ? new Date(toDate) : new Date();

            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);

            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);

            fromDateAux = !isNaN(startDate.valueOf()) ? startDate.toISOString() : '';
            toDateAux = !isNaN(endDate.valueOf()) ? endDate.toISOString() : '';

        }

        if (validate && validateEmpty) {
            if (!this.validateFields()) return;
        }

        email = this.escapeSpecialChars(email);
        this.props.actions.getBlackListEmail({ fromDate: fromDateAux, toDate: toDateAux, email, pageSize: 1000, sort: "desc" });
    }

    setEmail(e) {
        this.setState({ email: document.getElementById('emailInput').value })
    }

    clearInputs() {
        document.getElementById('emailInput').value = '';
        if (this.datePickerRef?.current) {
            this.datePickerRef.current.handleCleanImput();
        }

        this.setState(() => ({ fromDate: '', toDate: '', email: '' }), () => {
            this.searchEmail(false);
        });
    }

    dateChanged(e) {
        const { value, value2 } = e.detail;
        if (value && value2) {
            this.setState({ fromDate: value, toDate: value2, })
        } else {
            this.setState({ fromDate: '', toDate: '' })
        }
    }

    removeEmailAux(email) {
        this.props.actions.deleteBlackListEmail(email)
        this.deleteState = true;
    }

    removeEmail(email) {
        const result = ModalConfirmation('¿Estás seguro?', 'Si, remover', 'No, cancelar', `De eliminar el correo <b> ${email}`, 'warning')
        result.then(res => {
            if (res.value) {
                aux(email)
            }
        })
    }

    handleDate = (data) => {
        let fromDate = data.from != undefined ? new Date(data.from) : undefined;
        let toDate = data.to != undefined ? new Date(data.to) : undefined;
        this.setState({ fromDate: !isNaN(fromDate.valueOf()) ? fromDate : '' });
        this.setState({ toDate: !isNaN(toDate.valueOf()) ? toDate : '' })
        return { from: fromDate, to: toDate };
    }


    render() {
        let {
            emails,
            isLoading,
            remove
        } = this.props;


        // Get Data.
        let tableData = [];
        if (emails?.status == 200) {
            if (emails?.data?.emails.length > 0) {
                tableData = emails?.data?.emails.map((obj) => {
                    const date = new Date(obj.createdDate);
                    return {
                        ...obj,
                        _id: obj.email,
                        reason: reasons[obj.reason],
                        removeFlag: statesEnabledToRemove.indexOf(Number(obj.reason)) != -1 ? true : false,
                        createdDate: !isNaN(date.getTime()) ? dayjs(date).format("YYYY-MM-DD") : "Fecha inválida"
                    }
                });

                // Reverse.
                //tableData.reverse();
            }
        }

        // Remove black
        if (remove && this.deleteState) {
            if (remove?.status == 200) {
                ModalResponse({
                    title: 'Eliminación',
                    text: 'El correo se ha eliminado de forma correcta',
                    res: 200
                });
                this.searchEmail(true, false);
            } else {

                if (remove?.data.code == 5) {
                    ModalResponse({
                        title: 'Oops!',
                        text: 'El correo no se encuentra en un estado válido para remover.',
                        res: 404
                    });
                } else {
                    ModalResponse({
                        title: 'Error',
                        text: 'Ha ocurrido un problema, por favor inténtalo más tarde.',
                        res: 404
                    });
                }
            }
            this.deleteState = false;
        }

        return (
            <div>
                <div>
                    <h1>Correos bloqueados</h1>
                    <h5>Busca el correo que está presentando el bloqueo</h5>
                </div>
                <div className='datePickerContainer whiteBackgroundSection'>
                    <div className='datePicker'>
                        <DatePicker id="datePickerControl" ref={this.datePickerRef} handleDate={this.handleDate} />
                    </div>
                </div>
                <div className='controlsSectionContainer whiteBackgroundSection'>
                    <div>
                        <SiigoInputAtom
                            align="left"
                            id="emailInput"
                            label="Correo Electrónico"
                            maxLength={100}
                            minLength={3}
                            onBlur={this.setEmail}
                            prefixType="icon"
                            requiredMessage="Este campo es requerido."
                            type="text"
                            value={this.state.email}
                        />
                    </div>
                    <div>
                        <SiigoButtonAtom
                            id='searchEmailButton'
                            color="primary-green"
                            size="l"
                            type="button"
                            text="Buscar"
                            icon="fas fa-plus"
                            onClick={this.searchEmail}
                        />
                        <SiigoButtonAtom
                            id='clearInputsButton'
                            color="primary-yellow"
                            size="l"
                            type="button"
                            text="Limpiar"
                            icon="fas fa-plus"
                            onClick={(e) => this.clearInputs()}
                        />
                    </div>
                </div>
                <div>
                    {/*error && <h4>Error: {error.response.data}</h4>*/}
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div>
                            <TableReport
                                columns={tableHeaders} // Data columns to table
                                tabs={false} //Enable tabs
                                tabsData={[]} //Load data to tabs check option on tabsData const
                                headerCounterTab={true} //Counter tab option
                                btnActions={this.btnActions}
                                actionButtons={true} //Enable buttons (edit, delete, copy, open)
                                pageSize={10} // Enable page size
                                pagination={true} //Enable pagination (true, false)
                                paginationNav={'arrows'} //Nav options (arrow, dot)
                                paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
                                maxSizePagination={6} //Size pagination
                                defaultPaginationNumber={1} //Current pagination when start app
                                paginationGrouped={10} //Grouped by page
                                detailRow={false} //Open column detail with left arrow
                                data={tableData} //Data for table,
                                tooltip={toolTipReason}
                            />
                            <FontAwesomeIcon className='icon-question' icon={faQuestion} /> {/* Utilizar DSM?*/}
                            <p className='text-info-data'>Si requieres un reporte de más de 1.000 registros puedes comunicarte con el equipo de BI o la tribu STARK por medio de un Halp.</p>
                        </div>
                    )}

                </div>
            </div>
        );
    }
}

Date.prototype.isValid = function () {
    return this.getTime() === this.getTime();
};

BlackListEmail.propTypes = {
    error: PropTypes.object,
};

const mapStateToProps = (state) => ({
    error: state.blackListEmail.error,
    remove: state.blackListEmail.remove,
    errorRemove: state.blackListEmail.errorRemove,
    emails: state.blackListEmail.emails,
    isLoading: state.blackListEmail.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(MailActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(BlackListEmail);
