import React, { useState } from 'react';
import { disableDropdownControl, returnProductsListByCountry, getLicense } from './utils';
import config from '../../config';
import { NumEmployesByLicence } from './plandata';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Loader from '../tools/loader';
import { returnFormCompanyByCountry } from './model/formCompanyModel';

const FormCompany = ({ country, task, isLoadingCompany, onCancel, t, onSubmit }) => {
    const [citysOptions] = useState(country.countries.marketModal.citysOptions)
    const [isDropdownControlDisable] = useState(disableDropdownControl(config?.createCompanySelectEnable, task.showPlanTypeList))
    const [renderOptionsLicense] = useState(() => {
        const licensesByCountry = returnProductsListByCountry(country.country)
        task.license = getLicense(task.planType, licensesByCountry)
        return licensesByCountry.map((license, index) => (
            <option key={`${index}-${license.code}`} selected={task.planType === license.code ? "selected" : null} value={license.code}>{license.name}</option>
        ))
    });

    /* Se genera validación cuando el campo fue tocado y no cumple las reglas
     * para retornar clase de error en input
     */
    const setClassInpoutError = (isTouched, field) => {
        return isTouched && field != undefined && 'inputError'
    }

    return (
        <Formik
            initialValues={task}
            validationSchema={returnFormCompanyByCountry(country.country, t)}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit(values);
                setSubmitting(false);
            }}
        >
            {({ errors, touched, setFieldValue }) => (
                <Form>
                    <h3 className="h3Modal">{t("market.modal.formCompany.title")}</h3>
                    <div className="input-container">
                        <label className="labelOk">{t("market.modal.formCompany.license.placeHolder")}</label>
                        <Field
                            as="select"
                            name="license"
                            className={`slModal ${setClassInpoutError(touched?.license, errors?.license)}`}
                            disabled={isDropdownControlDisable}
                            onChange={(_event) => {
                                const { value } = _event.target;
                                setFieldValue('license', value)
                                setFieldValue('employee', NumEmployesByLicence[value] ? NumEmployesByLicence[value] : "1")
                            }}
                        >
                            <option value="-9">{t("market.modal.formCompany.license.optionPlaceHolder")}</option>
                            {renderOptionsLicense}
                        </Field>
                        <ErrorMessage className="error-message" name="license" component="span" />
                    </div>

                    <div className="input-container">
                        <label className="labelOk">{t("market.modal.formCompany.nit.placeHolder")}</label>
                        <Field placeholder={t("market.modal.formCompany.nit.placeHolder")} type="text" name="nit" className={`slModal ${setClassInpoutError(touched?.nit, errors?.nit)}`} />
                        <ErrorMessage className="error-message" name="nit" component="span" />
                    </div>

                    <div className="input-container">
                        <label className="labelOk">{t("market.modal.formCompany.company.placeHolder")}</label>
                        <Field placeholder={t("market.modal.formCompany.company.placeHolder")} type="text" name="company" className={`slModal ${setClassInpoutError(touched?.company, errors?.company)}`} />
                        <ErrorMessage className="error-message" name="company" component="span" />
                    </div>

                    <div className="input-container">
                        <label className="labelOk">{t("market.modal.formCompany.nameCompany.placeHolder")}</label>
                        <Field placeholder={t("market.modal.formCompany.nameCompany.placeHolder")} type="text" name="nameCompany" className={`slModal ${setClassInpoutError(touched?.nameCompany, errors?.nameCompany)}`} />
                        <ErrorMessage className="error-message" name="nameCompany" component="span" />
                    </div>

                    <div className="input-container">
                        <label className="labelOk">{t("market.modal.formCompany.emailCompany.placeHolder")}</label>
                        <Field placeholder={t("market.modal.formCompany.emailCompany.placeHolder")} type="text" name="emailCompany" className={`slModal ${setClassInpoutError(touched?.emailCompany, errors?.emailCompany)}`} />
                        <ErrorMessage className="error-message" name="emailCompany" component="span" />
                    </div>

                    <div className="input-container">
                        <label className="labelOk">{t("market.modal.formCompany.phoneCompany.placeHolder")}</label>
                        <Field placeholder={t("market.modal.formCompany.phoneCompany.placeHolder")} type="text" name="phoneCompany" className={`slModal ${setClassInpoutError(touched?.phoneCompany, errors?.phoneCompany)}`} />
                        <ErrorMessage className="error-message" name="phoneCompany" component="span" />
                    </div>

                    

                    <div className="input-container">
                        <label className="labelOk">{t("market.modal.formCompany.employee.placeHolder")}</label>
                        <Field placeholder={t("market.modal.formCompany.employee.placeHolder")} type="text" name="employee" className={`slModal ${setClassInpoutError(touched?.employee, errors?.employee)}`} disabled={true} />
                        <ErrorMessage className="error-message" name="employee" component="span" />
                    </div>

                    {country['countries']?.company?.pos?.visible ? (
                        <div className="input-container">
                            <label className="labelOk">{t("market.modal.formCompany.pos.placeHolder")}</label>
                            <Field placeholder={t("market.modal.formCompany.pos.placeHolder")} type="text" name="pos" className={`slModal ${setClassInpoutError(touched?.pos, errors?.pos)}`} disabled={true} />
                            <ErrorMessage className="error-message" name="pos" component="span" />
                        </div>
                    ) : null}
                    {isLoadingCompany ? <Loader /> : null}
                    <div className="actions">
                        <button className="red" onClick={onCancel}>
                            {t("market.modal.formCompany.buttonCancel")}
                        </button>
                        <button className="ml20 green-fill" type='submit' >
                            {t("market.modal.formCompany.buttonCreate")}
                        </button>
                    </div>
                </Form>
            )}
        </Formik >
    )
}

export default FormCompany;