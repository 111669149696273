import React from 'react';
import Swal from 'sweetalert2';

const ModalChangePlan = ({ actionChange, data, params }) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'button green',
      cancelButton: 'button orange'
    },
    buttonsStyling: false
  });
 
  swalWithBootstrapButtons
    .fire({
      title: '¿Estás seguro?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, cambiar!',
      cancelButtonText: 'No, cancelar!',
      reverseButtons: true,
      html:
        'De cambiar el plan <b>' +
        data.actual +
        '</b> por <b>' +
        data.changeTo +
        '</b>'
    })
    .then(result => {
      if (result.value) {
        actionChange(params);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'La empresa seguirá con el mismo plan',
          'error'
        );
      }
    });
  return <div></div>;
};

export default ModalChangePlan;
