import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';

import * as DependenceActions from '../../actions/dependences/dependenceActions';
let update = false;
class DependenceEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onHandleEdit = event => {
    event.preventDefault();

    let name = event.target.name.value;
    let description = event.target.description.value;

    const data = {
      name,
      description
    };
    this.props.actions.updateDependence(data, this.props.id);
    update = true;
  };

  componentDidMount() {
    document.title = 'Edit Dependence';
    this.props.actions.getDependence(this.props.id);
  }

  render() {
    let name,
      description = null;
    const { isLoading, dependence } = this.props;

    if (dependence) {
      if (dependence.data.error === 'Forbidden') {
        return <div>No tiene permiso para este componente</div>;
      }
      name = dependence.data.name;
      description = dependence.data.description;

      if (
        dependence.config.method === 'put' &&
        dependence.status === 200 &&
        update
      ) {
        update = false;
        this.props.history.push('/dependences');
      }
    }

    return (
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <form onSubmit={this.onHandleEdit}>
              <h3>Editar Dependecia</h3>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <input
                  type="text"
                  defaultValue={name}
                  name="name"
                  id="name"
                  placeholder="Nombre"
                />
              </div>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <input
                  type="text"
                  defaultValue={description}
                  name="description"
                  id="description"
                  placeholder="Descripcion"
                />
              </div>
              <div>
                <button className="button green-fill">Guardar cambios</button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

DependenceEdit.propTypes = {
  actions: PropTypes.object.isRequired,
  dependence: PropTypes.object,
  error: PropTypes.object
};

const mapStateToProps = state => ({
  dependence: state.dependences.dependence,
  error: state.dependences.err,
  isLoading: state.dependences.isLoading,
  isLoadingUp: state.dependences.isLoadingUp
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(DependenceActions, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DependenceEdit));
