import * as types from '../actions';

const countriesReducer = (state = { isLoading: true }, action = null) => {
  switch (action.type) {
    case types.GET_COUNTRIES:
      return { ...state, isLoading: true };
    case types.COUNTRIES_RECEIVED:
      return { ...state, isLoading: false, countries: action.data };
    case types.COUNTRIES_REQUEST_FAILED:
      return { ...state, isLoading: false, countries: action.error };

    case types.GET_CONFIG:
      return { ...state, isLoading: true };
    case types.CONFIG_RECEIVED:
      return {
        ...state,
        isLoading: false,
        config: action.data
      };
    case types.CONFIG_REQUEST_FAILED:
      return { ...state, isLoading: false, config: action.error };

    default:
      return state;
  }
};

export default countriesReducer;
