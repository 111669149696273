import React, { Component } from 'react';
import CreateConfigProvider from '../../components/auroraConfigProvider/createProvider';

class CreateConfigProviderContainer extends Component {
  render() {
    return (
      <div>
        <CreateConfigProvider parentPath={'/configproviders'} />
      </div>
    );
  }
}

export default CreateConfigProviderContainer;