import React, { useEffect, useState } from 'react';
import countriesConfig from './configComponents';
import i18n from '../utils/i18n';
import { COUNTRYS } from '../components/market/utils';

const CountryContext = React.createContext();

export const CountryProvider = ({ children }) => {
    const [country, setCountry] = useState();
    const [countries, setCountries] = useState(countriesConfig[country]);
    const [countryChanged, setCountryChanged] = useState(false);
    const [endpoints, setEndpoints] = useState([]);
    const [instanceId, setInstanceId] = useState("");

    const changeCountry = (newCountry, isDefault = false) => {
        if (country !== newCountry) {
            console.log("Country Changed: ", newCountry);
            setCountry(newCountry);
            if (!isDefault) setCountryChanged(true);
            setCountries(countriesConfig[newCountry]);
            i18n.changeLanguage(newCountry);
            localStorage.removeItem("paramsCompany");
        }

        localStorage.setItem('idInst', instanceId);
    };

    const getDefaultCountry = () => {
        const persistentCountry = localStorage.getItem("country");
        const findCountry = Object.keys(COUNTRYS).find((key) => key === persistentCountry?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase());
        return findCountry ? COUNTRYS[findCountry]?.CODE : COUNTRYS.COLOMBIA.CODE;
    }

    const setDefaultCountry = () => {
        const defaultCountry = getDefaultCountry();
        changeCountry(defaultCountry, true);
    }

    const setDefaultConfigurations = () => {
        setEndpoints(JSON.parse(localStorage.getItem("configInst")));
        setInstanceId(localStorage.getItem("idInst"));
    }

    const isCountryChanged = () => countryChanged;

    useEffect(() => {
        setDefaultCountry();
        setDefaultConfigurations();
    }, []);

    useEffect(() => {
        if (endpoints?.length) localStorage.setItem('configInst', JSON.stringify(endpoints));
        changeCountry(country);
    }, [endpoints]);

    useEffect(() => {
        if (instanceId?.length) localStorage.setItem('idInst', instanceId);
        changeCountry(country);
    }, [instanceId]);

    return (
        <CountryContext.Provider value={{
            country,
            countries,
            changeCountry,
            isCountryChanged,
            setCountryChanged,
            setInstanceId,
            setEndpoints,
        }}>
            {children}
        </CountryContext.Provider>
    );
};

export const CountryConsumer = CountryContext.Consumer;

export default CountryContext;