
export const CONSTVALUES = {
    MONGODB: 1,
    MICROSERVICE: 2
}

export const TYPES_AUTH = {
    GROUP_AUTH: "groupAuthentication",
    NEW_AUTH: "newAuthentication"
}

export const APPLY_FILTER_PAGINATION = {
    QUERY_PARAM: "queryParam",
    BODY: "body"
}

export const APPLY_POST_FILTER_PAGINATION = {
    KEY: "key",
    ROOT: "raiz"
}

export const DATAOPTIONS = {
    TYPECONNECTION: [
        {id: 1, label: "MongoDB", value: CONSTVALUES.MONGODB},
        {id: 2, label: "Microservicio", value: CONSTVALUES.MICROSERVICE},
    ],
    METHOD: [
        {id: 1, label: "GET", value: "GET"},
        {id: 2, label: "POST", value: "POST"},
    ],
    TYPEAUTH: [
        { id: 1, label: "Basic", value: "Basic" },
        { id: 2, label: "Token", value: "Token" }
    ],
    TYPEAUTHPOST: [
        { id: 1, label: "Autenticación del grupo", value: "groupAuthentication" },
        { id: 2, label: "Nueva Autenticación", value: "newAuthentication" },
    ],    
    TYPEAUTHENTICATION: [
        { id: 1, label: "Oauth 1.0", value: "oAuth1" }
    ],
    EXPORTTYPE: {
        EXCEL: [
            {
                checked: false,
                indeterminate: false,
                label: 'Excel',
                name: 'Op1'
            }
        ],
        PDF: [
            {
                checked: false,
                indeterminate: false,
                label: 'PDF',
                name: 'Op2'
            }
        ]
    },
    LISTBOX: [
        { label: 'One', value: 'One' },
        { label: 'Two', value: 'Two' },
        { label: 'Three', value: 'Three' },
        { label: 'Four', value: 'Four' },
        { label: 'Five', value: 'Five' },
        { label: 'Six', value: 'Six' },
        { label: 'Seven', value: 'Seven' },
        { label: 'Eigth', value: 'Eigth' },
        { label: 'Nine', value: 'Nine' },
        { label: 'Ten', value: 'Ten' },
    ],
    TYPEDATA: [
        { label: 'text', value: 'text' },
        { label: 'date', value: 'date' },
    ],
    HEADERSLISTTABLE: [
        {
            align: 'center',
            iconType: '',
            key: 'type',
            text: 'Tipo de Dato',
            type: 'string'
        },
        {
            align: 'center',
            iconType: '',
            key: 'value',
            text: 'Valor',
            type: 'string'
        },
        {
            align: 'center',
            iconType: '',
            key: 'actions',
            typeCell: "actions",
            text: 'Acción',
            type: 'string'
        }
    ],
    COLLECTIONS: [
        {id: 1, label: "users", value: "users"},
        {id: 2, label: "payments", value: "payments"},
    ],
    OPTION_APPLY_FILTER_PAGINATION : [
        {id: 1, label: "Query Params - GET", value: APPLY_FILTER_PAGINATION.QUERY_PARAM},
        {id: 2, label: "Body - POST", value: APPLY_FILTER_PAGINATION.BODY},
    ],
    OPTIONS_APPLY_POST_FILTER_PAGINATION : [
        {id: 1, label: "Raíz del JSON", value: APPLY_POST_FILTER_PAGINATION.ROOT},
        {id: 2, label: "Key personalizada", value: APPLY_POST_FILTER_PAGINATION.KEY},
    ],
    OPTION_TYPE_DATA_FILTER : [
        {id: 1, label: "Array", value: "array"},
        {id: 2, label: "Object", value: "object"}
    ]
}
