import React, { Component } from 'react';

import './SquareIconData.scss'

class SquareIconData extends Component {

    constructor(props) {
	    super(props);
    }

    render(){
        return(
            <>
                <div className='SquareIconData text-center'>
                    <div className='SquareIconData-icon-container align-center'>
                        <i className={ this.props.icon }></i>
                    </div>
                    
                    <label>{ this.props.label } </label>
                </div>
            </>
        )
    }
}

export default SquareIconData;
