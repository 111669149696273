import React, { Component } from 'react';
import EditDataSource from '../../components/datasource/editDatasource';
import Layout from '../layout';

class EditDataSourceContainer extends Component {
  
    constructor(props) {
        super(props);
        this.state = {};
    }
	render() {
        const id = this.props.match.params.id;
		return (	
			<div>
        <div>
            <Layout route={'/datasource/edit'} />
            <section className={'container'}>
              <div className="section">
                <EditDataSource id={id} />
              </div>
            </section>
          </div>
      </div>
		);
	}
}

export default EditDataSourceContainer;
