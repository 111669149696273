import * as types from '../index';

export const processRequest = (data) => {
    return { type: types.PROCESS_FILE_NSN, data }
}

export const downloadFile = () =>{
    return { type: types.DOWNLOAD_FILE_NSN }
}


