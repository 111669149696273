// a library to wrap and simplify api calls
import apisauce from 'apisauce';
import ActivationManagement from './services/ActivationManagement';
import config from '../../config';

// our "constructor"
const create = () => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"

    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'content-type': 'multipart/form-data',
      'content-type': 'application/json',
      Authorization: config.activationManagementToken
    },
    // 30 second timeout...
    timeout: 30000
  });

  const activationManagement = new ActivationManagement(api);

  //  return Api
  return {
    setHeader: (key, value) => api.setHeader(key, value),
    removeHeader: key => api.deleteHeader(key),
    // a list of the API functions from step 2
    activationManagement
  };
};

// let's return back our create method as the default.
export default {
  create
};
