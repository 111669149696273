import React, { Children, Component } from 'react'
import { Link } from 'react-router-dom'

import './smenu.scss'

class Smenu extends Component {
    render() {
        return (
            <>
                {/* MENÚ */}
                <div className='row subsheader subsheader-menu align-center'>                    
                    {/* explorar  */}
                    <div className='col text-center'>
                        <CustomLink to="/siigers/explorar">
                            <i className="fa-solid fa-magnifying-glass"></i>    
                        </CustomLink>                         
                    </div>
                    {/* viajes */}
                    <div className='col text-center'>
                        <CustomLink to="/siigers/viajes">
                            <i className="fa-solid fa-suitcase-rolling"></i>    
                        </CustomLink>                        
                    </div>
                    {/* mensajes */}
                    <div className='col text-center'>
                        <CustomLink to="/siigers/mensajes">
                            <i className="fa-solid fa-message"></i>  
                        </CustomLink>                        
                    </div>
                    {/* perfil */}
                    <div className='col text-center'>
                        <CustomLink to="/siigers/perfil">
                            <i className="fa-solid fa-user"></i> 
                        </CustomLink>
                    </div>
                </div>
            </>
        )
    }
}

/* para agregar la clases de activo en el menu ;) */
function CustomLink({to, children, ...props})
{
    const path = window.location.pathname;
  
    return(
        <Link to={to} className={ ((path === to)||((path ==="/siigers")&&(to ==="/siigers/explorar")) ) ? "menu-icon-button selected" : "menu-icon-button"} {...props}>                          
            {children}                   
        </Link>
    )   
}

export default Smenu