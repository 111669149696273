import * as types from '../actions';

const usersDomainReducer = (state = { isLoading: false }, action = null) => {
    switch (action.type) {
        case types.GET_USERS_USERS_DOMAIN:
            return { ...state, isLoading: true };
        case types.GET_USERS_USERS_DOMAIN_RECEIVED:
            return { ...state, isLoading: false, users: action.data };
        case types.GET_USERS_USERS_DOMAIN_FAILED:
            return { ...state, isLoading: false, users: action.error };

        case types.CREATE_USER_USERS_DOMAIN:
            return { ...state, isLoading: true };
        case types.CREATE_USER_USERS_DOMAIN_SUCCESS:
            return { ...state, isLoading: false, users: action.data };
        case types.CREATE_USER_USERS_DOMAIN_FAILED:
            return { ...state, isLoading: false, users: action.error };

        case types.UPDATE_USER_USERS_DOMAIN:
            return { ...state, isLoading: true };
        case types.UPDATE_USER_USERS_DOMAIN_SUCCESS:
            return { ...state, isLoading: false, users: action.data };
        case types.UPDATE_USER_USERS_DOMAIN_FAILED:
            return { ...state, isLoading: false, users: action.error };

        case types.DELETE_USER_USERS_DOMAIN:
            return { ...state, isLoading: true };
        case types.DELETE_USER_USERS_DOMAIN_SUCCESS:
            return { ...state, isLoading: false, users: action.data };
        case types.DELETE_USER_USERS_DOMAIN_FAILED:
            return { ...state, isLoading: false, users: action.error };

        default:
            return state;
    }
}

export default usersDomainReducer;