import * as types from '../index';

export const getPlans = () => {
    return { type: types.GET_PLANS }
};

export const getPlan = ( msisdn ) => {
    return { type: types.GET_PLAN, msisdn }
};

export const createPlan = ( plan ) => {
    return { type: types.CREATE_PLAN, plan }
};

export const updatePlan = ( plan, msisdn ) => {
    return { type: types.UPDATE_PLAN, msisdn, plan }
};

export const deletePlan = ( msisdn ) => {
    return { type: types.DELETE_PLAN, msisdn }
};
