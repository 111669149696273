import { combineReducers } from 'redux';
import register from './auth/registerReducer';
import login from './auth/loginReducer';
import plans from './planReducer';
import customers from './customerReducer';
import users from './userReducer';
import dependences from './dependenceReducer';
import components from './componentReducer';
import groups from './groupReducer';
import countries from './countriesReducer';
import market from './marketReducer';
import logs from './logsReducer';
import leads from './leadsReducer';
import utils from './utilsReducer';
import synergy from './synergyReducer';
import aurora from './auroraReducer';
import cloudLog from './cloudLogReducer'
import contracts from './contractsReducer';
import auroraSubscription from './auroraSubscriptionReducer';
import auroraTokens from './auroraTokensReducer';
import recurrentPaymentReport from './recurrentPaymentReportReducer';
import statePaymentReport from './statePaymentReportReducer';
import auroraConfigOriginsReducer from './auroraConfigOriginsReducer';
import processNSN from './processNSNreducer';
import five9 from './five9Reducer';
import productListSynergy from './productListSynergyReducer';
import blackListEmail from './blackListEmailReducer';
import generalReportReducer from './generalReportReducer';
import datasource from './dataSourceReducer';
import campaigns from './campaignReducer';
import shopReducer from './shopReducer';
import usersDomain from './usersDomainReducer';
import activationManagement from './activationManagementReducer';

export default combineReducers({
  register,
  login,
  plans,
  customers,
  users,
  dependences,
  components,
  groups,
  countries,
  market,
  logs,
  leads,
  utils,
  synergy,
  aurora,
  cloudLog,
  contracts,
  auroraSubscription,
  auroraTokens,
  recurrentPaymentReport,
  statePaymentReport,
  auroraConfigOriginsReducer,
  processNSN,
  five9,
  productListSynergy,
  blackListEmail,
  generalReportReducer,
  datasource,
  campaigns,
  shopReducer,
  usersDomain,
  activationManagement
});
