import React, { useState, useEffect, Component } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { checkCookie, getCookie } from '../utils/cookies';
import Slayout from '../components/siigers/slayout/slayout';

function SiigersRoute({ component: Component, changeCountry, ...rest }) {

    const location = useLocation();

    return (          
        <Route
            {...rest}
            render={ props =>
                checkCookie() !== null ? (
                    <div className="ContainerSiigo">
                        <Slayout {...rest}/>
                        <Component {...rest} /> 
                    </div>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}

export default SiigersRoute;