import React, { Component, Fragment } from 'react';
import PropTypes, { func } from 'prop-types';
import * as productListSynergyAction from '../../actions/productListSynergy/productListSynergyAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';
import ModalResponse from '../layout/modals/modalResponse';
import Loader from '../tools/loader';
import Swal from 'sweetalert2';


const columns = [
	{
		name: 'Codigo Item',
		selector: 'data.itemCode',
		sortable: true
	},
	{
		name: 'Descripcion',
		selector: 'data.itemDescription',
		sortable: true
	},
	{
		name: 'Descripcion Linea',
		selector: 'data.assormentDescription',
		sortable: true
	},
	{
		name: 'Tipo de producto',
		selector: 'data.productoType',		
		sortable: true
	},

	{
		name: 'Pais',
		selector: 'data.countryPrice',
		sortable: true
	},
	{
		name: 'Precio venta',
		selector: 'data.salesPrice',
		sortable: true
	},
	{
		name: 'Precio total de venta',
		selector: 'data.salesPriceTotal',
		sortable: true,
		filterDDL: true
	},
	
];


let deleteDep = false;
let date = new Date();
date.setHours(-24)
let downloadEnable = false;
class ProductListSynergy extends Component {

	constructor(props) {
		super(props);
		this.state = {
			show: false,
			detailLeads: [],
			commentLeads: [],
			logSynergy: '',
			fromDate: undefined,
			toDate: undefined,
			busyModal: true,
			dataToProcess: '',
			fileToProcess: '',
			processFile: false,
            uploadFile: false,
			downloadHandle: false
		};
	}

	showModal = () => {
		this.setState({ show: this.state.show });
	};


	loadExcel = (file) => {       
       const {files} = file.target
            if (files[0].name.indexOf('.xlsx') != -1 & files[0].name.indexOf('.xls') != -1) {
               
                this.props.actions.sendExcelObjectSynergy(files[0]);

            } else {
                ModalResponse({
                    title: 'Archivo cargado',
                    text: 'Solamente se pueden cargar archivos con extension .xlsx o .xls',
                    res: 400,
                });
            }   
    }


	onHandleDownload = async() => {
		let dataDownload = null;
		let itemString = '';
		const test = await this.props.actions.downloadFileSynergy();	
	};


	downloadExcel = (data) => {		
	  var blob = new Blob([data], { encoding: "UTF-8", type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	
			var a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(blob, {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
			a.download = "exportData" + new Date().toDateString() + ".xlsx";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}	

	render() {	
		let { error, isLoading, productListSynergy, productUploadData } = this.props;
		const btnActions = {
			btnShowDetail: this.showDetail,
			chMassiveActions: false
		};
		let datos = [];
		let status = 0;
		let text = '';
		let data = {};

		if(productUploadData?.status > 300) {
          Swal.fire({
			  title: 'Ocurrio un problema',
			  text: productUploadData?.data?.message,
			  icon: 'error',
			  confirmButtonText: 'Ok'
		  })
		}

		if(productUploadData?.status < 300) {
			Swal.fire({
				title: 'Fue exitoso el proceso de carga de productos',
				text: productUploadData?.data?.message,
				showClass: {
				  popup: 'animate__animated animate__fadeInDown'
				},
				hideClass: {
				  popup: 'animate__animated animate__fadeOutUp'
				}
			  })
		}

		if (productListSynergy && !isLoading) {			
			if (productListSynergy.data != null) {
                // call metodo para descargar el excel
                this.downloadExcel(productListSynergy.data);
				productListSynergy.data = null;
			}
		}

		return (
			<div>				                          
				<div>	
                       <h2 className="bulk-load">LISTA DE PRECIOS</h2>
						{
							this.props.isLoading &&
								<Loader />
						}
					   <div className="rows divInformation">
                                <div className="column">
								{ getAccess(this.props.parentPath, ACCESS.Download_Excel) && !this.props.isLoading ? (
                                        <a onClick={e => this.onHandleDownload()} className="downloadExcelContainer">
                                            <i className="siicon-process icon-excel" />
                                            <span className="description-green"><u>Descargar Lista productos</u></span>
                                        </a>
                               ) : null }

                                    </div>
                                {	
								   getAccess(this.props.parentPath, ACCESS.Bulk_load) && !this.props.isLoading &&  									
                                        this.state.busyModal && !this.props?.productListSynergy?.statusProcess ? (
                                            <div className="column">
                                                <i className="siicon-process icon-load">
                                                    <input className="input-process"
                                                        type='file'
                                                        id="loadExcel"
                                                        accept='.xlsx, .xls'
                                                        onChange={this.loadExcel} />
                                                </i>
                                            </div>
                                        ) : null
                                    }  
                        </div>
						{productUploadData?.data?.isSuccess == false ? (
                           <div className="rows divInformation container">
							   <h2 className="bulk-load title-noty">NOTIFICACION DE ERRORES</h2>
							   <table border="2" cellpadding="5" cellspacing="5">
								   <thead>
										<tr>
											<th>Codigo Producto</th>
											<th>Nombre Producto</th>
											<th>Reporte de Errores</th>
										</tr>
								   </thead>
									<tbody>
										{productUploadData?.data?.errors.map(error => {									
											return <tr>
												<td>{error.codeProduct}</td>
												<td>{error.nameProduct}</td>
												<td>{error.errors.map( error => {
													return <ul>

														<li><span className="font-error">{error.propertyName}: </span>{error.errorMessage}</li>
													</ul>
												})}</td>
											</tr>
										})}

									</tbody>									
								</table>			
						   </div>  
						): null}						      
                </div>
			</div>
							
		);
	}
}

ProductListSynergy.propTypes = {
	actions: PropTypes.object.isRequired,
	productListSynergy: PropTypes.object,
	error: PropTypes.object,
	excelData: PropTypes.object
};

const mapStateToProps = state => ({
    productListSynergy: state.productListSynergy.data,
	productUploadData: state.productListSynergy.updateData,
	error: state.productListSynergy.err,
	isLoading: state.productListSynergy.isLoading,
	excelData: state.synergy.excelData
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(productListSynergyAction, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductListSynergy);

