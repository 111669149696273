import React from 'react';
import './modal.css';
import PropTypes from 'prop-types';

export default class Modal extends React.Component {
  onClose = e => {
    this.props.onClose && this.props.onClose(e);
    // this.props.hideFrom();
  };

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div className="modal-parent">
        <div className="modal" id="modal">
          <div className="h2">
            <i
              onClick={this.onClose}
              className=" siicon icon-close closeModal"
            ></i>
          </div>
            <div>
                <div className="content">{this.props.children}</div>
            </div>
        </div>
      </div>
    );
  }
}
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};
