
import React, { Component } from 'react';
import { SiigoButtonIconAtom } from "@siigo/siigo-button-icon-atom-react";
import { Link } from 'react-router-dom'

import { countryOptions, statesOptions } from '../../data/data'
import { ConsoleView } from 'react-device-detect';

class Sheadnavigator extends Component {

    constructor(props) {
	    super(props);
        console.log('Navigator');
        console.log(JSON.stringify(this.props));
       
        let lastSearch = this.props.travel;
        if (lastSearch == undefined){
            lastSearch = { tpais: 0,  tciudad: 0,  tcantidad: 1, tdesde: '',   thasta: ''}
        }          
        let { tpais, tciudad, tdesde, thasta, tcantidad } = lastSearch;

        let objIndex = countryOptions.findIndex((obj => obj.id == tpais));
        let Pais = (countryOptions[objIndex] == undefined) ? '' : countryOptions[objIndex].label;
        objIndex = statesOptions.findIndex((obj => obj.id == tciudad));
        let Ciudad = (statesOptions[objIndex] == undefined) ? '' : statesOptions[objIndex].label;
            
        tdesde = (tdesde || '').replace('/2023','');
        thasta = (thasta || '').replace('/2023','');

        this.state = {
            caption: `${Pais}: ${tdesde}-${thasta}, ${tcantidad} ${(tcantidad == 1) ? 'huésped' : 'huéspedes'}`
        }
    }

    render(){
        return(
            <>
                <div className='row subsheader'>  
                    <Link to="/siigers/explorar"> 
                        <div className='col-1'>                        
                            <SiigoButtonIconAtom
                                ariaLabel="ariaLabel"
                                color="tertiary"
                                floatingPosition="top-left"
                                href={ this.props.urlReturn }
                                icon="fas fa-angle-left"
                                name=""
                                size="xl"
                                type="button"
                                />
                        </div>
                    </Link>

                    <div className='col'>
                        {/* <label>México, CDMX: 1-5 de dic, 2 huéspedes</label> */}
                        <label>{ this.state.caption }</label>
                    </div>
                </div>

            </>
        )
    }
}

export default Sheadnavigator;