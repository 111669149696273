import Swal from 'sweetalert2';

const ModalConfirmation = (title, confirm, cancel, html, type) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'button green',
      cancelButton: 'button orange'
    },
    buttonsStyling: false
  });
  return swalWithBootstrapButtons
    .fire({
      title: title,
      type: type,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: cancel,
      reverseButtons: true,
      html: html               
    })    
  
};

export default ModalConfirmation;
