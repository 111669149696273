import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as DependenceActions from '../../actions/dependences/dependenceActions';

class DependenceCreate extends Component {
  onHandleCreate = event => {
    event.preventDefault();

    let name = event.target.name.value;
    let description = event.target.description.value;

    const data = {
      name,
      description
    };

    this.props.actions.createDependence(data);
  };

  componentDidMount() {
    document.title = 'Create Dependence';
  }

  render() {
    const { dependence } = this.props;
    if (dependence) {
      if (dependence.data.error === 'Forbidden') {
        return <div>No tiene permiso para este componente</div>;
      }
    }
    return (
      <div>
        {dependence ? <Redirect to="/dependences" /> : null}
        <form onSubmit={this.onHandleCreate}>
          <h3>Crear Dependecia</h3>
          <div className="input-container">
            <i className="sii sii-user icon" />
            <input type="text" name="name" id="name" placeholder="Nombre" />
          </div>
          <div className="input-container">
            <i className="sii sii-user icon" />
            <input
              type="text"
              name="description"
              id="description"
              placeholder="Descripcion"
            />
          </div>
          <div>
            <button className="button green-fill"> Guardar</button>
          </div>
        </form>
      </div>
    );
  }
}

DependenceCreate.propTypes = {
  actions: PropTypes.object.isRequired,
  dependence: PropTypes.object,
  error: PropTypes.object
};

const mapStateToProps = state => ({
  dependence: state.dependences.dependence,
  error: state.dependences.err,
  isLoading: state.dependences.isLoading
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(DependenceActions, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DependenceCreate);
