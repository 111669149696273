function getEndPoint(name) {
  const endPoints = JSON.parse(localStorage.getItem("configInst"));
  console.log("endPoints:: ", endPoints);
  for (let x = 0; x < endPoints.length; x++) {
    if (endPoints[x].name == name) {
      return endPoints[x].url;
    }
  }
}

export default function (api) {
  console.log("api", api);
  return {
    getUsers: (param) =>
      api.get(getEndPoint("provisioningHandler") + "/" + param),
    createUsers: (params) =>
      api.post(getEndPoint("provisioningHandler"), { ...params }, {}),
    editUsers: (params) => 
      api.patch(getEndPoint("provisioningHandler") + "/" + params.userCode, { ...params }, {}),
    deleteUsers: (params) =>
      api.delete(
        getEndPoint("provisioningHandler") + "/" + params.id + "/" + params.item.userCode + "/" + params.CloudTenantID,
        {},
        {}
      ),
  };
}
