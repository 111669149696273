import * as types from '../actions';

const auroraTokensReducer = (state = { isLoading: false }, action = null) => {

    switch (action.type) {
        case types.GET_TOKENS:
            return { ...state, isLoading: true };

        case types.GET_TOKENS_RECEIVED:
            return { ...state, isLoading: false, tokens: action.data }

        case types.GET_TOKENS_FAILED:
            return { ...state, isLoading: false, tokensError: action.error }

        default:
            return state;
    }
};

export default auroraTokensReducer;