import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalResponse from '../layout/modals/modalResponse';
import './style.scss';
import Modal from '../layout/modals/detailSubscription/index';
import { createLog } from '../../actions/auroraConfigProvider/auroraConfigProviderActions';
import ConfigList from './configList';
import { getCookie } from '../../utils/cookies';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';
import { AxiosConfig } from './services';

let save = false;
let access = true;
class CreateConfigProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newProvider: {},
            showCreate: false,
            openCreate: false,
            showSuccess: false,
            openSuccess: false,
            showAccErr: false,
            openAccErr: false,
            disableBtnCreate: false,
            resources: [{ title: "", name: "", state: "Active", value: "", config: "", promo: "" }],
        };
    }

    componentDidMount() {
        document.title = "Crear Proveedor";
        access = getAccess(this.props.parentPath, ACCESS.create_provider);

        if (!access) {
            this.showModalAccErr();
        }
    }


    showModalAccErr = () => {
        this.setState({ showAccErr: !this.state.showAccErr, openAccErr: !this.state.openAccErr });
    }

    focusInput = (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove("inputError");
        e.currentTarget.classList.remove("inputOk");
        e.currentTarget.classList.add('inputFocus');
        if (e.currentTarget.previousSibling) {
            e.currentTarget.previousSibling.classList.remove('inputError');
            e.currentTarget.previousSibling.classList.remove('inputOk');
            e.currentTarget.previousSibling.classList.add('labelModal');
        }
    };

    blurInput = (e, optional = true) => {
        e.preventDefault();
        if (e.currentTarget.value == '' && optional) {
            e.currentTarget.classList.remove("inputOk");
            e.currentTarget.classList.add('inputError');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelOk');
                e.currentTarget.previousSibling.classList.add('labelError');
            }
        } else {
            e.currentTarget.classList.remove("inputError");
            e.currentTarget.classList.add('inputOk');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelError');
                e.currentTarget.previousSibling.classList.add('labelOk');
            }
        }
    };

    renderModalX(text) {
        return (
            <div>
                <div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
                    <h4 >{text}</h4>
                </div>
                <div className="uxDetailContainer" style={{ textAlign: "center" }}>
                    <button
                        style={{ margin: "20px" }}
                        className="orange-fill"
                        type="button"
                        onClick={(e) => { this.props.history.push('/configproviders') }}
                    >Aceptar
                    </button>

                </div>
            </div >
        );
    }

    renderModalConfirm() {
        return (
            <div>
                <div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
                    <h4 >Crear Proveedor</h4>
                </div>
                <div className="uxDetailContainer" style={{ textAlign: "center" }}>
                    <div >
                        <span class="labelUxDetail">¿Esta seguro que desea crear el nuevo proveedor?</span>
                    </div>
                    <br />
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnCreate ? "0.5" : "1.0" }}
                        disabled={this.state.disableBtnCreate}
                        className="orange-fill"
                        type="button"
                        onClick={this.createProvider}
                    >Aceptar
                    </button>
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnCreate ? "0.5" : "1.0" }}
                        className="red-fill"
                        disabled={this.state.disableBtnCreate}
                        type="button"
                        onClick={this.showModalCreateProvider}
                    >Cancelar
                    </button>
                </div>
            </div >
        );
    }

    showModalCreateProvider = (e) => {
        this.setState({ showCreate: !this.state.showCreate });
    }

    showModaSuccess = () => {
        this.setState({ showSuccess: !this.state.showSuccess, openSuccess: !this.state.openSuccess });
    }

    createProvider = () => {
        this.setState({ disableBtnCreate: true });
        this.createProviderApi(this.state.newProvider);
        save = true;
    }

    validValue(value) {
        return value === "" || value === undefined || value === null;
    }

    validarResources() {
        if (this.state.resources.length < 1) return false;
        for (let i = 0; i < this.state.resources.length; i++) {
            var resource = this.state.resources[i];
            console.log(resource);
            if (this.validValue(resource.value) || this.validValue(resource.name) || this.validValue(resource.state) || this.validValue(resource.title)) {
                return false;
            }
        }
        return true;
    }

    handleChange = e => {
        const label = {
            nameConfig: "name",
            valueConfig: "value",
            title: "title",
            state: "state",
            config: "config",
            promo: "promo"
        }
        if (Object.keys(label).includes(e.target.name)) {
            let resources = [...this.state.resources];
            resources[e.target.dataset.id][label[e.target.name]] = e.target.value;
            this.setState({ resources });
        }
    };

    createProviderApi(obj) {
        AxiosConfig.createProviderConfig(obj).then(resp => {
            this.setState({ disableBtnCreate: false });
            this.showModalCreateProvider();
            this.createLogActivity(resp.data);
            this.showModaSuccess();

        }).catch(error => {
            console.log("ERROR AXIOS createProviderApi => ", error);
            this.setState({ disableBtnCreate: false });
            this.showModalCreateProvider();
            ModalResponse({
                title: 'Crear Proveedor',
                text: "Ha ocurrido un problema, inténtelo más tarde.",
                res: 404
            });
        })
    }

    createLogActivity(object) {
        let aditionalData = { created_object: object }

        let params = {
            nameComponent: "Crear Proveedor",
            nameUser: getCookie('name'),
            action: "Create Provider",
            date: (new Date()).toISOString(),
            aditionalData: JSON.stringify(aditionalData),
        }

        this.props.dispatch(createLog(params));
    }

    addResource = e => {
        e.preventDefault();
        this.setState(prevState => ({
            resources: [...prevState.resources, { title: "", name: "", state: "Active", value: "", config: "", promo: "" }]
        }));
    };

    removeResource = e => {
        e.preventDefault();
        let resources = [...this.state.resources];
        resources.splice(e.target.dataset.id, 1);
        this.setState({ resources });
    };

    btnCreateProvider = (e) => {
        e.preventDefault();
        const title = e.target.titleProv.value;
        const name = e.target.nameProv.value;
        const descrip = e.target.description.value;
        const state = e.target.providerState.value;
        const credentials = e.target.credentials.value;
        const country = e.target.country.value;
        const currency = e.target.currency.value;
        const testMode = (e.target.testMode.value == "true") ? true : false;

        if (!this.validValue(title) && !this.validValue(name) && !this.validValue(descrip) && !this.validValue(state) && !this.validValue(credentials) && !this.validValue(country) && !this.validValue(currency) && this.validarResources()) {
            var provider = {
                title,
                name,
                description: descrip,
                state,
                credentials,
                country,
                currency,
                testMode,
                resources: this.state.resources
            }
            this.setState({ newProvider: provider, showCreate: true, openCreate: true });
        }
        else {
            ModalResponse({
                title: '¡Hey!',
                text: 'Te hacen falta campos por ingresar',
                res: 404
            });
        }
    }


    render() {

        return (
            <div style={{ paddingBottom: "5%" }}>
                <h3>Crear Configuración de Proveedor</h3>
                <br />
                <br />
                <form onSubmit={this.btnCreateProvider}>

                    <div className="input-container-accountant">
                        <label>Nombre del Proveedor</label>
                        <input
                            type="text"
                            name="nameProv"
                            id="nameProv"
                            placeholder=""
                            className="inputFilter customEnterText"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurInput(e)}
                            disabled={!access}
                        />
                    </div>
                    <div className="input-container-accountant">
                        <label>Título del Proveedor</label>
                        <input
                            type="text"
                            name="titleProv"
                            id="titleProv"
                            placeholder=""
                            className="inputFilter customEnterText"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurInput(e)}
                            disabled={!access}
                        />
                    </div>
                    <div className="input-container-accountant">
                        <label>Descripción</label>
                        <input
                            type="text"
                            name="description"
                            id="description"
                            placeholder=""
                            className="inputFilter customEnterText"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurInput(e)}
                            disabled={!access}
                        />
                    </div>
                    <div className="input-container-accountant">
                        <label>Credenciales (JSON)</label>
                        <textarea
                            type="text"
                            rows="6"
                            name="credentials"
                            id="credentials"
                            placeholder=""
                            className="inputFilter customEnterText"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurInput(e)}
                            disabled={!access}
                        />
                    </div>
                    <div className="input-container-accountant">
                        <label>Estado del Proveedor</label>
                        <select className="slFilter customEnterText" id="providerState" name="providerState"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurInput(e)}
                            disabled={!access}
                        >
                            <option value="">Ninguno</option>
                            <option className="successState" value="Active">Activo</option>
                            <option className="errorState" value="Inactive">Inactivo</option>
                        </select>
                    </div>
                    <div className="input-container-accountant">
                        <label>País</label>
                        <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder=""
                            className="inputFilter customEnterText"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurInput(e)}
                            disabled={!access}
                        />
                    </div>
                    <div className="input-container-accountant">
                        <label>Moneda</label>
                        <input
                            type="text"
                            name="currency"
                            id="currency"
                            placeholder=""
                            className="inputFilter customEnterText"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurInput(e)}
                            disabled={!access}
                        />
                    </div>
                    <div className="input-container-accountant">
                        <label>Estado Test Mode</label>
                        <select className="slFilter customEnterText" id="testMode" name="testMode"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurInput(e)}
                            disabled={!access}
                        >
                            <option value="">Ninguno</option>
                            <option className="successState" value="true">Activo</option>
                            <option className="errorState" value="false">Inactivo</option>
                        </select>
                    </div>

                    <h3>Configuraciones</h3>
                    <hr className="sep" />
                    <ConfigList
                        access={!access}
                        resources={this.state.resources}
                        change={this.handleChange}
                        remove={this.removeResource}
                        focusInput={this.focusInput}
                        blurInput={this.blurInput}
                    />
                    <br />
                    <br />
                    <div className="center">
                        <button
                            type="button"
                            className="btn btn-outline-primary margin-bottom-btn"
                            onClick={this.addResource}
                            disabled={!access}
                        >
                            <i>+</i> Agregar Configuración
                        </button>
                    </div>
                    <br />
                    <div className="p-top-20 center">
                        <button className="button green-fill" disabled={!access}>Crear Proveedor</button>
                    </div>
                </form>

                <Modal
                    onClose={this.showModalCreateProvider}
                    show={this.state.showCreate}
                    open={this.state.openCreate}>
                    {this.renderModalConfirm()}
                </Modal>

                <Modal
                    onClose={(e) => { this.props.history.push('/configproviders') }}
                    show={this.state.showSuccess}
                    title="Proveedor Creado"
                    open={this.state.openSuccess}>
                    {this.renderModalX("¡El proveedor fue creado exitosamente!")}
                </Modal>
                <Modal
                    onClose={(e) => { this.props.history.push('/configproviders') }}
                    show={this.state.showAccErr}
                    title="Crear Proveedor"
                    open={this.state.openAccErr}>
                    {this.renderModalX("¡No tienes permiso para crear proveedores! Comunicate con el Administrador.")}
                </Modal>

            </div>
        );
    }



}

CreateConfigProvider.propTypes = {
    actions: PropTypes.object.isRequired,
    log: PropTypes.object,
};

const mapStateToProps = state => ({
    log: state.logs.log,
});

/*const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(GroupActions, dispatch)
});*/
export default connect(mapStateToProps)(withRouter(CreateConfigProvider));
