import * as types from '../index';

export const getDataSource = params => {
  return { type: types.GET_DATASOURCE, params };
};

export const addDataSource = params => {
  return { type: types.ADD_DATASOURCE, params };
};

export const getProperties = params => {
  return { type: types.GET_PROPERTIES, params };
};

export const getCollections = params => {
  return { type: types.GET_COLLECTION, params };
};

export const saveData = params => {
  return { type: types.SAVE_DATA, params };
};

export const editDataSource = (id, data) => {
  return { type: types.EDIT_DATASOURCE, id, data }
}

export const getDetailDataSource = id => {
  return { type: types.GET_DETAIL_DATASOURCE, id }
}

export const reportData = params => {
  return { type: types.REPORT_DATA, params };
};