import Constants from "../components/shop/constants";

function CampaignConfigurationsModel(configuration, destination, type) {
    if (this.isValid(configuration)) {
      this.configurations = {};
      this.configurations.discount = {
        value: configuration.discountValue,
        type: Constants.CAMPAIGN_DISCOUNT_TYPE.PERCENTAGE.VALUE,
      };
      this.configurations.period = {
        type: configuration.periodType,
      };
      this.configurations.products = configuration.products;
      this.configurations.featured = configuration.featured || false;
      this.configurations.order = configuration.order;
      if (
        [Constants.CAMPAIGN_DESTINATIONS.SIIGO_COM.VALUE].includes(destination)
      )
        this.configurations.period = {
          type: Constants.CAMPAIGN_PERIOD.ANUAL.VALUE,
        };

      if ([Constants.CAMPAIGN_TYPES.DISCOUNT_CODE.VALUE].includes(type))
        this.configurations.period = null;
    }
}

CampaignConfigurationsModel.prototype.isValid = function (configuration) {
    if (!configuration.hasOwnProperty("periodType"))
        throw new Error("Missing periodType property");
    if (!configuration.hasOwnProperty("discountValue"))
        throw new Error("Missing discountValue property");
    if (!configuration.hasOwnProperty("products"))
        throw new Error("Missing product codes property");
    if (!configuration.hasOwnProperty("featured"))
        throw new Error("Missing featured property");
    return true;
}

function CampaignConfigurationsDecorator() { }

CampaignConfigurationsDecorator.prototype.steps = [];

CampaignConfigurationsDecorator.prototype.setInstallmentsPeriod = function (
    campaignType,
    installments
) {
    this.steps.push(() => {
        if (
          [Constants.CAMPAIGN_TYPES.COMBO.VALUE].includes(campaignType) &&
          [Constants.CAMPAIGN_PERIOD.INSTALLMENTS.VALUE].includes(
            this.configurations.period?.type
          )
        ) {
          if (!installments.number || !installments.minimumValue) {
            throw new Error(
              "Missing installments properties: number or minimumValue"
            );
          }
          this.configurations.period = {
            type: this.configurations.period.type,
            installments
          };
        }
        return this;
    });
    return this;
};

function CampaignConfigurationsBuilder() { }

CampaignConfigurationsBuilder.prototype.build = function () {
    for (const step of this.steps) {
        step();
    }
    return this.configurations;
}

CampaignConfigurationsBuilder.prototype.decorate = function (configuration) {
    const decoratedBuilder = Object.create(
        Object.assign(
            {},
            configuration,
            CampaignConfigurationsBuilder.prototype,
            CampaignConfigurationsDecorator.prototype
        )
    );
    return decoratedBuilder;
}

export {
    CampaignConfigurationsModel,
    CampaignConfigurationsBuilder
};