import { gql } from 'graphql-request';

export const getAllUsersQuery = gql`
    query GetUser(
        $tenantId: String!
    ) {
        GetUser(
            tenantId: $tenantId
        ) {
            users {
                id
                userLogin
                password
                companies {
                    isActive
                    tenantId
                    userId
                    basicInformation {
                        email
                        firstName
                        fullName
                        idType
                        identification
                        lastName
                        phone
                    }
                    systemParameters {
                        userLicenseType
                        isAdministrator
                    }
                }
            }
        }
    }`;