import React, { Component } from 'react';
import PassReset from '../../components/auth/passReset';
import SiigoLogo from '../../assets/img/siigo-ecommerce2x.png';
import './auth.css';

class Registration extends Component {
    render() {
        return (
            <div className="icommerce">
                <header className="ic-header">
                    <div className="logoContainer">
                        <img src={SiigoLogo} alt="Siigo iCommerce"/>
                    </div>
                    <PassReset />
                </header>
            </div>
        );
    }
}

export default Registration;
