import React, { Component } from 'react';
import ComponentCreate from '../../components/componentss/create';

class ComponentCreateContainer extends Component {
  render() {
    return (
      <div>
        <ComponentCreate />
      </div>
    );
  }
}

export default ComponentCreateContainer;
