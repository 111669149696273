import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './marketwebAccountant.scss';

const MarketwebAccountantComponent = (props) => {
  useEffect(() => {
    // componentDidMount logic here
  }, []);

  return (
    <div>
      <div id="single-spa-application:marketwebAccountantPortal" className="marketwebAccountantPortal"></div>
    </div>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MarketwebAccountantComponent);
