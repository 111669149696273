import React, { Component } from 'react';
import DataReport from '../../components/datareport';

class DataReportContainer extends Component {
  

	render() {

		return (	
			<DataReport 
				breadcrumbs = {[this.props.location.pathname]}
			/>

		);
	}
}

export default DataReportContainer;
