import React, { Component } from 'react';
import GeneralReport from '../../components/generalReport/index';
import Layout from '../layout';
import { checkCookie } from '../../utils/cookies';
import { Redirect } from 'react-router-dom';

const MENU_ACCESS = 'menuAccess';
class GeneralReportContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const id = this.props.match.params.id;
    return (
      <div>
        {checkCookie() !== null ? (
          <div>
            <Layout route={'/generalReport'} />
            <section className={'container'}>
              <div className="section">
                {localStorage.getItem(MENU_ACCESS).includes('generalReport') ? (
                  <GeneralReport parentPath={'/generalReport'}  id={id} />
                ) : (
                  <p>
                    No cuenta con los permisos para acceder al componente{' '}
                    <b>group/edit</b>, por favor comuníquese con el
                    administrador.
                  </p>
                )}
              </div>
            </section>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: this.props.location }
            }}
          />
        )}
      </div>
    );
  }
}

export default GeneralReportContainer;