import { takeLatest, all, take, call, actionChannel } from "redux-saga/effects";

/* ----------------- Types ----------------- */

import * as types from "../actions";

/* ----------------- Sagas ----------------- */

import { registerSaga, loginSaga } from "./authSaga";
import {
  getPlans,
  getPlan,
  createPlan,
  updatePlan,
  deletePlan,
} from "./plansSaga";
import {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer,
  deleteCustomer,
} from "./customersSaga";
import {
  getUsers,
  getUser,
  getPerfil,
  setAvatar,
  createUser,
  updateUser,
  deleteUser,
  getMenuUser,
} from "./usersSaga";
import {
  setCampaign,
  getCampaignUrl
} from "./campaigSaga";

import {
  saveMenuActivate,
  removeMenuActivate
} from "./activationManagementSaga";

import {
  getDependences,
  getDependence,
  createDependence,
  updateDependence,
  deleteDependence,
} from "./dependencesSaga";
import {
  getComponents,
  getComponent,
  createComponent,
  updateComponent,
  deleteComponent,
} from "./componentSaga";
import {
  getGroups,
  getGroup,
  createGroup,
  updateGroup,
  deleteGroup,
} from "./groupSaga";

import { getCountries, getConfig } from "./countriesSaga";
import {
  getCompanies,
  emailGetCompanies,
  setPlan,
  createLog,
  getTask,
  createCompany,
  modifyCompany,
  getCompanyLogs,
  changeCompanyAddonState,
  getPackage,
  addPackage,
  deleteBalance,
  getBalanePack,
  getPackageDetail,
  getUsersCompanyMarket,
  editUsersCompanyMarket,
  deleteUsersCompanyMarket,
  createUsersCompanyMarket,
  addPayrollPackage,
  getPayrollPackBalance,
  getPayrollDetail,
  getAddOnsNE,
  deletePayrollPackage,
  verifyNitEdition
} from "./marketSaga";
import { getLogs, getQueryLogs, createLogs } from "./logsSaga";
import { getLeads } from "./leadsSaga";
import {
  getNotifiactions,
  createNotifiactions,
  editNotifiactions,
  deleteNotifiactions,
  getAccountStatemets,
  sendEmail,
  getAccountant,
  modifyAccountant,
  sendEmailAccountant,
  sendExcelObject,
  resendMassiveEmails,
} from "./synergySaga";

import { getShortedUrl } from "./auroraSaga";
import { getCloudLogs } from "./cloudLogSaga";
import { getContracts, resendInvoiceEmail } from "./contractsSaga";
import { getSubscriptions, deleteSubscription } from "./auroraSubscriptionSaga";
import { getTokens } from './auroraTokensSaga';
import {
  createConfigOrigin,
  updateConfigOrigin,
  getConfigOrigin,
  getConfigOrigins,
} from "./auroraConfigOriginsSaga";
import { getPayments } from "./recurrentPaymetReportSaga";
import { getStatePayments } from "./statePaymetReportSaga";
import { processGifts, downloadFile } from "./processNSNsaga";
import {
  getCountriesFive9,
  addCountriesFive9,
  editCountriesFive9,
  deleteCountriesFive9,
  getRulesFive9,
  addRulesFive9,
  editRulesFive9,
  deleteRulesFive9,
  setLog,
} from "./five9Saga";
import { filterDataBySource, getConfigReport, downloadReportDataBySource } from './generalReportSaga'

import { sendExcelObjectSynergy, downloadFileSynergy } from "./productListSynergySaga";

import { getBlackListEmail, removeBlackListEmail } from './blackListEmailSaga';

import {
  getDataSource,
  addDataSource,
  getProperties,
  getCollection,
  saveCollection,
  getReport,
  getDetailtDataSource,
  editDataSource
} from "./dataSourceSagas";

import { getProducts, postCampaign, getCampaigns, patchCampaignState } from "./shopSaga";
import { createUserUsersDomain, deleteUserUsersDomain, getUsersUsersDomain, updateUserUsersDomain } from './usersDomainSaga';

/* ----------------- API ----------------- */

function* generalReportWatcher() {
  yield takeLatest(types.FILTER_DATA_BY_SOURCE, filterDataBySource);
  yield takeLatest(types.GET_CONFIG_GENERAL_REPORT, getConfigReport);
  yield takeLatest(types.DOWNLOAD_REPORT_DATA_BY_SOURCE, downloadReportDataBySource);
}

function* auroraSubscriptionActionWatcher() {
  yield takeLatest(types.GET_SUBSCRIPTIONS, getSubscriptions);
  yield takeLatest(types.DELETE_SUBSCRIPTION, deleteSubscription);
  yield takeLatest(types.CREATE_LOG, createLogs);
  yield takeLatest(types.SEND_EXCEL_OBJECT, sendExcelObject);
}

function* auroraTokensActionWatcher() {
  yield takeLatest(types.GET_TOKENS, getTokens);
}

function* auroraConfigOriginsWatcher() {
  yield takeLatest(types.GET_ORIGINS, getConfigOrigins);
  yield takeLatest(types.GET_ORIGIN, getConfigOrigin);
  yield takeLatest(types.CREATE_LOG, createLogs);
  yield takeLatest(types.CREATE_ORIGIN, createConfigOrigin);
  yield takeLatest(types.UPDATE_ORIGIN, updateConfigOrigin);
}

function* recurrentPaymetReportActionWatcher() {
  yield takeLatest(types.GET_RECURRENTPAYMENTS, getPayments);
  yield takeLatest(types.SEND_EXCEL_OBJECT, sendExcelObject);
}

function* statePaymetReportActionWatcher() {
  yield takeLatest(types.GET_STATEPAYMENTS, getStatePayments);
}

function* auroraConfigProviderActionWatcher() {
  yield takeLatest(types.CREATE_LOG, createLogs);
}

function* authActionWatcher() {
  yield takeLatest(types.REGISTER_USER, registerSaga);
  yield takeLatest(types.LOGIN_USER, loginSaga);
}

export function* plansWatcher() {
  const subChannel = yield actionChannel(types.GET_PLANS);
  while (true) {
    const action = yield take(subChannel);
    yield call(getPlans, action);
  }
}

function* planActionWatcher() {
  yield takeLatest(types.GET_PLAN, getPlan);
  yield takeLatest(types.CREATE_PLAN, createPlan);
  yield takeLatest(types.UPDATE_PLAN, updatePlan);
  yield takeLatest(types.DELETE_PLAN, deletePlan);
}

export function* customersWatcher() {
  const subChannel = yield actionChannel(types.GET_CUSTOMERS);
  while (true) {
    const action = yield take(subChannel);
    yield call(getCustomers, action);
  }
}

function* customerActionWatcher() {
  yield takeLatest(types.GET_CUSTOMER, getCustomer);
  yield takeLatest(types.CREATE_CUSTOMER, createCustomer);
  yield takeLatest(types.UPDATE_CUSTOMER, updateCustomer);
  yield takeLatest(types.DELETE_CUSTOMER, deleteCustomer);
}

/*export function* usersWatcher() {
  const subChannel = yield actionChannel(types.GET_USERS);
  while (true) {
    const action = yield take(subChannel);
    yield call(getUsers, action);
  }
}*/

function* dependenceActionWatcher() {
  yield takeLatest(types.GET_DEPENDENCE, getDependence);
  yield takeLatest(types.GET_DEPENDENCES, getDependences);
  yield takeLatest(types.CREATE_DEPENDENCE, createDependence);
  yield takeLatest(types.UPDATE_DEPENDENCE, updateDependence);
  yield takeLatest(types.DELETE_DEPENDENCE, deleteDependence);
}

function* componentActionWatcher() {
  yield takeLatest(types.GET_COMPONENT, getComponent);
  yield takeLatest(types.GET_COMPONENTS, getComponents);
  yield takeLatest(types.CREATE_COMPONENT, createComponent);
  yield takeLatest(types.UPDATE_COMPONENT, updateComponent);
  yield takeLatest(types.DELETE_COMPONENT, deleteComponent);
}

function* countriesActionWatcher() {
  yield takeLatest(types.GET_COUNTRIES, getCountries);
  yield takeLatest(types.GET_CONFIG, getConfig);
}

function* marketActionWatcher() {
  yield takeLatest(types.GET_COMPANIES, getCompanies);
  yield takeLatest(types.EMAIL_GET_COMPANIES, emailGetCompanies);
  yield takeLatest(types.SET_PLAN, setPlan);
  yield takeLatest(types.SET_LOG, createLog);
  yield takeLatest(types.GET_TASK, getTask);
  yield takeLatest(types.SET_COMPANY, createCompany);
  yield takeLatest(types.MODIFY_COMPANY, modifyCompany);
  yield takeLatest(types.GET_COMPANY_LOGS, getCompanyLogs);
  yield takeLatest(types.CHANGE_COMPANY_ADDON_STATE, changeCompanyAddonState);
  yield takeLatest(types.GET_PACKAGE, getPackage);
  yield takeLatest(types.ADD_PACKAGE, addPackage);
  yield takeLatest(types.DELETE_BALANCE, deleteBalance);
  yield takeLatest(types.GET_BALANCE_PACK, getBalanePack);
  yield takeLatest(types.GET_PACKAGE_DETAIL, getPackageDetail);

  yield takeLatest(types.GET_USERS_COMPANY_MARKET, getUsersCompanyMarket);
  yield takeLatest(types.EDIT_USER_COMPANY_MARKET, editUsersCompanyMarket);
  yield takeLatest(types.DELETE_USER_COMPANY_MARKET, deleteUsersCompanyMarket);
  yield takeLatest(types.CREATE_USER_COMPANY_MARKET, createUsersCompanyMarket);

  yield takeLatest(types.ADD_PAYROLL_PACKAGE, addPayrollPackage);
  yield takeLatest(types.GET_PAYROLL_PACKAGE_BALANCE, getPayrollPackBalance);
  yield takeLatest(types.GET_PAYROLL_PACKAGE_DETAIL, getPayrollDetail);
  yield takeLatest(types.GET_ADDONS, getAddOnsNE);
  yield takeLatest(types.DELETE_PAYROLL_PACKAGE, deletePayrollPackage);

  yield takeLatest(types.VERIFY_NIT_EDITION, verifyNitEdition);
}

function* groupActionWatcher() {
  yield takeLatest(types.GET_GROUP, getGroup);
  yield takeLatest(types.GET_GROUPS, getGroups);
  yield takeLatest(types.CREATE_GROUP, createGroup);
  yield takeLatest(types.UPDATE_GROUP, updateGroup);
  yield takeLatest(types.DELETE_GROUP, deleteGroup);
}

function* usersActionWatcher() {
  yield takeLatest(types.GET_USER, getUser);
  yield takeLatest(types.GET_USERS, getUsers);
  yield takeLatest(types.GET_USER_PERFIL, getPerfil);
  yield takeLatest(types.SET_USER_AVATAR, setAvatar);
  yield takeLatest(types.CREATE_USER, createUser);
  yield takeLatest(types.UPDATE_USER, updateUser);
  yield takeLatest(types.DELETE_USER, deleteUser);
  yield takeLatest(types.GET_MENU_USER, getMenuUser);
}
function* campaignActionWatcher() {
  yield takeLatest(types.SAVE_CAMPAIGN, setCampaign);
  yield takeLatest(types.GET_CAMPAIGN, getCampaignUrl);
}

function* activationManagementWatcher() {
  yield takeLatest(types.SAVE_ACTIVATION_MANAGEMENT, saveMenuActivate);
  yield takeLatest(types.REMOVE_ACTIVATION_MANAGEMENT, removeMenuActivate);
}

function* logsActionWatcher() {
  yield takeLatest(types.GET_LOGS, getLogs);
  yield takeLatest(types.GET_QUERY_LOGS, getQueryLogs);
}
function* leadsActionWatcher() {
  yield takeLatest(types.GET_LEADS, getLeads);
  yield takeLatest(types.SEND_EXCEL_OBJECT, sendExcelObject);
}

function* productListSynergyWatcher() {

  yield takeLatest(types.SEND_EXCEL_OBJECT_SYNERGY, sendExcelObjectSynergy);
  yield takeLatest(types.DOWNLOAD_FILE_SYNERGY, downloadFileSynergy);
}


function* notificationsActionWatcher() {
  yield takeLatest(types.GET_NOTIFICATIONS, getNotifiactions);
  yield takeLatest(types.CREATE_NOTIFICATION, createNotifiactions);
  yield takeLatest(types.EDIT_NOTIFICATION, editNotifiactions);
  yield takeLatest(types.DELETE_NOTIFICATION, deleteNotifiactions);
}

function* accountStatemetsActionWatcher() {
  yield takeLatest(types.GET_ACCOUNTS, getAccountStatemets);
  yield takeLatest(types.SEND_EMAIL, sendEmail);
}

function* accountantsActionWatcher() {
  yield takeLatest(types.GET_ACCOUNTANTS, getAccountant);
  yield takeLatest(types.MODIFY_ACCOUNTANT, modifyAccountant);
  yield takeLatest(types.SEND_MASSIVE_EMAIL_ACCOUNTANT, resendMassiveEmails);
  yield takeLatest(types.SEND_EMAIL_ACCOUNTANT, sendEmailAccountant);
  yield takeLatest(types.SEND_EXCEL_OBJECT, sendExcelObject);
}

function* auroraWatcher() {
  yield takeLatest(types.GET_SHORTED_URL, getShortedUrl);
}
function* contractsWatcher() {
  yield takeLatest(types.GET_INVOICES, getContracts);
  yield takeLatest(types.RESEND_INVOICE_EMAIL, resendInvoiceEmail);
  yield takeLatest(types.SEND_EXCEL_OBJECT, sendExcelObject);
}

function* cloudLogWatcher() {
  yield takeLatest(types.GET_CLOUD_LOGS, getCloudLogs);
  yield takeLatest(types.SEND_EXCEL_OBJECT, sendExcelObject);
}

function* processNSNWatcher() {
  yield takeLatest(types.PROCESS_FILE_NSN, processGifts);
  yield takeLatest(types.DOWNLOAD_FILE_NSN, downloadFile);
}

function* five9Watcher() {
  /********* FIVE9 COUNTRIES *********/
  yield takeLatest(types.GET_FIVE9_COUNTRY, getCountriesFive9);
  yield takeLatest(types.ADD_FIVE9_COUNTRY, addCountriesFive9);
  yield takeLatest(types.EDIT_FIVE9_COUNTRY, editCountriesFive9);
  yield takeLatest(types.DELETE_FIVE9_COUNTRY, deleteCountriesFive9);
  yield takeLatest(types.SET_LOG_FIVE9_COUNTRY, setLog);
  /********* FIVE9 RULES *********/
  yield takeLatest(types.GET_FIVE9_RULES, getRulesFive9);
  yield takeLatest(types.ADD_FIVE9_RULES, addRulesFive9);
  yield takeLatest(types.EDIT_FIVE9_RULES, editRulesFive9);
  yield takeLatest(types.DELETE_FIVE9_RULES, deleteRulesFive9);
  yield takeLatest(types.SET_LOG_FIVE9_RULES, setLog);
}

function* mailActionsWatcher() {
  yield takeLatest(types.GET_BLACK_LIST_EMAIL, getBlackListEmail);
  yield takeLatest(types.DELETE_BLACK_LIST_EMAIL, removeBlackListEmail)
}

function* dataSourceWatcher() {
  yield takeLatest(types.GET_DATASOURCE, getDataSource);
  yield takeLatest(types.GET_PROPERTIES, getProperties);
  yield takeLatest(types.ADD_DATASOURCE, addDataSource);
  yield takeLatest(types.GET_COLLECTION, getCollection);
  yield takeLatest(types.SAVE_DATA, saveCollection);
  yield takeLatest(types.REPORT_DATA, getReport);
  yield takeLatest(types.GET_DETAIL_DATASOURCE, getDetailtDataSource);
  yield takeLatest(types.EDIT_DATASOURCE, editDataSource);
}

function* shopWatcher() {
  yield takeLatest(types.GET_PRODUCTS, getProducts);
  yield takeLatest(types.POST_CAMPAIGN, postCampaign);
  yield takeLatest(types.GET_CAMPAIGNS, getCampaigns);
  yield takeLatest(types.PATCH_CAMPAIGNSTATE, patchCampaignState);
}

function* usersDomainWatcher() {
  yield takeLatest(types.GET_USERS_USERS_DOMAIN, getUsersUsersDomain);
  yield takeLatest(types.CREATE_USER_USERS_DOMAIN, createUserUsersDomain);
  yield takeLatest(types.UPDATE_USER_USERS_DOMAIN, updateUserUsersDomain);
  yield takeLatest(types.DELETE_USER_USERS_DOMAIN, deleteUserUsersDomain);
}

export default function* root() {
  yield all([
    authActionWatcher(),
    planActionWatcher(),
    plansWatcher(),
    customerActionWatcher(),
    customersWatcher(),
    usersActionWatcher(),
    dependenceActionWatcher(),
    componentActionWatcher(),
    groupActionWatcher(),
    countriesActionWatcher(),
    marketActionWatcher(),
    logsActionWatcher(),
    leadsActionWatcher(),
    notificationsActionWatcher(),
    accountStatemetsActionWatcher(),
    accountantsActionWatcher(),
    auroraWatcher(),
    cloudLogWatcher(),
    contractsWatcher(),
    auroraSubscriptionActionWatcher(),
    recurrentPaymetReportActionWatcher(),
    statePaymetReportActionWatcher(),
    auroraConfigProviderActionWatcher(),
    processNSNWatcher(),
    auroraConfigOriginsWatcher(),
    five9Watcher(),
    auroraTokensActionWatcher(),
    productListSynergyWatcher(),
    mailActionsWatcher(),
    generalReportWatcher(),
    dataSourceWatcher(),
    campaignActionWatcher(),
    shopWatcher(),
    usersDomainWatcher(),
    activationManagementWatcher(),
  ]);
}
