import React, { Fragment, useState } from 'react';
import Modal from "./modal";

const ModalRules = ({ props, changeStateModal, showModal, loadRuleInformation, data = { }, action , listCountries}) => {
    return (
        < Fragment >
            {
                showModal &&
                <Modal
                    setShowModal={changeStateModal}
                    props={props}
                    loadRuleInformation={loadRuleInformation}
                    data={data}
                    action={action}
                    listCountries = {listCountries}
                >
                </Modal>
            }
        </Fragment >
    );
}

export default ModalRules;