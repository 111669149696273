import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ES_MX from "../assets/i18n/es-MX.json";
import ES from "../assets/i18n/es-ES.json";
import EN from "../assets/i18n/en.json";
import CL from "../assets/i18n/es-CL.json";
import EC from "../assets/i18n/es-EC.json";
import PE from "../assets/i18n/es-PE.json";

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: true,
		fallbackLng: 'co',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			"mx": ES_MX,
			"co": ES,
			"ec": EC,
			"en": EN,
			"cl": CL,
			"pe": PE
		}
	});

	


export default i18n;