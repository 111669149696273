import React, { Component } from 'react';
import Layout from '../layout';
import { checkCookie } from '../../utils/cookies';
import { Redirect } from 'react-router-dom';
import CompanyLogs from '../../components/market/companyLogs';

class CompanyLogsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const id = this.props.match.params.id;
		return (
			<div>
				{checkCookie() !== null ? (
					<div>
						<Layout route={'/market'} />
						<section className={'container'}>
							<div className="section">
								<CompanyLogs parentPath={'/market'} id={id} />
							</div>
						</section>
					</div>
				) : (
					<Redirect
						to={{
							pathname: '/',
							state: { from: this.props.location }
						}}
					/>
				)}
			</div>
		);
	}
}

export default CompanyLogsContainer;
