import React, { Component } from 'react';
import CampaignSettingsRules from '../../components/five9/w2cRules/index'
class W2cCountryContainer extends Component {
  render() {
    return (
      <div>
        <CampaignSettingsRules parentPath={this.props.path} />
      </div>
    );
  }
}

export default W2cCountryContainer;