import React from 'react';
import './modal.css';
import PropTypes from 'prop-types';

export default class ModalProcess extends React.Component {

	onClose = (e) => {
		this.props.onClose && this.props.onClose(e);
        // this.props.hideForm();
	};

	render() {
		console.log(this.props.uploadFile)
		if (!this.props.uploadFile) {
		return null;
		}
		return (
		<div className="modal-parent">
			<div className="modal" id="modal">
			<div className="h2">
            <span className="title-process">{this.props.title}</span>{' '}
				<i
				onClick={this.onClose}
				className=" siicon icon-close closeModal"
				></i>
			</div>
			<div>
				<div className="content">{this.props.children}</div>
            </div>
			</div>
		</div>
		);
	}
}
ModalProcess.propTypes = {
  onClose: PropTypes.func.isRequired,
  uploadFile: PropTypes.bool.isRequired,
};
