import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../tools/loader';
import ModalResponse from '../layout/modals/modalResponse';

import { updateUser, getUser } from '../../actions/users/usersAction';
import Groups from './groups';
let update = false;
let actGroup = true;
class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupsUser: ['']
    };
  }

  onHandleEdit = event => {
    event.preventDefault();
    let firstName = event.target.firstName.value;
    let lastName = event.target.lastName.value;
    let email = event.target.email.value;
    let cellphone = event.target.cellphone.value;
    let phone = event.target.phone.value;
    let identification = event.target.identification.value;
    let estado = event.target.estado.value;
    let groupUser = this.state.groupsUser;
    const data = {
      firstName,
      lastName,
      email,
      cellphone,
      phone,
      identification,
      estado,
      groupUser
    };
    this.props.dispatch(updateUser(data, this.props.id));
    update = true;
  };

  handleChange = e => {
    if (['group'].includes(e.target.name)) {
      let groupsUser = [...this.state.groupsUser];
      groupsUser[e.target.dataset.id] = e.target.value;
      this.setState({ groupsUser });
    }
  };

  actGroups = groupsUser => {
    if (actGroup) {
      this.setState({ groupsUser });
      actGroup = false;
    }
  };

  addGroups = e => {
    this.setState(prevState => ({
      groupsUser: [...prevState.groupsUser, '']
    }));
  };

  removeGroup = e => {
    let groupsUser = [...this.state.groupsUser];
    groupsUser.splice(e.target.dataset.id, 1);
    this.setState({ groupsUser });
  };

  componentDidMount() {
    document.title = 'Edit User';
    actGroup = true;
    this.props.dispatch(getUser(this.props.id));
  }

  render() {
    let firstName,
      lastName,
      email,
      cellphone,
      identification,
      estado,
      phone = null;
    let groupsUser = [];
    let text = '';
    const { isLoading, user } = this.props;

    if (user) {
      if (user.data.error === 'Forbidden') {
        return <div>No tiene permiso para este componente</div>;
      }

      firstName = user.data.firstName;
      lastName = user.data.lastName;
      email = user.data.email;
      cellphone = user.data.cellphone;
      phone = user.data.phone;
      identification = user.data.identification;
      estado = user.data.estado;
      groupsUser = user.data.groupUser;
      if (actGroup && !isLoading) {
        this.actGroups(groupsUser);
      }

      if (user.config.method === 'put' && update && !isLoading) {
        if (user.status === 200) {
          this.props.history.push('/users');
        } else if (update) {
          text = 'No fue posible guardar el Usuario';
        }
        update = false;
      }
    }

    return (
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {text !== '' ? (
              <ModalResponse title={'Editar!'} text={text} res={404} />
            ) : null}
            <form onSubmit={this.onHandleEdit}>
              <h3>Editar Usuario</h3>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <input
                  type="text"
                  defaultValue={firstName}
                  name="firstName"
                  id="firstName"
                  placeholder="Nombre"
                />
              </div>
              <div className="input-container">
                <input
                  type="text"
                  defaultValue={lastName}
                  name="lastName"
                  id="lastName"
                  placeholder="Apellido"
                />
              </div>
              <div className="input-container">
                <input
                  type="text"
                  defaultValue={identification}
                  name="identification"
                  id="identification"
                  placeholder="Identificación"
                />
              </div>
              <div className="input-container">
                <input
                  type="text"
                  defaultValue={estado}
                  name="estado"
                  id="estado "
                  placeholder="Estado"
                />
              </div>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <input
                  type="text"
                  defaultValue={email}
                  name="email"
                  id="email"
                  placeholder="Correo Electrónico"
                  disabled
                />
              </div>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <input
                  type="text"
                  defaultValue={cellphone}
                  name="cellphone"
                  id="cellphone"
                  placeholder="Celular"
                />
              </div>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <input
                  type="text"
                  defaultValue={phone}
                  name="phone"
                  id="phone"
                  placeholder="Telefono"
                />
              </div>
              <h3>Grupos</h3>
              <hr />
              <Groups
                groupsUser={this.state.groupsUser}
                change={this.handleChange}
                remove={this.removeGroup}
              />
              <div className="center">
                <button
                  type="button"
                  className="btn btn-outline-primary margin-bottom-btn"
                  onClick={this.addGroups}
                >
                  <i className="fa fa-plus"></i> Agregar Grupo
                </button>
              </div>
              <div className="p-top-20 center">
                <button className="button green-fill">Guardar</button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

UserEdit.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object,
  error: PropTypes.object
};

const mapStateToProps = state => ({
  user: state.users.user,
  error: state.users.err,
  isLoading: state.users.isLoading
});

/*const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(UserActions, dispatch)
});*/
export default connect(mapStateToProps)(withRouter(UserEdit));
