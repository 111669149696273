import { object, string } from 'yup';

export const changeUserCodeSchema = (currentEmail) => object().shape({
    userCode: string()
        .required("Este campo es requerido")
        .email("Comprueba el correo")
        .max(100, "Comprueba el correo")
        .matches(/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+\.[a-zA-Z0-9-_.]+$/, "Comprueba el correo")
        .test("email", "El correo nuevo no puede ser igual al actual", (value) => value !== currentEmail)
});
