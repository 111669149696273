import React, { Component } from 'react';
import CampaignSetting from '../../components/five9/w2cCountry/index';

class W2cCountryContainer extends Component {
  render() {
    return (
      <div>
        <CampaignSetting parentPath={this.props.path} />
      </div>
    );
  }
}

export default W2cCountryContainer;