import * as types from '../actions';

const invoicesReducer = (state = { isLoading: false }, action = null) => {
  switch (action.type) {
    case types.GET_INVOICES:
      return { ...state, isLoading: true };
    case types.INVOICES_RECEIVED:
      return {
        ...state,
        isLoading: false,
        invoices: action.data,
      };
    case types.INVOICES_REQUEST_FAILED:
      return { ...state, isLoading: false, invoices: action.error };

    case types.RESEND_INVOICE_EMAIL:
      return { ...state, isLoadingResendEmail: true };
    case types.RESEND_INVOICE_EMAIL_RECEIVED:
      return {
        ...state,
        isLoadingResendEmail: false,
        dataResendInvoice: action.data,
      };
    case types.RESEND_INVOICE_EMAIL_FAILED:
      return {
        ...state,
        isLoadingResendEmail: false,
        dataResendInvoice: action.error,
      };

    default:
      return state;
  }
};

export default invoicesReducer;
