import * as types from '../actions';

const campaignReducer = (state = { isLoading: true }, action = null) => {
  switch (action.type) {
    case types.GET_CAMPAIGN:
      return { ...state, isLoading: true };
      case types.GET_CAMPAIGN_RECEIVED:
      return { ...state, isLoading: false, imageurl: action.data };
    case types.SAVE_CAMPAIGN:
      return { ...state, isLoading: true };
    case types.SAVE_CAMPAIGN_RECEIVED:
      return { ...state, isLoading: false, imageResponse: action.data };
    case types.SAVE_CAMPAIGN_FAILED:
      return { ...state, isLoading: false, error: action.error };
    case types.CLEAR_CAMPAIGN_STATE:
      return { ...state, isLoading: false, error: null, imageResponse: null };
    default:
      return state;
  }
};

export default campaignReducer;
