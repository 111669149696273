import * as types from '../index';

export const getCountriesFive9 = (params) => {
  return { type: types.GET_FIVE9_COUNTRY, params };
};
export const addCountriesFive9 = (params) => {
  return { type: types.ADD_FIVE9_COUNTRY, params };
};
export const editCountriesFive9 = (params) => {
  return { type: types.EDIT_FIVE9_COUNTRY, params };
};
export const deleteCountriesFive9 = (params) => {
  return { type: types.DELETE_FIVE9_COUNTRY, params };
};
export const setLog = (params) => {
  return { type: types.SET_LOG_FIVE9_COUNTRY, params };
};