import React, { Component } from 'react';

export const paymentStates = {
    RESPONSE_RECEIVED_APPROVED: "Aprobada",
    RESPONSE_RECEIVED_DECLINED: "Declinada",
    RESPONSE_RECEIVED_ERROR: "Fallida",
}

export const paymentStatesClasses = {
    RESPONSE_RECEIVED_APPROVED: "successState",
    RESPONSE_RECEIVED_DECLINED: "warningState",
    RESPONSE_RECEIVED_ERROR: "errorState",
}

export class PaymentStates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render(){
        
        var state = this.renderStates();
        console.log(this.props.col);
        return (
            <span className={state.clase === undefined ? "" : state.clase}>
                {state.value === undefined ? "N/A" : state.value}
            </span>
        );
    }


    renderStates() {
        
        var selector = this.props.selector;
        var paymentState = this.props.paymentState;
        var valueSelector = this.props.valueSelector;

        var resp = {
            value: "",
            clase: "",
        };

        selector = selector === undefined ? "" : selector;
        valueSelector = valueSelector === undefined ? "N/A" : String(valueSelector);

        if (paymentState) {

            if(selector === "data.auroraOrderStatus"){
                resp.clase = valueSelector === "N/A" ? "" : paymentStatesClasses[valueSelector];
                resp.value = valueSelector === "N/A" ? valueSelector : paymentStates[valueSelector];
            }else{
                resp.value = valueSelector;
            }

        }
        else {
            resp.value = valueSelector;
        }

        return resp;

    }
}