import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./datereport.scss"

class Table extends Component {
    constructor(props) {
        super(props);
    }

    handleChange = (e) => {
        this.props.onSelectOption({label: e.target.options[e.target.selectedIndex].text, value: e.target.value });            
    }

    render() {
        const {data} = this.props;
        return (
            <div className="grid25">
                {data.map(group => 
                    <div className="card-report">
                        <div className="container-report">
                            <div className="text-center">
                                <h3 className="text-center">
                                    {group.name}
                                </h3>
                            </div>
                            <div className="mt-3">
                                {group.datasources.map(item => 
                                    <div className="card-item text-center" key={item._id}>
                                        <Link className="link-card" to={'/datasources/edit/'+item._id}>
                                            {item.name}
                                        </Link>
                                    </div>
                                )}
                            </div>
                            
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Table;