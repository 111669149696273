import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../tools/loader';
import ModalResponse from '../layout/modals/modalResponse';

import { updateGroup, getGroup } from '../../actions/groups/groupAction';
import { getDependences } from '../../actions/dependences/dependenceActions';
import Permisos from './permisos';
let update = false;
let actPermiso = true;
class GroupEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			permisos: [{ component: '', name: '', rol: 'readOnly' }]
		};
	}

	onHandleEdit = event => {
		event.preventDefault();

		let name = event.target.nameGroup.value;
		let description = event.target.description.value;
		let dependence = event.target.dependence.value;
		let permisos = this.state.permisos;

		const data = {
			name,
			description,
			dependence,
			permisos
		};

		console.log("On handle edit");
		console.log(this.props);
		console.log(this.state);

		this.props.dispatch(updateGroup(data, this.props.id));
		update = true;
	};

	handleChange = e => {
		if (['component', 'name', 'rol'].includes(e.target.name)) {
			let permisos = [...this.state.permisos];
			permisos[e.target.dataset.id][e.target.name] = e.target.value;
			/*if (e.target.name == 'component') {
				var index = e.nativeEvent.target.selectedIndex;

				permisos[e.target.dataset.id]['name'] =
					e.nativeEvent.target[index].text;
			}*/
			this.setState({ permisos });
		}
	};

	actPermisos = permisos => {
		if (actPermiso) {
			this.setState({ permisos });
			actPermiso = false;
		}
	};

	addPermisos = e => {
		this.setState(prevState => ({
			permisos: [...prevState.permisos, { component: '', name: '', rol: '' }]
		}));
	};

	removePermiso = e => {
		let permisos = [...this.state.permisos];
		permisos.splice(e.target.dataset.id, 1);
		this.setState({ permisos });
	};

	componentDidMount() {
		document.title = 'Edit Group';
		this.props.dispatch(getDependences());

		actPermiso = true;
		this.props.dispatch(getGroup(this.props.id));
	}

	render() {
		let name,
			description,
			dependence = null;
		let deps = [];
		let permisos = [];
		let text = '';
		//let actPermiso = true;
		const { isLoading, group, dependences } = this.props;

		console.log("Props");
		console.log(this.props);


		if (group) {
			if (group.data.error === 'Forbidden') {
				return <div>No tiene permiso para este componente</div>;
			}
			name = group.data.name;
			description = group.data.description;
			dependence = group.data.dependence;
			permisos = group.data.permisos;
			if (actPermiso && !isLoading) {
				this.actPermisos(permisos);
			}

			if (group.config.method === 'put' && update && !isLoading) {
				if (group.status === 200) {
					this.props.history.push('/groups');
				} else if (update) {
					text = 'No fue posible guardar el Grupo';
				}
				update = false;
			}
		}

		if (dependences) {
			deps = dependences.data;
		}

		return (
			<div>
				{isLoading ? (
					<Loader />
				) : (
						<div>
							{text !== '' ? (
								<ModalResponse title={'Editar!'} text={text} res={404} />
							) : null}
							<form onSubmit={this.onHandleEdit}>
								<h3>Editar Grupo</h3>
								<div className="input-container">
									<i className="sii sii-user icon" />
									<input
										type="text"
										defaultValue={name}
										name="nameGroup"
										id="nameGroup"
										placeholder="Nombre"
									/>
								</div>
								<div className="input-container">
									<i className="sii sii-user icon" />
									<input
										type="text"
										defaultValue={description}
										name="description"
										id="description"
										placeholder="Descripcion"
									/>
								</div>
								<div className="input-container">
									<i className="sii sii-user icon" />
									<select name="dependence" id="dependence" value={dependence}>
										<option value="-9">Seleccionar Dependencia</option>
										{deps.map((dep, index) => {
											return (
												<option key={index} value={dep._id}>
													{dep.name}
												</option>
											);
										})}
									</select>
								</div>
								<h3>Permisos</h3>
								<Permisos
									permisos={this.state.permisos}
									change={this.handleChange}
									remove={this.removePermiso}
									isNew={false}
								/>
								<div className="center">
									<button
										type="button"
										className="btn btn-outline-primary margin-bottom-btn"
										onClick={this.addPermisos}
									>
										<i>+</i> Agregar Permiso
								</button>
								</div>
								<div className="p-top-20 center">
									<button className="button green-fill">Guardar</button>
								</div>
							</form>
						</div>
					)}
			</div>
		);
	}
}

GroupEdit.propTypes = {
	actions: PropTypes.object.isRequired,
	group: PropTypes.object,
	error: PropTypes.object
};

const mapStateToProps = state => ({
	group: state.groups.group,
	dependences: state.dependences.dependences,
	error: state.groups.err,
	isLoading: state.groups.isLoading,
	isLoadingUp: state.groups.isLoadingUp
});

/*const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(GroupActions, dispatch)
});*/
export default connect(mapStateToProps)(withRouter(GroupEdit));
