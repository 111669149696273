
import apisauce from 'apisauce';
import Five9 from './services/Five9';
import config from '../../config.js';

const create = (baseURL = config.urlHubspot) => {

  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'content-type': 'application/json'
    },
    // 10 second timeout...
    timeout: 10000
  });

  const five9 = new Five9(api);
  //  return Api
  return {
    setHeader: (key, value) => api.setHeader(key, value),
    removeHeader: key => api.deleteHeader(key),
    // a list of the API functions from step 2
    five9
  };
};

// let's return back our create method as the default.
export default {
  create
};
