import React, { Children, Component } from 'react'
import { Link } from 'react-router-dom'
import { deleteCookie, getCookie } from '../../../../utils/cookies'; 
import config from '../../../../config';

import './sheader.scss'
import '../../../../siicon.scss'
import SiigerLogo from '../../assets/img/SiigerLogo.svg'

class Sheader extends Component {

    constructor(props) {
        super(props);
        let _avatar = '';
        let _nombre = getCookie('name');
        let _ini = 'NN';

        const myArray = _nombre.split(" ");
        _ini = myArray[0][0] + myArray[myArray.length - 1][0]

        if (getCookie('avatar') != 'undefined'){
            _avatar = config.url + 'auth/avatar/' + getCookie('avatar')
        }

        this.state = {
            avatar: _avatar,
            iniciales: _ini       
        }
    } 

    render() {
        return (
            <>
                {/* HEADER */}
                <div className='row sheader'>
                    {/* Logo */}
                    <div className='col width-50'>
                        <Link to="/siigers/explorar">
                            <img id="logo" src={SiigerLogo} alt='Siiger' />
                        </Link>   
                    </div>
                    {/* Perfil */}
                    <div className='col width-50'>
                        <div className='container-avatar'>
                            <Link to="/siigers/perfil">

                                {(this.state.avatar == '')
                                    ? <div><div className='avatar-circle'>{ this.state.iniciales } </div></div>
                                    : <img src={this.state.avatar}  className='avatar-header'></img>
                                }      
                            </Link> 
                        </div>
                    </div>
                </div>                             
            </>
        );
    }
}

export default Sheader;