import React from 'react';
import 'react-day-picker/lib/style.css';
import 'moment/locale/es';
import { SiigoDatepickerMolecule } from "@siigo/siigo-datepicker-molecule-react";
import moment from 'moment';

export default class DatePickerControl extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.datePickerRef = React.createRef();
		this.handleClose = this.handleClose.bind(this);
	}

	handleCleanImput() {
		this.datePickerRef.current.onClearDatepickerEvent();
	}

	handleChange(_event) {
		if (_event?.detail?.value && _event?.detail?.value2) {
			const dateFromSelected = moment(_event.detail.value, 'DD/MM/YYYY').utcOffset(0, true);
			const setHoursFrom = dateFromSelected.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
			const dateToSelected = moment(_event.detail.value2, 'DD/MM/YYYY').utcOffset(0, true);
			const setHoursTo = dateToSelected.set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).toDate();
			this.props.handleDate({ from: setHoursFrom, to: setHoursTo });
		}else{
			this.props.handleDate({ from: '', to: '' });
		}
	}

	handleClose(_event){
		if(_event && this.props?.handleInvalidRange){
			if (_event.srcElement.value && _event.srcElement.value2) {
				let fromDate = this.createDate(_event.srcElement.value);
				let toDate = this.createDate(_event.srcElement.value2);
				if(toDate < fromDate){
					this.props.handleInvalidRange();
				}
			}
		}
	}

	createDate(strDate){
		let day = parseInt(strDate.substring(0,2));
		let mon = parseInt(strDate.substring(3,5));
		let year = parseInt(strDate.substring(6,10));
		let resDate = new Date(year, mon-1, day);
		return resDate;
	}

	render() {
		return (
			<div className="InputFromTo">
				<SiigoDatepickerMolecule
					ref={this.datePickerRef}
					name="datePicker"
					class="inputDatePicker"
					textOptions={[
						{
							hiddenHint: true,
							label: 'Fecha desde'
						},
						{
							hiddenHint: true,
							label: 'Fecha hasta'
						}
					]}
					dateRanges={[
						{
							min: '',
							max: moment(new Date()).format('DD/MM/YYYY')
						}
					]}
					errorMessages={[
						{
							format: 'Fecha no válida',
							range: 'Rango incorrecto',
						}
					]}
					onDateChange={this.handleChange}
					isDateRangePicker
					onCloseCalendar={this.handleClose}
				/>
			</div>
		);
	}
}