import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import React from "react";

function errorDialog(translate, message) {
  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message || translate("errorLoadingData")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => window.location.reload()}
            color="primary"
            autoFocus
          >
            {translate("reloadPage")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default { errorDialog };
