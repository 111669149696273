import * as types from '../actions';

const logsReducer = (state = { isLoading: false }, action = null) => {
  switch (action.type) {
    case types.GET_LOGS:
      return { ...state, isLoading: true };
    case types.LOGS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        logs: action.data
      };
    case types.LOGS_REQUEST_FAILED:
      return { ...state, isLoading: false, logs: action.error };

    case types.GET_QUERY_LOGS:
      return { ...state, isLoading: true };
    case types.GET_QUERY_LOGS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        filteredLogs: action.data
      };
    case types.GET_QUERY_LOGS_FAILED:
      return { ...state, isLoading: false, filteredLogs: action.error };
    
      case types.CREATE_LOG:
        return { ...state, isLoading: true };
      case types.CREATE_LOG_SUCCESS:
        return {
          ...state,
          isLoadingCL: false,
          log: action.data
        };
      case types.CREATE_LOG_FAILED:
        return { ...state, isLoadingCL: false, log: action.error };

    default:
      return state;
  }
};

export default logsReducer;
