function getParsedQueryParams(availableValues, queryParams){
  let data = {};
  Object.keys(availableValues).forEach((key) => {
    data[key] = queryParams.get(key) ?? "";
  });
  return data;
}

export default {
  getParsedQueryParams,
};
