import React, { Component } from 'react';
import ProductListSynergy from '../../components/productListSynergy';

class ProductListSynergyContainer extends Component {
  render() {
    return (
      <div>
        <ProductListSynergy  parentPath={this.props.path}/>
      </div>
    );
  }
}

export default ProductListSynergyContainer;