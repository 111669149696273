import React, { Component } from 'react';
import DependenceList from '../../components/dependences';

class DependencesContainer extends Component {
  render() {
    return (
      <div>
        <DependenceList />
      </div>
    );
  }
}

export default DependencesContainer;
