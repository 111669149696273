import * as types from '../actions';

const leadsReducer = (state = { isLoading: false }, action = null) => {
  switch (action.type) {
    case types.GET_LEADS:
      return { ...state, isLoading: true };
    case types.LEADS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        leads: action.data
      };
    case types.LEADS_REQUEST_FAILED:
      return { ...state, isLoading: false, leads: action.error };

    default:
      return state;
  }
};

export default leadsReducer;
