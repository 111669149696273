import React, { Component } from 'react';
import GroupList from '../../components/groups';

class GroupsContainer extends Component {
  render() {
    return (
      <div>
        <GroupList />
      </div>
    );
  }
}

export default GroupsContainer;
