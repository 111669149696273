import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from "../services/api";

const api = API.create();

export function* createPlan(action) {
    try {
        const { plan } = action;
        const planDetail = yield call(api.plans.createPlan, plan).then(response => response.data);
        yield put({ type: types.CREATE_PLAN_SUCCESS, data: planDetail });
    } catch (err) {
        yield put({ type: types.CREATE_PLAN_FAILED, err});
    }
}

export function* updatePlan (action) {
    try {
        const { plan, msisdn } = action;
        const planDetail = yield call(api.plans.updatePlan, msisdn, plan).then(response => response.data);
        yield put({ type: types.UPDATE_PLAN_SUCCESS, data: planDetail });
    } catch (err) {
        yield put( { type: types.UPDATE_PLAN_FAILED, err})
    }
}

export function* deletePlan (action) {
    const { msisdn } = action;
    try {
        const response = yield call(api.plans.deletePlan, msisdn).then(response => response.data);
        yield put({ type: types.DELETE_PLAN, response });
    } catch (err) {
        yield put( { type: types.DELETE_PLAN, err})
    }
}

export function* getPlans() {
    try {
        let plans = yield call(api.plans.plans);
        yield put({ type: types.PLANS_RECEIVED,  data: plans })
    } catch (err) {
        yield put({ type: types.PLANS_REQUEST_FAILED, err })
    }
}


export function* getPlan(action) {
    try {
        const { msisdn } = action;
        let plans = yield call(api.plans.planDetail, msisdn);
        yield put({ type: types.PLAN_RECEIVED,  data: plans })
    } catch (err) {
        yield put({ type: types.PLAN_REQUEST_FAILED, err })
    }
}
