import * as types from '../index';

export const getBlackListEmail = params => {
  return { type: types.GET_BLACK_LIST_EMAIL, params };
};

export const deleteBlackListEmail = params => {
  return { type: types.DELETE_BLACK_LIST_EMAIL, params };
};

