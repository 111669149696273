import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as MarketActions from '../../actions/market/marketAction';
import CountryContext from '../../context/countries-context';
import { getCountryAbbreviation } from './utils';
import { SiigoDataTableMolecule } from '@siigo/siigo-data-table-molecule-react';
import { SiigoPaginatorMolecule } from '@siigo/siigo-paginator-molecule-react';
import { SiigoPanelModalMolecule } from "@siigo/siigo-panel-modal-molecule-react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import moment from 'moment';
import Loader from '../tools/loader';
import { EVENTS_LOGS, MODULE_ENUM } from './plandata';
import { useHistory, useLocation } from 'react-router-dom';

const ALLOWED_PARAMETERS = {
    "created-company": ["CompanyFullName", "PlanType", "AllowedInvoices", "SpaceAvailable", "SpaceAdditional", "Nit", "Email", "UserFullName", "UserEmail", "Serial", "AllowedUsers", "PrepaidPlanType"],
    "created-addon": {
        PAYROLL_LITE: ["name", "module", "documentBase",],
        PAYROLL_PLUS: ["name", "module", "documentBase",],
        PAYROLL_PRO: ["name", "module", "documentBase",],
        PAYROLL_CLOUD: ["name", "module", "documentBase",],
        POS_WEB_INITIAL: ["name", "module", "aditionalCashiers", "documentBase"],
        POS_WEB_ADVANCE: ["name", "module", "aditionalCashiers", "documentBase"]
    },
    "updated-addon": {
        PAYROLL_LITE: ["name", "state", "module", "documentBase", "subState", "updateType"],
        PAYROLL_PLUS: ["name", "state", "module", "documentBase", "subState", "updateType"],
        PAYROLL_PRO: ["name", "state", "module", "documentBase", "subState", "updateType"],
        PAYROLL_CLOUD: ["name", "state", "module", "documentBase", "subState", "updateType"],
        POS_WEB_INITIAL: ["name", "state", "module", "aditionalCashiers", "documentBase", "subState", "updateType"],
        POS_WEB_ADVANCE: ["name", "state", "module", "aditionalCashiers", "documentBase", "subState", "updateType"],
    },
    "deleted-addon": {
        PAYROLL_LITE: ["name", "state", "module", "documentBase"],
        PAYROLL_PLUS: ["name", "state", "module", "documentBase"],
        PAYROLL_PRO: ["name", "state", "module", "documentBase"],
        PAYROLL_CLOUD: ["name", "state", "module", "documentBase"],
        POS_WEB_INITIAL: ["name", "state", "module", "aditionalCashiers", "documentBase", "subState", "updateType"],
        POS_WEB_ADVANCE: ["name", "state", "module", "aditionalCashiers", "documentBase", "subState", "updateType"]
    },
    "resent-mail": ["changedPassword"]
}

function CompanyLogs(props) {

    const countryContext = useContext(CountryContext);
    const marketState = useSelector((_state) => _state.market);
    const { t } = useTranslation();
    const siigoDataTable = useRef();
    const [companyPrincipal] = useState(JSON.parse(localStorage.getItem("Company")));
    const [filtersLogs, setFiltersLogs] = useState();
    const [logsData, setLogsData] = useState([]);
    const [openModalDetail, setOpenModalDetail] = useState(false);
    const [logsDetailData, setLogsDetailData] = useState([]);
    const [modalDetailTitle, setModalDetailTitle] = useState("");
    const history = useHistory();
    const location = useLocation();
    const [paginationParams, setPaginationParams] = useState({
        page: 1,
        pageDocuments: 10,
        totalDocuments: 0
    })
    const [headers] = useState([
        {
            key: "date",
            text: t('market.companyLogs.table.headers.date'),
            type: "date",
            align: "center",
            sortable: true
        },
        {
            key: "user",
            text: t('market.companyLogs.table.headers.user'),
            type: "string",
            align: "center",
            sortable: true
        },
        {
            key: "event",
            text: t('market.companyLogs.table.headers.event'),
            type: "string",
            align: "center",
            height: "auto",
            sortable: true
        },
        {
            key: "actions",
            text: t('market.companyLogs.table.headers.actions'),
            align: "center",
            width: "20%",
            type: "string"
        },
    ]);
    const [headersDetails] = useState([
        {
            key: "properties",
            text: t('market.companyLogs.tableLogs.headers.properties'),
            type: "properties",
            align: "center"
        },
        {
            key: "previousData",
            text: t('market.companyLogs.tableLogs.headers.previousData'),
            type: "string",
            align: "center"
        },
        {
            key: "newData",
            text: t('market.companyLogs.tableLogs.headers.newData'),
            type: "string",
            align: "center"
        }
    ]);
    const actionsMarket = useDispatch();

    useEffect(() => {
        if (countryContext?.isCountryChanged()) {
            countryContext.setCountryChanged(false);
            window.location.href = "/market";
        }
    }, [countryContext]);

    useEffect(() => {
        if (companyPrincipal?.CloudTenantCompanyKey && countryContext?.country)
            setFiltersLogs({
                filters: [{
                    field: "country",
                    operator: "EQUAL",
                    value: getCountryAbbreviation(countryContext?.country)
                }, {
                    field: "event",
                    operator: "EQUAL",
                    value: {
                        entityId: companyPrincipal?.CloudTenantCompanyKey?.toLowerCase()
                    }
                }],
                orderBy: "timestamp",
                orderType: "ASC",
                limit: 10,
            });
    }, [companyPrincipal?.CloudTenantCompanyKey, countryContext?.country]);

    useEffect(() => {
        if (filtersLogs)
            actionsMarket(MarketActions.getCompanyLogs(filtersLogs));
    }, [filtersLogs]);

    useEffect(() => {
        if (marketState.companyLogs?.total) {
            setPaginationParams({
                ...paginationParams,
                totalDocuments: marketState?.companyLogs?.total
            });
            setLogsData(mapDataTable(marketState?.companyLogs?.data));
        }
    }, [marketState?.companyLogs]);

    useEffect(() => {
        logsData?.length &&
            siigoDataTable?.current?.setDefaultTemplate({
                actions: (value, _params) => generateActionButton(_params),
            });
    }, [logsData]);

    const mapDataTable = (_logs) => _logs?.map((_log) => ({
        date: moment(new Date(_log?.timestamp)).format('MMMM DD° YYYY, hh:mm:ss a'),
        user: _log?.actor?.username,
        event: t(`market.companyLogs.table.events.${_log?.event?.name}`)
    }));

    const returnParamString = (_param) => {
        const defaultParameters = [
            null, undefined, -1, "-1"
        ]
        return defaultParameters.includes(_param) ? t('market.companyLogs.tableLogs.na') : String(_param)
    };

    const mapDataTableDetail = (_log) => {
        const eventName = _log?.event?.name;
        let module, child;
        setModalDetailTitle(eventName);
        switch (eventName) {
            case EVENTS_LOGS.CREATED_COMPANY:
                return Object.entries(_log?.event?.resultingState?.Company)?.
                    filter(_param => ALLOWED_PARAMETERS[eventName].includes(_param[0])).
                    map(_param => ({
                        properties: _param[0],
                        previousData: t('market.companyLogs.tableLogs.na'),
                        newData: returnParamString(_param[1])
                    }));
            case EVENTS_LOGS.CREATE_ADDON:
                module = Object.entries(MODULE_ENUM).find(_module => _module[1] == _log?.event?.resultingState.module)[0];
                return Object.entries(_log?.event?.resultingState)?.
                    filter(_param => {
                        return typeof _param[1] === 'object' && _param[1] !== null ?
                            Object.entries(_param[1]).
                                filter(_objetcParam => {
                                    if (ALLOWED_PARAMETERS[eventName][module].includes(_objetcParam[0])) {
                                        child = _objetcParam[0]
                                        return true;
                                    }
                                    return false;
                                }) :
                            ALLOWED_PARAMETERS[eventName][module].includes(_param[0])
                    }).
                    map(_param => typeof _param[1] === 'object' && _param[1] !== null ?
                        {
                            properties: child,
                            previousData: t('market.companyLogs.tableLogs.na'),
                            newData: returnParamString(_log?.event?.resultingState[_param[0]][child]),
                        } :
                        {
                            properties: _param[0],
                            previousData: t('market.companyLogs.tableLogs.na'),
                            newData: returnParamString(_param[1])
                        });
            case EVENTS_LOGS.UPDATED_ADDON:
                module = Object.entries(MODULE_ENUM)?.find(_module => _module[1] == _log?.event?.resultingState.module)[0];
                return Object.entries(_log?.event?.resultingState)?.
                    filter(_param => {
                        return typeof _param[1] === 'object' && _param[1] !== null ?
                            Object.entries(_param[1]).
                                filter(_objetcParam => {
                                    if (ALLOWED_PARAMETERS[eventName][module].includes(_objetcParam[0])) {
                                        child = _objetcParam[0]
                                        return true;
                                    }
                                    return false;
                                }) :
                            ALLOWED_PARAMETERS[eventName][module].includes(_param[0])
                    }).
                    map(_param => typeof _param[1] === 'object' && _param[1] !== null ?
                        {
                            properties: child,
                            previousData: returnParamString(_log?.event?.priorState[_param[0]][child]),
                            newData: returnParamString(_param[1][child])
                        } :
                        {
                            properties: _param[0],
                            previousData: returnParamString(_log?.event?.priorState[_param[0]]),
                            newData: returnParamString(_param[1])
                        });
            case EVENTS_LOGS.DELETED_ADDON:
                module = Object.entries(MODULE_ENUM)?.find(_module => _module[1] == _log?.event?.priorState.module)[0];
                return Object.entries(_log?.event?.priorState)?.
                    filter(_param => {
                        return typeof _param[1] === 'object' && _param[1] !== null ?
                            Object.entries(_param[1]).
                                filter(_objetcParam => {
                                    if (ALLOWED_PARAMETERS[eventName][module].includes(_objetcParam[0])) {
                                        child = _objetcParam[0]
                                        return true;
                                    }
                                    return false;
                                }) :
                            ALLOWED_PARAMETERS[eventName][module].includes(_param[0])
                    }).
                    map(_param => typeof _param[1] === 'object' && _param[1] !== null ?
                        {
                            properties: child,
                            previousData: returnParamString(_log?.event?.priorState[_param[0]][child]),
                            newData: t('market.companyLogs.tableLogs.na')
                        } :
                        {
                            properties: _param[0],
                            previousData: returnParamString(_log?.event?.priorState[_param[0]]),
                            newData: t('market.companyLogs.tableLogs.na')
                        });
            case EVENTS_LOGS.UPDATED_COMPANY_INFO:
            case EVENTS_LOGS.UPGRADED_COMPANY:
                return Object.entries(_log?.event?.priorState?.cloudTenant)?.
                    map(_param => ({
                        properties: _param[0],
                        previousData: returnParamString(_param[1]),
                        newData: returnParamString(_log?.event?.resultingState?.cloudTenant[_param[0]])
                    }));
            case EVENTS_LOGS.RESENT_MAIL:
                return Object.entries(_log?.event?.resultingState)?.
                    filter(_param => ALLOWED_PARAMETERS[eventName].includes(_param[0])).
                    map(_param => ({
                        properties: _param[0],
                        previousData: t('market.companyLogs.tableLogs.na'),
                        newData: true,
                    }));
            case EVENTS_LOGS.ADD_FE_DOCUMENTS:
            case EVENTS_LOGS.ADD_NE_DOCUMENTS:
            case EVENTS_LOGS.REMOVE_FE_DOCUMENTS:
            case EVENTS_LOGS.REMOVE_NE_DOCUMENTS:
                 return Object.entries(_log?.event?.resultingState).
                    map(_param => ({
                        properties: _param[0],
                        previousData: t('market.companyLogs.tableLogs.na'),
                        newData: _param[1],
                    }));
            default:
                break;
        }
        return [];
    }

    const generateActionButton = (_params) => {
        const buttonViewDetail = document.createElement("siigo-button-atom");
        buttonViewDetail.text = t('market.companyLogs.table.actionsButtons.viewDetail');
        buttonViewDetail.color = "secondary";
        buttonViewDetail.size = "m";
        buttonViewDetail.addEventListener("click", function (_event) {
            setLogsDetailData(mapDataTableDetail(marketState?.companyLogs?.data[_params.id]));
            setOpenModalDetail(true);
        });
        return buttonViewDetail
    }

    const selectedPage = (event) => {
        event.preventDefault();
        const params = {
            pageNumber: (event?.detail?.limit / event?.detail?.size),
            pageDocuments: event?.detail?.size
        }
        if (params.pageNumber != paginationParams.page || params.pageDocuments != paginationParams.pageDocuments) {
            setPaginationParams({ page: params.pageNumber, pageDocuments: params.pageDocuments });
            setFiltersLogs({
                ...filtersLogs,
                limit: (event?.detail?.limit - event?.detail?.offset),
                offset: event?.detail?.offset
            });
        }
    }

    const handleBackClick = () => {
        const previusUrl = location.state?.detail;
        history.push({
            pathname: previusUrl,
            state: { from: location.state?.from, company: companyPrincipal }
        });
    };


    return (
        <>
            <div className='header-company-detail'>
                <div className="sub-title">
                    <a className="link-back" onClick={handleBackClick}>
                        <i className="siicon icon-atras"></i>
                        <span>{t("market.companyLogs.buttonBack")}</span>
                    </a>
                </div>
            </div>
            {marketState?.isLoading && <Loader full />}
            <div className='table-company-logs'>
                <SiigoDataTableMolecule
                    className='logs-table'
                    headers={headers}
                    data={logsData}
                    dataTableMessage={t('market.companyLogs.table.notData')}
                    select-rows={false}
                    sortable
                    reorder={false}
                    ref={siigoDataTable}
                />
                <div className='table-pagination'>
                    <SiigoPaginatorMolecule
                        collectionSize={paginationParams.totalDocuments}
                        currentPage={paginationParams.page}
                        dropdownPosition={{
                            control: {
                                type: 'select'
                            },
                        }}
                        onChanged={(event) => selectedPage(event)}
                    />
                </div>
            </div>
            <SiigoPanelModalMolecule
                openModal={openModalDetail}
                onClosePanelModal={() => {
                    setOpenModalDetail(!openModalDetail);
                }}
                title={t(`market.companyLogs.table.events.${modalDetailTitle}`)}
                width="1000px"
            >
                <SiigoDataTableMolecule
                    className='logs-table'
                    headers={headersDetails}
                    data={logsDetailData}
                    dataTableMessage={t('market.companyLogs.table.notData')}
                    select-rows={false}
                    sortable={false}
                    reorder={false}
                    ref={siigoDataTable}
                />
            </SiigoPanelModalMolecule>
        </>
    )
}

export default CompanyLogs;
