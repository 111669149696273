import React, { Component } from 'react';
import Sheadnavigator from '../slayout/sheader/shaednavigator';
import './sviajes.scss';
import imgMapa from '../assets/img/map.png' 
import imgDiscovery from '../assets/img/discovery.png' 
import { Redirect } from 'react-router-dom';
import WithoutResult from '../sshared/withoutResult/withoutresult';
import { SearchLodging } from '../helpers/SiigerHelper';
import SviajePerfil from './sviajesperfil';


class Sviajes extends Component {

    constructor(props) {
	    super(props);
        console.log('constructor');

        // Initializing the state 
        let stSearch = (JSON.parse(localStorage.getItem('siigertravelsearch')) || undefined);
        this.state = { 
            withoutResult: true,  
            lengthResult: 0,
            searchResult: undefined,
            siigerTravelSearch: stSearch
        };                
    } 

    componentDidMount() {
        let stSearch = (JSON.parse(localStorage.getItem('siigertravelsearch')) || undefined);

        this.setState({ 
                        siigerTravelSearch: this.state.siigerTravelSearch,
                        withoutSearch: (this.state.siigerTravelSearch == undefined)||
                                        (this.state.siigerTravelSearch.tpais == 0)||
                                        (this.state.siigerTravelSearch.tciudad  == 0)||
                                        (this.state.siigerTravelSearch.tdesde  == '')||
                                        (this.state.siigerTravelSearch.thasta  == ''),
                        withoutResult: false,
                        waitingResult: true,
            }, () => 
            {
                if (this.state.siigerTravelSearch == undefined)
                {
                    this.setState({        
                        withoutResult: true,   
                        waitingResult: false,            
                    });
                }
                else{
                    //Hacemos la busqueda
                    SearchLodging(this.state.siigerTravelSearch).then((response)  => {
                        var _data = response.data.documents;      

                        if (_data == undefined){
                            //busqueda sin resultados
                            this.setState({        
                                withoutResult: true,   
                                waitingResult: false,            
                            });
                        }
                        else{                       
                            //busqueda con resultados

                            this.setState({                             
                                lengthResult: _data.length,
                                searchResult: _data,
                                siigerTravelSearch: this.state.siigerTravelSearch,
                                withoutResult: (_data.length == 0),
                                waitingResult: false,               
                            });                        
                        }

                    }, []);
                }
            });
    }    

    GoToPerfilAlojamiento(p){
        // console.log('perfil alojamiengo go!!');
    }

    render() {
        //si data para buscar.
        if (this.state.withoutSearch) {
            return (
                <Redirect to="/siiger/explorar"/>
            )
        }

        //ups, no hay resultados.
        if (this.state.withoutResult) {            
            return (
                <>
                    <Sheadnavigator urlReturn="/siiger/explorar" travel={ this.state.siigerTravelSearch } />  
                    <WithoutResult img={ imgMapa } msg="0 resultados :("/>
                </>
            )
        }

        //Loading.... buscando... 
        if (this.state.waitingResult) {            
            return (
                <>
                    <Sheadnavigator urlReturn="/siiger/explorar" travel={ this.state.siigerTravelSearch } />  
                    <WithoutResult img={ imgDiscovery } msg="Búscando..." />
                </>
            )
        }        

        //show data
        return(
            <>
                <Sheadnavigator urlReturn="/siiger/explorar" travel={ this.state.siigerTravelSearch }/>    

                <div className='container siiger'>
                    <label className='alojamiento-count-result'>{ this.state.lengthResult } alojamientos</label>

                    {this.state.searchResult.map(item => (  
                        <div key={item._id}>
                            <SviajePerfil { ...item } travel={ this.state.siigerTravelSearch } />
                        </div>                                      
                    ))}                     
                </div>
            </>
        );
    }
}

export default Sviajes;