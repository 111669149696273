import React, { Component } from "react";
import TabsTable from "./components/tabs";
import HeaderTable from "./components/header";
import BodyTable from "./components/body";
import Pagination from "./components/pagination";
import Search from "./components/search";
import "./table-report.scss";

class TableReport extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      hasFilter: false,
      columns: {},
      data: this.props.data,
      sort: true,
      dataPack: this.props.dataPack,
    };

    this.filterData = this.filterData.bind(this);
  }

  componentDidUpdate(prevProps){
    if(prevProps !== this.props){this.setState({...this.state, data: this.props.data})}
  }

  dynamicSort = (property) => {
    if (property == "createdTime"){
      this.setState({
        data : this.props.data.reverse()
      })
    }
    else{
    const sort = this.state.sort
      ? { smaller: -1, greater: 1 }
      : { smaller: 1, greater: -1 };
    this.setState({ sort: !this.state.sort });
    this.setState({
      data: this.state.data.sort(function (a, b) {
        let dataA = '';
        let dataB = '';
        if(property.includes('.')){
          dataA = (a[property.split('.')[0]] === undefined || a[property.split('.')[0]] === null) ? "" : a[property.split('.')[0]][property.split('.')[1]];
          dataB = (b[property.split('.')[0]] === undefined || b[property.split('.')[0]] === null) ? "" : b[property.split('.')[0]][property.split('.')[1]];
          dataA = a[property] != null ? dataA.toUpperCase() : dataA;
          dataB = a[property] != null ? dataB.toUpperCase() : dataB;
        }else{
          dataA = a[property] != null && property != "state" ? a[property].toUpperCase() : a[property];
          dataB = b[property] != null && property != "state" ? b[property].toUpperCase() : b[property];
        }

        if (dataB < dataA || dataB == null) {
          return sort.smaller;
        } else if (dataB > dataA || dataB == null) {
          return sort.greater;
        }
        return 0;
      }),
    });
  }
  };

  groupData = (property) => {
    if (property.filterName === "all") {
      this.setState({
        data: this.props.data,
      });
    } else {
      this.setState({
        data: this.props.data.filter(
          (item) => item[this.props.tabsData.filterCol] === property.filterName
        ),
      });
    }
  };

  filterData = (data, number) => {
    let hasFilter = false;
    if (number != 0) {
      hasFilter = true;
    } else {
      hasFilter = false;
    }
    this.setState({
      data,
      hasFilter,
    });
  };

  pageData = (data) => {
    this.setState({
      data: data,
    });
  };

  setCurrentPage = (page) => {
    this.props.serverRenderPagination(page)
  }

  render() {
    let {
      columns,
      tabs,
      tabsData,
      headerCounterTab,
      actionButtons,
      btnActions,
      pagination,
      serverPagination,  
      pageCountServer,  
      startingPage,  
      paginationNav,
      paginationPosition,
      defaultPaginationNumber,
      maxSizePagination,
      pageSize,
      filter,
      tooltip,
    } = this.props;  
    const { data, hasFilter } = this.state;
  

    return (
      <div className={"container table-report"}>
        <div>
          {filter ? (
            <Search data={this.props.data} returnData={this.filterData} />
          ) : null}
        </div>
        {data ? (
          <div className="table-components">
            {tabs ? (
              <TabsTable
                tabs={tabsData}
                data={headerCounterTab ? this.props.data : false}
                groupData={this.groupData}
              />
            ) : null}
            {!hasFilter &&
            ((pagination && paginationPosition === "top") ||
              (pagination && paginationPosition === "both")) ? (
              <Pagination
                paginationNav={paginationNav}
                defaultPaginationNumber={defaultPaginationNumber}
                maxSizePagination={maxSizePagination}
              />
            ) : null}
            <table
              className={
                (pagination && paginationPosition === "top") ||
                (pagination && paginationPosition === "both")
                  ? "table no-pad"
                  : "table"
              }
            >
              {columns ? (
                <HeaderTable
                  data={data}
                  columns={columns}
                  actionButtons={actionButtons}
                  btnActions={btnActions}
                  dynamicSort={this.dynamicSort}
                />
              ) : null}
              {data ? (
                <BodyTable
                  data={data}
                  dataPack={this.state.dataPack}
                  actionButtons={actionButtons}
                  btnActions={btnActions}
                  tabsData={tabsData}
                  columns={columns}
                  tooltip={tooltip}
                  pageSize={this.props.pageSize}
                />
              ) : (
                <p>No se encontró información</p>
              )}
            </table>
            {(!hasFilter && pagination && paginationPosition === "bottom") ||
            (pagination && paginationPosition === "both") ? (
              <Pagination
                paginationNav={paginationNav}
                defaultPaginationNumber={defaultPaginationNumber}
                maxSizePagination={maxSizePagination}
                data={this.state.data}
                fullData={this.props.data}
                pageData={this.pageData}
                pageSize={pageSize}
                serverPagination={serverPagination}
                pageCountServer={pageCountServer}
                setCurrentPage={this.setCurrentPage}
                startingPage={startingPage}
              />
            ) : null}
          </div>
        ) : (
          <div className="message alert">
            <h6>No se encontró información</h6>
          </div>
        )}
      </div>
    );
  }
}
export default TableReport;
