import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./menuadmin.scss";

class MenuAdminComponent extends Component {
  
	constructor(props) {
		super(props);
		this.state = {};
	}
  componentDidMount() {
  }
    render() {
        return (
          <div >
            <div id="single-spa-application:menuadmin" class="menu-admin"></div>
          </div>
        ); 
    }
}
MenuAdminComponent.propTypes = {};
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(MenuAdminComponent);