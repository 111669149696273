import * as types from "../index";

export const getUsersUsersDomain = (params) => {
    return { type: types.GET_USERS_USERS_DOMAIN, params };
};

export const createUserUsersDomain = (params) => {
    return { type: types.CREATE_USER_USERS_DOMAIN, params };
};

export const updateUserUsersDomain = (params) => {
    return { type: types.UPDATE_USER_USERS_DOMAIN, params };
}

export const deleteUserUsersDomain = (params) => {
    return { type: types.DELETE_USER_USERS_DOMAIN, params };
}