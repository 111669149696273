import React, { Component } from 'react';
import RecurrentPaymentReport from '../../components/recurrentPaymentReport';

class RecurrentPaymentReportContainer extends Component {
  render() {
    return (
      <div>
        <RecurrentPaymentReport parentPath={this.props.path} />
      </div>
    );
  }
}

export default RecurrentPaymentReportContainer;