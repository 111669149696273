import React, { Fragment, useState, useEffect } from 'react';
import Modal from "./modal";

const ModalCountry = ({ props, changeStateModal, showModal, loadCountryInformation, data = { }, action }) => {

    return (
        < Fragment >
            {
                showModal &&
                <Modal
                    setShowModal={changeStateModal}
                    props={props}
                    loadCountryInformation={loadCountryInformation}
                    data={data}
                    action={action}
                >
                </Modal>
            }
        </Fragment >
    );
}

export default ModalCountry;