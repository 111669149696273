import * as types from '../actions';

const customerReducer = ( state = { isLoading: true }, action= null)  => {

    switch (action.type) {
        case types.GET_CUSTOMERS:
            return { ...state, isLoading: true };
        case types.CUSTOMERS_RECEIVED:
            return { ...state, isLoading: false, customers: action.data };
        case types.CUSTOMERS_REQUEST_FAILED:
            return { ...state, isLoading: false, customers: action.error };
        case types.GET_CUSTOMER:
            return { ...state, isLoading: true };
        case types.CUSTOMER_RECEIVED:
            return { ...state, isLoading: false, customers: action.data };
        case types.CUSTOMER_REQUEST_FAILED:
            return { ...state, isLoading: false, customers: action.error };
        case types.CREATE_CUSTOMER:
            return { ...state, isLoading: true };
        case types.CREATE_CUSTOMER_SUCCESS:
            return { ...state, isLoading: false, customers: action.data };
        case types.CREATE_CUSTOMER_FAILED:
            return { ...state, isLoading: false, customers: action.error };
        case types.UPDATE_CUSTOMER:
            return { ...state, isLoading: true };
        case types.UPDATE_CUSTOMER_SUCCESS:
            return { ...state, isLoading: false, customers: action.data };
        case types.UPDATE_CUSTOMER_FAILED:
            return { ...state, isLoading: false, customers: action.error };
        case types.DELETE_CUSTOMER:
            return { ...state, isLoading: true };
        case types.DELETE_CUSTOMER_SUCCESS:
            return { ...state, isLoading: false, customers: action.data };
        case types.DELETE_CUSTOMER_FAILED:
            return { ...state, isLoading: false, customers: action.error };
        default:
            return state;
    }
};

export default customerReducer;
