import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as LogAction from '../../actions/logs/logActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalDelete from '../layout/modals/modalDelete';
import ModalResponse from '../layout/modals/modalResponse';
import svgs from '../layout/icons';
import LogsContainer from '../../containers/logs';
import moment from 'moment';
import 'moment/locale/es';
import './logs.scoped.scss';

const dateFormat = 'YYYY-MM-DD';

const stateDefinitions = {
  available: {
    color: null,
    label: 'Available'
  },
  enquire: {
    color: '#ffd200',
    label: 'Enquire'
  },
  unavailable: {
    selectable: false,
    color: '#78818b',
    label: 'Unavailable'
  }
};

const columns = [
  {
    name: 'Tipo de Cambio',
    selector: 'action',
    sortable: true,
    filterDDL: true
  },
  {
    name: 'Descripción',
    selector: 'nameComponent',
    sortable: true
  },
  {
    name: 'Usuario',
    selector: 'nameUser',
    sortable: true,
    filterDDL: true
  },

  {
    name: 'Fecha',
    selector: 'dateLog',
    sortable: true,
    filterDDL: true
  }
];

let deleteDep = false;
class LogList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      unfileredLogs: [],
      filteredLogs: [],
      visibleModal: false,
      searchUser: '',
      searchDateRange: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      searchTipoAccion: 'Default',
      value: '',
      states: '',
      showHelperBusqueda: true
    };
  }

  componentDidMount() {
    this.props.actions.getLogs();
  }

  showModal = () => {
    this.setState({
      visibleModal: true
    });
  };

  hideModal = () => {
    this.setState({
      visibleModal: false
    });
  };

  changeSearchUser = e => {
    const valor = e.target.value;

    this.setState({
      searchUser: valor
    });
  };

  changeSearchTipoAccion = e => {
    const tipo = e.target.value;

    this.setState({
      searchTipoAccion: tipo
    });
  };

  changeDateRange = e => {
    const dateRange1 = document.getElementById('date1').value;
    const dateRange2 = document.getElementById('date2').value;

    this.setState({
      searchDateRange: [dateRange1, dateRange2]
    });
  };

  customLogSearch = () => {
    if (
      (this.state.searchDateRange[0] || this.state.searchDateRange[1]) == '' &&
      this.state.searchTipoAccion == 'Default' &&
      this.state.searchUser == ''
    ) {
      ModalResponse({
        title: 'Consultar logs',
        text:
          'Debes de tener <b>al menos</b>  un valor entre  <b>nombre</b> , <b>rango de fechas</b>  o <b>tipo de acción</b>  para poder buscar personalizadamente.',
        res: 404
      });
    } else {
      const parametros = {
        searchDateRange: this.state.searchDateRange,
        searchTipoAccion: this.state.searchTipoAccion,
        searchUser: this.state.searchUser
      };

      this.props.actions.getQueryLogs(parametros);

      this.state.showHelperBusqueda = false;
    }
  };

  cleanFilters = () => {
    window.location.reload();
  };

  handleSelect(range, states) {
    // range is a moment-range object
    this.setState({
      value: range,
      states: states
    });
  }

  onChange = date => this.setState({ date });

  showLog = aditionalData => {
    ModalResponse({
      text: aditionalData,
      res: 800 //800 info code
    });
  };

  render() {
    const btnActions = {
      btnShow: this.showLog
    };

    const { error, isLoading, logList, filteredLogs } = this.props;
    let datos = [];
    let status = 0;
    let text = '';

    if (logList && Array.isArray(logList.data)) {
      if (logList.data.error === 'Forbidden') {
        return <div>No tiene permiso para este componente</div>;
      }

      // We are gonna format the incoming dates

      moment.locale('es');

      datos = logList.data.map(log => ({
        _id: log._id,
        nameComponent: log.nameComponent,
        nameUser: log.nameUser,
        dateLog:
          moment((new Date(log.date)).toDateString())
            .format('MMMM Do YYYY, h:mm:ss a')
            .charAt(0)
            .toUpperCase() +
          moment(log.date)
            .format('MMMM Do YYYY, h:mm:ss a')
            .slice(1),
        action: log.action,
        aditionalData: log.aditionalData
      }));

      if (filteredLogs) {
        try{
          datos = filteredLogs.data.map(log => ({
            _id: log._id,
            nameComponent: log.nameComponent,
            nameUser: log.nameUser,
            dateLog:
              moment(log.date)
                .format('MMMM Do YYYY, h:mm:ss a')
                .charAt(0)
                .toUpperCase() +
              moment(log.date)
                .format('MMMM Do YYYY, h:mm:ss a')
                .slice(1),
            action: log.action,
            aditionalData: log.aditionalData
          }));
        }
        catch (error) {
          ModalResponse({
            title: 'Error al cargar los datos',
            text:
              '¡Error! Ha ocurrido un problema, inténtelo más tarde.',
            res: 404,
          });
        }
      }
    }
    return (
      <div>
        {error && <h4>Error: {error.response.data}</h4>}
        {isLoading ? (
          <Loader></Loader>
        ) : (
          <div className="use-antd">
            <h3>Logs de acciones</h3>
            {datos.length > 1000 ? (
              <div className="ftralert ftralert-warning">
                <h3 className="use-antd">
                La cantidad de registros es superior a 1000. Si desea ver más, por favor contacte con el administrador 
                </h3>
              </div>
            ) : (
              <div></div>
            )}
            <div>
              <br></br>
              <h5>Búsqueda especializada:</h5>
            </div>

            {
              <div className="gridLogSearch">
                <div className="columna1 ">
                  <input
                    id="inputUser"
                    className="siigoInput "
                    value={this.state.searchUser}
                    placeholder="Buscar por usuario"
                    onChange={this.changeSearchUser.bind(this)}
                  />
                </div>

                <div className="columna2 ml-4">
                  {this.state.searchDateRange[0] == '' ||
                  this.state.searchDateRange[1] == '' ? (
                    <div className="fila">
                      <input
                        type="date"
                        id="date1"
                        onChange={this.changeDateRange.bind(this)}
                      ></input>
                      <input
                        type="date"
                        id="date2"
                        onChange={this.changeDateRange.bind(this)}
                      ></input>
                    </div>
                  ) : (
                    <div className="fila">
                      <input
                        type="date"
                        id="date1"
                        value={this.state.searchDateRange[0]}
                        onChange={this.changeDateRange.bind(this)}
                      ></input>
                      <input
                        type="date"
                        id="date2"
                        value={this.state.searchDateRange[1]}
                        onChange={this.changeDateRange.bind(this)}
                      ></input>
                    </div>
                  )}
                </div>

                <div className="columna1 ml-4 mt-1 siigoInput ">
                  <select
                    name="select"
                    id="inptuSelectTipo"
                    onChange={this.changeSearchTipoAccion.bind(this)}
                    className="border-select"
                    defaultValue={this.state.searchTipoAccion}
                  >
                    <option value="Default">Escoger tipo de cambio</option>
                    <option value="Change plan">Cambio de Plan</option>
                    <option value="Create Company">Creación de Empresa</option>
                    <option value="Edit Company">Edición de Empresa</option>
                    <option value="Cancel Subscription">Cancelación de Suscripción</option>
                    <option value="Create Provider">Creación de Proveedor</option>
                    <option value="Edit Provider">Edición Proveedor</option>
                    <option value="Disable Provider">Inhabilitación Proveedor</option>
                    <option value="Create Origin">Creación de Origen</option>
                    <option value="Edit Origin">Edición Origen</option>
                    <option value="Disable Origin">Inhabilitación Origen</option>
                  </select>
                </div>

                <div className="columna">
                <button
                    type="button"
                    className="green-fill mr-2"
                    onClick={this.customLogSearch}
                  >
                    <span className="siicon icon-search searchMarket"></span>
                    <span>Buscar</span>
                  </button>

                  <button
                    type="button"
                    className="orange-fill"
                    onClick={this.cleanFilters}
                  >
                    Limpiar
                  </button>
                </div>
              </div>
            }

            {datos.length <= 0 ? (
              <div className="logsCenter">
                <h4 className="blue-h4">
                  No existen logs con los parámetros actuales.
                </h4>

                {this.showHelperBusqueda ? (
                  <div>
                    <p>
                      Nota: si desea Logs de una fecha diferente a la de{' '}
                      <b> hoy </b>(por defecto), por favor use la Búsqueda
                      personalizada.
                    </p>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            ) : (
              <TableReport
                columns={columns} // Data columns to table
                tabs={false} //Enable tabs
                tabsData={[]} //Load data to tabs check option on tabsData const
                headerCounterTab={true} //Counter tab option
                btnActions={btnActions}
                actionButtons={true} //Enable buttons (edit, delete, copy, open)
                pageSize={10} // Enable page size
                pagination={true} //Enable pagination (true, false)
                paginationNav={'arrows'} //Nav options (arrow, dot)
                paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
                maxSizePagination={6} //Size pagination
                defaultPaginationNumber={1} //Current pagination when start app
                paginationGrouped={10} //Grouped by page
                detailRow={false} //Open column detail with left arrow
                data={datos} //Data for table
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

LogList.propTypes = {
  actions: PropTypes.object.isRequired,
  logList: PropTypes.object,
  error: PropTypes.object,
  filteredLogs: PropTypes.object
};

const mapStateToProps = state => ({
  filteredLogs: state.logs.filteredLogs,
  logList: state.logs.logs,
  error: state.logs.err,
  isLoading: state.logs.isLoading,
  isLoadingDel: state.logs.isLoadingDel
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(LogAction, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LogList);
