import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ModalResponse from '../layout/modals/modalResponse';
import './style.scss';
import * as NotificationsActions from '../../actions/notificationAS/notificationAction';

let createNoti = false;
class NotificationCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textEditor: ''
    };
  }
  onHandleCreate = event => {
    event.preventDefault();

    let criterion = event.target.criterion.value;
    let description = event.target.description.value;
    let state = event.target.state.value;
    let subject = event.target.subject.value;
    let message = this.state.textEditor;
    const data = {
      criterion,
      description,
      state,
      subject,
      message
    };
    console.log('createNotification: ', data);
    this.props.actions.createNotification(data);
    createNoti = true;
  };

  componentDidMount() {
    document.title = 'Create Notification';
  }

  render() {
    const { notification, isLoading } = this.props;
    if (notification) {
      console.log('notification-create::: ', notification);
      if (!notification.data.status && createNoti && !isLoading) {
        ModalResponse({
          title: 'Crear Notificación',
          text: notification.data.errorMessage,
          res: 404
        });
        createNoti = false;
      }
      if (notification.data.status) {
        ModalResponse({
          title: 'Crear Notificación',
          text: 'Se creó la configuración de notificación correctamente',
          res: 200
        });
      }
    }
    return (
      <div>
        {notification ? (
          notification.data.status ? (
            <Redirect to="/notificationas" />
          ) : null
        ) : null}
        <form onSubmit={this.onHandleCreate}>
          <h3 className="h3Noti">Crear notificación</h3>
          <div className="input-container">
            <input
              type="text"
              name="criterion"
              id="criterion"
              placeholder="Criterio"
            />
          </div>
          <div className="input-container">
            <input
              type="text"
              name="description"
              id="description"
              placeholder="Descripcion"
            />
          </div>
          <div className="input-container">
            <select id="state" name="state">
              <option value="1">Activo</option>
              <option value="0">Inactivo</option>
            </select>
          </div>

          <h3 className="h3Noti">Contenido del correo</h3>
          <div className="input-container">
            <input
              type="text"
              name="subject"
              id="subject"
              placeholder="Asunto"
            />
          </div>
          <div className="input-container">
            <CKEditor
              editor={ClassicEditor}
              data="<p>Mensaje</p>"
              onInit={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('mensaje', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                this.setState({ textEditor: data });
                console.log({ event, editor, data });
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
          </div>
          <div>
            <button type="submit" className="button green-fill">
              {' '}
              Guardar
            </button>
          </div>
        </form>
      </div>
    );
  }
}

NotificationCreate.propTypes = {
  actions: PropTypes.object.isRequired,
  notification: PropTypes.object,
  error: PropTypes.object
};

const mapStateToProps = state => ({
  notification: state.synergy.notification,
  error: state.synergy.err,
  isLoading: state.synergy.isLoading
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(NotificationsActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationCreate);
