export const licenses = {
    "9410001000101": "Nuevo Siigo Nube",
    "9410001000106": "Siigo Nube Independiente",
    "9410001000107": "Siigo Nube Emprendedor",
    "9410001000108": "Siigo Nube Premium",
    "9380001000001": "Solo Facturación",
    "9380002000009": "Solo Facturacion 100 documentos",
    "9380002000013": "Solo Facturacion 80 documentos",
    "9380002000012": "Solo Facturacion 260 documentos"
}

