
let finalAccess = false;
export function getAccess(parentPath, accId) {
    const access = localStorage.getItem('compA');
    finalAccess = false;
    if (access && access != '[]' && access !== "") {
        const accessData = JSON.parse(access);
        validatePermisos(accessData, parentPath, accId);
    }
    return finalAccess;
}


function validatePermisos(accessData, parentPath, accId) {
    accessData.forEach(elem => {
        if (elem.path == parentPath) {
            if (elem.accessList.indexOf(accId) != -1) {
                finalAccess = true;
                return;
            }
        } else {
            if (elem.child?.length > 0) {
                validatePermisos(elem.child, parentPath, accId);
            }
        }
    })
}
