import axios from 'axios';
import config from '../../config';
import { getCookie } from '../../utils/cookies';

export class AxiosSubscription {

    static instanceAxios = axios.create({
        baseURL: config.urlMailProvider,
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
        },
        timeout: 100000
    });

    static getConfigAxios = (configAxios = {}, auth = true) => {
        if (configAxios === null || configAxios === undefined || typeof configAxios !== 'object') return {};
        configAxios.headers = auth ? { Authorization: 'bearer ' + getCookie('token'), ...configAxios.headers } : configAxios.headers;
        return configAxios;
    }

    static sendMail = (obj, configAxios = {}, auth = true) => {
        return AxiosSubscription.instanceAxios.post(config.pathSendMail, obj, AxiosSubscription.getConfigAxios(configAxios, auth));
    }

}