import React from 'react';
import MarketwebAccountantComponent from '../../components/marketwebAccountantPortal/index';

const MarketwebAccountantContainer = (props) => {
  return (
    <div>
      <MarketwebAccountantComponent parentPath={props.path} />
    </div>
  );
};

export default MarketwebAccountantContainer;
