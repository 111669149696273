import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalResponse from '../layout/modals/modalResponse';

import { createGroup } from '../../actions/groups/groupAction';
import { getDependences } from '../../actions/dependences/dependenceActions';
import Permisos from './permisos';
let save = false;
class GroupCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			permisos: [{ component: '', name: '', rol: 'readOnly' }]
		};
	}

	onHandleCreate = event => {
		event.preventDefault();

		let name = event.target.nameGroup.value;
		let description = event.target.description.value;
		let dependence = event.target.dependence.value;
		let permisos = this.state.permisos;

		const data = {
			name,
			description,
			dependence,
			permisos
		};

		this.props.dispatch(createGroup(data));
		save = true;
	};

	handleChange = e => {
		if (['component', 'name', 'rol'].includes(e.target.name)) {
			let permisos = [...this.state.permisos];
			permisos[e.target.dataset.id][e.target.name] = e.target.value;
			/*if (e.target.name == 'component') {
				var index = e.nativeEvent.target.selectedIndex;

				permisos[e.target.dataset.id]['name'] =
					e.nativeEvent.target[index].text;
			}*/
			this.setState({ permisos });
		}
	};

	addPermisos = e => {
		this.setState(prevState => ({
			permisos: [...prevState.permisos, { component: '', name: '', rol: '' }]
		}));
	};

	removePermiso = e => {
		let permisos = [...this.state.permisos];
		permisos.splice(e.target.dataset.id, 1);
		this.setState({ permisos });
	};

	componentDidMount() {
		document.title = 'Create Group';
		this.props.dispatch(getDependences());
	}

	render() {
		const { isLoading, group, dependences } = this.props;

		let deps = [];
		let text = '';
		if (group && save && !isLoading) {
			if (group.data.error === 'Forbidden') {
				return <div>No tiene permiso para este componente</div>;
			} else {
				if (group.status === 201) {
					this.props.history.push('/groups');
				} else {
					text = 'No fue posible guardar el Grupo';
				}
			}

			save = false;
		}

		if (dependences) {
			deps = dependences.data;
		}

		return (
			<div>
				{text !== '' ? (
					<ModalResponse title={'Crear!'} text={text} res={404} />
				) : null}
				<form onSubmit={this.onHandleCreate}>
					<h3>Crear Grupo</h3>
					<div className="input-container">
						<i className="sii sii-user icon" />
						<input
							type="text"
							name="nameGroup"
							id="nameGroup"
							placeholder="Nombre"
						/>
					</div>
					<div className="input-container">
						<i className="sii sii-user icon" />
						<input
							type="text"
							name="description"
							id="description"
							placeholder="Descripcion"
						/>
					</div>
					<div className="input-container">
						<i className="sii sii-user icon" />
						<select name="dependence" id="dependence">
							<option value="-9">Seleccionar Dependencia</option>
							{deps.map((dep, index) => {
								return (
									<option key={index} value={dep._id}>
										{dep.name}
									</option>
								);
							})}
						</select>
					</div>
					<h3>Permisos</h3>
					<Permisos
						permisos={this.state.permisos}
						change={this.handleChange}
						remove={this.removePermiso}
						isNew={true}
					/>
					<div className="center">
						<button
							type="button"
							className="btn btn-outline-primary margin-bottom-btn"
							onClick={this.addPermisos}
						>
							<i>+</i> Agregar Permiso
						</button>
					</div>
					<br />
					<div className="p-top-20 center">
						<button className="button green-fill">Guardar</button>
					</div>
				</form>
			</div>
		);
	}
}

GroupCreate.propTypes = {
	actions: PropTypes.object.isRequired,
	group: PropTypes.object,
	error: PropTypes.object
};

const mapStateToProps = state => ({
	group: state.groups.group,
	dependences: state.dependences.dependences,
	error: state.groups.err,
	isLoading: state.groups.isLoading
});

/*const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(GroupActions, dispatch)
});*/
export default connect(mapStateToProps)(withRouter(GroupCreate));
