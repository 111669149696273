import * as types from '../actions';

const componentReducer = (state = { isLoading: true }, action = null) => {
  switch (action.type) {
    case types.GET_COMPONENTS:
      return { ...state, isLoading: true };
    case types.COMPONENTS_RECEIVED:
      return { ...state, isLoading: false, components: action.data };
    case types.COMPONENTS_REQUEST_FAILED:
      return { ...state, isLoading: false, components: action.error };
    case types.GET_COMPONENT:
      return { ...state, isLoadingComp: true };
    case types.COMPONENT_RECEIVED:
      return { ...state, isLoadingComp: false, component: action.data };
    case types.COMPONENT_REQUEST_FAILED:
      return { ...state, isLoadingComp: false, component: action.error };
    case types.CREATE_COMPONENT:
      return { ...state, isLoading: true };
    case types.CREATE_COMPONENT_SUCCESS:
      return { ...state, isLoading: false, component: action.data };
    case types.CREATE_COMPONENT_FAILED:
      return { ...state, isLoading: false, component: action.error };
    case types.UPDATE_COMPONENT:
      return { ...state, isLoading: true };
    case types.UPDATE_COMPONENT_SUCCESS:
      return { ...state, isLoading: false, component: action.data };
    case types.UPDATE_COMPONENT_FAILED:
      return { ...state, isLoading: false, component: action.error };
    case types.DELETE_COMPONENT:
      return { ...state, isLoadingDel: true };
    case types.DELETE_COMPONENT_SUCCESS:
      return { ...state, isLoadingDel: false, component: action.data };
    case types.DELETE_COMPONENT_FAILED:
      return { ...state, isLoadingDel: false, component: action.error };
    default:
      return state;
  }
};

export default componentReducer;
