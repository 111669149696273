import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TabsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'all'
    };
  }

  activeTab = tab => {
    this.setState({ activeTab: tab.filterName });
    this.props.groupData(tab);
  };

  render() {
    const { tabs, data } = this.props;
    return (
      <div className="tabs">
        <ul>
          {tabs.data.map((item, key) => (
            <li
              key={key}
              className={
                item.filterName === this.state.activeTab ? `active tab` : `tab`
              }
            >
              <Link to="#" onClick={() => this.activeTab(item)}>
                {item.name}
                {item.filterName === 'all' ? (
                  <span className={`text ${item.color}`}>{data.length}</span>
                ) : (
                  <span className={`text ${item.color}`}>
                    {
                      data.filter(dt => dt[tabs.filterCol] === item.filterName)
                        .length
                    }
                  </span>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default TabsTable;
