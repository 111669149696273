import * as types from '../actions';

const auroraReducer = (state = { isLoading : false}, action = null) => {

    switch (action.type) {
        case types.GET_SHORTED_URL:
            return { ...state, isLoading: true };  

        case types.GET_SHORTED_URL_CLEARED:
            return { ...state, isLoading: false, shortedUrl: undefined };

        case types.GET_SHORTED_URL_RECEIVED:
            return { ...state, isLoading: false, shortedUrl: action.data }

        case types.GET_SHORTED_URL_FAILED:
            return { ...state, isLoading: false, err: action.error }
        
        default:
            return state;
    }
};

export default auroraReducer;