import * as types from '../index';

export const createLog = params => {
    return { type: types.CREATE_LOG, params };
};

export const createConfigOrigin = params => {
    return { type: types.CREATE_ORIGIN, params };
}

export const getConfigOrigin = params => {
    return { type: types.GET_ORIGIN, params };
} 

export const getConfigOrigins = params => {
    return { type: types.GET_ORIGINS, params };
} 

export const updateConfigOrigin = params => {
    return { type: types.UPDATE_ORIGIN, params };
}