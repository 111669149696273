import React, { Component } from 'react';
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react'

import Smenu from '../slayout/smenu/smenu';

import './smensajes.scss';
import Anfitrionperfil from '../sshared/anfitrionperfil/anfitrionperfil';

import noMessage from '../assets/img/no-message.png' 
import imgDiscovery from '../assets/img/discovery.png' 
import WithoutResult from '../sshared/withoutResult/withoutresult';
import { SearchBooking } from '../helpers/SiigerHelper';
import { getCookie } from '../../../utils/cookies';
import SviajePerfil from '../viajes/sviajesperfil';

class Smensajes extends Component {

    constructor(props) {
	    super(props);
        let _email = getCookie('email');

        this.state = { 
             withoutResult: false, 
             waitingResult: true,
             emailSiiger: _email
        };               
    } 

    componentDidMount() {
        this.setState({ 
            emailSiiger: this.state.emailSiiger,
            withoutResult: false, 
            waitingResult: true 
        }, () => 
            {
                //voy a buscar mis reservaciones
                SearchBooking(this.state.emailSiiger).then((response)  => {
                    var _data = response.data.documents;                          

                    console.log(_data);

                    //esto es por que el searchBooking, no solo traigo mis booking si no que traigo tambien
                    //traigo los que son de mi email, esto es para hacer la funcion de aceptar, rechazar hospedaje.

                    // if (_data != undefined){
                    //     _data = _data.filter(function(_si){
                    //         return _si.booking.length > 0;
                    //     });
                    // }

                    if ((_data == undefined)||
                        (_data.length == 0)||
                        (_data[0].booking == undefined)||
                        (_data[0].booking.length == 0)){
                        //Una vez que filtro, aquellos que no tienen booking, si no me queda nada... 
                        this.setState({   
                            withoutResult: true,   
                            waitingResult: false,            
                        });
                    }
                    else{                       
                        //búsqueda con resultados
                        this.setState({                             
                            lengthResult: _data.length,
                            searchResult: _data,
                            withoutResult: (_data.length == 0),
                            waitingResult: false,               
                        });           
                    }
                });
            }        
        )
    }   

    render(){
        if (this.state.withoutResult) {
            return (
                <>
                    <Smenu />
                    <WithoutResult img={ noMessage } msg="0 resultados :("/>
                </>
            )
        }

        if (this.state.waitingResult) {
            return (
                <>
                    <Smenu />
                    <WithoutResult img={ imgDiscovery } msg="Búscando..." />
                </>
            )
        }        


        const itemsWithBooking = this.state.searchResult.filter((item) => item.booking !== null);

        return(
            <>
                <Smenu />  
                <div className='container siiger'>
                {/* Esto es magia negra */}
                {itemsWithBooking.map(item => (  
                    <div key={item._id}>                        
                        {item.booking.map((boook, index) => ( 
                            <div key={boook.id}>
                                <SviajePerfil { ...item } hideActions={ true } fromMessage={ true } bookingIndex={ index }/>
                            </div>                            
                        )).reverse(a => a.state)}                        
                    </div>                                      
                ))} 
                </div> 
            </>
        );
    }
}

export default Smensajes;