import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api';

export function* createGroup(action) {
  const api = API.create();
  try {
    const { gro } = action;
    const groupDetail = yield call(api.groups.createGroup, gro); //.then(response => response.data);
    yield put({
      type: types.CREATE_GROUP_SUCCESS,
      data: groupDetail
    });
  } catch (err) {
    yield put({ type: types.CREATE_GROUP_FAILED, err });
  }
}

export function* updateGroup(action) {
  const api = API.create();
  try {
    const { gro, msisdn } = action;
    const groupDetail = yield call(api.groups.updateGroup, gro, msisdn); //.then(response => response.data);
    yield put({
      type: types.UPDATE_GROUP_SUCCESS,
      data: groupDetail
    });
    //yield put({ type: types.GET_GROUPS });
  } catch (err) {
    yield put({ type: types.UPDATE_GROUP_FAILED, err });
  }
}

export function* deleteGroup(action) {
  const api = API.create();
  const { msisdn } = action;
  try {
    console.log('entro en delete sagas');
    const response = yield call(api.groups.deleteGroup, msisdn);
    yield put({ type: types.DELETE_GROUP_SUCCESS, data: response });
    yield put({ type: types.GET_GROUPS });
  } catch (err) {
    yield put({ type: types.DELETE_GROUP_FAILED, err });
  }
}

export function* getGroups() {
  const api = API.create();
  try {
    let groups = yield call(api.groups.groups);
    yield put({ type: types.GROUPS_RECEIVED, data: groups });
  } catch (err) {
    yield put({ type: types.GROUPS_REQUEST_FAILED, err });
  }
}

export function* getGroup(action) {
  const api = API.create();
  try {
    const { msisdn } = action;
    let groups = yield call(api.groups.groupDetail, msisdn);
    yield put({ type: types.GROUP_RECEIVED, data: groups });
  } catch (err) {
    yield put({ type: types.GROUP_REQUEST_FAILED, err });
  }
}
