import * as types from '../index';

export const getSubscriptions = params => {
  return { type: types.GET_SUBSCRIPTIONS, params };
};

export const deleteSubscription = params => {
  return { type: types.DELETE_SUBSCRIPTION, params };
}

export const createLog = params => {
  return { type: types.CREATE_LOG, params };
}

export const sendExcelObject = params => {
  return { type: types.SEND_EXCEL_OBJECT, params };
};