import { Link, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import svgs from '../../layout/icons';
import { SiigoButtonDropdownAtom } from "@siigo/siigo-button-dropdown-atom-react";
class ButtonActions extends Component {
    render() {
        const {
            actionButtons,
            btnActions,
            item,
            filterName,
            deletePkg
        } = this.props;

        const handleIngresarClick = () => {
            const { history, btnActions, item, location } = this.props;
            const originUrl = location.pathname;
            history.push({
                pathname: `${btnActions.btnIngresar}${item.CloudTenantID}`,
                state: { company: item, from: originUrl }
            });
        };


        const renderActions = () => {
            if (actionButtons) {
                if (filterName) {
                    return buttonActionsWithFilterName();
                }
                //else
                return buttonActionsWithOutFilterName();
            }
        }

        const buttonActionsWithFilterName = () => {
            if (btnActions.btnDelete) {
                return (
                    <button
                        onClick={(e) => btnActions.btnDelete(item._id)}
                        className="button red no-pad"
                    >
                        Ingresar
                    </button>
                );
            }
            if (btnActions.btnEdit) {
                return (
                    <Link to={btnActions.btnEdit + item._id}>Editar</Link>
                );
            }
            if (btnActions.btnShow) {
                return (
                    <button
                        onClick={(e) =>
                            btnActions.btnShow(item.aditionalData)
                        }
                        className="button gray no-pad"
                    >
                        Ver detalles
                    </button>
                );
            }
            if (btnActions.btnCopyUrl) {
                return (
                    <button
                        onClick={(e) =>
                            btnActions.btnCopyUrl(item.invoiceUrl)
                        }
                        className="button gray no-pad"
                    >
                        Copiar URL
                    </button>
                );
            }
            if (btnActions.btnResendInvoice) {
                return (
                    <button
                        onClick={(e) =>
                            btnActions.btnResendInvoice(item.invoiceUrl)
                        }
                        className="button gray no-pad"
                    >
                        Re enviar.
                    </button>

                );
            }
        }

        const buttonActionsWithOutFilterName = () => {
            let result = []
            if (btnActions.btnEdit) {
                result.push(
                    <Link to={btnActions.btnEdit + item._id}>
                        <img
                            className="icon-editar"
                            src={svgs['editar']}
                            alt=""
                        />
                    </Link>
                );
            }

            if (btnActions.btnDelete) {
                result.push(
                    <img
                        onClick={(e) => btnActions.btnDelete(item._id)}
                        className="icon-eliminar"
                        src={svgs['eliminar']}
                        alt=""
                    />

                );
            }
            if (btnActions.btnIngresar) {
                result.push(
                    <button
                        className="a-button green"
                        onClick={handleIngresarClick}
                    >
                        Ingresar
                    </button>
                );
            }

            if (btnActions.btnShow) {
                result.push(
                    <button
                        onClick={(e) => btnActions.btnShow(item.aditionalData)}
                        className="button gray no-pad"
                    >
                        Ver detalles
                    </button>
                );
            }

            if (btnActions.btnShowDetail) {
                result.push(
                    <div
                        onClick={(e) => btnActions.btnShowDetail(item)}
                        className="btnView"
                        title="Ver detalle"
                    >
                        <i className="siicon icon-ver" alt="Ver detalle"></i>
                    </div>
                );
            }

            if (btnActions.btnResendEmail) {
                result.push(
                    <div
                        onClick={(e) => btnActions.btnResendEmail(item)}
                        className="btnView"
                        title="Re enviar correo"
                    >
                        <i
                            className="siicon icon-siigo-app-correo"
                            alt="Re enviar correo"
                        ></i>
                    </div>
                );
            }

            if (btnActions.btnModifyAccountant) {
                result.push(
                    <div
                        onClick={(e) => btnActions.btnModifyAccountant(item)}
                        class="btnView"
                        title="Modificar registro"
                    >
                        <i
                            className="siicon icon-siigo-app-editar"
                            alt="Modificar registro"
                        ></i>
                    </div>);
            }

            if (btnActions.btnShowEditCreditCard && (String(item.isActive) === "1" || String(item.isActive) === "true" || String(item.isActive) === "Activa")) {
                result.push(
                    <div
                        onClick={(e) => btnActions.btnShowEditCreditCard(item)}
                        class="btnView"
                        title="Editar Metodo de Pago"
                    >
                        <i
                            className="siicon icon-siigo-app-editar"
                            alt="Editar Metodo de Pago"
                        ></i>
                    </div>
                );
            }
            if (btnActions.btnDeleteSubscription && (String(item.isActive) === "1" || String(item.isActive) === "true" || String(item.isActive) === "Activa")) {
                result.push(
                    <div

                        onClick={(e) => btnActions.btnDeleteSubscription(item)}
                        class="btnView"
                        title={String(item.isActive) === "1" || String(item.isActive) === "true" || String(item.isActive) === "Activa" ? "Eliminar Suscripción" : "Suscripción Cancelada"}

                    >
                        <i
                            className="siicon icon-deleteX"
                            alt="Eliminar Suscripción"
                        ></i>
                    </div>
                );
            }
            if (btnActions.btnDisableProvider && item.state !== "Inactive") {
                result.push(
                    <div

                        onClick={(e) => btnActions.btnDisableProvider(item)}
                        class="btnView"
                        title={item.state !== "Inactive" ? "Deshabilitar Proveedor" : "Proveedor Deshabilitado"}

                    >
                        <i
                            className="siicon icon-deleteX"
                            alt="Deshabilitar Proveedor"
                        ></i>
                    </div>
                );
            }
            if (btnActions.btnDeletePacket) {
                result.push(
                    <div
                        onClick={(e) => !deletePkg ? null : btnActions.btnDeletePacket(e, item)}
                        class={!deletePkg ? null : "btnView"}
                        title={!deletePkg ? null : "Eliminar"}
                    >
                        <img
                            className={!deletePkg ? null : "icon-eliminar"}
                            src={!deletePkg ? null : svgs['eliminar']}
                            alt=""
                        />
                    </div>
                );
            }
            if (btnActions.btnCopyUrl) {
                result.push(
                    <button
                        onClick={(e) => btnActions.btnCopyUrl(item.invoiceUrl)}
                        className="button green"
                    >
                        Copiar URL
                    </button>
                );
            }
            if (btnActions.btnResendInvoice) {
                result.push(
                    <button
                        onClick={(e) =>
                            btnActions.btnResendInvoice({
                                invoiceId: item['invoiceId'],
                                name: item['name'],
                                email: item['email'],
                                invoiceUrl: item['invoiceUrl'],
                            })
                        }
                        className="button orange"
                    >
                        Reenviar
                    </button>
                );
            }
            if (btnActions.btnDropDown) {
                result.push(
                    <SiigoButtonDropdownAtom
                        text={btnActions.label}
                        type="split"
                        size="medium"
                        color="secondary"
                        options={btnActions.btnOptions}
                        onClickSplitButton={btnActions?.clickBtn?.bind(this, item)}
                        onSelectOption={btnActions?.clickOption?.bind(this, item)}
                    ></SiigoButtonDropdownAtom>
                )
            }

            if (btnActions.btnDisableOrigin && item.state !== "Inactive") {
                result.push(
                    <div
                        onClick={(e) => btnActions.btnDisableOrigin(item)}
                        class="btnView"
                        title={item.state !== "Inactive" ? "Deshabilitar Origen" : "Origen Deshabilitado"}
                    >
                        <i
                            className="siicon icon-deleteX"
                            alt="Deshabilitar Origen"
                        ></i>
                    </div>
                )
            }

            if (btnActions.btnEditOrigin) {
                result.push(
                    <img
                        onClick={(e) => { window.location.assign(encodeURI(btnActions.btnEditOrigin + item._id)); }}
                        className="icon-editar"
                        style={{ cursor: "pointer" }}
                        src={svgs['editar']}
                        alt=""
                    />
                );
            }


            if (btnActions.btnRemoveBlackListShow) {
                if (item.removeFlag) {
                    result.push(
                        <button
                            onClick={(e) => btnActions.btnRemoveBlackList(item._id)}
                            className="button red no-pad"
                        >
                            Eliminar
                        </button>
                    );
                }
            }

            if (btnActions.btnRefund && !item.data?.refundTransactionId && !item.data?.refundStatus) {
                result.push(
                    <div
                        onClick={(e) => btnActions.btnRefund(item)}
                        class="btnView"
                        title="Reembolsar pago"
                    >
                        <i
                            className="siicon icon-siigo-app-editar"
                            alt="Reembolsar Pago"
                        ></i>
                    </div>
                );
            }

            return result;
        }
        return (
            <div>{renderActions()} </div>
        );
    }
}



export default withRouter(ButtonActions);