import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/indexActivationManagement';

export function* saveMenuActivate(action) {
	const { params } = action;
  const api = API.create();
  const formData = new FormData();
  formData.append('Module', 1);
  formData.append('File', params, params.name);

  try {
		const response = yield call(api.activationManagement.saveMenuActivate, formData);
    yield put({ type: types.SAVE_ACTIVATION_MANAGEMENT_RECEIVED, data: response.data });
  } catch (err) {
    yield put({ type: types.SAVE_ACTIVATION_MANAGEMENT_REQUEST_FAILED, err });
  }
}

export function* removeMenuActivate(action) {
  const { params } = action;
  const api = API.create();
  const formData = new FormData();
  formData.append('Module', 1);
  formData.append('File', params, params.name);

  try {
   const response = yield call(api.activationManagement.removeMenuActivate, formData);
    yield put({ type: types.REMOVE_ACTIVATION_MANAGEMENT_RECEIVED, data: response.data });
  } catch (err) {
    yield put({ type: types.REMOVE_ACTIVATION_MANAGEMENT_REQUEST_FAILED, err });
  }
}
