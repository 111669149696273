import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sheadmodal from '../slayout/sheader/sheadermodal';
import SquareIconData from '../sshared/SquareIconData/SquareIconData';
import SviajePerfil from '../viajes/sviajesperfil';

class SperfilAlojamiento extends Component {
    constructor(props) {
	    super(props);
        let _siiger = (this.props.host == undefined) ? this.props.location.state : this.props;
        let _searchFilter = (this.props.host == undefined) ? this.props.location.state.travel : undefined;
        
        this.state = {
            siiger: _siiger,
            searchFilter: _searchFilter,
            fromPerfil: (this.props.host != undefined),
            fromViaje: (this.props.host == undefined)
        }
    }

    render(){

        function GetInitialName(n){
            const myArray = n.split(" ");
            let _ini = myArray[0][0] + myArray[myArray.length - 1][0];
            return _ini;
        }

        return(
            <>

            {this.state.fromViaje  
                ? <Sheadmodal urlReturn="/siigers/viajes" caption="Seguir buscando..."/>
                : <></>
            }

            <div className='container siiger'>
                {this.state.fromViaje  
                    ? <SviajePerfil { ...this.state.siiger } hideActions={ true } />                      
                    : <></>
                }            
            
                <div id="DivConPerfil" className='scard'>                            
                    <div className='row'>
                        <h4>Tipo de alojamiento</h4>
                    </div>
                    <div className='row'>
                        {{
                            1 : <SquareIconData icon={"fa-solid fa-hotel"} label={"APARTAMENTO"}/>,
                            2 : <SquareIconData icon={"fa-solid fa-house-chimney-user"} label={"CASA"}/>,                   
                            default: <SquareIconData icon={"fa-solid fa-bug"} label={"ups!"}/>                                                             
                        }[this.state.siiger.host.type]}
                    </div>                            

                    <div className='row'>
                        <h4>Comodidades y características</h4>
                    </div>               
                    <div className='row'>             
                        {(this.state.siiger.host.tentAllow ? <SquareIconData icon={"fa-solid fa-campground"} label={"CARPA"} /> : null )}
                        {(this.state.siiger.host.garage ? <SquareIconData icon={"fa-solid fa-square-parking"} label={"PARQUEO"} /> : null )}
                        {/* {(props.host.tentAllow ? <SquareIconData icon={"fa-solid fa-bed"} label={"CAMA INFLABLE"} /> : null )} */}
                    </div>
                    <div className='row'>
                        <h4>Tipo de anfitrión</h4>
                    </div>    
                    <div className='row'>
                    
                        {{
                            1: <SquareIconData icon={"fa-solid fa-user"} label={"SOLO"} />, 
                            2: <SquareIconData icon={"fa-solid fa-people-group"} label={"FAMILIA"} />,
                            default: <SquareIconData icon={"fa-solid fa-bug"} label={"ups!"}/> 
                        }[this.state.siiger.host.familyHost]}

                        {(this.state.siiger.host.pets ? <SquareIconData icon={"fa-solid fa-cat"} label={"GATOS"} /> : null )}
                        {(this.state.siiger.host.pets ? <SquareIconData icon={"fa-solid fa-dog"} label={"PERROS"}/>: null )}

                    </div>
                    <div className='row'>
                        <h4>Espacio 1</h4>
                    </div>            

                    <div className='row'>
                        {{
                            1: <SquareIconData icon={"fa-solid fa-user"} label={"HABITACIÓN INDIVIDUAL"} />,
                            2: <SquareIconData icon={"fa-solid fa-people-group"} label={"HABITACIÓN COMPARTIDA"} />,
                            default:  <SquareIconData icon={"fa-solid fa-bug"} label={"ups!"}/>                                                             
                        }[this.state.siiger.host.place[0].placetype]}
                        
                        {{
                            1: <SquareIconData icon={"fa-solid fa-bed"} label={"CAMA SIMPLE"} />,
                            2: <SquareIconData icon={"fa-solid fa-bed"} label={"CAMA DOBLE"} />,
                            3: <SquareIconData icon={"fa-solid fa-bed"} label={"CAMA INFABLE SIMPLE"} />,
                            4: <SquareIconData icon={"fa-solid fa-bed"} label={"CAMA INFABLE DOBLE"} />,
                            5: <SquareIconData icon={"fa-solid fa-bed"} label={"SLEEPING BAG"} />,
                            default:  <SquareIconData icon={"fa-solid fa-bug"} label={"ups!"}/>                                                             
                        }[this.state.siiger.host.place[0].bedtype]}

                        {(this.state.siiger.host.place[0].peoplecount == 1)
                            ? <SquareIconData icon={"fa-solid fa-user"} label={"1 HUÉSPED"} />                
                            : <SquareIconData icon={"fa-solid fa-people-group"} label={ this.state.siiger.host.place[0].peoplecount + " HUÉSPEDES"} />                
                        }
                    </div>
                    
                    <hr className='siiger'></hr>
                    {this.state.fromViaje  
                        ?<Link to={{ pathname: "/siigers/viajes/reservas", state: this.state.siiger, searchFilter: this.state.searchFilter }}>   
                            <SiigoButtonAtom
                                id='searchBuscarViajesButton'
                                color="primary-green"
                                size="l"
                                type="button"
                                text="Solicitar reserva"
                                icon="fas"                                    
                            />                     
                        </Link>
                        :<Link to="/siigers/perfil/anfitrion">
                            <SiigoButtonAtom
                                id='searchBuscarViajesButton'
                                color="primary"
                                size="l"
                                type="button"                            
                                text="Editar perfil de anfitrión"
                                icon="fas"                                    
                            />                     
                        </Link>
                    }
                </div>    
            </div>
            </>    
        )
    }
}

export default SperfilAlojamiento;