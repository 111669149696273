import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    footer: {
        border: "solid 0.1rem black",
        "border-top-left-radius": "15px",
        "border-bottom-left-radius": "15px",
        "border-bottom-right-radius": "15px",
        "border-top-right-radius": "15px",
        padding: "1rem",
        "font-size": "12px",
        color: "#000",
        display: "flex",
        "align-items": "center"
    },
    list: {
        padding: "0.5rem 1rem",
        "list-style": "disc",
        color: "#000",
    },
    primaryButton: {
        "&:hover": {
            color: "white",
        },
    },
    secondaryButton: {
        "&:hover": {
            backgroundColor: theme.palette.secondary,
            color: "white",
        },

    },
}));

export default function InformationalFooter({ content }) {
    const classes = useStyles();
    const elementTemplates = {
        text: (element, size) => (
            <Grid item xs={size}>
                {
                    element?.content ? <Typography variant="inherit" gutterBottom>
                        {element.content}
                    </Typography> : null
                }
                {
                    element?.list ? <ul className={classes.list}>
                        {element.list.children.map((child, index) => <li key={index}>{child.text}</li>)}
                    </ul> : null
                }
            </Grid>
        ),
        button: (element, size) => (
            <Grid item xs={size}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.secondaryButton}
                    onClick={element.action}
                >
                    {element.text}
                </Button>
            </Grid>
        )
    }

    return (
        <Grid container className={classes.footer}>
            {Object.keys(content).map((key) => elementTemplates?.[key]?.(content[key], content[key].size))}
        </Grid>
    )
}