import React, { Component } from 'react';
import Campaign from '../../../components/shop/campaign/index';

class CampaignContainer extends Component {
  render() {
    return (
      <div>
        <Campaign />
      </div>
    );
  }
}

export default CampaignContainer;
