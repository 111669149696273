import * as types from '../actions';

const auroraSubscriptionReducer = (state = { isLoading: false }, action = null) => {

    switch (action.type) {
        case types.GET_SUBSCRIPTIONS:
            return { ...state, isLoading: true };

        case types.GET_SUBSCRIPTIONS_RECEIVED:
            return { ...state, isLoading: false, subscriptions: action.data }

        case types.GET_SUBSCRIPTIONS_FAILED:
            return { ...state, isLoading: false, subscriptions: action.error }

        case types.DELETE_SUBSCRIPTION:
            return { ...state, isLoadingDelete: true };

        case types.DELETE_SUBSCRIPTION_RECEIVED:
            return { ...state, isLoadingDelete: false, deleteResponse: action.data }

        case types.DELETE_SUBSCRIPTION_FAILED:
            return { ...state, isLoadingDelete: false, deleteResponse: action.error }

        default:
            return state;
    }
};

export default auroraSubscriptionReducer;