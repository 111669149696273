import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/es';
import { formatDate, parseDate } from 'react-day-picker/moment';

export default class SearchFilter extends React.Component {
    constructor(props) {
        super(props);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.handleFiltrarSubscriptions = this.handleFiltrarSubscriptions.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);

        this.state = {
            from: this.props.from ? this.props.from : undefined,
            to: this.props.to ? this.props.to : undefined,
            filter: this.props.filter,
            disablebDownload: this.props.disablebDownload,
            downloading: this.props.downloading,
            datos: this.props.datos,
            locale: 'es',
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.from !== this.state.from) {
            this.setState({ from: nextProps.from });
        }
        if (nextProps.to !== this.state.to) {
            this.setState({ to: nextProps.to });
        }
        if (nextProps.filter !== this.state.filter) {
            this.setState({ filter: nextProps.filter });
        }
        if (nextProps.downloading !== this.state.downloading) {
            this.setState({ downloading: nextProps.downloading });
        }
        if (nextProps.disablebDownload !== this.state.disablebDownload) {
            this.setState({ disablebDownload: nextProps.disablebDownload });
        }
        if (nextProps.datos !== this.state.datos) {
            this.setState({ datos: nextProps.datos })
        }
    }

    handleChangeInput(e) {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
        this.props.setFilterValue(e.target.value);
    }

    handleFiltrarSubscriptions(e) {
        e.preventDefault();
        this.props.filtrarSubscriptions(e);
    }

    handleFromChange(from) {
        this.setState({ from });
        this.props.handleDate({ from: from, to: this.state.to });
    }

    handleToChange(to) {
        this.setState({ to: to });
        this.props.handleDate({ from: this.state.from, to: to });
    }

    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        return (
            <form onSubmit={this.handleFiltrarSubscriptions} style={{ width: "100%" }} >

                <div className="divSearchFilter">
                    <br style={{ padding: "5px" }} />
                    <div className={!this.state.disablebDownload ? "gridSearchFilter2" : "gridSearchFilter"}>
                        <div className="itemSFDate">
                            <DayPickerInput
                                inputProps={{ className: "inputDatePicker datePickerFilter" }}
                                value={from}
                                placeholder="Desde"
                                format="LL"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                dayPickerProps={{
                                    selectedDays: [from, { from, to }],
                                    disabledDays: { after: to },
                                    toMonth: to,
                                    modifiers,
                                    numberOfMonths: 1,
                                    onDayClick: () => this.to.getInput().focus(),
                                    localeUtils: MomentLocaleUtils,
                                    locale: this.state.locale
                                }}
                                onDayChange={this.handleFromChange}

                            />
                            <i className="arrow down"></i>
                        </div>
                        <div className="itemSFDate">
                            <DayPickerInput
                                inputProps={{ className: "inputDatePicker datePickerFilter" }}
                                ref={el => (this.to = el)}
                                value={to}
                                placeholder="Hasta"
                                format="LL"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                dayPickerProps={{
                                    selectedDays: [from, { from, to }],
                                    disabledDays: { before: from },
                                    modifiers,
                                    month: from,
                                    fromMonth: from,
                                    numberOfMonths: 1,
                                    localeUtils: MomentLocaleUtils,
                                    locale: this.state.locale
                                }}
                                onDayChange={day => { this.handleToChange(day); }}
                            />
                            <i className="arrow down"></i>
                        </div>
                        <div className="row itemSFInput" title="Filtrar por: Rango de fechas / RUC / Identificación / Nombre / Correo / N° Cotización / Id Primer Pago">
                            <span className="iconSearch siicon icon-search"></span>
                            <input
                                className="imputSearchFilter"
                                id="filter"
                                name="filter"
                                type="text"
                                placeholder="RUC / Nombre / Correo / N° Cotización..."
                                onChange={e => { this.handleChangeInput(e) }}
                                value={this.state.filter ? this.state.filter : ""}
                                maxLength="50"
                            />

                            <button style={{ margin: "0px", borderColor:"#69aa29", border:"5px" }} type="submit" className="green-fill">
                                Buscar
                            </button>
                        </div>
                        <div title="Limpiar Filtros" className="itemClear">
                            {/* <i style={{ textAlign: "right", fontSize: "35px", fontWeight: "lighter" }} alt="Limpiar Filtros" className="siicon icon-reload"></i> */}
                            <button
                                onClick={e => { this.props.cleanFilter(e) }}
                                className="orange-fill"
                                type="button"
                            >
                                Limpiar
                            </button>
                        </div>
                        {this.state.disablebDownload ?
                            <div onClick={e => { this.props.onHandleDownload(this.state.datos) }} title="Descargar Reporte Excel" className="itemDownload">
                                {!this.state.downloading ?
                                    <i style={{ textAlign: "right", fontSize: "35px", fontWeight: "lighter" }} alt="Descargar Reporte Excel" className="siicon icon-excel"></i>
                                    : <div><div className="loader btnLoadInterSubs" >
                                        <div title="Descargando Reporte Excel..." style={{ marginRight: "54px", marginBottom: "12px" }} className="lds-ring btnLoadInterSubs" >
                                            <div className="btnLoadInterSubs divLoadSubs" ></div>
                                            <div className="btnLoadInterSubs divLoadSubs" ></div>
                                            <div className="btnLoadInterSubs divLoadSubs" ></div>
                                            <div className="btnLoadInterSubs divLoadSubs" ></div>
                                        </div>
                                    </div></div>
                                }
                            </div>
                            : null}


                    </div>
                </div>
            </form>
        );
    }
}