function replaceInitialConfigurationData(configurations) {
  let output = [];
  // Se agrega una última posición sin datos para que la interfaz quede acorde a la implementación
  configurations.push({});
  configurations.forEach((element, index) => {
    output.push({
      id: index,
      productCodes: element.products || [],
      discountPercentage: element.discount?.value || null,
      period: element.period?.type || "",
      numberOfInstallment: element.period?.installments?.number || "",
      installmentMinimumValue: element.period?.installments?.minimumValue || ""
    })
  });
  return output;
}

export default {
  replaceInitialConfigurationData,
};
