// a library to wrap and simplify api calls
import apisauce from 'apisauce';
import Leads from './services/Leads';
import Notification from './services/NotificationAS';
import Account from './services/Account';
import Accountant from './services/Accountant';
import Aurora from './services/Aurora';
import config from '../../config.js';
import Contracts from './services/Contracts';
import AuthJwt from './services/jwtAuth';
import Package from './services/Package';
import NewPackage from './services/NewPackage';
import AuroraSubscription from './services/AuroraSubscription';
import AuroraPayment from './services/AuroraPayment ';
import AuroraTokens from './services/AuroraTokens';

// our "constructor"
const create = (baseURL = config.urlHubspot) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    // 10 second timeout...
    timeout: 100000,
    mode: 'no-cors'
  });

  const leads = new Leads(api);
  const notification = new Notification(api);
  const account = new Account(api);
  const accountant = new Accountant(api);
  const aurora = new Aurora(api);
  const contracts = new Contracts(api);
  const authJwt = new AuthJwt(api);
  const packages = new Package(api);
  const newPackages = new NewPackage(api);
  const auroraSubscription = new AuroraSubscription(api);
  const auroraPayment = new AuroraPayment(api)
  const auroraTokens = new AuroraTokens(api);
  //  return Api
  return {
    setHeader: (key, value) => api.setHeader(key, value),
    removeHeader: (key) => api.deleteHeader(key),
    // a list of the API functions from step 2
    leads,
    notification,
    account,
    accountant,
    aurora,
    contracts,
    authJwt,
    packages,
    newPackages,
    auroraSubscription,
    auroraPayment,
    auroraTokens
  };
};

// let's return back our create method as the default.
export default {
  create,
};
