import React, { Component } from 'react';
import RefundAurora from '../../components/refundAurora';

class RefundAuroraContainer extends Component {
  render() {
    return (
      <div>
        <RefundAurora parentPath={this.props.path} />
      </div>
    );
  }
}

export default RefundAuroraContainer;
