import * as types from '../index';


export const sendExcelObjectSynergy = params => {
  return { type: types.SEND_EXCEL_OBJECT_SYNERGY, params };
};


export const downloadFileSynergy = () =>{
  return { type: types.DOWNLOAD_FILE_SYNERGY }
}