import React, { Component } from 'react';
import StatePaymentReport from '../../components/statePaymentReport';

class StatePaymentReportContainer extends Component {

  render() {
    return (
      <div>
        <StatePaymentReport parentPath={this.props.path} />
      </div>
    );
  }
}

export default StatePaymentReportContainer;