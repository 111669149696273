import React, { Component } from 'react';
import './loader.scss';

class Loader extends Component {
    render(){
        const loaderClass = this.props.full ? 'loader full': 'loader';
        return(
            <div className={loaderClass}>
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }
}

export default Loader;
