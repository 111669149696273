import { SiigoPanelModalMolecule } from "@siigo/siigo-panel-modal-molecule-react";
import React, { useEffect, useState } from "react";

const ModalResendEmail = ({ showModal, handleCloseModal, emailDataChange, resendEmails, t }) => {

    const [emails, setEmails] = useState("");
    const [labelClass, setLabelClass] = useState("labelOk");
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    
    useEffect(() => {
        if (emailDataChange && emailDataChange.email) {
            setEmails(emailDataChange.email);
            setValidationMessage("");
            setLabelClass("labelOk");
        }
    }, [emailDataChange]);

    const handleEmailChange = (e) => {
        const emailList = e.target.value;
        const emailArray = emailList.split(',').map(email => email.trim()).filter(email => email);
        setEmails(emailList);
        if (emailList === "") {
            setLabelClass("labelError");
            setIsSubmitDisabled(true);
            setValidationMessage(t("invoice.report.modalResendMails.fieldRequired"));
            return;
        } else {
            setValidationMessage('');
            validateEmails(emailArray);
            setEmails(emailList);
        }

    };

    const validateEmails = (emailArray) => {
        const specialCharRegex = /[%#!|()&]/;
        const hasInvalidSpecialChars = emailArray.some(email => specialCharRegex.test(email));

        if (hasInvalidSpecialChars || emailArray.length > 5) {
            setLabelClass("labelError");
            setValidationMessage(t("invoice.report.modalResendMails.fieldVerify"));
            setIsSubmitDisabled(true);
        } else {
            setLabelClass("labelOk");
            setIsSubmitDisabled(false);
        }
    };

    const handleResendEmailData = () => {
        const emailFormatRegex = /.+@.+\..+/;
        const emailArray = emails.split(',').map(email => email.trim()).filter(email => email);
        const hasInvalidFormat = emailArray.some(email => !emailFormatRegex.test(email));
        const hasInvalidCharCount = emailArray.some(email => {
            const atCount = (email.match(/@/g) || []).length;
            const dotCount = (email.match(/\./g) || []).length;
            return atCount !== 1 || dotCount < 1;
        });

        if (hasInvalidFormat || hasInvalidCharCount) {
            setLabelClass("labelError");
            setValidationMessage(t("invoice.report.modalResendMails.fieldVerify"));
            return;
        }
        emailDataChange = { ...emailDataChange, email: emails };
        resendEmails(emailDataChange)
    };
    const actionsStyle = {
        display: 'flex',
        flexDirection: 'row',
        gap: '15px',
        justifyContent: 'center',
    };

    return (
        
        <SiigoPanelModalMolecule
            title="Reenvío de mails"
            openModal={showModal}
            onClosePanelModal={handleCloseModal}
        >
            <div className="content">
                <div className="input-container">
                    <div className="column">
                        <div className="input-container">
                            <label className={labelClass}>{t("invoice.report.modalResendMails.labelTitle")}</label>
                            <input
                                className="inputFilter"
                                id="emails"
                                name="emails"
                                type="text"
                                placeholder="Correo electrónico"
                                value={emails}
                                onChange={handleEmailChange}
                            />
                            <label className="labelError">{validationMessage}</label>
                        </div>
                        <div className="center">
                            <span>{t("invoice.report.modalResendMails.fixedMessage")}</span>
                        </div>
                    </div>
                </div>
                <div className="actions" style={actionsStyle}>
                    <button className="red" onClick={handleCloseModal}>
                        Cancelar
                    </button>
                    <button className="green-fill" type="button" onClick={handleResendEmailData} disabled={isSubmitDisabled}>
                        Reenviar
                    </button>
                </div>
            </div>

        </SiigoPanelModalMolecule>
    )
}

export default ModalResendEmail;