import React from "react";
import { withRouter, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as DataSourceActions from '../../actions/dataSource/dataSourceActions';
import { withTranslation } from 'react-i18next';

import { SiigoNotificationsInlineMolecule } from "@siigo/siigo-notifications-inline-molecule-react";
import { SiigoButtonAtom } from "@siigo/siigo-button-atom-react";

import Loader from '../tools/loader';

import FormDataSource from "./formDataSource";

import moment from "moment";
import "moment/locale/es"

class EditDataSource extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: false,
            goBack: false
        }
    }

    
    componentDidMount(){
        this.props.actions.getDetailDataSource(this.props.id);
    }

    getFormatDate(date){
        if(date){
            return moment(date).locale('es').format("MMMM Do YYYY, h:mm:ss a");
        }else{
            return "";
        }
    }

    render(){
        return (
            <div>
                {this.props.error ? (
                    <div className="row mt-3">
                        <SiigoNotificationsInlineMolecule
                            style={{ width: "100%" }}
                            eventTimeout={90000}
                            textBody={{
                                text: this.props.messageError
                            }}
                            type="error"
                        />  
                    </div>
                ) : null}
                {this.state.goBack ? (
                    <Redirect 
                        to={{
                            pathname: "/datareport"
                        }}
                    />
                ) : null}
                <div className="row mt-4">
                    <div className="column2">
                        <SiigoButtonAtom
                            ariaLabel="click here"
                            color="primary-blue"
                            icon="fas fa-solid fa-arrow-left"
                            iconPosition="start"
                            onClick={() => this.setState({ goBack: true})}
                            size="m"
                            text="Regresar"
                            type="button"
                        />
                    </div>
                </div>
                {this.props.isLoading ? (
                    <Loader/>
                ) : (
                    <>
                        <h2 className="mt-3">Editar Reporte <span style={{ color: "rgb(0, 157, 255)" }}>{this.props.detailDatasource?.nameReport}</span></h2>
                        <div className="row mt-3" style={{ display: "block" }}>
                            <p><span style={{ fontWeight: "bold" }}>Ultima modificacion: </span>{this.getFormatDate(this.props.detailDatasource?.lastModified)}</p>
                            <p><span style={{ fontWeight: "bold" }}>Por: </span>{this.props.detailDatasource?.userModified?.name} | {this.props.detailDatasource?.userModified?.email}</p>
                        </div>

                        <h5 className="mt-4">Edición de reportes</h5>
                        <FormDataSource
                            propsFather={this.props}
                            isEdit
                            dataDetail={this.props.detailDatasource}
                        />
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    error: state.datasource.error,
    messageError: state.datasource.messageError,
    dataProperties: state.datasource.dataProperties,
    isLoading: state.datasource.isLoading,
    isLoadingProperties: state.datasource.isLoadingProperties,
    isLoadingCollection: state.datasource.isLoadingCollection,
    isLoadingCreatedData: state.datasource.isLoadingCreatedData,
    dataCollection: state.datasource.dataCollection,
    successSave: state.datasource.successSave,
    editSuccess: state.datasource.editSuccess,
    detailDatasource: state.datasource.detailDatasource,
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(DataSourceActions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(EditDataSource), withRouter(EditDataSource));