import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

const SiigoDualListBox = ({ selected, onSelected, options, t}) => {

    return (
        <DualListBox
            className="mt-3"
            canFilter
            options={options} 
            selected={selected} 
            onChange={value => onSelected(value)}
            icons={{
                moveLeft: '<',
                moveAllLeft: '<<',
                moveRight: '>',
                moveAllRight: '>>'
            }}
            lang={{
                filterPlaceholder: t('dataSourceReport.configDualListBox.filterPlaceholder'),
                moveAllLeft: t('dataSourceReport.configDualListBox.moveAllLeft'),
                moveAllRight: t('dataSourceReport.configDualListBox.moveAllRight'),
                moveLeft: t('dataSourceReport.configDualListBox.moveLeft'),
                moveRight: t('dataSourceReport.configDualListBox.moveRight')
            }}
        />
    );
}

SiigoDualListBox.propTypes = {
    selected: PropTypes.array.isRequired,
    onSelected: PropTypes.func.isRequired,
    options: PropTypes.array
}
 
export default SiigoDualListBox;