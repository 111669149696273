import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/indexHubspot';
import APIGeneral from '../services/api/indexGeneral';
import config from '../config.js';

export function* getLeads(action) {
  const api = API.create();
  try {
    const { params } = action;
    let leads = yield call(api.leads.getLeads, params);
    yield put({ type: types.LEADS_RECEIVED, data: leads });
  } catch (err) {
    yield put({ type: types.LEADS_REQUEST_FAILED, err });
  }
}


export function* sendExcelObject(action) {
	console.log("Excel");
	const api = APIGeneral.create(config.urlExcelApi);
	try {
		const { params } = action;
		console.log(params);
		console.log('params-saga::: ', params);
		let auth = yield call(api.accountant.authExcel, { username: 'admin', password: 'admin' });
		console.log("Auth data");
		console.log(auth);
		api.setHeader('authorization', auth.data.token);
		let excel = yield call(api.accountant.sendExcelObject, params);
		console.log("excel result");
		console.log(excel);
		yield put({ type: types.SEND_EXCEL_OBJECT_RECEIVED, data: excel });
	} catch (err) {
		yield put({ type: types.SEND_EXCEL_OBJECT_FAILED, err });
	}
}