import config from '../../../config';

export default function (api) {
  return {
    getNotifications: () =>
      api.get('getNotification', {
        code: config.cod_notificacion,
      }),
    createNotification: (params) =>
      api.get('createNotification', {
        ...params,
        code: config.cod_notificacion,
      }),
    editNotification: (params) =>
      api.get('editNotification', {
        ...params,
        code: config.cod_notificacion,
      }),
    deleteNotification: (id) =>
      api.get('deleteNotification', {
        id: id,
        code: config.cod_notificacion,
      }),
  };
}
