import * as types from '../index';

export const getRulesFive9 = (params) => {
  return { type: types.GET_FIVE9_RULES, params };
};

export const addRulesFive9 = (params) => {
  return { type: types.ADD_FIVE9_RULES, params };
};

export const editRulesFive9 = (params) => {
  return { type: types.EDIT_FIVE9_RULES, params };
};
export const deleteRulesFive9 = (params) => {
  return { type: types.DELETE_FIVE9_RULES, params };
};

export const setLog = (params) => {
  return { type: types.SET_LOG_FIVE9_RULES, params };
};

export const getCountryFive9 = (params) => {
  return { type: types.GET_FIVE9_COUNTRY, params };
};