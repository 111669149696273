import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import ModalResponse from '../layout/modals/modalResponse';

import * as ComponentActions from '../../actions/components/componentActions';
let update = false;
class ComponentEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onHandleEdit = event => {
    event.preventDefault();

    let name = event.target.name.value;
    let description = event.target.description.value;
    let icon = event.target.icon.value;
    let order = event.target.order.value;
    let path = event.target.path.value;
    let parent =
      event.target.parent.value === '-9' ? [] : event.target.parent.value;
    const data = {
      name,
      description,
      icon,
      order,
      path,
      parent
    };
    this.props.actions.updateComponent(data, this.props.id);
    update = true;
  };

  componentDidMount() {
    document.title = 'Edit Component';
    this.props.actions.getComponents();
    this.props.actions.getComponent(this.props.id);
  }

  render() {
    let name,
      description,
      icon,
      order,
      path,
      parent = null;
    const { isLoading, component, components } = this.props;
    let comps = [];
    let text = '';
    if (component) {
      if (component.data.error === 'Forbidden') {
        return <div>No tiene permiso para este componente</div>;
      }
      name = component.data.name;
      description = component.data.description;
      icon = component.data.icon;
      order = component.data.order;
      path = component.data.path;
      parent = component.data.parent;

      if (component.config.method === 'put' && update && !isLoading) {
        if (component.status === 200) {
          this.props.history.push('/components');
        } else if (update) {
          text = 'No fue posible guardar el Componente';
        }
        update = false;
      }
    }

    if (components) {
      comps = components.data;
    }

    return (
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {text !== '' ? (
              <ModalResponse title={'Editar!'} text={text} res={404} />
            ) : null}
            <form onSubmit={this.onHandleEdit}>
              <h3>Editar Componente</h3>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <input
                  type="text"
                  defaultValue={name}
                  name="name"
                  id="name"
                  placeholder="Nombre"
                />
              </div>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <input
                  type="text"
                  defaultValue={description}
                  name="description"
                  id="description"
                  placeholder="Descripcion"
                />
              </div>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <input
                  type="text"
                  defaultValue={icon}
                  name="icon"
                  id="icon"
                  placeholder="Icon"
                />
              </div>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <input
                  type="text"
                  defaultValue={order}
                  name="order"
                  id="order"
                  placeholder="Orden"
                />
              </div>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <input
                  type="text"
                  defaultValue={path}
                  name="path"
                  id="path"
                  placeholder="Path"
                />
              </div>
              <div className="input-container">
                <i className="sii sii-user icon" />
                <select name="parent" id="parent" defaultValue={parent}>
                  <option value="-9">Raiz</option>
                  {comps.map((comp, index) => {
                    return (
                      <option key={index} value={comp._id}>
                        {comp.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <button className="button green-fill">Guardar</button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

ComponentEdit.propTypes = {
  actions: PropTypes.object.isRequired,
  component: PropTypes.object,
  error: PropTypes.object
};

const mapStateToProps = state => ({
  component: state.components.component,
  components: state.components.components,
  error: state.components.err,
  isLoading: state.components.isLoadingComp,
  isLoadingUp: state.components.isLoadingUp
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ComponentActions, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ComponentEdit));
