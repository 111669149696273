import React from 'react'
import { SiigoAccordionMolecule } from "@siigo/siigo-accordion-molecule-react";
import { SiigoPanelAtom } from "@siigo/siigo-panel-atom-react";
import { SiigoInputAtom } from "@siigo/siigo-input-atom-react";

export const EditLabelsColumns = ({ jsonColumns, handlerChangeLabel, t }) => {
    return (
        <SiigoAccordionMolecule>
            <SiigoPanelAtom
                titlePanel="Editar Labels de Columnas"
                collapsible
            >
                <>
                    {
                        jsonColumns?.map((item, index) => (
                            <div key={`${item.value || item}`} className="row" style={{ marginBottom: "2%", marginRight: "6%", marginLeft: "3%" }}>
                                <div className="mt-3" style={{ flex: "42%" }} >
                                    <label style={{ color: "#737373" }}>{item.value || item}</label>
                                </div>
                                <div className="mt-3" style={{ flex: "55%" }}>
                                    <SiigoInputAtom
                                        style={{ width: '100%' }}
                                        align="left"
                                        errorMessage="Error."
                                        id={item.value || item}
                                        label={item.value || item}
                                        name={item.value || item}
                                        onchange={(e) => handlerChangeLabel(e, item, index)}
                                        type="text"
                                        required
                                        requiredMessage={t('dataSourceReport.errorValidation.requiredMessage')}
                                        value={(typeof item === "string" && item) || item.label}
                                    />
                                </div>

                            </div>
                        ))

                    }
                </>
            </SiigoPanelAtom>

        </SiigoAccordionMolecule>
    )
}
