import axios from 'axios';
import config from '../../../config';
import getEndPoint from '../../../utils/endpoints'

const sendMail = (mail) => {
  return axios.post(config.urlMailProvider + config.pathSendMail, mail).then((res) => res.data);
};

const resetPassword = async (options) => {

  return axios(options);
};

const authProvisioning = () => {
  return axios
  .get(config.urlProvisioning + "auth", { headers: { userdta: "stark:iidkju===dk0" } })
  .then((res) => res.data);
}

export default {
  sendMail,
  resetPassword,
};